// @flow

import {
  SHOW_SITE_DRAWER,
  HIDE_SITE_DRAWER,
  CHANGE_SITE_DRAWER_SECTION,
  FETCH_SELECTED_SITE,
  FETCH_SELECTED_SITE_SUCCESS,
  FETCH_SELECTED_SITE_ERROR,
  FETCH_SELECTED_SITE_PLACEMENTS,
  FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS,
  FETCH_SELECTED_SITE_PLACEMENTS_ERROR
} from '../actions/types';
import type { Site, Placement, DrawerNavigationItem } from '../schemas';
import navItems from '../config/organizationDrawerNavigationItems';

type State = {
  +isVisible: boolean,
  +selectedSite: ?Site,
  +selectedSitePlacements: ?Placement[],
  +currentSection: DrawerNavigationItem,
  +sections: DrawerNavigationItem[],
  +isLoading: boolean,
  +error: ?string
};

type Action = {
  type:
    | typeof SHOW_SITE_DRAWER
    | typeof HIDE_SITE_DRAWER
    | typeof CHANGE_SITE_DRAWER_SECTION
    | typeof FETCH_SELECTED_SITE
    | typeof FETCH_SELECTED_SITE_SUCCESS
    | typeof FETCH_SELECTED_SITE_ERROR
    | typeof FETCH_SELECTED_SITE_PLACEMENTS
    | typeof FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS
    | typeof FETCH_SELECTED_SITE_PLACEMENTS_ERROR,
  payload: Object
};

const initialState = {
  isVisible: false,
  selectedSite: undefined,
  selectedSitePlacements: [],
  currentSection: navItems[0],
  sections: navItems,
  isLoading: false,
  error: undefined
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case SHOW_SITE_DRAWER:
      return {
        ...state,
        isVisible: true,
        currentSection: initialState.currentSection
      };
    case HIDE_SITE_DRAWER:
      return initialState;
    case CHANGE_SITE_DRAWER_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    case FETCH_SELECTED_SITE:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SELECTED_SITE_SUCCESS:
      return {
        ...state,
        selectedSite: action.payload,
        isLoading: false
      };
    case FETCH_SELECTED_SITE_ERROR:
      return {
        ...state,
        selectedSite: initialState.selectedSite,
        isLoading: false,
        error: action.payload
      };

    case FETCH_SELECTED_SITE_PLACEMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS:
      return {
        ...state,
        selectedSitePlacements: action.payload,
        isLoading: false
      };
    case FETCH_SELECTED_SITE_PLACEMENTS_ERROR:
      return {
        ...state,
        selectedSitePlacements: initialState.selectedSitePlacements,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

// @flow

import _ from 'lodash';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Chart, PieSeries, Title, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import {
  Scale, Animation, EventTracker, HoverState, Palette
} from '@devexpress/dx-react-chart';

import { Paper, Divider, Typography } from '@material-ui/core';
import { ContractIcon } from '../Icons';

type Props = {
  classes: Object,
  data: any[],
};

type State = {
  chartData: any[],
  hover?: Object,
  hoverObject: Object,
  renderChartData: Function,
};

const scheme = ['#FF7043', '#FFCA28', '#9CCC65', '#42A5F5'];
const styles = theme => ({
  stats: {
    padding: theme.spacing(1) / 2,
    textAlign: 'center',
  }
});

class PlacementContractStatusChart extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      hover: undefined,
      hoverObject: {},
      renderChartData: this.renderChartData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { chartData } = state.renderChartData();

    if (state.chartData !== chartData) {
      return { chartData: chartData };
    }

    return null;
  }

  changeHover = hover => {
    const { chartData } = this.state;
    let hoverObject = {};

    if (hover) {
      const data = chartData[hover.point];
      switch (data.status) {
        case 'noSite':
          hoverObject.title = 'No Site';
          break;
        case 'noContract':
          hoverObject.title = 'No Contract';
          break;
        case 'requested':
          hoverObject.title = 'Requested';
          break;
        case 'inForce':
          hoverObject.title = 'In Force';
          break;
        default:
          hoverObject.title = '';
          break;
      }
      hoverObject.value = data.count;
    }

    this.setState({ hover, hoverObject });
  };

  renderChartData = () => {
    const { data } = this.props;
    const chartData = [];

    _.forEach(data, (value, key) => {
      chartData.push({
        status: key.charAt(0).toLowerCase() + key.slice(1),
        count: value,
      });
    });

    return { chartData: chartData };
  };

  render() {
    const { classes } = this.props;
    const { hover, chartData } = this.state;

    const Overlay = () => {
      const { hover, hoverObject } = this.state;
      if (hover) {
        return (
          <Typography variant="caption">
            {hoverObject.value} {hoverObject.title}
          </Typography>
        );
      }
      return null;
    };

    const percentageFor = field => {
      const { chartData } = this.state;
      let dividend = 0;
      let divisor = 0;
      divisor = _.reduce(chartData, function(acc, value) {
        return acc += value.count;
      }, 0);
      dividend = _.reduce(chartData, function(acc, value) {
        return acc += value.status === field ? value.count : 0;
      }, 0);

      return ((dividend / divisor) * 100).toFixed(2) + '%';
    };

    return (
      <Paper style={{ padding: 10 }}>
        {chartData.length > 0 && (<Chart data={chartData}>
          <Palette scheme={scheme} />
          <Title
            text="Placements Contract Status"
            style={{ textAlign: 'center', width: '100%' }}
          />
          <PieSeries
            valueField="count"
            argumentField="status"
          />
          <Animation />
          <Scale name="placements" />
          <EventTracker />
          <Tooltip contentComponent={Overlay} />
          <HoverState hover={hover} onHoverChange={this.changeHover} />
        </Chart>)}
        <Divider />
        <div className={classes.stats}>
          <ContractIcon /> {percentageFor('inForce')} of Placements have a contract In Force.
        </div>
      </Paper>
    );
  }
}

export default (withStyles(styles, { name: 'PlacementContractStatusChart', withTheme: true })(PlacementContractStatusChart): any);


import _ from 'lodash';

export const updateItem = (items, payload) => {
  return _.map(items, item => {
    if (item.id !== payload.id) return item;
    return { ...item, ...payload };
  });
};

export const deleteItem = (items, payload) => {
  const index = _.findIndex(items, item => item.id === payload.id);
  return [ ...items.slice(0, index), ...items.slice(index + 1) ];
};

export const createItem = (items, payload) => {
  return [ ...items, payload ];
};

export const valueItems = items => {
  if (items.length > 1) {
    return _.map(items, item => {
      return { id: item.id, name: item.name ? item.name : item.title };
    });
  }
  return { id: items.id, name: items.name ? items.name : items.title };
};

export const orderItems = (items, fields, directions) => {
  return _.orderBy(items, fields, directions);
};

export const filterItems = (items, options) => {
  return _.filter(items, options);
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Select, Checkbox } from '../Formik/';
import { TextField } from 'formik-material-ui';

import {
  FormControlLabel, Grid, Button, Typography,
} from '@material-ui/core';

import { organizationValidation } from '../../validation';

import { fetchStates } from '../../actions/creators/states';
import { fetchCountries } from '../../actions/creators/countries';

import type { ValueListItem } from '../../schemas/';

type Props = {
  classes: Object,
  initialValues: any[],
  stateOptions: ValueListItem[],
  countryOptions: ValueListItem[],
  canUpdate: boolean,
  canDelete: boolean,
  handleSubmit: Function,
  handleDelete: Function,
  fetchStates: Function,
  fetchCountries: Function,
};

const styles = theme => ({
  formSection: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  }
});

class OrganizationForm extends Component<Props> {

  componentDidMount() {
    this.props.fetchStates();
    this.props.fetchCountries();
  }

  render() {
    const { classes } = this.props;

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={organizationValidation}
        onSubmit={this.props.handleSubmit}
      >
        {({
          handleReset, touched, isSubmitting, isValid, values
        }) => (
          <Form style={{ width: '100%' }}>
            <Grid container className={classes.formSection}>
              <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
                Organization Information
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={9} sm={8} xs={12}>
                  <Field
                    fullWidth
                    required
                    name="name"
                    label="Name"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="abbreviation"
                    label="Abbreviation"
                    type="text"
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.formSection}>
              <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
                Contact Information
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={6} sm={8} xs={12}>
                  <Field
                    fullWidth
                    required
                    name="contact"
                    label="Contact"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={6} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="contactTitle"
                    label="Title"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <Field
                    fullWidth
                    name="contactEmail"
                    label="Email Address"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="fax"
                    label="Fax"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="website"
                    label="Website"
                    type="text"
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.formSection}>
              <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
                Address Information
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} sm={8} xs={12}>
                  <Field
                    fullWidth
                    required
                    name="address"
                    label="Address"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="addressLine2"
                    label="Address Line 2"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Field
                    fullWidth
                    name="addressLine3"
                    label="Address Line 3"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    required
                    name="city"
                    label="City"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    required={values.CountryId === 'US'}
                    name="StateId"
                    label="State"
                    options={this.props.stateOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    required={values.CountryId === 'US'}
                    name="postalCode"
                    label="Zip Code"
                    type="text"
                    component={TextField}
                  />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                  <Field
                    fullWidth
                    required
                    id="CountryId"
                    name="CountryId"
                    label="Country"
                    options={this.props.countryOptions}
                    component={Select}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.formSection}>
              <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
                Additional Information
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        checked={values.isActive}
                        name="isActive"
                        label="Active?"
                        component={Checkbox}
                      />
                    }
                    label="Active?"
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        checked={values.isDuke}
                        name="isDuke"
                        label="Duke?"
                        component={Checkbox}
                      />
                    }
                    label="Duke?"
                  />
                </Grid>
              </Grid>
            </Grid>

            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.props.canUpdate || isSubmitting || !isValid}
              >
                Save Organization
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.props.canDelete}
                onClick={this.props.handleDelete}
              >
                Delete Organization
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    stateOptions: state.states.values,
    countryOptions: state.countries.values
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchStates, fetchCountries
  }),
  withStyles(styles, { withTheme: true, name: 'OrganizationForm'}),
)(OrganizationForm): any);

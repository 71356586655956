import axios from '../../api';
import * as actions from '../types';

export const fetchStudents = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENTS, payload: undefined });
    const res = await axios.get('/student');
    dispatch({ type: actions.FETCH_STUDENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudentsList = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENTS, payload: undefined });
    const res = await axios.get('/student/list');
    dispatch({ type: actions.FETCH_STUDENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudentsForActiveTerm = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENTS, payload: undefined });
    const res = await axios.get('/student/activeTerm');
    dispatch({ type: actions.FETCH_STUDENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudentValues = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENT_VALUES, payload: undefined });
    const res = await axios.get('/student/values');
    dispatch({ type: actions.FETCH_STUDENT_VALUES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENT_VALUES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudent = student => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENT, payload: undefined });
    const res = await axios.get(`/student/${student.id}`);
    dispatch({ type: actions.FETCH_STUDENT_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENT_ERROR,
      payload: error.response.data
    });
  }
};

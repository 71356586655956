// @flow

import { createItem, updateItem, deleteItem } from './methods';
import * as actions from '../actions/types';
import type { Contract } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Contract[],
  +selectedItem: ?Contract
};

type Action = {
  type:
    | typeof actions.FETCH_CONTRACTS
    | typeof actions.FETCH_CONTRACTS_SUCCESS
    | typeof actions.FETCH_CONTRACTS_ERROR
    | typeof actions.FETCH_CONTRACT
    | typeof actions.FETCH_CONTRACT_SUCCESS
    | typeof actions.FETCH_CONTRACT_ERROR
    | typeof actions.CREATE_CONTRACT
    | typeof actions.CREATE_CONTRACT_SUCCESS
    | typeof actions.CREATE_CONTRACT_ERROR
    | typeof actions.UPDATE_CONTRACT
    | typeof actions.UPDATE_CONTRACT_SUCCESS
    | typeof actions.UPDATE_CONTRACT_ERROR
    | typeof actions.DELETE_CONTRACT
    | typeof actions.DELETE_CONTRACT_SUCCESS
    | typeof actions.DELETE_CONTRACT_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selectedItem: undefined
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case actions.FETCH_CONTRACTS:
      return {
        ...state,
        isLoading: true
      };
    case actions.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case actions.FETCH_CONTRACTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.FETCH_CONTRACT:
      return {...state,
        isLoading: true
      };
    case actions.FETCH_CONTRACT_SUCCESS:
      return {...state,
        isLoading: false,
        selectedItem: action.payload
      };
    case actions.FETCH_CONTRACT_ERROR:
      return {...state,
        isLoading: false,
        error: action.payload
      };

    case actions.CREATE_CONTRACT:
      return { ...state, isLoading: true };
    case actions.CREATE_CONTRACT_SUCCESS:
      return { ...state,
        isLoading: false,
        items: createItem(state.items, action.payload)
      };
    case actions.CREATE_CONTRACT_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case actions.UPDATE_CONTRACT:
      return { ...state, isLoading: true };
    case actions.UPDATE_CONTRACT_SUCCESS:
      return { ...state,
        isLoading: false,
        selectedItem: action.payload,
        items: updateItem(state.items, action.payload),
      };
    case actions.UPDATE_CONTRACT_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case actions.DELETE_CONTRACT:
      return { ...state, isLoading: true };
    case actions.DELETE_CONTRACT_SUCCESS:
      return { ...state,
        isLoading: false,
        items: deleteItem(state.items, action.payload)
      };
    case actions.DELETE_CONTRACT_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

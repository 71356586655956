// @flow

import {
  CLEAR_TOUCHPOINTS,
  FETCH_TOUCHPOINTS,
  FETCH_TOUCHPOINTS_SUCCESS,
  FETCH_TOUCHPOINTS_ERROR,
  FETCH_TOUCHPOINT,
  FETCH_TOUCHPOINT_SUCCESS,
  FETCH_TOUCHPOINT_ERROR
} from '../actions/types';

import { valueItems } from './methods';
import type { Touchpoint } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Touchpoint>,
  +values: any[],
  +selected: Object
};

type Action = {
  type:
    | typeof CLEAR_TOUCHPOINTS
    | typeof FETCH_TOUCHPOINTS
    | typeof FETCH_TOUCHPOINTS_SUCCESS
    | typeof FETCH_TOUCHPOINTS_ERROR
    | typeof FETCH_TOUCHPOINT
    | typeof FETCH_TOUCHPOINT_SUCCESS
    | typeof FETCH_TOUCHPOINT_ERROR,
  payload: Array<Touchpoint> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_TOUCHPOINTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TOUCHPOINTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: valueItems(action.payload)
      };
    case FETCH_TOUCHPOINTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_TOUCHPOINT:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TOUCHPOINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_TOUCHPOINT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CLEAR_TOUCHPOINTS:
      return initialState;
    default:
      return state;
  }
};

// @flow

import _ from 'lodash';

import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

import {fetchContract} from '../../../actions/creators/contracts';

import {fetchContractStatusCodes} from '../../../actions/creators/contractStatusCodes';
import {fetchContractTypes} from '../../../actions/creators/contractTypes';
import {fetchTerms} from '../../../actions/creators/terms';
import {fetchUsers} from '../../../actions/creators/users';
import {fetchStudentsList} from '../../../actions/creators/students';

import {getSelectedContract} from '../../../selectors/contracts';

import ContractDetail from '../../Contract/ContractDetail';

type Props = {
  id: number,
  model: string,
  handleClose: Function
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ContractDialog = (props: Props): React$Element<React$FragmentType> => {
  const {id,model,handleClose} = props;
  const dispatch = useDispatch();

  const contract = useSelector(getSelectedContract);
  const contractStatusCodes = useSelector(state => state.contractStatusCodes.items);
  const contractTypes = useSelector(state => state.contractTypes.items);
  const terms = useSelector(state => state.terms.items);
  const users = useSelector(state => state.users.items);
  const students = useSelector(state => state.students.items);

  let haveAssociations = Boolean(
    contractStatusCodes.length && contractTypes.length && terms.length
    && users.length && students.length
  );

  useEffect(() => {
    dispatch(fetchContract({ id: id }));
  }, [dispatch,id]);

  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchContractStatusCodes());
      dispatch(fetchContractTypes());
      dispatch(fetchTerms());
      dispatch(fetchUsers());
      dispatch(fetchStudentsList());
    }
  }, [dispatch,haveAssociations]);

  const objectMatch = _.get(contract, 'id', undefined) === id;

  return (
    <React.Fragment>
      {(!objectMatch || !haveAssociations) &&  <DialogTitle id={`${model}-${id}-dialog`}>
        <LinearProgress variant="query" color="primary" />
      </DialogTitle>}
      {objectMatch && contract && haveAssociations && <DialogTitle id={`${model}-${id}-dialog`} onClose={handleClose}>
        Contract Details
      </DialogTitle>}
      {objectMatch && haveAssociations && <DialogContent dividers>
        {<ContractDetail contract={contract} hideContractModal={handleClose} />}
      </DialogContent>}
    </React.Fragment>
  );
};

export default ContractDialog;

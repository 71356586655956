import * as actions from '../types';
import axios from '../../api';

export const showSiteDrawer = () => async dispatch => {
  dispatch({ type: actions.SHOW_SITE_DRAWER });
};

export const hideSiteDrawer = () => {
  return { type: actions.HIDE_SITE_DRAWER };
};

export const changeSection = section => {
  return { type: actions.CHANGE_SITE_DRAWER_SECTION, payload: section };
};

export const fetchSelectedSite = site => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_SITE });
    const res = await axios.get(`/site/${site.id}`);
    dispatch({
      type: actions.FETCH_SELECTED_SITE_SUCCESS,
      payload: res.data
    });
    dispatch(fetchSelectedSitePlacements(site));
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_SITE_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSelectedSitePlacements = site => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_SITE_PLACEMENTS });
    const res = await axios.get(`/site/${site.id}/placement`);
    dispatch({
      type: actions.FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_SITE_PLACEMENTS_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import {
  DashboardIcon,
  LegalIcon,
  ContractIcon,
  AddendaIcon,
  AmendmentIcon,
  AdminstrationIcon,
  UsersIcon,
  RolesIcon,
  ManagementIcon,
  SpecialtiesIcon,
  OrganizationIcon,
  StashIcon,
  StudentIcon,
  SiteIcon,
  PlacementIcon,
  CourseIcon,
  ManagePlacementsIcon,
  StyleGuideIcon,
  TermIcon,
  PreceptorIcon,
  ResourcesIcon,
  ApplicationIcon,
  GlobalStashIcon,
  AddToStashIcon,
  DeleteIcon,
  CreateIcon,
  UpdateIcon,
  ReadIcon,
  SaveIcon,
  AddSpecialtyIcon,
  HoursIcon,
  AttachmentAIcon,
  ExpandIcon,
  MergeIcon,
  MenuIcon,
  VerifiedUserIcon,
  WorkflowIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  FirstPageIcon,
  LastPageIcon,
  AddIcon,
  RemoveIcon,
  CloseIcon,
  ObjectIcon,
  SuccessIcon,
  ErrorIcon,
  DragHandleIcon,
  NearMeIcon,
  RevertIcon,
  NotificationsIcon,
  SettingsIcon,
  SearchIcon,
  CheckBox,
  CheckBoxBlank,
  FilterListIcon,
  MasqueradeIcon,
  AnonymousAvatarIcon,
  AttachmentIcon
} from '../../components/Icons';

type Props = {
  classes: Object
};

export default function IconUsage(props: Props): React$Element<"div"> {
  return (
    <div>
      <h2>Icon Usage</h2>
      <h3>Application Icons</h3>
      <Typography variant="body1">
        Application icon components are defined in{' '}
        <em>&quot;/src/components/Icons&quot;</em>. If you need a new icon, add
        it here, and then import it. This will help to keep things clean. These
        are simply material-ui icons that have been renamed for their intended
        use.
      </Typography>
      <Paper className={props.classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Entities</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Here we are referring to the <em>things</em> that the application
              manages, like Sites and Preceptors.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DashboardIcon /> DashboardIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LegalIcon /> LegalIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ContractIcon /> ContractIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AddendaIcon /> AddendaIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AmendmentIcon /> AmendmentIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AdminstrationIcon /> AdminstrationIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <UsersIcon /> UsersIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RolesIcon /> RolesIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ManagementIcon /> ManagementIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SpecialtiesIcon /> SpecialtiesIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <OrganizationIcon /> OrganizationIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <StashIcon /> StashIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <StudentIcon /> StudentIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SiteIcon /> SiteIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <PlacementIcon /> PlacementIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CourseIcon /> CourseIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ManagePlacementsIcon /> ManagePlacementsIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <StyleGuideIcon /> StyleGuideIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TermIcon /> TermIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <PreceptorIcon /> PreceptorIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ResourcesIcon /> ResourcesIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ApplicationIcon /> ApplicationIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <GlobalStashIcon /> GlobalStashIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <HoursIcon /> HoursIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AttachmentAIcon /> AttachmentAIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SettingsIcon /> SettingsIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <WorkflowIcon /> WorkflowIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <NotificationsIcon /> NotificationsIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AttachmentIcon /> AttachmentIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ObjectIcon /> ObjectIcon
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Actions</Typography>
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <UpdateIcon /> CreateIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <ReadIcon /> ReadIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <CreateIcon /> UpdateIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <DeleteIcon /> DeleteIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SaveIcon /> SaveIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MergeIcon /> MergeIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AddIcon /> AddIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RemoveIcon /> RemoveIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RevertIcon /> RevertIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CloseIcon /> CloseIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MasqueradeIcon /> MasqueradeIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AddSpecialtyIcon /> AddSpecialtyIcon
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AddToStashIcon /> AddToStashIcon
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">UI Elements</Typography>
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <ExpandIcon /> ExpandIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <MenuIcon /> MenuIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <VerifiedUserIcon /> VerifiedUserIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <DragHandleIcon /> DragHandleIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <SearchIcon /> SearchIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <CheckBox /> CheckBox
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <CheckBoxBlank /> CheckBoxBlank
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <FilterListIcon /> FilterListIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <AnonymousAvatarIcon /> AnonymousAvatarIcon
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Pagination</Typography>
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <KeyboardArrowLeftIcon /> KeyboardArrowLeftIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <KeyboardArrowRightIcon /> KeyboardArrowRightIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <FirstPageIcon /> FirstPageIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <LastPageIcon /> LastPageIcon
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Status</Typography>
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <SuccessIcon /> SuccessIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <ErrorIcon /> ErrorIcon
          </Grid>
          <Grid item md={6} xs={12} lg={3}>
            <NearMeIcon /> NearMeIcon
          </Grid>

        </Grid>
      </Paper>
    </div>
  );
}

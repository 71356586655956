// @flow

import {
  LOAD_APPINFO,
  LOAD_APPINFO_SUCCESS,
  LOAD_APPINFO_ERROR
} from '../actions/types';

import type { AppInfo } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +name: string,
  +description: string,
  +version: string,
  +mode: string
};

type Action = {
  type:
    | typeof LOAD_APPINFO
    | typeof LOAD_APPINFO_SUCCESS
    | typeof LOAD_APPINFO_ERROR,
  payload: AppInfo | Object
};

const initialState = {
  isLoading: false,
  error: '',
  name: '',
  description: '',
  version: '',
  mode: ''
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case LOAD_APPINFO:
      return {
        ...state,
        isLoading: true
      };
    case LOAD_APPINFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case LOAD_APPINFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

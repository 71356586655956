// @flow

import * as types from '../actions/types';
import { orderItems } from './methods';

type State = {
  +isLoading: boolean,
  +error: string,
  +overview: Object,
  +statuses: any[],
  +coordinators: any[],
  +contracts: Object,
};

type Action = {
  type:
    | typeof types.FETCH_DESKTOP_OVERVIEW
    | typeof types.FETCH_DESKTOP_OVERVIEW_SUCCESS
    | typeof types.FETCH_DESKTOP_OVERVIEW_ERROR
    | typeof types.FETCH_DESKTOP_STATUS
    | typeof types.FETCH_DESKTOP_STATUS_SUCCESS
    | typeof types.FETCH_DESKTOP_STATUS_ERROR
    | typeof types.FETCH_DESKTOP_COORDINATOR
    | typeof types.FETCH_DESKTOP_COORDINATOR_SUCCESS
    | typeof types.FETCH_DESKTOP_COORDINATOR_ERROR
    | typeof types.FETCH_DESKTOP_CONTRACT
    | typeof types.FETCH_DESKTOP_CONTRACT_SUCCESS
    | typeof types.FETCH_DESKTOP_CONTRACT_ERROR,
  payload: any[]
};

const initialState = {
  isLoading: false,
  error: '',
  statuses: [],
  coordinators: [],
  contracts: {},
  overview: {},
};

export default (state: State = initialState, action: Action): Function => {
  const { payload } = action;

  switch (action.type) {

    case types.FETCH_DESKTOP_OVERVIEW:
      return { ...state, isLoading: true };
    case types.FETCH_DESKTOP_OVERVIEW_SUCCESS:
      return { ...state, isLoading: false, overview: payload };
    case types.FETCH_DESKTOP_OVERVIEW_ERROR:
      return { ...state, isLoading: false, error: payload };

    case types.FETCH_DESKTOP_STATUS:
      return { ...state, isLoading: true };
    case types.FETCH_DESKTOP_STATUS_SUCCESS:
      return { ...state,
        isLoading: false,
        statuses: orderItems(payload, ['order'], ['asc']),
      };
    case types.FETCH_DESKTOP_STATUS_ERROR:
      return { ...state, isLoading: false, error: payload };

    case types.FETCH_DESKTOP_COORDINATOR:
      return { ...state, isLoading: true };
    case types.FETCH_DESKTOP_COORDINATOR_SUCCESS:
      return { ...state,
        isLoading: false,
        coordinators: orderItems(payload, ['name'], ['asc']),
      };
    case types.FETCH_DESKTOP_COORDINATOR_ERROR:
      return { ...state, isLoading: false, error: payload };

    case types.FETCH_DESKTOP_CONTRACT:
      return { ...state, isLoading: true };
    case types.FETCH_DESKTOP_CONTRACT_SUCCESS:
      return { ...state, isLoading: false, contracts: payload };
    case types.FETCH_DESKTOP_CONTRACT_ERROR:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;

  }
};

// @flow

import _ from 'lodash';
import type { Permissions, MenuItem } from '../schemas';
import {
  DashboardIcon,
  LegalIcon,
  ContractIcon,
  AmendmentIcon,
  AdminstrationIcon,
  UsersIcon,
  RolesIcon,
  ManagementIcon,
  SpecialtiesIcon,
  OrganizationIcon,
  GlobalStashIcon,
  SiteIcon,
  PlacementIcon,
  ManagePlacementsIcon,
  StyleGuideIcon,
  TermIcon,
  PreceptorIcon,
  ResourcesIcon,
  ApplicationIcon,
  AttachmentAIcon,
  MergeIcon,
  ReportIcon,
  LocationsIcon,
  CourseIcon,
} from '../components/Icons';

const MenuItems = (permissions: Permissions): MenuItem[] => {
  const allRoles = ['*'];
  const accessContracts = permissions.contract ? permissions.contract.read : [];
  const accessAdministration = permissions.user ? permissions.user.create : [];
  const accessManagement =
    permissions.placement && permissions.specialty && permissions.term && permissions.organization
      ? _.concat(
        permissions.placement.super,
        permissions.specialty.create,
        permissions.term.create,
        permissions.organization.merge
      )
      : [];
  return [
    {
      label: 'Dashboard',
      icon: DashboardIcon,
      url: '/dashboard',
      allowedRoles: allRoles
    },
    {
      label: 'Placements',
      icon: PlacementIcon,
      url: '/placements',
      allowedRoles: permissions.placement ? permissions.placement.read : []
    },
    {
      label: 'Legal',
      icon: LegalIcon,
      url: '#',
      allowedRoles: accessContracts,
      Children: [
        {
          label: 'Contracts',
          icon: ContractIcon,
          url: '/contracts',
          allowedRoles: accessContracts
        },
        {
          label: 'Amendments',
          icon: AmendmentIcon,
          url: '/contracts/amendments',
          allowedRoles: accessContracts
        },
        {
          label: 'Attachments A',
          icon: AttachmentAIcon,
          url: '/contracts/attachmentAs',
          allowedRoles: accessContracts
        }
      ]
    },
    {
      label: 'Resources',
      icon: ResourcesIcon,
      url: '#',
      allowedRoles: accessContracts,
      Children: [
        {
          label: 'Organizations',
          icon: OrganizationIcon,
          url: '/organizations',
          allowedRoles: permissions.organization ? permissions.organization.read : []
        },
        {
          label: 'Sites',
          icon: SiteIcon,
          url: '/sites',
          allowedRoles: permissions.site ? permissions.site.read : []
        },
        {
          label: 'Preceptors',
          icon: PreceptorIcon,
          url: '/preceptors',
          allowedRoles: ['*']
        }
      ]
    },
    {
      label: 'Global Stash',
      icon: GlobalStashIcon,
      url: '/stash',
      allowedRoles: permissions.site ? permissions.site.read : []
    },
    {
      // Student Placements
      label: 'My Placements',
      icon: PlacementIcon,
      url: '/student/placements',
      allowedRoles: permissions.student ? permissions.student.placement.read : []
    },
    {
      // Student Placement Applications
      label: 'My Applications',
      icon: ApplicationIcon,
      url: '/student/applications',
      allowedRoles: permissions.student ? permissions.student.placementApp.create : []
    },
    {
      label: 'Administration',
      icon: AdminstrationIcon,
      url: '#',
      allowedRoles: accessAdministration,
      Children: [
        {
          label: 'Users',
          icon: UsersIcon,
          url: '/users',
          allowedRoles: accessAdministration
        },
        {
          label: 'Roles',
          icon: RolesIcon,
          url: '/roles',
          allowedRoles: accessAdministration
        }
      ]
    },
    {
      label: 'Management',
      icon: ManagementIcon,
      url: '#',
      allowedRoles: accessManagement,
      Children: [
        {
          label: 'Manage Placements',
          icon: ManagePlacementsIcon,
          url: '/placements/manage',
          allowedRoles: permissions.placement ? permissions.placement.super : []
        },
        {
          label: 'Merge Placements',
          icon: MergeIcon,
          url: '/placements/merge',
          allowedRoles: permissions.placement ? permissions.placement.merge: []
        },
        {
          label: 'Merge Organizations',
          icon: MergeIcon,
          url: '/organizations/merge',
          allowedRoles: permissions.organization ? permissions.organization.merge: []
        },
        {
          label: 'Merge Preceptors',
          icon: MergeIcon,
          url: '/preceptors/merge',
          allowedRoles: permissions.preceptor ? permissions.preceptor.merge: []
        },
        {
          label: 'Geocode Errors',
          icon: LocationsIcon,
          url: '/locations',
          allowedRoles: permissions.location ? permissions.location.update : []
        },
        {
          label: 'Specialties',
          icon: SpecialtiesIcon,
          url: '/specialties',
          allowedRoles: permissions.specialty
            ? permissions.specialty.create
            : []
        },
        {
          label: 'Terms',
          icon: TermIcon,
          url: '/terms',
          allowedRoles: permissions.term ? permissions.term.update : []
        },
        {
          label: 'Courses',
          icon: CourseIcon,
          url: '/courses',
          allowedRoles: permissions.course ? permissions.course.update : []
        }
      ]
    },
    {
      label: 'Reports',
      icon: ReportIcon,
      url: '#',
      allowedRoles: accessManagement,
      Children: [
        {
          label: 'Placements',
          icon: PlacementIcon,
          url: '/reports/placements',
          allowedRoles: permissions.placement ? permissions.placement.super : []
        },
        {
          label: 'Placement Audit',
          icon: PlacementIcon,
          url: '/reports/placementAudit',
          allowedRoles: permissions.placement ? permissions.placement.super : []
        }
      ]
    },
    {
      label: 'Style Guide',
      icon: StyleGuideIcon,
      url: '/styleguide',
      allowedRoles: ['system_admin']
    }
  ];
};

export default MenuItems;

// @flow

import _ from 'lodash';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {
  Grid,Chip,IconButton,Tooltip,ListItem,Card,CardHeader,CardContent,Typography
} from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PersonIcon from '@material-ui/icons/Person';

import {
  DeleteIcon,AddToStashIcon,OrganizationIcon,ReleaseSlotIcon,ClaimSlotIcon
} from '../../Icons';

import {
  deletePlacementSlot,stashPlacementSlot,attachPlacementSlot,updatePlacementSlot
} from '../../../actions/creators/placementSlots';

import {getSelectedPlacement} from '../../../selectors/placementDrawer';
import {getCurrentUser} from '../../../selectors/users';
import {getAllAttachmentAs} from '../../../selectors/attachmentAs';

import {hoursForSiteEnrollment} from '../../../utils/placementHelpers';

import SiteEnrollmentDetail from '../../../components/SiteEnrollment/SiteEnrollmentDetail';
import PreceptorEnrollmentsList from './PreceptorEnrollmentsList';
import TouchpointDialog from '../../Touchpoint';
import DeepLink from '../../DeepLink';

import type {SiteEnrollment} from '../../../schemas';

type Props = {
  item: SiteEnrollment,
  editable: boolean,
  displayOnly: boolean,
};

const useStyles = makeStyles(theme => {
  return {
    buttonsContainer: {
      marginTop: theme.spacing(1)
    },
    button: {
      marginBottom: theme.spacing(2)
    },
    container: {
      marginBottom: theme.spacing(2)
    },
    hoursChip: {
      marginLeft: theme.spacing(2)
    },
    orgIcon: {
      fontSize: 'inherit'
    }
  };
});

function PlacementSlotListItem(props: Props): React$Element<"div"> {
  const {item,editable,displayOnly} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [visibleDialog, setVisibleDialog] = React.useState(false);

  const currentUser = useSelector(getCurrentUser);
  const selectedPlacement = useSelector(getSelectedPlacement);
  const attachmentAs = useSelector(getAllAttachmentAs);

  function handleDeleteClick() {
    const confirmation = window.confirm(
      `Are you sure you want to delete this placement slot?`
    );
    if (confirmation)
      dispatch(deletePlacementSlot(item,selectedPlacement));
  }

  function handleStashClick() {
    const confirmation = window.confirm(
      `Are you sure you want to stash this placement slot?`
    );
    if (confirmation)
      dispatch(stashPlacementSlot(item, selectedPlacement));
  }

  function handleAddClick() {
    dispatch(attachPlacementSlot(item, selectedPlacement));
  }

  function handleDialogOpen() {
    setVisibleDialog(true);
  }

  function handleDialogClose() {
    setVisibleDialog(false);
  }

  function handleSlotUpdate(action) {
    if (action === 'claim') item.ManagerId = currentUser.username;
    else if (action === 'release') item.ManagerId = null;
    dispatch(updatePlacementSlot(item));
  }

  function handleFetchSelectedPlacement() {
    const {
      selectedPlacement,fetchSelectedPlacement,showPlacementDrawer
    } = this.props;
    if (selectedPlacement)
      dispatch(fetchSelectedPlacement(selectedPlacement.id,false))
        .then(() => dispatch(showPlacementDrawer()));
  }

  function renderDialog() {
    if (visibleDialog) return (
      <TouchpointDialog
        open={true}
        handleClose={handleDialogClose}
        data={{
          TouchableId: item.SiteId,
          name: item.Site.name,
          model: 'site'
        }}
      />
    );
  }

  function renderHeaderActions() {
    const isStashed = (item.PackageId === null);
    const isOwner = (currentUser.username === item.ManagerId);
    const canClaim = (!isOwner && item.ManagerId === null);
    const managerName = _.get(item, 'Manager.name', 'N/A');

    return (
      <div>
        {editable && (
          <Tooltip title="Site Touchpoints">
            <IconButton
              className={classes.button}
              color="primary"
              onClick={handleDialogOpen}
            >
              <ContactPhoneIcon />
            </IconButton>
          </Tooltip>
        )}
        {editable && (
          <Tooltip title="Stash Placement Slot">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={handleStashClick}
            >
              <AddToStashIcon />
            </IconButton>
          </Tooltip>
        )}
        {!displayOnly && isStashed && (
          <Tooltip title="Add to Placement">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={handleAddClick}
            >
              <PlaylistAddIcon />
            </IconButton>
          </Tooltip>
        )}
        {!displayOnly && editable && (
          <Tooltip title="Delete Placement Slot">
            <IconButton
              color="secondary"
              className={classes.button}
              onClick={handleDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {isStashed && isOwner && (
          <Tooltip title="Release Placement Slot">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => handleSlotUpdate('release')}
            >
              <ReleaseSlotIcon />
            </IconButton>
          </Tooltip>
        )}
        {isStashed && canClaim && (
          <Tooltip title="Claim Placement Slot">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => handleSlotUpdate('claim')}
            >
              <ClaimSlotIcon />
            </IconButton>
          </Tooltip>
        )}
        {isStashed && !canClaim && !isOwner && (
          <Tooltip title={managerName}>
            <IconButton color="primary" disableRipple className={classes.button}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  }

  function renderCardTitle() {
    const siteHours = hoursForSiteEnrollment(item);
    return (
      <div>
        <DeepLink
          id={item.Site.id}
          model="site"
          afterClose={handleFetchSelectedPlacement}
        >
          <Typography variant="h6" component="span">
            {item.Site.name}
          </Typography>
        </DeepLink>
        <Chip label={siteHours + ' hrs'} className={classes.hoursChip} />
      </div>
    );
  }

  return (
    <div>
      <ListItem className={classes.container}>
        <Card style={{ width: '100%' }}>
          <CardHeader
            title={renderCardTitle()}
            subheader={
              <DeepLink
                id={item.Site.Organization.id}
                model="organization"
                afterClose={handleFetchSelectedPlacement}
              >
                <OrganizationIcon className={classes.orgIcon} />
                &nbsp;
                {item.Site.Organization.name}
              </DeepLink>
            }
            action={renderHeaderActions()}
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <SiteEnrollmentDetail
                  siteEnrollment={item}
                  placement={selectedPlacement}
                  editable={editable}
                  displayOnly={displayOnly}
                  attachmentAs={attachmentAs}
                />
              </Grid>
              <Grid item xs={12}>
                <PreceptorEnrollmentsList
                  items={item.PreceptorEnrollments}
                  siteEnrollment={item}
                  editable={editable}
                  displayOnly={displayOnly}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ListItem>
      {renderDialog()}
    </div>
  );
}

export default PlacementSlotListItem;

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Paper, Drawer, Typography, TableCell, Select, Button
} from '@material-ui/core';
import { UpdateIcon, CreateIcon, CloseIcon } from '../../components/Icons';
import CommonStyles from '../../styles/CommonStyles';

import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting, IntegratedSelection, SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import LoadingIndicator from '../../components/LoadingIndicator';

import {
  fetchStudentPlacements, fetchStudentPlacement, updateStudentPlacement
} from '../../actions/creators/studentPlacements';
import {
  fetchApplication, deleteApplication
} from '../../actions/creators/studentApplications';
import { fetchUsers } from '../../actions/creators/users';
import { fetchStudent } from '../../actions/creators/students';
import { fetchTerms } from '../../actions/creators/terms';
import { createUserMessage } from '../../actions/creators/userMessages';
import { fetchPermissions } from '../../actions/creators/permissions';
import { hasAccess } from '../../utils/permissionsCheck';
import ApplicationDetail from '../../components/Students/ApplicationDetail';

import { getActiveTerm } from '../../selectors/terms';

import type { Student, User, Placement, PlacementApp } from '../../schemas';

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      padding: theme.spacing(2)
    },
    flex: { flex: 1 },
    cell: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    cellCenter: {
      width: '100%',
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    input: { width: '100%' },
    icon: { margin: theme.spacing(1) },
    leftIcon: { marginRight: theme.spacing(1) },
    rightIcon: { marginLeft: theme.spacing(1) },
    iconSmall: { fontSize: 20 },
    chip: { margin: theme.spacing(1) },
    chipWarn: {
      margin: theme.spacing(1),
      backgroundColor: commonStyles.warn.backgroundColor,
      color: commonStyles.warn.color
    },
    chipRow: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  };
  return classes;
};

type Props = {
  classes: Object,
  colSpan: number,
  users: User[],
  currentUser: User,
  currentStudent: Student,
  permissions: Object,
  studentPlacements: Placement[],
  dataLoading: boolean,
  selectedApplication: ?PlacementApp,
  fetchUsers: Function,
  fetchStudent: Function,
  fetchTerms: Function,
  fetchPermissions: Function,
  fetchStudentPlacements: Function,
  fetchStudentPlacement: Function,
  fetchApplication: Function,
  deleteApplication: Function,
  updateStudentPlacement: Function,
  createUserMessage: Function,
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  sortingStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedApplication: ?PlacementApp,
  selectedPlacement: ?Placement,
  rows: Placement[],
  loading: boolean
};

class StudentPlacementsPage extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: 'CourseId',
          title: 'Course ID',
          getCellValue: row =>
            row.Course ? `N.${row.Course.CatalogNumberId}` : 'N/A'
        },
        {
          name: 'Course',
          title: 'Course Name',
          getCellValue: row => (row.Course ? `${row.Course.description}` : 'N/A')
        },
        {
          name: 'Term',
          title: 'Term',
          getCellValue: row => (row.Term ? row.Term.name : 'N/A')
        },
        {
          name: 'PlacementApp',
          title: 'Create or Update Application',
          getCellValue: row => (row.PlacementApp ? true : false)
        },
        {
          name: 'CancelPlacements',
          title: 'Cancel Placement',
          getCellValue: row => row.shouldDelete
        }
      ],
      defaultFilters: [],
      defaultCurrentPage: 0,
      defaultPageSize: 10,
      defaultSorting: [
        { columnName: 'Term', direction: 'desc' }
      ],
      pageSizes: [10, 20, 50, 100, 250],
      filteringStateColumnExtensions: [
        { columnName: 'PlacementApp', filteringEnabled: false },
        { columnName: 'CancelPlacements', filteringEnabled: false }
      ],
      sortingStateColumnExtensions: [
        { columnName: 'PlacementApp', sortingEnabled: false },
        { columnName: 'CancelPlacements', sortingEnabled: false }
      ],
      selection: [],
      selectedApplication: undefined,
      selectedPlacement: undefined,
      showDrawer: false,
      rows: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.studentPlacements !== state.rows) {
      return { rows: props.studentPlacements };
    }
    if (props.dataLoading !== state.loading) {
      return { loading: props.dataLoading };
    }

    return null;
  }

  loadData = async () => {
    const studentId = _.get(this.props.currentUser, 'StudentId', null);

    await this.props.fetchTerms();
    await this.props.fetchUsers();
    await this.props.fetchPermissions();
    if (studentId) {
      await this.props.fetchStudent({ id: studentId });
      await this.props.fetchStudentPlacements({ id: studentId });
    }
  };

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.create);
  }

  canDelete() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  }

  handleClose = () => {
    this.setState({
      selection: [],
      selectedApplication: undefined,
      showDrawer: false
    });
  };

  applicationUpdate = async props => {
    const { fetchApplication, fetchStudentPlacement } = this.props;
    await fetchApplication({ id: props.row.PlacementApp.id });
    await fetchStudentPlacement({ id: props.row.id });
    await this.setState({
      selectedApplication: this.props.selectedApplication,
      selectedPlacement: props.row,
      showDrawer: true
    });
  };

  applicationCreate = async props => {
    await fetchStudentPlacement({ id: props.row.id });
    await this.setState({
      selectedApplication: undefined,
      selectedPlacement: props.row,
      showDrawer: true
    });
  };

  placementCancelRequest = async props => {
    const { currentUser, updateStudentPlacement, createUserMessage } = this.props;
    const confirmation = window.confirm(
      `Submit a Placement Cancelation Request for this placement?`
    );
    if (confirmation) {
      props.row.shouldDelete = true;
      await updateStudentPlacement(props.row);
      const message = `${currentUser.name} has submitted a Placement Cancelation Request.`;
      if (props.row.managerId) {
        const user = this.getUserForUsername(props.row.managerId);
        await createUserMessage({
          message: message,
          tag: 'placement',
          RecipientId: user.id,
          SenderId: currentUser.id
        });
      } else {
        const users = this.getUsersForRole('cpo_manager');
        _.each(users, async user => {
          await createUserMessage({
            message: message,
            tag: 'placement',
            RecipientId: user.id,
            SenderId: currentUser.id
          });
        });
      }
    }
  };

  getUsersForRole = slug => {
    return _.filter(this.props.users, user => {
      const roles = _.map(user.Roles, 'slug');
      return _.includes(roles, slug);
    });
  };

  getUserForUsername = username => {
    return _.find(this.props.users, { username: username });
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      defaultFilters,
      defaultCurrentPage,
      defaultPageSize,
      defaultSorting,
      pageSizes,
      filteringStateColumnExtensions,
      sortingStateColumnExtensions,
      selectedApplication,
      selectedPlacement,
      showDrawer,
      rows,
      loading
    } = this.state;
    const {
      applicationCreate,
      applicationUpdate,
      placementCancelRequest
    } = this;

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>
        </TableCell>
      );
    }

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'hasPlacement') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'PlacementApp') {
        return (
          <Table.Cell>
            {props.value ? (
              <Button
                color="primary"
                size="small"
                fullWidth={true}
                onClick={() => applicationUpdate(props)}
              >
                Update Application
                <UpdateIcon className={classNames(classes.rightIcon, classes.iconSmall)} />
              </Button>
            ) : (
              <Button
                color="primary"
                size="small"
                fullWidth={true}
                onClick={() => applicationCreate(props)}
              >
                Create Application
                <CreateIcon className={classNames(classes.rightIcon, classes.iconSmall)} />
              </Button>
            )}
          </Table.Cell>
        );
      }
      if (props.column.name === 'CancelPlacements') {
        return (
          <Table.Cell>
            <Button
              disabled={props.value}
              color="secondary"
              size="small"
              fullWidth={true}
              onClick={e => {
                placementCancelRequest(props);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Cancel Placement
              <CloseIcon className={classNames(classes.rightIcon, classes.iconSmall)} />
            </Button>
          </Table.Cell>
        );
      }

      return <Table.Cell {...props} />;
    }

    function getRowId(row) {
      return row.id;
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          Placements
        </Typography>
        <Paper>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState
              defaultSorting={defaultSorting}
              columnExtensions={sortingStateColumnExtensions}

            />
            <SelectionState />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table
              cellComponent={props => Cell(props)}
              noDataCellComponent={props => NoDataCell(props)}
            />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => FilterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer open={showDrawer} anchor="bottom" onClose={this.handleClose}>
          <ApplicationDetail
            handleClose={this.handleClose}
            selectedApplication={selectedApplication}
            selectedPlacement={selectedPlacement}
            canUpdate={this.canUpdate()}
            canDelete={this.canDelete()}
            currentUser={this.props.currentUser}
            currentStudent={this.props.currentStudent}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    activeTerm: getActiveTerm(state),
    currentUser: state.auth.currentUser,
    currentStudent: state.students.selected,
    permissions: state.permissions.placementApp,
    studentPlacements: state.studentPlacements.items,
    selectedPlacement: state.studentPlacements.selected,
    selectedApplication: state.studentApplications.selected,
    dataLoading: state.studentPlacements.isLoading,
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchUsers, fetchStudent, fetchTerms, fetchPermissions,
    fetchStudentPlacements, fetchStudentPlacement, fetchApplication,
    deleteApplication, updateStudentPlacement, createUserMessage
  })
)(StudentPlacementsPage): any);

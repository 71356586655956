// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {List} from '@material-ui/core';

import PlacementSlotListItem from './PlacementSlotListItem';

import type {SiteEnrollment} from '../../../schemas';

type Props = {
  items: SiteEnrollment[],
  editable: boolean,
  displayOnly?: boolean
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      flex: 1,
      marginTop: theme.spacing(2)
    },
    noSite: {
      flex: 1,
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3)
    }
  };
});

function PlacementSlotList(props: Props): React$Element<"div"> {
  const {items,editable,displayOnly} = props;
  const classes = useStyles();

  const siteEnrollments = _.orderBy(items, ['id'], ['desc']);

  return (
    <div>
      {items.length === 0 ? (
        <div className={classes.noSite}>No Site Enrollments Found.</div>
      ) : (
        ''
      )}
      <List className={classes.root}>
        {siteEnrollments.map(siteEnrollment => {
          return (
            <div key={siteEnrollment.id}>
              <PlacementSlotListItem
                editable={editable}
                item={siteEnrollment}
                displayOnly={displayOnly || false}
              />
            </div>
          );
        })}
      </List>
    </div>
  );
}

export default PlacementSlotList;

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Paper, Drawer, Typography } from '@material-ui/core';

import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  IntegratedSelection,
  SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import AddendumStatusCodeFormatter from '../../components/AddendumStatusCode/AddendumStatusCodeFormatter';
import AddendumDetail from '../../components/Addendum/AddendumDetail';

import type { Addendum, User, ValueListItem } from '../../schemas';
import _ from 'lodash';

import SelectFilterCellBase from '../../components/ReactGrid/SelectFilterCellBase';

import { fetchAddenda } from '../../actions/creators/addenda';

import { fetchAddendumStatusCodes } from '../../actions/creators/addendumStatusCodes';
import { fetchTerms } from '../../actions/creators/terms';
import { fetchPermissions } from '../../actions/creators/permissions';

import LoadingIndicator from '../../components/LoadingIndicator';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  }
});

type Props = {
  classes: Object,
  addenda: Addendum[],
  currentUser: User,
  permissions: Object,
  fetchAddenda: Function,
  fetchAddendumStatusCodes: Function,
  addendaStatusCodes: ValueListItem[],
  fetchTerms: Function,
  fetchPermissions: Function,
  colSpan: number,
  dataLoading: boolean,
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedAddendum: ?Addendum,
  rows: Addendum[],
  loading: boolean
};

class Addenda extends Component<Props, State> {
  state = {
    columns: [
      { name: 'id', title: 'ID' },
      {
        name: 'organization',
        title: 'Organization/Site',
        getCellValue: row =>
          _.get(row, 'Contract.Organization.name', 'No Data Available')
      },
      {
        name: 'ContractId',
        title: 'Contract ID'
      },
      {
        name: 'status',
        title: 'Status',
        getCellValue: row => {
          return _.get(row, 'AddendumStatusCodeId', 'No Data Available');
        }
      },
      {
        name: 'startTerm',
        title: 'Start Term',
        getCellValue: row => {
          return _.get(row, 'StartTerm.name', '0 - No Data Available');
        }
      }
    ],
    defaultFilters: [],
    defaultCurrentPage: 0,
    defaultPageSize: 10,
    defaultSorting: [{ columnName: 'id', direction: 'desc' }],
    pageSizes: [10, 20, 50, 100, 250],
    filteringStateColumnExtensions: [],
    selection: [],
    selectedAddendum: undefined,
    showDrawer: false,
    rows: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.addenda !== state.rows) {
      return { rows: props.addenda };
    }
    if (props.dataLoading !== state.loading) {
      return { loading: props.dataLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchAddenda();
    await this.props.fetchAddendumStatusCodes();
    await this.props.fetchTerms();
    await this.props.fetchPermissions();
  };

  handleSelection = selection => {
    const { addenda } = this.props;
    const selectedAddendum = addenda[selection];

    this.setState({ showDrawer: true, selectedAddendum });
  };

  handleClose = () => {
    this.setState({
      selection: [],
      selectedAddendum: undefined,
      showDrawer: false
    });
  };

  filterCell = props => {
    if (props.column.name === 'status') {
      const optionItems = this.props.addendaStatusCodes;
      const newProps = { ...props, optionItems };
      return <SelectFilterCellBase {...newProps} />;
    }

    return <TableFilterRow.Cell {...props} />;
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      defaultFilters,
      defaultCurrentPage,
      defaultPageSize,
      defaultSorting,
      pageSizes,
      filteringStateColumnExtensions,
      selection,
      selectedAddendum,
      showDrawer,
      rows,
      loading
    } = this.state;

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          GNE Addenda
        </Typography>
        <Paper>
          <Grid rows={rows} columns={columns}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState defaultSorting={defaultSorting} />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <DataTypeProvider
              for={['status']}
              formatterComponent={AddendumStatusCodeFormatter}
            />

            <Table noDataCellComponent={props => NoDataCell(props)} />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => this.filterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer open={showDrawer} anchor="bottom" onClose={this.handleClose}>
          {selectedAddendum && (
            <AddendumDetail
              handleClose={this.handleClose}
              addendum={selectedAddendum}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addenda: state.addenda.items,
    dataLoading: _.some([
      state.addenda.isLoading,
      state.addendumStatusCodes.isLoading,
      state.permissions.isLoading,
      state.terms.isLoading,
    ]),
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contracts,
    addendumStatusCodes: state.addendumStatusCodes.values,
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchAddenda,
    fetchAddendumStatusCodes,
    fetchTerms,
    fetchPermissions
  })
)(Addenda): any);

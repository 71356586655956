import { store } from './index';
import { LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actions/types';
import axios from 'axios';
import { notify } from 'reapop';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      console.error('Forbidden (403)');
      store.dispatch(
        notify({
          title: 'Forbidden (403)',
          message: 'Something was requested that you do not have access to. You have been automatically logged out of CPS. Please contact an administrator.',
          status: 'error',
        })
      );
      store.dispatch({ type: LOGOUT_USER, payload: null });
      store.dispatch({ type: LOGOUT_USER_SUCCESS, payload: null });
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    if (error.response.status === 401) {
      console.error('Login Required (401)');
      store.dispatch(
        notify({
          title: 'Login Required (401)',
          message: 'Your session on the CPS application has expired. Please login again.',
          status: 'error',
        })
      );
      store.dispatch({ type: LOGOUT_USER, payload: null });
      store.dispatch({ type: LOGOUT_USER_SUCCESS, payload: null });
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;

// @flow

import _ from 'lodash';

import * as React from 'react';
import {connect,useSelector} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import  {DropTarget} from 'react-dnd';
import Typography from '@material-ui/core/Typography';

import PlacementDrawerWorkspaceCard from './PlacementDrawerWorkspaceCard';

import * as DNDTypes from '../../schemas/dndTypes';
import {addToWorkspace} from '../../actions/creators/placementDrawer';
import CommonStyles from '../../styles/CommonStyles';

import type {WorkspaceItem} from '../../schemas';

type Props = {
  connectDropTarget: Function,
  canDrop: boolean,
  isOver: boolean,
  placeholderItem: WorkspaceItem
};

const useStyles = makeStyles(theme => {
  const commonStyles = CommonStyles(theme);
  const workspaceDropZoneStyles = {
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden'
  };
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      width: '20vw',
      height: 'calc(67vh - 110px)',
      position: 'absolute'
    },
    dropZone: {
      ...commonStyles.dropZone,
      ...workspaceDropZoneStyles
    },
    dropZoneCanDrop: {
      ...commonStyles.dropZoneCanDrop,
      ...workspaceDropZoneStyles
    },
    dropZoneActive: {
      ...commonStyles.dropZoneActive,
      ...workspaceDropZoneStyles
    },
    item: {
      marginTop: theme.spacing(1)
    },
    blankMessage: {
      marginTop: theme.spacing(4),
      textAlign: 'center'
    },
    cardContainer: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    fakeCard: {
      opacity: 0.3,
      width: '80%',
      marginTop: theme.spacing(2)
    }
  };
});

// Drag-and-drop spec/collect functions needed for the DragSource
const dropTargetSpec = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.addToWorkspace(item);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    placeholderItem: monitor.getItem()
  };
}

function PlacementDrawerWorkspace(props: Props) {
  const {
    connectDropTarget,canDrop,isOver,placeholderItem
  } = props;
  const classes = useStyles();

  const items = useSelector(state =>
    _.get(state.placementDrawer, 'selectedPlacement.WorkspaceItems', [])
  );

  const isActive = isOver && canDrop;

  let dropZoneClass = 'dropZone';
  if (isActive) dropZoneClass = 'dropZoneActive';
  else if (canDrop) dropZoneClass = 'dropZoneCanDrop';

  const itemAlreadyInList = _.find(items, placeholderItem);
  const showPlaceholder = canDrop && isOver && itemAlreadyInList === undefined;
  const showList = items.length > 0;

  return connectDropTarget(
    <div className={classes.root}>
      <div className={classes[dropZoneClass]}>
        {showList &&
          items.map(item => {
            return (
              <div className={classes.cardContainer} key={item.id}>
                <PlacementDrawerWorkspaceCard item={item} />
              </div>
            );
          })}
        {!showList &&
          !showPlaceholder && (
          <Typography className={classes.blankMessage}>
            Drag a site or preceptor here to add it to your workspace.
          </Typography>
        )}
        {showPlaceholder && (
          <div className={classes.fakeCard}>
            <PlacementDrawerWorkspaceCard item={placeholderItem} />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => state;

export default (compose(
  connect(mapStateToProps, {addToWorkspace}),
  DropTarget(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dropTargetSpec, collect)
)(PlacementDrawerWorkspace): any);

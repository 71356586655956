// @flow

import {
  FETCH_REPORT_PLACEMENTS,
  FETCH_REPORT_PLACEMENTS_SUCCESS,
  FETCH_REPORT_PLACEMENTS_ERROR
} from '../actions/types';

import type { Placement } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +placements: Placement[]
};

type Action = {
  type:
    | typeof FETCH_REPORT_PLACEMENTS
    | typeof FETCH_REPORT_PLACEMENTS_SUCCESS
    | typeof FETCH_REPORT_PLACEMENTS_ERROR,
  payload: ?Array<Placement> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  placements: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_REPORT_PLACEMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_REPORT_PLACEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        placements: action.payload
      };
    case FETCH_REPORT_PLACEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const createTodo = todo => async dispatch => {
  try {
    let { data } = await axios.post(`/todo`, todo);
    dispatch({ type: actions.CREATE_TODO_SUCCESS });
    dispatch(notify({
      title: 'Success',
      message: 'Todo successfully created.',
      status: 'success',
    }));
    return data;
  } catch (err) {
    dispatch(notify({
      title: 'Error',
      message: 'Could not create todo.',
      status: 'error',
    }));
  }
};

export const updateTodo = todo => async dispatch => {
  try {
    let { data } = await axios.put(`/todo/${todo.id}`, todo);
    dispatch({ type: actions.UPDATE_TODO_SUCCESS });
    dispatch(
      notify({
        title: 'Success',
        message: 'Todo successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update todo.',
        status: 'error',
      })
    );
  }
};

export const deleteTodo = todo => async dispatch => {
  try {
    let { data } = await axios.delete(`/todo/${todo.id}`);
    dispatch({ type: actions.DELETE_TODO_SUCCESS });
    dispatch(notify({
      title: 'Success',
      message: 'Todo successfully deleted.',
      status: 'success',
    }));
    return data;
  } catch (err) {
    dispatch(notify({
      title: 'Error',
      message: 'Could not delete todo.',
      status: 'error',
    }));
  }
};

export const fetchContractTodos = contract => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_TODOS, payload: undefined });
    const res = await axios.get(`/todo/Contract/${contract.id}`);
    dispatch({
      type: actions.FETCH_CONTRACT_TODOS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_TODOS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPlacementTodos = placement => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENT_TODOS, payload: undefined });
    const res = await axios.get(`/todo/Placement/${placement.id}`);
    dispatch({
      type: actions.FETCH_PLACEMENT_TODOS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PLACEMENT_TODOS_ERROR,
      payload: error.response.data
    });
  }
};

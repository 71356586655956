// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  Grid,
  Typography
} from '@material-ui/core';

import AttachmentList from '../../Attachment/AttachmentList';
import CreateAttachmentButton from '../../Attachment/CreateAttachmentButton';

import { fetchContractAttachments } from '../../../actions/creators/attachments';

import type { Contract, User, Attachment } from '../../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  isLoading: boolean,
  currentUser: User,
  permissions: Object,
  attachments: Attachment[],
  fetchContractAttachments: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    paddingLeft: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1)
  }
});

class Attachments extends Component<Props> {
  render() {
    const { classes, attachments, contract } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={10}>
            <Typography variant="h5" gutterBottom>
              File Attachments
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <CreateAttachmentButton
              model="ContractRequest"
              foreignKey={contract.id}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AttachmentList attachments={attachments} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.contractDrawer.selectedContract,
    attachments: state.contractDrawer.attachments,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(
  connect(mapStateToProps, { fetchContractAttachments }),
  withStyles(styles)
)(Attachments): any);

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import {
  Typography, Grid, Button, Card, CardHeader, CardContent, Dialog,
  DialogTitle, DialogActions
} from '@material-ui/core';

import type { Site, User } from '../../../schemas';

import { fetchPermissions } from '../../../actions/creators/permissions';
import { fetchSpecialtyValues } from '../../../actions/creators/specialties';
import { fetchSelectedSite } from '../../../actions/creators/siteDrawer';
import { hasAccess } from '../../../utils/permissionsCheck';

import DeepLink from '../../DeepLink';
import SitePreceptorAddDialog from './SitePreseptorAddDialog';

type State = {
  dialogVisible: boolean,
  shouldClone: boolean,
  cloneDialogVisible: boolean,
};

type Props = {
  classes: Object,
  currentUser: User,
  permissions: Object,
  site: Site,
  isLoading: boolean,
  Sites: Array<Site>,
  fetchSelectedSite: Function,
  fetchPermissions: Function,
  fetchSpecialtyValues: Function,
  deletePreceptor: Function
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  siteContainer: {
    paddingLeft: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1)
  },
  chipContainer: {
    textAlign: 'right'
  },
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
});

class SiteDetails extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      shouldClone: false,
      cloneDialogVisible: false,
    };
  }

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.fetchSpecialtyValues();
  }

  handleDialogShow = async () => {
    await this.setState({ dialogVisible: true });
    this.handleCloneDialogClose();
  };
  handleDialogClose = () => {
    this.setState({ dialogVisible: false, shouldClone: false });
  };

  handleClone = async () => {
    await this.setState({ shouldClone: true, cloneDialogVisible: false });
    this.handleDialogShow();
  };
  handleCloneDialogShow = () => {
    this.setState({ cloneDialogVisible: true });
  };
  handleCloneDialogClose = () => {
    this.setState({ cloneDialogVisible: false });
  };

  canCreate = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'create', []));
  };

  canUpdate = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'update', []));
  };

  canDelete = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'delete', []));
  };

  renderCreatePreceptorButton = () => {
    const { classes } = this.props;

    return (
      <Button
        disabled={!this.canCreate()}
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={this.handleCloneDialogShow}
      >
        Create Preceptor
      </Button>
    );
  };

  renderPreceptors = () => {
    const { site, classes } = this.props;
    const preceptors = _.get(site, 'Preceptors', []);

    return(
      <Grid container spacing={2}>
        {preceptors.length === 0 && (
          <Typography className={classes.emptyMessage}>
            There are no sites associated with this site.
          </Typography>
        )}
        {preceptors.length > 0 &&
          preceptors.map(preceptor => {
            const geoAddress = `${preceptor.address},${preceptor.addressLine2},${preceptor.city},${preceptor.StateId},${preceptor.postalCode}`;
            const website = site.website
              ? (<a href={`${site.website}`} rel="noopener noreferrer" target="_blank">{site.website}</a>)
              : null;
            const phone = preceptor.phone
              ? (<a href={`tel:${preceptor.phone}`} target="_top">{preceptor.phone}</a>)
              : null;
            const address = (preceptor.city && preceptor.StateId && preceptor.postalCode)
              ? (
                <a href={`http://maps.google.com/?q=${geoAddress}`} rel="noopener noreferrer" target="_blank">
                  {preceptor.address}<br/>
                  {preceptor.addressLine2 && <div>{preceptor.addressLine2}</div>}
                  {preceptor.city}, {preceptor.StateId} {preceptor.postalCode}
                </a>
              )
              : null;
            return (
              <Grid item xs={12} sm={6} md={4} key={preceptor.id}>
                <Card className={classes.card}>
                  <CardHeader
                    title={
                      <DeepLink id={preceptor.id} model="preceptor">
                        <Typography variant="h5" component="h2">
                          {preceptor.name}
                        </Typography>
                      </DeepLink>
                    }
                    subheader={
                      <div>
                        <Typography variant="caption" gutterBottom>
                          {_.get(site, 'name', null)}<br/>
                          {website}
                        </Typography>
                      </div>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Address</strong>
                        </Typography>
                        <Typography component="div">
                          {address && <div>{address}</div>}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Contact Info</strong>
                        </Typography>
                        <Typography component="div">
                          {phone && <div><strong>Phone:</strong> {phone}</div>}
                        </Typography>
                        <Typography component="div">
                          {preceptor.fax && <div><strong>Fax:</strong> {preceptor.fax}</div>}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  getInitialValues = () => {
    const { site } = this.props;
    const { shouldClone } = this.state;

    return {
      isActive: true,
      Sites: [site],
      phone: shouldClone ? site.phone : '',
      fax: shouldClone ? site.fax : '',
      address: shouldClone ? site.address : '',
      addressLine2: shouldClone ? site.addressLine2 : '',
      city: shouldClone ? site.city : '',
      StateId: shouldClone ? site.StateId : '',
      CountryId: shouldClone ? site.CountryId : '',
      postalCode: shouldClone ? site.postalCode : ''
    };
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, cloneDialogVisible } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Site Preceptors
        </Typography>
        {this.renderPreceptors()}
        <Grid container>
          <Grid item>
            {this.renderCreatePreceptorButton()}
          </Grid>
        </Grid>
        <SitePreceptorAddDialog
          open={dialogVisible}
          onClose={this.handleDialogClose}
          handleClose={this.handleDialogClose}
          canCreate={this.canCreate()}
          canDelete={this.canDelete()}
          initialValues={this.getInitialValues()}
        />
        <Dialog
          fullWidth
          maxWidth="xs"
          open={cloneDialogVisible}
          onClose={this.handleCloneDialogClose}
          aria-labelledby="clone-dialog-title"
        >
          <DialogTitle id="clone-dialog-title">Clone Address, Phone, & FAX from Site?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClone} color="primary">
              Yes
            </Button>
            <Button onClick={this.handleDialogShow} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    site: state.siteDrawer.selectedSite,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.preceptor
  };
};

export default (compose(connect(mapStateToProps, {
  fetchSelectedSite,
  fetchPermissions,
  fetchSpecialtyValues
}), withStyles(styles))(
  SiteDetails
): any);

// @flow
import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import type { AttachmentA } from '../../schemas';
import moment from 'moment';
import _ from 'lodash';

type Props = {
  attachmentA: AttachmentA
};

class AttachmentADetails extends Component<Props> {
  render(): React$Element<"div"> {
    const { attachmentA } = this.props;
    const siteName = _.get(attachmentA, 'Site.name', 'No Data Available');
    const orgName = _.get(
      attachmentA,
      'Contract.Organization.name',
      'No Data Available'
    );
    const startDate = attachmentA.startDate
      ? moment(attachmentA.startDate).format('ll')
      : 'N/A';
    const endDate = attachmentA.endDate
      ? moment(attachmentA.endDate).format('ll')
      : 'N/A';

    const status = _.get(
      attachmentA,
      'AttachmentAStatusCode.name',
      'No Data Available'
    );
    return (
      <div>
        <Typography>
          <strong>Organization: </strong>
          {orgName}
        </Typography>
        <Typography>
          <strong>Site: </strong>
          {siteName}
        </Typography>
        <Typography>
          <strong>Rotation Dates: </strong>
          {startDate} - {endDate}
        </Typography>
        <Typography gutterBottom>
          <strong>Status: </strong> {status}
        </Typography>
      </div>
    );
  }
}

export default AttachmentADetails;

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import AppInfo from '../../components/AppInfo';

type Props = {
  loadAppInfo: Function,
  appInfo: Object,
  classes: Object
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '84px',
    padding: theme.spacing(2)
  },
  pageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%'
  },
  boxContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    flexShrink: 1,
    boxShadow: theme.shadows[2]
  }
});

const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL : '';
const SAML_URL = `${API_URL}/auth/saml`;

class Login extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.pageContainer} spacing={2}>
          <Grid container item className={classes.boxContainer}>
            <AppInfo appInfo={this.props.appInfo} />
            <Button variant="contained" color="primary" href={SAML_URL}>
              Login with your NetId
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ appInfo }) => {
  return { appInfo };
};

export default (connect(mapStateToProps)(withStyles(styles)(Login)): any);

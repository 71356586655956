// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

import type { Student } from '../../schemas';

type Props = {
  student: Student,
  classes: Object
};

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: theme.typography.pxToRem(16)
  },
  contentContainer: {
    flex: 1
  },
  actionsContainer: {
    justifyContent: 'flex-end'
  }
});

class StudentDetail extends Component<Props> {
  renderAddress = type => {
    const { student, classes } = this.props;
    const address = _.get(student, `${type}Address`, undefined);
    const address2 = _.get(student, `${type}Address_2`, undefined);
    const city = _.get(student, `${type}City`, undefined);
    const state = _.get(student, `${type}State`, undefined);
    const postalCode = _.get(student, `${type}PostalCode`, undefined);
    const addressLink = `${address},${city},${state},${postalCode}`;
    return (
      <Grid item xs>
        {address && (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {type.charAt(0).toUpperCase() + type.slice(1)} Address
            </Typography>
            <Divider className={classes.divider} />
            <a
              href={`http://maps.google.com/?q=${addressLink}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {address}
              <br />
              {address2 && <div>{address2}</div>}
              {city}, {state} {postalCode}
            </a>
          </div>
        )}
      </Grid>
    );
  };

  renderPhone = type => {
    const { student } = this.props;
    const phone = _.get(student, `${type}Phone`, undefined);
    return (
      <div>
        {phone && (
          <Typography variant="caption" gutterBottom>
            <strong>
              Phone(<small>{type}</small>)
            </strong>{' '}
            &bull;{' '}
            <a href={`tel:${phone}`} target="_top">
              {phone}
            </a>
          </Typography>
        )}
      </div>
    );
  };

  render() {
    const { student, classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography variant="h6" gutterBottom>
              {student.fullName}
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="caption" gutterBottom>
                <strong>Email</strong> &bull;{' '}
                <a href={`mailto:${student.email}`} target="_top">
                  {student.email}
                </a>
              </Typography>
              {this.renderPhone('main')}
              {this.renderPhone('home')}
            </div>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {this.renderAddress('home')}
            {this.renderAddress('mailing')}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default (withStyles(styles)(StudentDetail): any);

// @flow

import {
  FETCH_COORDINATORS,
  FETCH_COORDINATORS_SUCCESS,
  FETCH_COORDINATORS_ERROR
} from '../actions/types';

import type { CoordinatorValueListItem } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<CoordinatorValueListItem>
};

type Action = {
  type:
    | typeof FETCH_COORDINATORS
    | typeof FETCH_COORDINATORS_SUCCESS
    | typeof FETCH_COORDINATORS_ERROR,
  payload: ?Object
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_COORDINATORS:
      return { ...state, isLoading: true };
    case FETCH_COORDINATORS_SUCCESS:
      return { ...state, isLoading: false, items: action.payload };
    case FETCH_COORDINATORS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

// @flow


import * as types from '../actions/types';
import { createItem, updateItem, deleteItem, filterItems } from './methods';
import type { User } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<User>,
  +selected: Object
};

type Action = {
  type:
    | typeof types.FETCH_USERS
    | typeof types.FETCH_USERS_SUCCESS
    | typeof types.FETCH_USERS_ERROR
    | typeof types.FETCH_USER
    | typeof types.FETCH_USER_SUCCESS
    | typeof types.FETCH_USER_ERROR
    | typeof types.CREATE_USER
    | typeof types.CREATE_USER_SUCCESS
    | typeof types.CREATE_USER_ERROR
    | typeof types.UPDATE_USER
    | typeof types.UPDATE_USER_SUCCESS
    | typeof types.UPDATE_USER_ERROR
    | typeof types.DELETE_USER
    | typeof types.DELETE_USER_SUCCESS
    | typeof types.DELETE_USER_ERROR,
  payload: Array<User> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case types.FETCH_USERS:
      return { ...state, isLoading: true };
    case types.FETCH_USERS_SUCCESS:
      return { ...state,
        isLoading: false,
        items: filterItems(action.payload, { isActive: true })
      };
    case types.FETCH_USERS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.CREATE_USER:
      return { ...state, isLoading: true };
    case types.CREATE_USER_SUCCESS:
      return { ...state,
        isLoading: false,
        items: filterItems(createItem(state.items, action.payload), { isActive: true })
      };
    case types.CREATE_USER_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.FETCH_USER:
      return { ...state, isLoading: true };
    case types.FETCH_USER_SUCCESS:
      return { ...state, isLoading: false, selected: action.payload };
    case types.FETCH_USER_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.UPDATE_USER:
      return { ...state, isLoading: true };
    case types.UPDATE_USER_SUCCESS:
      return { ...state,
        isLoading: false,
        items: filterItems(updateItem(state.items, action.payload), { isActive: true })
      };
    case types.UPDATE_USER_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.DELETE_USER:
      return { ...state, isLoading: true };
    case types.DELETE_USER_SUCCESS:
      return { ...state,
        isLoading: false,
        items: filterItems(deleteItem(state.items, action.payload), { isActive: true })
      };
    case types.DELETE_USER_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

// @flow

import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { ContractIcon } from '../Icons';

import type { Placement } from '../../schemas';
import CommonStyles from '../../styles/CommonStyles';
import { Theme } from '@material-ui/core/styles';
import { hasAllContracts, contractExpiring } from '../../utils/placementHelpers';

type Props = {
  placement: Placement,
  theme: typeof Theme
};

function LegalStatusIcon(props: Props) {
  const { placement, theme } = props;
  const commonStyles = CommonStyles(theme);
  const isCovered = hasAllContracts(placement);
  const isExpiring = contractExpiring(placement);

  const iconColor = isCovered && !isExpiring
    ? commonStyles.success.backgroundColor
    : commonStyles.warn.backgroundColor;

  let titleText = isCovered ? 'Contract(s) signed' : 'Contract(s) needed';
  if (isExpiring) titleText = 'Contract(s) expiring';

  return (
    <Tooltip placement="top" title={titleText}>
      <ContractIcon fontSize="small" style={{ color: iconColor }} />
    </Tooltip>
  );
}

export default (withTheme(LegalStatusIcon): any);

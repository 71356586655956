// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper, Drawer, Typography, TableCell, Select, AppBar, Toolbar
} from '@material-ui/core';
import { CheckBox, CheckBoxBlank } from '../../components/Icons';
import CommonStyles from '../../styles/CommonStyles';

import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting, IntegratedSelection, SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import LoadingIndicator from '../../components/LoadingIndicator';

import { fetchTerms } from '../../actions/creators/terms';
import { fetchPermissions } from '../../actions/creators/permissions';
import { hasAccess } from '../../utils/permissionsCheck';

import { getAllTerms } from '../../selectors/terms';

import TermDetail from '../../components/AcademicTerm/AcademicTermDetail';

import type { Term, User } from '../../schemas';

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      padding: theme.spacing(2)
    },
    flex: { flex: 1 },
    cell: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    cellCenter: {
      width: '100%',
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    input: { width: '100%' },
    icon: { margin: theme.spacing(1) },
    chip: { margin: theme.spacing(1) },
    chipWarn: {
      margin: theme.spacing(1),
      backgroundColor: commonStyles.warn.backgroundColor,
      color: commonStyles.warn.color
    },
    chipRow: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  };
  return classes;
};

type Props = {
  classes: Object,
  colSpan: number,
  currentUser: User,
  permissions: Object,
  terms: Term[],
  termsLoading: boolean,
  fetchPermissions: Function,
  fetchTerms: Function
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  sortingStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedTerm: ?Term,
  rows: Term[],
  loading: boolean
};

class AcademicTerms extends Component<Props, State> {
  state = {
    columns: [
      {
        name: 'name',
        title: 'Name'
      },
      {
        name: 'TermAttr.startDate',
        title: 'Start Date',
        getCellValue: row =>
          row.TermAttr && row.TermAttr.startDate
            ? moment.parseZone(row.TermAttr.startDate).format('MMMM DD, YYYY')
            : 'N/A'
      },
      {
        name: 'TermAttr.endDate',
        title: 'End Date',
        getCellValue: row =>
          row.TermAttr && row.TermAttr.endDate
            ? moment.parseZone(row.TermAttr.endDate).format('MMMM DD, YYYY')
            : 'N/A'
      },
      {
        name: 'TermAttr.numberOfWeeks',
        title: '# of Weeks',
        getCellValue: row => _.get(row, 'TermAttr.numberOfWeeks', 0)
      },
      {
        name: 'TermAttr.approvalDeadline',
        title: 'Approval Deadline',
        getCellValue: row =>
          row.TermAttr && row.TermAttr.approvalDeadline
            ? moment.parseZone(row.TermAttr.approvalDeadline).format('MMMM DD, YYYY')
            : 'N/A'
      },
      {
        name: 'TermAttr.isActive',
        title: 'Active?',
        getCellValue: row => _.get(row, 'TermAttr.isActive', false)
      }
    ],
    defaultFilters: [],
    defaultCurrentPage: 0,
    defaultPageSize: 10,
    defaultSorting: [{ columnName: 'name', direction: 'asc' }],
    pageSizes: [10, 20, 50, 100, 250],
    filteringStateColumnExtensions: [],
    sortingStateColumnExtensions: [],
    selection: [],
    selectedTerm: undefined,
    showDrawer: false,
    rows: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.terms !== state.rows) {
      return { rows: props.terms };
    }
    if (props.termsLoading !== state.loading) {
      return { loading: props.termsLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchPermissions();
  };

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.create);
  }

  canDelete() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  }

  handleSelection = selection => {
    const { terms }= this.props;
    const selectedTerm = terms[selection];
    if (selectedTerm) {
      this.setState({ showDrawer: true, selectedTerm });
    } else {
      this.setState({ showDrawer: true, selectedTerm: undefined });
    }
  };

  handleClose = () => {
    this.setState({
      selection: [],
      selectedTerm: undefined,
      showDrawer: false
    });
  };

  renderAppBar = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar />
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, pageSizes, filteringStateColumnExtensions,
      sortingStateColumnExtensions, selection, selectedTerm, showDrawer, rows,
      loading
    } = this.state;

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Select>
        </TableCell>
      );
    }

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'TermAttr.isActive') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'TermAttr.isActive') {
        return (
          <Table.Cell className={classes.cellCenter}>
            {props.value ? (
              <CheckBox className={classes.icon} />
            ) : (
              <CheckBoxBlank className={classes.icon} />
            )}
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          Terms
        </Typography>
        <Paper>
          {this.renderAppBar()}
          <Grid rows={rows} columns={columns}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState
              defaultSorting={defaultSorting}
              columnExtensions={sortingStateColumnExtensions}

            />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table
              cellComponent={props => Cell(props)}
              noDataCellComponent={props => NoDataCell(props)}
            />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => FilterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer open={showDrawer} anchor="bottom" onClose={this.handleClose}>
          <TermDetail
            handleClose={this.handleClose}
            selectedTerm={selectedTerm}
            canUpdate={this.canUpdate()}
            canDelete={this.canDelete()}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    terms: getAllTerms(state),
    termsLoading: state.terms.isLoading,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.term
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTerms,
    fetchPermissions
  })
)(AcademicTerms): any);

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

import OrganizationPanel from '../Contract/OrganizationPanel';

import { hasAccess } from '../../utils/permissionsCheck';
import EditAmendmentForm from './EditAmendmentForm';
import WorkflowEntriesPanel from '../WorkflowEntriesPanel';

import {
  updateAmendment,
  deleteAmendment,
  postAmendmentWorkflowLogEntry
} from '../../actions/creators/amendments';

import type { Amendment, User } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  amendment: Amendment,
  updateAmendment: Function,
  deleteAmendment: Function,
  handleClose: Function,
  classes: Object,
  currentUser: User,
  permissions: Object,
  postAmendmentWorkflowLogEntry: Function
};

class AmendmentDetail extends Component<Props> {

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  handleSubmit = async values => {
    const {
      amendment,
      updateAmendment,
      handleClose,
      currentUser,
      postAmendmentWorkflowLogEntry
    } = this.props;

    if (amendment.AmendmentStatusCodeId !== values.AmendmentStatusCodeId) {
      let workflowItem = {
        AmendmentId: amendment.id,
        UserId: currentUser.id,
        StatusChangedToId: values.AmendmentStatusCodeId
      };

      await postAmendmentWorkflowLogEntry(workflowItem);
    }

    let result = updateAmendment(values);
    if (result) {
      handleClose();
    }
  };

  handleDelete = async () => {
    const { amendment, deleteAmendment, handleClose } = this.props;
    let confirmation = window.confirm(
      'Are you sure you want to delete this amendment?'
    );

    if (confirmation) {
      let result = await deleteAmendment(amendment);
      if (result) {
        handleClose();
      }
    }
  };

  render() {
    const { amendment, classes } = this.props;
    const organization = _.get(amendment, 'Contract.Organization', undefined);
    const logEntries = _.get(amendment, 'AmendmentWorkflowLogEntries', undefined);

    return (
      <div className={classes.root}>
        {amendment && (
          <Grid container>
            <Grid item xs={12} sm={8}>
              {this.canUpdate() && (
                <EditAmendmentForm
                  initialValues={amendment}
                  handleSubmit={this.handleSubmit}
                  handleDelete={this.handleDelete}
                />
              )}
              {!this.canUpdate() && (
                <div>
                  <Typography variant="h6" gutterBottom>
                    Amendment Details
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography gutterBottom>
                    <strong>Status</strong> {amendment.AmendmentStatusCode.name}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Description</strong> {amendment.description}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <OrganizationPanel organization={organization} />
              {logEntries && <WorkflowEntriesPanel entries={logEntries} />}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(
  connect(mapStateToProps, {
    updateAmendment,
    deleteAmendment,
    postAmendmentWorkflowLogEntry
  }),
  withStyles(styles)
)(AmendmentDetail): any);

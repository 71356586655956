// @flow

import React from 'react';
import {Formik,Form,Field} from 'formik';

import {Grid,Button,Typography,FormControlLabel,makeStyles} from '@material-ui/core';
import {Select,Checkbox,TextField,MultiSelect} from '../../Formik';
import {preceptorValidation} from '../../../validation';

type Props = {
  classes?: Object,
  initialValues: Object,
  associations: Object,
  canCreate: boolean,
  canUpdate: boolean,
  canDelete: boolean,
  handleSubmit: Function,
  handleClose: Function,
  handleDelete: Function,
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  formSection: {
    marginBottom: theme.spacing(2)
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const PreceptorForm = (props: Props): React$Element<any> => {
  const {initialValues,canCreate,associations,handleSubmit} = props;
  const {states,specialties,sites,countries} = associations;
  const classes = useStyles();

  return(
    <Formik
      initialValues={initialValues}
      validationSchema={preceptorValidation}
      onSubmit={values => handleSubmit(values)}
    >
      {({values,isSubmitting,isValid,dirty,handleReset}) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography type="subtitle1" className={classes.groupLabel}>
                Person Information
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="firstName"
                label="First Name"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="lastName"
                label="Last Name"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="credentials"
                label="Credentials"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                name="Specialties"
                label="Specialties"
                options={specialties}
                component={MultiSelect}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="previousSpecialty"
                label="Previous Specialty"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="emailAddress"
                label="Email Address"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="phone"
                label="Phone"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="fax"
                label="Fax"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="pager"
                label="Pager"
                component={TextField}
              />
            </Grid>
            <Grid item md={12} sm={6} xs={12}>
              <Field
                fullWidth
                name="Sites"
                label="Sites"
                options={sites}
                component={MultiSelect}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography type="subtitle1" className={classes.groupLabel}>
                Contact Information
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="address"
                label="Address"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="addressLine2"
                label="Address Line 2"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="city"
                label="City"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required={values.CountryId === 'US' || !values.CountryId}
                name="StateId"
                label="State"
                options={states}
                component={Select}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required={values.CountryId === 'US' || !values.CountryId}
                type="text"
                name="postalCode"
                label="Postal Code"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                id="CountryId"
                name="CountryId"
                label="Country"
                options={countries}
                component={Select}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="county"
                label="County"
                component={TextField}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography type="subtitle1" className={classes.groupLabel}>
                Credentials & Certifications
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isAhecEligible}
                    name="isAhecEligible"
                    label="Is AHEC Eligible"
                    component={Checkbox}
                  />
                }
                label="Is AHEC Eligible"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="ahecLegacy"
                label="AHEC Legacy"
                component={TextField}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="npiNumber"
                label="NPI Number"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="license"
                label="License"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="certifications"
                label="Certifications"
                component={TextField}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography type="subtitle1" className={classes.groupLabel}>
                Additional Information
              </Typography>
            </Grid>
            {/* <Grid item md={3} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="preceptor"
                label="Preceptor"
                component={TextField}
              />
            </Grid> */}
            <Grid item md={3} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="lastActiveSemester"
                label="Last Active Semester"
                component={TextField}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="activeLegacy"
                label="Active Legacy"
                component={TextField}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isAlumni}
                    name="isAlumni"
                    label="Is Alumni"
                    component={Checkbox}
                  />
                }
                label="Is Alumni"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isActive}
                    name="isActive"
                    label="Is Active"
                    component={Checkbox}
                  />
                }
                label="Is Active"
              />
            </Grid>
          </Grid>

          <div className={classes.buttons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!canCreate || isSubmitting || !isValid || !dirty}
            >
              Create Preceptor
            </Button>
            <Button
              className={classes.button}
              disabled={!dirty}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PreceptorForm;


import _ from 'lodash';
import { createSelector } from 'reselect';
import { getTermsWithAttrs, getActiveTermId } from './terms';

export const getAllUsers = state => state.users.items;

export const getAllUserValues = createSelector(
  getAllUsers,
  users => _.chain(users).map(el => ({ id: el.id, name: el.name })).value()
);

export const getCurrentUser = state => state.auth.currentUser;

export const getCurrentUserRoles = createSelector(
  getCurrentUser, (currentUser) => currentUser.Roles || []
);

export const getCurrentUserSetting = (state, type) => createSelector(
  getCurrentUser,
  user => _.chain(user).get('UserSettings', [])
    .find({ UserSettingType: { slug: type }}).get('value', null).value()
)(state);

export const getCurrentUsersWorkingTerm = createSelector(
  getCurrentUser, getTermsWithAttrs, getActiveTermId,
  (user, terms, activeTermId) => {
    const workingTermId = _.chain(user).get('UserSettings', [])
      .find({ UserSettingType: { slug: 'working_semester' }})
      .get('value', activeTermId).value();

    return _.find(terms, { id: parseInt(workingTermId, 10) });
  }
);

export const getUserForId = (state, id) => createSelector(
  getAllUsers, users => _.find(users, { id: id })
)(state);

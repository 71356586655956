// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Typography,
  Paper,
  Divider,
  Grid
} from '@material-ui/core';

import PlacementDrawerWorkspace from '../../../components/PlacementDrawer/PlacementDrawerWorkspace';
import PlacementSlotList from '../../../components/PlacementDrawer/PlacementSlots/PlacementSlotList';
import AddToWorkspaceButton from '../../../components/PlacementDrawer/AddToWorkspaceButton';

import { fetchStudentHistory } from '../../../actions/creators/placementDrawer';
import _ from 'lodash';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  term: {
    marginTop: theme.spacing(2)
  },
  headline: {
    ...theme.typography.h5,
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  content: {
    flex: 3
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  header: {
    padding: theme.spacing(2)
  },
  historyItem: {
    marginBottom: theme.spacing(4)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  }
});

type Classes = {
  root: Object,
  headline: Object,
  container: Object,
  content: Object,
  title: Object,
  divider: Object,
  header: Object,
  buttonContainer: Object,
  button: Object,
  rightIcon: Object,
  sidebar: Object,
  term: Object,
  historyItem: Object
};

type Props = {
  classes: Classes,
  fetchStudentHistory: Function,
  placementDrawer: Object
};

class StudentPlacementHistorySection extends Component<Props> {
  renderWorkspaceButton = placement => {
    const PlacementId = this.props.placementDrawer.selectedPlacement.id;
    let workspaceItems = [];
    // get the site and make workspace items
    let siteEnrollments = _.get(placement, 'Package.SiteEnrollments');
    let sites = _.map(siteEnrollments, 'Site');
    const siteItems = sites.map(site => {
      return {
        model: 'site',
        foreignKey: site.id,
        name: site.name,
        PlacementId
      };
    });
    // get the preceptors and make workspace items
    let preceptorEnrollments = _.flatten(
      _.map(siteEnrollments, 'PreceptorEnrollments')
    );

    let preceptors = preceptorEnrollments.map(
      preceptorEnrollment => preceptorEnrollment.Preceptor
    );

    const preceptorItems = preceptors.map(preceptor => {
      return {
        model: 'preceptor',
        foreignKey: preceptor.id,
        name: preceptor.name,
        PlacementId
      };
    });

    // make workspace items
    workspaceItems = [...siteItems, ...preceptorItems];

    // render the AddToWorkspace Button w/ items
    return <AddToWorkspaceButton items={workspaceItems} />;
  };
  render() {
    const { classes } = this.props;
    const { studentHistory } = this.props.placementDrawer;
    const termNames = Object.keys(studentHistory);
    const hasTerms = termNames.length > 0;

    return (
      <div className={classes.root}>
        <div>
          <span className={classes.headline}>Student Placement History</span>
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            {!hasTerms && (
              <Typography gutterBottom>
                This student does not have a placement history.
              </Typography>
            )}
            {hasTerms &&
              termNames.map(termName => {
                return (
                  <div key={termName}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.term}
                    >
                      {termName}
                    </Typography>

                    <Divider className={classes.divider} />
                    {studentHistory[termName].map(historyItem => {
                      return (
                        <Paper
                          key={historyItem.id}
                          className={classes.historyItem}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs={7}>
                              <Typography
                                variant="h6"
                                className={classes.header}
                              >
                                N.{historyItem.Course.CatalogNumberId} &bull;{' '}
                                {historyItem.Course.description}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              className={classes.buttonContainer}
                            >
                              {this.renderWorkspaceButton(historyItem)}
                            </Grid>
                          </Grid>
                          <Divider />

                          <PlacementSlotList
                            editable={false}
                            displayOnly={true}
                            items={historyItem.Package.SiteEnrollments}
                          />
                        </Paper>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          <div className={classes.sidebar}>
            <PlacementDrawerWorkspace />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placementDrawer: state.placementDrawer,
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchStudentHistory })
)(StudentPlacementHistorySection): any);

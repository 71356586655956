import Color from 'color';

const GREEN = '#00FF00';
const YELLOW = '#FFFF00';
const ORANGE = '#FF9900';
const RED = '#FF0000';
const GREY = '#BBBBBB';

const successColor = Color(GREEN);
const warnColor = Color(ORANGE);
const errorColor = Color(RED);

const green = Color(GREEN);
const yellow = Color(YELLOW);
const orange = Color(ORANGE);
const grey = Color(GREY);

const wellShadow = 'inset 0px 0px 5px 2px rgba(0,0,0,0.12)';

export default theme => {
  const backgroundColor =
    theme.palette.type === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[900];
  const backgroundColorActive =
    theme.palette.type === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.main;
  const backgroundColorCanDrop =
    theme.palette.type === 'light'
      ? theme.palette.primary.light
      : theme.palette.primary.dark;
  const scrollbarBg =
    theme.palette.type === 'light'
      ? theme.palette.grey[300]
      : theme.palette.common.black;
  const scrollbarThumbOutlineColor =
    theme.palette.type === 'light'
      ? theme.palette.grey[400]
      : theme.palette.grey[700];
  const scrollbarThumbBg =
    theme.palette.type === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  const backgroundColorSuccess =
    theme.palette.type === 'light'
      ? successColor
        .lighten(0.25)
        .hex()
        .toString()
      : successColor
        // .darken(0.3)
        .hex()
        .toString();
  const backgroundColorWarn =
    theme.palette.type === 'light'
      ? warnColor
        .lighten(0.3)
        .hex()
        .toString()
      : warnColor
        .darken(0.3)
        .hex()
        .toString();
  const backgroundColorError =
    theme.palette.type === 'light'
      ? errorColor
        .lighten(0.3)
        .hex()
        .toString()
      : errorColor
        .darken(0.1)
        .hex()
        .toString();
  const emphasize = color =>
    Color(color)
      .darken(0.3)
      .hex()
      .toString();
  const generateBackgroundColor = color => (
    theme.palette.type === 'light'
      ? color
        .darken(0.2)
        .hex()
        .toString()
      : color
        .darken(0.4)
        .hex()
        .toString()
  );
  const backgroundColorGreen = generateBackgroundColor(green);
  const backgroundColorYellow = generateBackgroundColor(yellow);
  const backgroundColorOrange = generateBackgroundColor(orange);
  const backgroundColorGrey = generateBackgroundColor(grey);

  const dropZoneStyles = {
    textAlign: 'center',
    transition: 'background 0.3s ease',
    '&:focus': {
      outline: 'none'
    },
    '&::-webkit-scrollbar': {
      backgroundColor: scrollbarBg
    },
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 16px rgba(0,0,0,0.1)`
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarThumbBg,
      border: `1px solid ${scrollbarThumbOutlineColor}`,
      outline: `1px solid ${scrollbarThumbOutlineColor}`,
      borderRadius: 8
    }
  };

  const listStyles = {
    ...dropZoneStyles,
    width: 300
  };

  const generateFillStyle = bg => ({
    fill: bg,
    color: theme.palette.getContrastText(bg)
  });
  const generateStrokeStyle = bg => ({
    stroke: bg,
    strokeWidth: '25px'
  });

  const styles = {
    greenFill: generateFillStyle(backgroundColorGreen),
    greenStroke: generateStrokeStyle(backgroundColorGreen),
    yellowFill: generateFillStyle(backgroundColorYellow),
    yellowStroke: generateStrokeStyle(backgroundColorYellow),
    orangeFill: generateFillStyle(backgroundColorOrange),
    orangeStroke: generateStrokeStyle(backgroundColorOrange),
    greyFill: generateFillStyle(backgroundColorGrey),
    greyStroke: generateStrokeStyle(backgroundColorGrey),


    list: {
      ...listStyles,
      '&::-webkit-scrollbar': {
        backgroundColor: scrollbarBg,
        borderRadius: 8
      },
      '&::-webkit-scrollbar-track': {
        '-webkitBoxShadow': `inset 0 0 16px rgba(0,0,0,0.1)`,
        borderRadius: 8
      }
    },
    lane: {
      ...listStyles,
      backgroundColor: backgroundColor,
      boxShadow: theme.shadows[2]
    },
    listCanDrop: {
      ...listStyles,
      backgroundColor: backgroundColorCanDrop
    },
    listActive: {
      ...listStyles,
      backgroundColor: backgroundColorActive
    },
    dropZone: {
      ...dropZoneStyles,
      boxShadow: wellShadow,
      backgroundColor: backgroundColor,
      color: theme.palette.getContrastText(backgroundColor)
    },
    dropZoneActive: {
      ...dropZoneStyles,
      boxShadow: wellShadow,
      backgroundColor: backgroundColorActive,
      color: theme.palette.getContrastText(backgroundColorActive)
    },
    dropZoneCanDrop: {
      ...dropZoneStyles,
      boxShadow: wellShadow,
      backgroundColor: backgroundColorCanDrop,
      color: theme.palette.getContrastText(backgroundColorCanDrop)
    },
    success: {
      backgroundColor: backgroundColorSuccess,
      color: theme.palette.getContrastText(backgroundColorSuccess)
    },
    warn: {
      backgroundColor: backgroundColorWarn,
      color: theme.palette.getContrastText(backgroundColorSuccess)
    },
    error: {
      backgroundColor: backgroundColorError,
      color: theme.palette.getContrastText(backgroundColorSuccess)
    },
    warnEmphasis: {
      backgroundColor: emphasize(backgroundColorWarn),
      color: theme.palette.getContrastText(emphasize(backgroundColorWarn))
    },
    successEmphasis: {
      backgroundColor: emphasize(backgroundColorSuccess),
      color: theme.palette.getContrastText(emphasize(backgroundColorSuccess))
    },
    errorEmphasis: {
      backgroundColor: emphasize(backgroundColorError),
      color: theme.palette.getContrastText(emphasize(backgroundColorError))
    }
  };

  return styles;
};

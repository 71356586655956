// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper, Typography, TableCell, Select
} from '@material-ui/core';
import { CheckBox, CheckBoxBlank} from '../../components/Icons';

import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting, IntegratedSelection, SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import LoadingIndicator from '../../components/LoadingIndicator';
import SiteDrawer from '../../components/SiteDrawer/SiteDrawer';
import PreceptorDrawer from '../../components/PreceptorDrawer/PreceptorDrawer';

import {
  showSiteDrawer, fetchSelectedSite
} from '../../actions/creators/siteDrawer';
import {
  showPreceptorDrawer, fetchSelectedPreceptor
} from '../../actions/creators/preceptorDrawer';

import { fetchPreceptorAttachments } from '../../actions/creators/attachments';
import { locationsByConfidence } from '../../actions/creators/locations';
import { fetchPermissions } from '../../actions/creators/permissions';
import { fetchStates } from '../../actions/creators/states';
import { fetchSpecialties } from '../../actions/creators/specialties';
import { fetchSiteValues } from '../../actions/creators/sites';

import type { User } from '../../schemas';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  flex: { flex: 1 },
  cell: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  cellCenter: {
    width: '100%',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  input: { width: '100%' },
  icon: { margin: theme.spacing(1) },
});

type Props = {
  classes: Object,
  colSpan: number,
  currentUser: User,
  permissions: Object,
  locations: any[],
  locationsLoading: boolean,
  locationsByConfidence: Function,
  fetchPermissions: Function,
  fetchPreceptor: Function,
  updatePreceptor: Function,
  fetchSite: Function,
  updateSite: Function,
  fetchStudent: Function,
  fetchSpecialties: Function,
  fetchStates: Function,
  fetchPreceptorAttachments: Function,
  fetchSiteValues: Function,
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  rows: any[],
  loading: boolean
};

class LocationsPage extends Component<Props, State> {
  state = {
    columns: [
      {
        name: 'name',
        title: 'Name',
        getCellValue: row => {
          if (row?.name) return row.name;
          if (row?.fullName) return row.fullName;
          return null;
        }
      },
      {
        name: 'address',
        title: 'Address',
        getCellValue: row => {
          if (row?.address) return row.address;
          if (row?.homeAddress) return row.homeAddress;
          return null;
        }
      },
      {
        name: 'city',
        title: 'City',
        getCellValue: row => {
          if (row?.city) return row.city;
          if (row?.homeCity) return row.homeCity;
          return null;
        }
      },
      {
        name: 'state',
        title: 'State',
        getCellValue: row => {
          if (row?.State?.id) return row.State.id;
          if (row?.homeState) return row.homeState;
          return null;
        }
      },
      {
        name: 'postalCode',
        title: 'Postal Code',
        getCellValue: row => {
          if (row?.postalCode) return row.postalCode;
          if (row?.homePostalCode) return row.homePostalCode;
          return null;
        }
      },
      {
        name: 'type',
        title: 'Type',
        getCellValue: row => {
          if (row?.Location?.model) return row.Location.model;
          return null;
        }
      }
    ],
    defaultFilters: [],
    defaultCurrentPage: 0,
    defaultPageSize: 10,
    defaultSorting: [{ columnName: 'type', direction: 'desc' }],
    pageSizes: [10, 20, 50, 100, 250],
    filteringStateColumnExtensions: [],
    selection: [],
    selectedSpecialty: undefined,
    showDrawer: false,
    rows: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.locations !== state.rows) {
      return { rows: props.locations };
    }
    if (props.locationsLoading !== state.loading) {
      return { loading: props.locationsLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.locationsByConfidence('0.0');
    await this.props.fetchPermissions();
    await this.props.fetchSpecialties();
    await this.props.fetchStates();
  };

  handleSelection = async selection => {
    const { rows } = this.state;
    const item = rows[selection];

    const type = `${item.Location.model.charAt(0).toUpperCase()}${item.Location.model.slice(1)}`;

    if (item.Location.model === 'preceptor') {
      await this.props.fetchSiteValues();
      await this.props.fetchPreceptorAttachments({ id: item.id });
    }

    await this.props[`fetchSelected${type}`]({ id: item.id });

    this.props[`show${type}Drawer`]();
  };

  render() {
    const { classes } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, pageSizes, filteringStateColumnExtensions,
      selection, rows, loading
    } = this.state;

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Select>
        </TableCell>
      );
    }

    function TypeFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value="preceptor">Preceptors</option>
            <option value="site">Sites</option>
          </Select>
        </TableCell>
      );
    }

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'isActive') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      if (props.column.name === 'type') {
        const newProps = { ...props, classes };
        return <TypeFilterCellBase {...newProps} />;
      }

      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'isActive') {
        return (
          <Table.Cell className={classes.cellCenter}>
            {props.value ? (
              <CheckBox className={classes.icon} />
            ) : (
              <CheckBoxBlank className={classes.icon} />
            )}
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h1">
          Geocode Errors
        </Typography>
        <Typography paragraph variant="subtitle1" component="em">
          These locations were unable to be geocoded. Please edit the location address and then save it.
        </Typography>
        <Paper>
          <Grid rows={rows} columns={columns}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState defaultSorting={defaultSorting} />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table
              cellComponent={props => Cell(props)}
              noDataCellComponent={props => NoDataCell(props)}
            />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => FilterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <SiteDrawer />
        <PreceptorDrawer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: state.locations.items,
    locationsLoading: state.locations.isLoading,
    selectedPreceptor: state.preceptorDrawer.selectedPreceptor,
    selectedSite: state.siteDrawer.selectedSite,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.specialty
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    locationsByConfidence,
    fetchPermissions,
    showSiteDrawer,
    fetchSelectedSite,
    showPreceptorDrawer,
    fetchSelectedPreceptor,
    fetchPreceptorAttachments,
    fetchSpecialties,
    fetchStates,
    fetchSiteValues
  })
)(LocationsPage): any);

// @flow

import {
  BEGIN_MASQUERADE,
  BEGIN_MASQUERADE_SUCCESS,
  BEGIN_MASQUERADE_ERROR,
  END_MASQUERADE,
  END_MASQUERADE_SUCCESS,
  END_MASQUERADE_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  FETCH_CURRENT_USER_SETTINGS,
  FETCH_CURRENT_USER_SETTINGS_ERROR,
  FETCH_CURRENT_USER_SETTINGS_SUCCESS
} from '../actions/types';

import type { Auth, User, UserSetting } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +isLoggedIn: boolean,
  +currentUser: ?User,
  +isMasquerading: boolean
};

type Action = {
  type:
    | typeof BEGIN_MASQUERADE
    | typeof BEGIN_MASQUERADE_SUCCESS
    | typeof BEGIN_MASQUERADE_ERROR
    | typeof END_MASQUERADE
    | typeof END_MASQUERADE_SUCCESS
    | typeof END_MASQUERADE_ERROR
    | typeof LOGIN_USER
    | typeof LOGIN_USER_SUCCESS
    | typeof LOGIN_USER_ERROR
    | typeof LOGOUT_USER
    | typeof LOGOUT_USER_SUCCESS
    | typeof LOGOUT_USER_ERROR
    | typeof FETCH_CURRENT_USER_SETTINGS
    | typeof FETCH_CURRENT_USER_SETTINGS_ERROR
    | typeof FETCH_CURRENT_USER_SETTINGS_SUCCESS,
  payload: Auth | UserSetting
};

const initialState = {
  currentUser: undefined,
  isMasquerading: false,
  isLoggedIn: false,
  isLoading: false,
  error: ''
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state,
        isLoading: true
      };
    case LOGIN_USER_SUCCESS:
      return { ...state,
        isLoading: false,
        isLoggedIn: true,
        currentUser: action.payload
      };
    case LOGIN_USER_ERROR:
      return { ...state,
        isLoading: false,
        error: action.payload
      };

    case LOGOUT_USER:
      return { ...state,
        isLoading: true
      };
    case LOGOUT_USER_SUCCESS:
      return { ...initialState };
    case LOGOUT_USER_ERROR:
      return { ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CURRENT_USER_SETTINGS:
      return {...state,
        isLoading: true
      };
    case FETCH_CURRENT_USER_SETTINGS_SUCCESS:
      return { ...state,
        isLoading: false,
        currentUser: updateCurrentUserSettings(state, action.payload)
      };
    case FETCH_CURRENT_USER_SETTINGS_ERROR:
      return { ...state,
        isLoading: false,
        error: action.payload
      };

    case BEGIN_MASQUERADE:
      return { ...state,
        isLoading: true
      };
    case BEGIN_MASQUERADE_SUCCESS:
      return { ...state,
        isLoading: false,
        currentUser: action.payload,
        isMasquerading: true
      };
    case BEGIN_MASQUERADE_ERROR:
      return { ...state,
        isLoading: false,
        error: action.payload
      };

    case END_MASQUERADE:
      return { ...state,
        isLoading: true
      };
    case END_MASQUERADE_SUCCESS:
      return { ...state,
        isLoading: false,
        currentUser: action.payload,
        isMasquerading: false
      };
    case END_MASQUERADE_ERROR:
      return { ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

function updateCurrentUserSettings(state, settings) {
  const currentUser = state.currentUser;
  return {
    ...currentUser,
    UserSettings: settings
  };
}

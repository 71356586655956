// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';

import { CreateIcon } from '../Icons';

import {
  createAttachmentA,
  fetchAttachmentAs,
  postAttachmentAWorkflowLogEntry
} from '../../actions/creators/attachmentAs';
import { updatePreceptor } from '../../actions/creators/placementSlots';
import { fetchPlacementAttachmentAs } from '../../actions/creators/placementDrawer';
import { createUserMessage } from '../../actions/creators/userMessages';
import { fetchUsers } from '../../actions/creators/users';

import { getContractsForTypeAndGroup } from '../../utils/placementHelpers';

import CreatePlacementAttachmentAForm from './CreatePlacementAttachmentAForm';

import type { User, Placement, SiteEnrollment, PreceptorEnrollment } from '../../schemas';

type Props = {
  classes: Object,
  mini: ?boolean,
  color: ?string,
  currentUser: User,
  users: User[],
  placement: Placement,
  siteEnrollment: SiteEnrollment,
  preceptorEnrollment: PreceptorEnrollment,
  displayOnly: ?boolean,
  fetchPlacementAttachmentAs: Function,
  fetchUsers: Function,
  fetchPlacementAttachmentsA: Function,
  fetchAttachmentAs: Function,
  postAttachmentAWorkflowLogEntry: Function,
  createAttachmentA: Function,
  updatePreceptor: Function,
  createUserMessage: Function
};

type State = {
  dialogVisible: boolean,
  template: Object
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  icon: {
    fontSize: 18,
    marginLeft: theme.spacing(1)
  }
});

const determineContractId = (placement, siteEnrollment) => {
  // find the contract for the site that matches the contract type of the placement.
  const placementContractTypeId = _.get(
    placement,
    'Course.CatalogNumber.CourseAttr.ContractTypeId',
    ''
  );

  const activeContracts = getContractsForTypeAndGroup(
    placementContractTypeId, siteEnrollment, 'active'
  );

  const requestedContracts = getContractsForTypeAndGroup(
    placementContractTypeId, siteEnrollment, 'request'
  );

  const contracts = _.concat(activeContracts, requestedContracts);

  if (contracts.length > 0) {
    return activeContracts.length > 0
      ? activeContracts[0].id
      : requestedContracts[0].id;
  }

  return undefined;
};

class CreatePlacementAttachmentAButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { placement, siteEnrollment } = props;
    const course = _.get(placement, 'Course', undefined);
    let templateDescription = 'No Description Available';

    if (course) {
      templateDescription = `${course.CatalogNumberId} | ${course.description}`;
    }

    let template = {
      StartTermId: _.get(placement, 'TermId', undefined),
      CourseId: _.get(placement, 'CourseId', undefined),
      description: templateDescription,
      SiteId: siteEnrollment.Site.id,
      ContractId: determineContractId(placement, siteEnrollment),
      AttachmentAStatusCodeId: 'R',
      startDate: null,
      endDate: null,
      dateRequested: Date.now(),
      willSend: false,
    };

    this.state = {
      dialogVisible: false,
      template
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  usersForRole = role => {
    const { users } = this.props;
    return _.filter(users, user => _.includes(_.map(user.Roles, 'slug'), role));
  };

  handleOpen = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleNotification = async props => {
    const { createUserMessage, currentUser } = this.props;
    const usersForRole = this.usersForRole('contracts_manager');

    let userMessage = `You created AttachmentA #${props.attachmentA.id}.`;
    let roleMessage = `${currentUser.name} created AttachmentA #${props.attachmentA.id} and requested that you send it.`;
    if (props.willSend) {
      userMessage = `You created AttachmentA #${props.attachmentA.id} that you agreed to send.`;
      roleMessage = `${currentUser.name} created AttachmentA #${props.attachmentA.id}.`;
    }

    createUserMessage({
      message: userMessage, tag: 'attachment-a', SenderId: currentUser.id,
      RecipientId: currentUser.id
    });

    for (const user of usersForRole) {
      createUserMessage({
        message: roleMessage, tag: 'attachment-a', SenderId: currentUser.id,
        RecipientId: user.id
      });
    }

    return;
  };

  handleSubmit = async values => {
    const {
      createAttachmentA, fetchPlacementAttachmentAs, fetchAttachmentAs,
      placement, currentUser, postAttachmentAWorkflowLogEntry, updatePreceptor,
      preceptorEnrollment
    } = this.props;

    const willSend = values.willSend;
    delete values.willSend;

    let result = await createAttachmentA(values);

    if (result) {
      const attachmentA = result;

      let workflowItem = {
        AttachmentAId: attachmentA.id,
        UserId: currentUser.id,
        StatusChangedToId: 'R'
      };

      await postAttachmentAWorkflowLogEntry(workflowItem);

      preceptorEnrollment.AttachmentAId = attachmentA.id;
      await updatePreceptor( placement, preceptorEnrollment );

      await fetchAttachmentAs();
      await fetchPlacementAttachmentAs( placement.id );
      await this.handleNotification({ attachmentA, willSend });

      this.handleClose();
    }
  };

  render() {
    const { placement, siteEnrollment, displayOnly, classes } = this.props;
    const { dialogVisible, template } = this.state;

    return (
      <div>
        {!displayOnly && (
          <Button
            color={_.get(this.props, 'color', 'primary')}
            variant={_.get(this.props, 'variant', 'outlined')}
            size={_.get(this.props, 'size', 'small')}
            className={classes.button}
            onClick={this.handleOpen}
          >
            Create
            <CreateIcon className={classes.icon} />
          </Button>
        )}

        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="create-attachment-dialog"
        >
          <DialogTitle id="create-attachment-dialog">
            Create Attachment A
          </DialogTitle>
          <DialogContent>
            {placement && (
              <CreatePlacementAttachmentAForm
                placement={placement}
                siteEnrollment={siteEnrollment}
                initialValues={template}
                handleSubmit={this.handleSubmit}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.users.items
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchUsers, createAttachmentA, fetchPlacementAttachmentAs, fetchAttachmentAs,
    postAttachmentAWorkflowLogEntry, updatePreceptor, createUserMessage
  }),
  withStyles(styles)
)(CreatePlacementAttachmentAButton): any);

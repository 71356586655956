// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Grow, Tabs, Tab, AppBar, Button, Paper, Typography, Toolbar as MaterialToolbar
} from '@material-ui/core';
import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';

import { CheckBox, CheckBoxBlank } from '../../components/Icons';

import type { Organization, User } from '../../schemas';
import BooleanFilterCellBase from '../../components/ReactGrid/BooleanFilterCellBase';

import { fetchOrganizationList, mergeOrganizations } from '../../actions/creators/organizations';
import { fetchPermissions } from '../../actions/creators/permissions';

import LoadingIndicator from '../../components/LoadingIndicator';
import { hasAccess } from '../../utils/permissionsCheck';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  appBar: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  button: {
    margin: theme.spacing(1),
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

type Props = {
  classes: Object,
  organizations: Organization[],
  dataLoading: boolean,
  currentUser: User,
  permissions: Object,
  fetchOrganizationList: Function,
  mergeOrganizations: Function,
  fetchPermissions: Function,
  colSpan: number
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  integratedFilteringColumnExtensions: any[],
  defaultColumnWidths: Object[],
  master: number,
  duplicates: number[],
  tabValue: any,
  rows: Organization[],
  loading: boolean
};

class OrganizationsMerge extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'isMaster', title: 'Master' },
        { name: 'isDuplicate', title: 'Duplicate' },
        { name: 'id', title: 'ID' },
        { name: 'name', title: 'Name' },
        { name: 'city', title: 'City' },
        { name: 'StateId', title: 'State' },
        { name: 'postalCode', title: 'Zip Code' }
      ],
      defaultColumnWidths: [
        { columnName: 'isMaster', width: 110 },
        { columnName: 'isDuplicate', width: 110 },
        { columnName: 'id', width: 80 },
        { columnName: 'name', width: 480 },
        { columnName: 'city', width: 140 },
        { columnName: 'StateId', width: 80 },
        { columnName: 'postalCode', width: 120 }
      ],
      defaultFilters: [],
      defaultCurrentPage: 0,
      defaultPageSize: 10,
      defaultSorting: [
        { columnName: 'name', direction: 'asc' },
        { columnName: 'city', direction: 'asc' }
      ],
      pageSizes: [10, 20, 50, 100, 250],
      filteringStateColumnExtensions: [
        { columnName: 'isMaster', filteringEnabled: false },
        { columnName: 'isDuplicate', filteringEnabled: false }
      ],
      integratedFilteringColumnExtensions: [],
      master: 0,
      duplicates: [],
      tabValue: null,
      rows: [],
      loading: true,
      handleFilterOrganizations: this.handleFilterOrganizations
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    const rows = state.handleFilterOrganizations(props.organizations, state.tabValue);

    if (rows !== state.rows) {
      return { rows: rows };
    }
    if (props.dataLoading !== state.loading) {
      return { loading: props.dataLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchOrganizationList();
    await this.props.fetchPermissions();
  };

  componentFilterCell = props => {
    if (props.column.name === 'isActive') {
      return <BooleanFilterCellBase {...props} />;
    }

    return <TableFilterRow.Cell {...props} />;
  };

  componentNoDataCell = props => {
    const { classes } = this.props;
    const { loading } = this.state;
    const { colSpan } = props;
    return (
      <Table.Cell colSpan={colSpan}>
        {loading ? (
          <LoadingIndicator />
        ) :
          <div className={classes.centerContainer}>
            No data to view...
          </div>
        }
      </Table.Cell>
    );
  };

  componentCell = props => {
    const { classes } = this.props;
    const { master, duplicates } = this.state;
    if (props.column.name === 'isMaster') {
      return (
        <Table.Cell>
          <div className={classes.centerContainer}>
            {props.row.id === master ? (
              <CheckBox
                color="secondary"
                onClick={() => this.handleMasterSelection({...props})}
                className={classes.icon}
              />
            ) : (
              <CheckBoxBlank
                onClick={() => this.handleMasterSelection({...props})}
                className={classes.icon}
              />
            )}
          </div>
        </Table.Cell>
      );
    }
    if (props.column.name === 'isDuplicate') {
      return (
        <Table.Cell>
          <div className={classes.centerContainer}>
            {_.includes(duplicates, props.row.id) ? (
              <CheckBox
                color="primary"
                onClick={() => this.handleDuplicateSelection({...props})}
                className={classes.icon}
              />
            ) : (
              <CheckBoxBlank
                onClick={() => this.handleDuplicateSelection({...props})}
                className={classes.icon}
              />
            )}
          </div>
        </Table.Cell>
      );
    }

    return <Table.Cell {...props} />;
  };

  canMergeOrganization = () => {
    const { currentUser, permissions } = this.props;
    const { master, duplicates } = this.state;
    const hasMergeAccess = hasAccess(currentUser.Roles, _.get(permissions, 'merge', []));
    const hasSelections = (duplicates.length && master);
    return (hasMergeAccess && hasSelections);
  };

  handleFilterOrganizations = (organizations, value) => {
    const isActive = value === 'true' ? true : false;
    if (!value) {
      return organizations;
    }
    return _.filter(organizations, { isActive: isActive });
  };

  handleTabChange = (event, value) => {
    const { organizations } = this.props;
    let rows = this.handleFilterOrganizations(organizations, value);
    this.setState({ tabValue: value, rows });
  };

  handleMasterSelection = props => {
    const { master, duplicates } = this.state;
    if (_.includes(duplicates, props.row.id)) {
      duplicates.splice(duplicates.indexOf(props.row.id), 1);
    }
    if (props.row.id !== master) {
      this.setState({ master: props.row.id });
    } else {
      this.setState({ master: 0 });
    }
  };

  handleDuplicateSelection = async props => {
    const { master, duplicates } = this.state;
    if (props.row.id === master) {
      await this.setState({ master: 0, duplicates });
    }
    if (!_.includes(duplicates, props.row.id)) {
      duplicates.push(props.row.id);
    } else {
      duplicates.splice(duplicates.indexOf(props.row.id), 1);
    }
    this.setState({ duplicates: duplicates });
  };

  handleMerge = async () => {
    const { master, duplicates } = this.state;
    const confirmation = window.confirm(
      `Are you sure you want to merge ${duplicates.length} Duplicate(s) into the selected Master?`
    );
    if (confirmation) {
      await this.props.mergeOrganizations({
        masterOrg: master,
        dupOrgs: duplicates
      });
      this.setState({ master: 0, duplicates: [] });
    }
  };

  renderMergeOrganizationButton = () => {
    const { classes } = this.props;
    return (
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={this.handleMerge}
        className={classes.button}
      >
        Merge Organizations
      </Button>
    );
  };

  renderAppBar = () => {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <AppBar position="static" className={classes.appBar}>
        <MaterialToolbar>
          <Tabs
            value={tabValue}
            onChange={this.handleTabChange}
            className={classes.flex}
          >
            <Tab value={null} label="All Organizations" />
            <Tab value={'true'} label="Active Organizations" />
            <Tab value={'false'} label="Inactive Organizations" />
          </Tabs>
          {this.canMergeOrganization()
            ? this.renderMergeOrganizationButton()
            : null
          }
        </MaterialToolbar>
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, defaultColumnWidths, pageSizes, rows, loading,
      filteringStateColumnExtensions, integratedFilteringColumnExtensions
    } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h1">
          Merge Organizations
        </Typography>
        <Typography paragraph variant="subtitle1" component="em">
          Select a Master Organization and any number of Duplicates to merge into the selected Master Organization.
        </Typography>
        {loading && <LoadingIndicator loading={loading} overlay />}
        <Grow in={!loading} timeout={500}>
          <Paper>
            {this.renderAppBar()}
            <Grid rows={rows} columns={columns}>
              <FilteringState
                defaultFilters={defaultFilters}
                columnExtensions={filteringStateColumnExtensions}
              />
              <PagingState
                defaultCurrentPage={defaultCurrentPage}
                defaultPageSize={defaultPageSize}
              />
              <SortingState defaultSorting={defaultSorting} />

              <IntegratedFiltering
                columnExtensions={integratedFilteringColumnExtensions}
              />
              <IntegratedSorting />
              <IntegratedPaging />

              <Table
                cellComponent={props => this.componentCell(props)}
                noDataCellComponent={props => this.componentNoDataCell(props)}
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow cellComponent={props => this.componentFilterCell(props)} />

              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Grow>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.items,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.organization,
    dataLoading: _.some([
      state.organizations.isLoading,
      state.permissions.isLoading
    ]),
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchOrganizationList,
    mergeOrganizations,
    fetchPermissions
  })
)(OrganizationsMerge): any);

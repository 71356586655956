import axios from '../../api';
import * as actions from '../types';

export const fetchPlacementStatuses = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENT_STATUSES, payload: undefined });
    const res = await axios.get('/placementStatusCode');
    dispatch({
      type: actions.FETCH_PLACEMENT_STATUSES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PLACEMENT_STATUSES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow



import React, { Component } from 'react';

import { connect } from 'react-redux';
import type { Node } from 'react';
import socketIOClient from 'socket.io-client';

import { store } from '../index';
import { notify } from 'reapop';

import { fetchUsers } from '../actions/creators/users';
import { fetchSpecialties } from '../actions/creators/specialties';
import { fetchPermissions } from '../actions/creators/permissions';
import { fetchUserMessages } from '../actions/creators/userMessages';
import type { User } from '../schemas/';

type Props = {
  children: Node,
  currentUser: User,
  fetchUsers: Function,
  fetchSpecialties: Function,
  fetchPermissions: Function,
  fetchUserMessages: Function,
};

let ioConfig = {
  url: process.env.REACT_APP_BASE_API_URL,
  options: {
    secure: false,
    path: '/api/socket.io',
    transports: ['websocket']
  }
};

if (process.env.REACT_APP_BUILD_ENV !== 'local') {
  ioConfig.options.secure = true;
}

const events = [
  'user',
  'role',
  'specialty',
  'term',
  'termAttr',
  'site',
  'placement',
  'placementApp',
  'preceptor',
  'organization'
];

class SocketContainer extends Component<Props> {

  componentDidMount() {
    const socket = socketIOClient(ioConfig.url, ioConfig.options);

    this.props.fetchPermissions();

    events.forEach(event => {
      socket.on(`${event}Event`, el => {
        if (el.action === 'error') {
          this.handleErrorEvent({ title: event.toUpperCase(), message: el.message });
        } else {
          this.handleSuccessEvent({ object: el.object, action: `${event}-${el.action}`});
        }
      });
    });

    socket.on(`userMessageEvent`, el => {
      const { fetchUserMessages, currentUser } = this.props;
      if (
        el.object.RecipientId === currentUser.id ||
        el.object.SenderId === currentUser.id
      ) {
        fetchUserMessages(currentUser);
      }
    });

    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['polling', 'websocket'];
    });
  }

  handleSuccessEvent(el) {
    const action = el.action.split('-');
    store.dispatch(
      notify({
        title: `${action[0]} ${action[1]}`,
        message: `${action[0].charAt(0).toUpperCase() +
          action[0].slice(1)} ID "${el.object.id}" has been successfully ${
          action[1]
        }!`,
        status: 'success',
      })
    );
  }

  handleErrorEvent = el => {
    store.dispatch(
      notify({
        title: el.title,
        message: el.message,
        status: 'error',
      })
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this.props.children}
      </div>
    );
  }
}

export default (connect(
  state => ({
    currentUser: state.auth.currentUser
  }),
  {
    fetchUsers,
    fetchSpecialties,
    fetchPermissions,
    fetchUserMessages
  }
)(SocketContainer): any);

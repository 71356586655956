// @flow

import _ from 'lodash';

import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

import {fetchPreceptor,updatePreceptor} from '../../../actions/creators/preceptors';
import {fetchStates} from '../../../actions/creators/states';
import {fetchSpecialties} from '../../../actions/creators/specialties';
import {fetchSiteList} from '../../../actions/creators/sites';

import {getSelectedPreceptor} from '../../../selectors/preceptors';
import {getAllStateValues} from '../../../selectors/states';
import {getAllSpecialtyValues} from '../../../selectors/specialties';
import {getAllSiteValues} from '../../../selectors/sites';

import {useCached} from '../../../hooks';
import {hasAccess} from '../../../utils/permissionsCheck';

import PreceptorForm from './PreceptorForm';

type Props = {
  id: number,
  model: string,
  handleClose: Function
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PreceptorDialog = (props: Props): React$Element<React$FragmentType> => {
  const {id,model,handleClose} = props;
  const dispatch = useDispatch();

  const preceptor = useSelector(getSelectedPreceptor);
  const permissions = useSelector(state => state.permissions.preceptor);
  const currentUser = useSelector(state => state.auth.currentUser);
  const states = useCached(useSelector(getAllStateValues));
  const specialties = useCached(useSelector(getAllSpecialtyValues));
  const sites = useCached(useSelector(getAllSiteValues));

  let haveAssociations = Boolean(
    states.length && specialties.length && sites.length
  );

  useEffect(() => {
    dispatch(fetchPreceptor({ id: id }));
  }, [dispatch,id]);

  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchStates());
      dispatch(fetchSpecialties());
      dispatch(fetchSiteList());
    }
  }, [dispatch,haveAssociations]);

  const handleSubmit = async values => {
    values.GeocodeStatusId = null;
    await dispatch(updatePreceptor(values));
    handleClose(true);
  };

  const canUpdate = hasAccess(currentUser.Roles, permissions.update);
  const objectMatch = _.get(preceptor, 'id', undefined) === id;

  return (
    <React.Fragment>
      {(!objectMatch || !haveAssociations) &&  <DialogTitle id={`${model}-${id}-dialog`}>
        <LinearProgress variant="query" color="primary" />
      </DialogTitle>}
      {objectMatch && preceptor && haveAssociations && <DialogTitle id={`${model}-${id}-dialog`} onClose={() => handleClose()}>
        Preceptor: {preceptor.name}{preceptor.credentials ? ', ' + preceptor.credentials : ''}
      </DialogTitle>}
      {objectMatch && haveAssociations && <DialogContent dividers>
        {<PreceptorForm
          initialValues={preceptor}
          canUpdate={canUpdate}
          handleSubmit={handleSubmit}
          associations={{states,specialties,sites}}
        />}
      </DialogContent>}
    </React.Fragment>
  );
};

export default PreceptorDialog;

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import Toolbar from './Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

type Classes = {
  root: Object
};

type Props = {
  items: any[],
  classes: Classes,
  renderItem: Function,
  renderEmpty: Function,
  title: string
};

type State = {
  rowsPerPage: number,
  currentPageIndex: number,
  lastPageIndex: number,
  items: any[],
};

const calculateLastIndex = (rowsPerPage, items) => {
  return Math.floor(items.length / rowsPerPage);
};

const calculateItemRange = (
  currentPageIndex,
  rowsPerPage,
  collectionLength
): Object => {
  let min = currentPageIndex * rowsPerPage;
  let calculatedMax = currentPageIndex * rowsPerPage + rowsPerPage;
  let max =
    calculatedMax <= collectionLength ? calculatedMax : collectionLength;

  return { min, max };
};

const styles = theme => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(2)
  }
});

class PaginatedList extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 5,
      currentPageIndex: 0,
      lastPageIndex: 0,
      items: [],
    };
  }

  componentDidMount() {
    const { items } = this.props;
    this.setLastPageIndex(items);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.items !== state.items) {
      const lastPageIndex = calculateLastIndex(state.rowsPerPage, props.items);
      return {
        items: props.items,
        lastPageIndex
      };
    }

    return null;
  }

  setLastPageIndex = items => {
    const { rowsPerPage } = this.state;

    const lastPageIndex = calculateLastIndex(rowsPerPage, items);
    this.setState({ lastPageIndex });
  };

  handleRowsPerPageChange = (rowsPerPage: number) => {
    const { items } = this.props;
    const lastPageIndex = calculateLastIndex(rowsPerPage, items);

    this.setState({ rowsPerPage, lastPageIndex, currentPageIndex: 0 });
  };

  handlePageChange = (newIndex: number) => {
    this.setState({ currentPageIndex: newIndex });
  };

  render() {
    const { rowsPerPage, currentPageIndex, lastPageIndex } = this.state;
    const { classes, items, renderItem, title, renderEmpty } = this.props;
    let itemRange = calculateItemRange(
      currentPageIndex,
      rowsPerPage,
      items.length
    );

    const displayItems: any[] = items.slice(itemRange.min, itemRange.max);
    const isEmpty = items.length === 0;

    return (
      <div>
        {!isEmpty && (
          <div>
            <Toolbar
              rowsPerPage={rowsPerPage}
              currentPageIndex={currentPageIndex}
              lastPageIndex={lastPageIndex}
              collectionLength={items.length}
              handleRowsPerPageChange={this.handleRowsPerPageChange}
              handlePageChange={this.handlePageChange}
              calculateItemRange={calculateItemRange}
              title={title}
            />

            <List className={classes.root}>{displayItems.map(renderItem)}</List>

            <Toolbar
              rowsPerPage={rowsPerPage}
              currentPageIndex={currentPageIndex}
              lastPageIndex={lastPageIndex}
              collectionLength={items.length}
              handleRowsPerPageChange={this.handleRowsPerPageChange}
              handlePageChange={this.handlePageChange}
              calculateItemRange={calculateItemRange}
              title=""
            />
          </div>
        )}
        {isEmpty && (
          <div className={classes.root}>{renderEmpty && renderEmpty()}</div>
        )}
      </div>
    );
  }
}

export default (compose(withStyles(styles))(PaginatedList): any);

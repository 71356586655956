// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Drawer, IconButton } from '@material-ui/core';

import navItems from '../../config/preceptorDrawerNavigationItems';
import { hidePreceptorDrawer } from '../../actions/creators/preceptorDrawer';
import { CloseIcon } from '../Icons';

import PreceptorDrawerNavigation from './PreceptorDrawerNavigation';
import PreceptorDrawerSections from './PreceptorDrawerSections';

import type { Preceptor } from '../../schemas';

type Props = {
  selectedPreceptor: Preceptor,
  isVisible: boolean,
  hidePreceptorDrawer: Function,
  classes: Object,
  currentSection: Object
};

const styles = theme => ({
  root: {
    overflow: 'hidden',
    minHeight: '50vh'
  },
  content: {
    marginLeft: '64px',
    paddingLeft: theme.spacing(2),
    minHeight: '50vh',
    overflow: 'scroll'
  },
  button: {
    margin: theme.spacing(1),
    float: 'right'
  },
});

class PreceptorDrawer extends Component<Props> {
  render() {
    const {
      classes,
      isVisible,
      hidePreceptorDrawer,
      currentSection
    } = this.props;
    return (
      <div className={classes.root}>
        <Drawer anchor="bottom" open={isVisible} onClose={hidePreceptorDrawer}>
          <PreceptorDrawerNavigation items={navItems} />
          <div className={classes.content}>
            <IconButton
              aria-label="Close"
              onClick={hidePreceptorDrawer}
              className={classes.button}
            >
              <CloseIcon />
            </IconButton>
            <PreceptorDrawerSections currentSection={currentSection} />
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.preceptorDrawer
  };
};

export default (compose(
  connect(mapStateToProps, { hidePreceptorDrawer }),
  withStyles(styles, { name: 'PreceptorDrawer', withTheme: true })
)(PreceptorDrawer): any);

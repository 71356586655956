// @flow

import * as React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {
  Grid,Button,Input,InputLabel,Select,MenuItem,FormControl,IconButton
} from '@material-ui/core';

import {CloseIcon} from '../../components/Icons';

type Props = {
  isVisible: boolean,
  isEnabled: boolean,
  placementFilters: Object,
  handleClose: Function,
  handlePlacementFiltersChange: Function,
  handleClearFilters: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      position: 'fixed',
      boxSizing: 'border-box',
      height: 'calc(100% - 64px)',
      width: '240px',
      padding: '8px',
      right: '-240px',
      top: '64px',
      backgroundColor: theme.palette.background.paper,
      flexDirection: 'column',
      boxShadow: theme.shadows[2],
      zIndex: theme.zIndex.appBar,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard
      })
    },
    closeIcon: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    formControl: {
      width: '100%'
    }
  };
});

function PlacementFilters(props: Props): React$Element<"div"> {
  const {
    isVisible,isEnabled,placementFilters,handleClose,handleClearFilters,
    handlePlacementFiltersChange
  } = props;
  const classes = useStyles();

  const coordinators = useSelector(state => state.coordinators.items);

  function rightPosition() {
    return isVisible && isEnabled ? 0 : '-240px';
  }

  function onClearFilters(e) {
    handleClearFilters(e);
    handleClose();
  }

  function onFilterChange(e) {
    handlePlacementFiltersChange(e);
  }

  function renderCoordinatorsList(items) {
    if (items !== undefined) {
      return items.map(item => (
        <MenuItem key={item.username} value={item.username}>
          {item.name}
        </MenuItem>
      ));
    }
  }

  return (
    <div className={classes.root} style={{ right: rightPosition() }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.closeIcon}>
          <IconButton
            className="material-icons"
            onClick={() => handleClose()}
            label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <h3>Filters</h3>
        </Grid>
        {placementFilters['Student.fullName'] && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="student">Student</InputLabel>
              <Input
                type="text"
                placeholder="Student"
                id="student"
                name="Student.fullName"
                alt="Student Name"
                value={placementFilters['Student.fullName'].value}
                onChange={e => onFilterChange(e)}
              />
            </FormControl>
          </Grid>
        )}
        {placementFilters['Course.CatalogNumberId'] && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="course-number">Course Number</InputLabel>
              <Input
                type="text"
                placeholder="Course Number"
                id="course-number"
                name="Course.CatalogNumberId"
                alt="Course Number"
                value={placementFilters['Course.CatalogNumberId'].value}
                onChange={e =>
                  onFilterChange(e)
                }
              />
            </FormControl>
          </Grid>
        )}
        {placementFilters['managerId'] && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="coordinator-id">Coordinator</InputLabel>
              <Select
                value={placementFilters['managerId'].value}
                name="managerId"
                onChange={e => onFilterChange(e)}
                input={<Input id="coordinator-id" />}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {renderCoordinatorsList(coordinators)}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} />
        <Button
          variant="contained"
          color="primary"
          onClick={e => onClearFilters(e)}
        >
          Clear Filters
        </Button>
      </Grid>
    </div>
  );
}

export default (PlacementFilters);

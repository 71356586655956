// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Typography } from '@material-ui/core';

import ContractDetail from '../../Contract/ContractDetail';
import type { Contract } from '../../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  isLoading: boolean
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }
});

class ContractDetails extends Component<Props> {
  render() {
    const { classes, contract } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Contract Details
        </Typography>

        <ContractDetail contract={contract} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.contractDrawer.selectedContract
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  ContractDetails
): any);

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import OrganizationForm from '../Organization/OrganizationForm';
import { createOrganization, fetchOrganizations } from '../../actions/creators/organizations';

type Classes = {
  centered: Object,
  dialogContent: Object,
  dialogActions: Object
};

type Props = {
  open: boolean,
  searchValue: string,
  onClose: Function,
  handleClose: Function,
  classes: Classes,
  fetchOrganizations: Function,
  createOrganization: Function
};

const styles = theme => ({
  centered: {
    textAlign: 'center'
  },
  dialogContent: {
    maxHeight: 435,
    padding: 0,
    marginBottom: 0
  },
  dialogActions: {
    paddingTop: theme.spacing(1),
    marginTop: 0
  }
});

class OrganizationAddDialog extends Component<Props> {

  handleSubmit = async values => {
    const { fetchOrganizations, createOrganization, handleClose } = this.props;
    await createOrganization(values);
    handleClose();
    await fetchOrganizations();
  };

  render() {
    const { open, onClose, handleClose, searchValue } = this.props;

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="list-dialog">
        <DialogTitle id="list-dialog">Create an Organization</DialogTitle>
        <DialogContent>
          <OrganizationForm
            handleClose={handleClose}
            canUpdate={true}
            canDelete={false}
            handleSubmit={this.handleSubmit}
            initialValues={{
              isActive: true, name: searchValue, CountryId: 'US'
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default (compose(
  connect(null, { fetchOrganizations, createOrganization }),
  withStyles(styles)
)(OrganizationAddDialog): any);

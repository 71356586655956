// @flow
import {
  SHOW_PLACEMENT_DRAWER,
  HIDE_PLACEMENT_DRAWER,
  CHANGE_PLACEMENT_DRAWER_SECTION,
  FETCH_SELECTED_PLACEMENT,
  FETCH_SELECTED_PLACEMENT_SUCCESS,
  FETCH_SELECTED_PLACEMENT_ERROR,
  FETCH_STASHED_PLACEMENT_SLOTS,
  FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS,
  FETCH_STASHED_PLACEMENT_SLOTS_ERROR,
  FETCH_STUDENT_HISTORY,
  FETCH_STUDENT_HISTORY_ERROR,
  FETCH_STUDENT_HISTORY_SUCCESS,
  FETCH_SELECTED_PLACEMENT_NOTES,
  FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS,
  FETCH_SELECTED_PLACEMENT_NOTES_ERROR,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS,
  FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR,
  FETCH_PLACEMENT_TODOS,
  FETCH_PLACEMENT_TODOS_SUCCESS,
  FETCH_PLACEMENT_TODOS_ERROR,
  FETCH_PLACEMENT_APPLICATION,
  FETCH_PLACEMENT_APPLICATION_SUCCESS,
  FETCH_PLACEMENT_APPLICATION_ERROR,
  SET_SITE_FILTERS,
  SET_SITE_FILTERS_ERROR,
  SET_PRECEPTOR_FILTERS,
  SET_PRECEPTOR_FILTERS_ERROR
} from '../actions/types';
import type {
  Placement,
  DrawerNavigationItem,
  SiteEnrollment,
  Note,
  AttachmentA,
  Attachment,
  Todo,
  PlacementApp
} from '../schemas';
import navItems from '../config/placementDrawerNavigationItems';

type State = {
  +isVisible: boolean,
  +selectedPlacement: ?Placement,
  +currentSection: DrawerNavigationItem,
  +sections: DrawerNavigationItem[],
  +isLoading: boolean,
  +error: ?string,
  +stashedPlacementSlots: SiteEnrollment[],
  +studentHistory: Placement[],
  +notes: Note[],
  +todos: Todo[],
  +attachmentAs: AttachmentA[],
  +attachments: Attachment[],
  +application: ?PlacementApp,
  +siteFilters: Object,
  +preceptorFilters: Object,
};

type Action = {
  type:
    | typeof SHOW_PLACEMENT_DRAWER
    | typeof HIDE_PLACEMENT_DRAWER
    | typeof CHANGE_PLACEMENT_DRAWER_SECTION
    | typeof FETCH_SELECTED_PLACEMENT
    | typeof FETCH_SELECTED_PLACEMENT_SUCCESS
    | typeof FETCH_SELECTED_PLACEMENT_ERROR
    | typeof FETCH_STASHED_PLACEMENT_SLOTS
    | typeof FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS
    | typeof FETCH_STASHED_PLACEMENT_SLOTS_ERROR
    | typeof FETCH_STUDENT_HISTORY
    | typeof FETCH_STUDENT_HISTORY_ERROR
    | typeof FETCH_STUDENT_HISTORY_SUCCESS
    | typeof FETCH_SELECTED_PLACEMENT_NOTES
    | typeof FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS
    | typeof FETCH_SELECTED_PLACEMENT_NOTES_ERROR
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS
    | typeof FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR
    | typeof FETCH_PLACEMENT_TODOS
    | typeof FETCH_PLACEMENT_TODOS_SUCCESS
    | typeof FETCH_PLACEMENT_TODOS_ERROR
    | typeof FETCH_PLACEMENT_APPLICATION
    | typeof FETCH_PLACEMENT_APPLICATION_SUCCESS
    | typeof FETCH_PLACEMENT_APPLICATION_ERROR
    | typeof SET_SITE_FILTERS
    | typeof SET_SITE_FILTERS_ERROR
    | typeof SET_PRECEPTOR_FILTERS
    | typeof SET_PRECEPTOR_FILTERS_ERROR,
  payload: Object
};

const initialState = {
  isVisible: false,
  selectedPlacement: undefined,
  currentSection: navItems[0],
  sections: navItems,
  isLoading: false,
  error: undefined,
  stashedPlacementSlots: [],
  studentHistory: [],
  notes: [],
  todos: [],
  attachmentAs: [],
  attachments: [],
  application: undefined,
  siteFilters: undefined,
  preceptorFilters: undefined,
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case SHOW_PLACEMENT_DRAWER:
      return {
        ...state,
        isVisible: true,
        currentSection: initialState.currentSection
      };

    case HIDE_PLACEMENT_DRAWER:
      return initialState;

    case CHANGE_PLACEMENT_DRAWER_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    case FETCH_SELECTED_PLACEMENT:
    case FETCH_STUDENT_HISTORY:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SELECTED_PLACEMENT_SUCCESS:
      return {
        ...state,
        selectedPlacement: action.payload,
        isLoading: false
      };
    case FETCH_SELECTED_PLACEMENT_ERROR:
      return {
        ...state,
        selectedPlacement: initialState.selectedPlacement,
        isLoading: false,
        error: action.payload
      };

    case FETCH_STASHED_PLACEMENT_SLOTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stashedPlacementSlots: action.payload
      };
    case FETCH_STASHED_PLACEMENT_SLOTS_ERROR:
      return {
        ...state,
        isLoading: false,
        stashedPlacementSlots: initialState.stashedPlacementSlots,
        error: action.payload
      };

    case FETCH_STUDENT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentHistory: action.payload
      };
    case FETCH_STUDENT_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_SELECTED_PLACEMENT_NOTES:
      return {
        ...state,
        isLoading: true,
        notes: initialState.notes
      };
    case FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notes: action.payload
      };
    case FETCH_SELECTED_PLACEMENT_NOTES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        notes: initialState.notes
      };

    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A:
      return {
        ...state,
        isLoading: true,
        attachmentAs: initialState.attachmentAs
      };
    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS:
      return {
        ...state,
        isLoading: false,
        attachmentAs: action.payload
      };
    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        attachmentAs: initialState.attachmentAs
      };

    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS:
      return {
        ...state,
        isLoading: true,
        attachments: initialState.attachments
      };
    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        attachments: action.payload
      };
    case FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        attachments: initialState.attachments
      };

    case FETCH_PLACEMENT_TODOS:
      return {
        ...state,
        isLoading: true,
        todos: initialState.todos
      };
    case FETCH_PLACEMENT_TODOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        todos: action.payload
      };
    case FETCH_PLACEMENT_TODOS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        todos: initialState.todos
      };

    case FETCH_PLACEMENT_APPLICATION:
      return {
        ...state,
        isLoading: true,
        application: initialState.application
      };
    case FETCH_PLACEMENT_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        application: action.payload
      };
    case FETCH_PLACEMENT_APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        application: initialState.application
      };

    case SET_SITE_FILTERS:
      return { ...state, siteFilters: action.payload };
    case SET_SITE_FILTERS_ERROR:
      return { ...state, error: action.payload };
    case SET_PRECEPTOR_FILTERS:
      return { ...state, preceptorFilters: action.payload };
    case SET_PRECEPTOR_FILTERS_ERROR:
      return { ...state, error: action.payload };


    default:
      return state;
  }
};


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllPlacements = state => state.placements.items;
export const getReportPlacements = state => state.reports.placements;
export const getAllPlacementStatuses = state => state.placementStatuses.items;
export const getAllPlacementOrigins = state => state.placementOrigins.items;
export const getPlacementSelectedTermId = state => state.placements.selectedTermId;

export const getAllPlacementStatusValues = createSelector(
  getAllPlacementStatuses,
  statuses =>
    _.map(_.orderBy(statuses, ['name']), el => ({ id: el.id, name: el.name }))
);

export const getAllPlacementOriginValues = createSelector(
  getAllPlacementOrigins,
  origins =>
    _.map(_.orderBy(origins, ['name']), el => ({ id: el.id, name: el.name }))
);

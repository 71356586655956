import axios from '../../api';
import * as actions from '../types';

export const fetchUserMessages = user => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_USER_MESSAGES, payload: undefined });
    const res = await axios.get(`/userMessage/user/${user.id}`);
    dispatch({ type: actions.FETCH_USER_MESSAGES_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_USER_MESSAGES_ERROR,
      payload: err.response.data
    });
  }
};

export const createUserMessage = message => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_USER_MESSAGE, payload: message });
    await axios.post('/userMessage', message);
    dispatch({ type: actions.CREATE_USER_MESSAGE_SUCCESS });
  } catch (err) {
    dispatch({
      type: actions.CREATE_USER_MESSAGE_ERROR,
      payload: err.response.data
    });
  }
};

export const updateUserMessage = message => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_USER_MESSAGE, payload: message });
    await axios.put(`/userMessage/${message.id}`, message);
    dispatch({ type: actions.UPDATE_USER_MESSAGE_SUCCESS });
    dispatch(fetchUserMessages(message.Recipient));
  } catch (err) {
    dispatch({
      type: actions.UPDATE_USER_MESSAGE_ERROR,
      payload: err.response.data
    });
  }
};

export const deleteUserMessage = message => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_USER_MESSAGE, payload: message });
    await axios.delete(`/userMessage/${message.id}`);
    dispatch({ type: actions.DELETE_USER_MESSAGE_SUCCESS });
    dispatch(fetchUserMessages(message.Recipient));
  } catch (err) {
    dispatch({
      type: actions.DELETE_USER_MESSAGE_ERROR,
      payload: err.response.data
    });
  }
};

export const readAllUserMessages = messages => async dispatch => {
  const recipient = messages[0].Recipient;
  const messageIds = messages.map(message => message.id);

  try {
    dispatch({ type: actions.UPDATE_USER_MESSAGES, payload: messageIds });
    await axios.post(`/userMessage/readAll`, messageIds);
    dispatch({ type: actions.UPDATE_USER_MESSAGES_SUCCESS });
    dispatch(fetchUserMessages(recipient));
  } catch (err) {
    dispatch({
      type: actions.UPDATE_USER_MESSAGES_ERROR,
      payload: err.response.data
    });
  }
};

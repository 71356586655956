// @flow

import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import type { ValueListItem } from '../../schemas';

type Props = {
  value: any,
  addendumStatusCodes: ValueListItem[]
};

class AddendumStatusCodeFormatter extends Component<Props> {
  render() {
    const { value, addendumStatusCodes } = this.props;

    const item = _.find(addendumStatusCodes, { id: value });
    const displayValue = _.get(item, 'name', 'No Data Available');

    return displayValue;
  }
}

const mapStateToProps = state => {
  return {
    addendumStatusCodes: state.addendumStatusCodes.values
  };
};

export default (connect(mapStateToProps, {})(AddendumStatusCodeFormatter): any);

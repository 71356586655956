// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Formik,Form,Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {noteValidation} from '../../validation';

import {Button} from '@material-ui/core';

type Props = {
  initialValues: Object,
  handleSubmit: Function,
  handleCancel: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(1),
      minWidth: 320
    },
    buttonContainer: {
      marginTop: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0
    }
  };
});

function NoteForm(props: Props): React$Element<any> {
  const {initialValues,handleSubmit,handleCancel} = props;
  const classes = useStyles();

  const isEditing = _.has(initialValues,'id');

  return(
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      validationSchema={noteValidation}
      onSubmit={values => handleSubmit(values)}
    >
      {({isSubmitting,isValid}) => (
        <Form>
          <Field
            fullWidth
            required
            multiline
            minRows={2}
            type="text"
            name="description"
            label="Note"
            component={TextField}
          />
          <div style={{ padding: '2em 0 1em' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid}
            >
              {isEditing ? 'Update Note' : 'Create Note'}
            </Button>
            &nbsp;
            <Button className={classes.button} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NoteForm;

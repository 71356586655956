// @flow

import _ from 'lodash';

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {Button} from '@material-ui/core';

import {AddIcon,RemoveIcon} from '../../components/Icons';

import {
  addToWorkspace,removeFromWorkspace
} from '../../actions/creators/placementDrawer';

import {itemInWorkspace} from '../../utils/workspaceHelpers';

import type {WorkspaceItem} from '../../schemas';

type Props = {
  item?: WorkspaceItem,
  items?: WorkspaceItem[]
};

const useStyles = makeStyles(theme => {
  return {
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  };
});

function AddToWorkspaceButton(props: Props): React$Element<any> {
  const {item,items} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const workspaceItems = useSelector(state =>
    _.get(state, 'placementDrawer.selectedPlacement.WorkspaceItems', [])
  );

  function handleAdd(item) {
    dispatch(addToWorkspace(item));
  }

  function handleRemove(item) {
    dispatch(removeFromWorkspace(item));
  }

  // multiple items (from a site enrollment)
  if (items) return (
    <Button
      color="primary"
      onClick={() => {
        _.each(items, tempItem => {
          let itemFromWorkspace = itemInWorkspace(workspaceItems,tempItem);
          if (!itemFromWorkspace) handleAdd(tempItem);
        });
      }}
    >
      Add Items to Workspace <AddIcon className={classes.rightIcon} />
    </Button>
  );

  // single item
  const itemFromWorkspace = itemInWorkspace(workspaceItems,item);
  if (!itemFromWorkspace) return (
    <Button
      color="primary"
      onClick={() => handleAdd(item)}
    >
      Add to Workspace <AddIcon className={classes.rightIcon} />
    </Button>
  );

  return (
    <Button
      color="secondary"
      onClick={() => handleRemove(itemFromWorkspace)}
    >
      Remove from Workspace <RemoveIcon className={classes.rightIcon} />
    </Button>
  );
}

export default AddToWorkspaceButton;

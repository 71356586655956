import axios from '../../api';
import * as actions from '../types';

export const fetchOverview = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_DESKTOP_OVERVIEW, payload: term });
    const res = await axios.get(`/dashboard/overview/${term.id}`);
    dispatch({ type: actions.FETCH_DESKTOP_OVERVIEW_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.FETCH_DESKTOP_OVERVIEW_ERROR, payload: error.response.data });
  }
};

export const fetchStatuses = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_DESKTOP_STATUS, payload: term });
    const res = await axios.get(`/dashboard/status/${term.id}`);
    dispatch({ type: actions.FETCH_DESKTOP_STATUS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.FETCH_DESKTOP_STATUS_ERROR, payload: error.response.data });
  }
};

export const fetchCoordinators = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_DESKTOP_COORDINATOR, payload: term });
    const res = await axios.get(`/dashboard/coordinator/${term.id}`);
    dispatch({ type: actions.FETCH_DESKTOP_COORDINATOR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.FETCH_DESKTOP_COORDINATOR_ERROR, payload: error.response.data });
  }
};

export const fetchContracts = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_DESKTOP_CONTRACT, payload: term });
    const res = await axios.get(`/dashboard/contract/${term.id}`);
    dispatch({ type: actions.FETCH_DESKTOP_CONTRACT_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.FETCH_DESKTOP_CONTRACT_ERROR, payload: error.response.data });
  }
};

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';

import { Grid, Chip, Avatar } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import ContractBrief from '../Contract/ContractBrief';
import SpecialtyChips from '../Specialty/SpecialtyChips';
import CommonStyles from '../../styles/CommonStyles';

import { siteAttachmentAsSigned, hasActiveContract } from '../../utils/placementHelpers';
import type { SiteEnrollment, Placement, AttachmentA } from '../../schemas';

type Classes = {
  warn: Object,
  warnAvatar: Object,
  error: Object,
  errorAvatar: Object,
  success: Object,
  successAvatar: Object
};

type Props = {
  siteEnrollment: SiteEnrollment,
  placement: Placement,
  attachmentAs: AttachmentA[],
  editable: boolean,
  displayOnly: boolean,
  classes: Classes
};

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    warn: commonStyles.warn,
    warnAvatar: commonStyles.warnEmphasis,
    error: commonStyles.error,
    success: commonStyles.success,
    successAvatar: commonStyles.successEmphasis,
  };
  return classes;
};

class SiteEnrollmentDetail extends Component<Props> {
  specialties = _.get(this.props.siteEnrollment, 'Site.Specialties', []);
  isPlural = _.get(this.props.siteEnrollment, 'PreceptorEnrollments').length >= 2;
  attachmentAWord = this.isPlural ? 'AttachmentAs' : 'AttachmentA';

  render() {
    const {
      siteEnrollment,
      placement,
      classes,
      editable,
      displayOnly
    } = this.props;

    const attachmentAsSigned = siteAttachmentAsSigned(placement, siteEnrollment);
    const contractSigned = hasActiveContract(placement, siteEnrollment);

    return (
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <ContractBrief
            placement={placement}
            siteEnrollment={siteEnrollment}
            editable={editable && !displayOnly}
          />
          {attachmentAsSigned && contractSigned ? (
            <Chip
              avatar={<Avatar className={classes.successAvatar}>A</Avatar>}
              label={`Has signed ${this.attachmentAWord}`}
              className={classes.success}
            />
          ) : (
            <Chip
              avatar={<Avatar className={classes.warnAvatar}>A</Avatar>}
              label={`${this.attachmentAWord} needed`}
              className={classes.warn}
            />
          )}
        </Grid>
        <Grid item xs={8}>
          <SpecialtyChips specialties={this.specialties} />
        </Grid>
      </Grid>
    );
  }
}

export default (compose(withStyles(styles))(SiteEnrollmentDetail): any);

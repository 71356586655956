import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const fetchAddenda = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ADDENDA, payload: undefined });
    const res = await axios.get('/addendum');
    dispatch({ type: actions.FETCH_ADDENDA_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ADDENDA_ERROR,
      payload: error.response.data
    });
  }
};

export const createAddendum = addendum => async dispatch => {
  try {
    let { data } = await axios.post(`/addendum`, addendum);
    dispatch({ type: actions.CREATE_ADDENDUM_SUCCESS, payload: data });
    dispatch(fetchAddenda());
    dispatch(fetchContractAddenda(addendum.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Addendum successfully created.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create addendum.',
        status: 'error',
      })
    );
  }
};

export const updateAddendum = addendum => async dispatch => {
  try {
    let { data } = await axios.put(`/addendum/${addendum.id}`, addendum);
    dispatch({ type: actions.UPDATE_ADDENDUM_SUCCESS, payload: data });
    dispatch(fetchAddenda());
    dispatch(fetchContractAddenda(addendum.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Addendum successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update addendum.',
        status: 'error',
      })
    );
  }
};

export const deleteAddendum = addendum => async dispatch => {
  try {
    let { data } = await axios.delete(`/addendum/${addendum.id}`);
    dispatch({ type: actions.DELETE_ADDENDUM_SUCCESS });
    dispatch(fetchAddenda());
    dispatch(fetchContractAddenda(addendum.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Addendum successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update addendum.',
        status: 'error',
      })
    );
  }
};

export const fetchContractAddenda = ContractId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_ADDENDA, payload: undefined });
    const res = await axios.get('/addendum', { params: { ContractId } });
    dispatch({
      type: actions.FETCH_CONTRACT_ADDENDA_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_ADDENDA_ERROR,
      payload: error.response.data
    });
  }
};

export const postAddendumWorkflowLogEntry = logEntry => async dispatch => {
  try {
    dispatch({ type: actions.POST_ADDENDUM_WORKFLOW_LOG_ENTRY });
    const res = await axios.post('/addendumWorkflowLogEntry', logEntry);
    dispatch({
      type: actions.POST_ADDENDUM_WORKFLOW_LOG_ENTRY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: actions.POST_ADDENDUM_WORKFLOW_LOG_ENTRY_ERROR,
      payload: err.response.data
    });
  }
};

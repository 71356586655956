// @flow

import * as React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import { FieldProps } from 'formik';

type FieldPropTypes = typeof FieldProps;
type MuiTextFieldPropTypes = typeof MuiTextFieldProps;
type TextFieldProps = {
  ...FieldPropTypes,
  ...MuiTextFieldPropTypes
}

const TextField: React.ComponentType<TextFieldProps> = ({
  field,
  form,
  ...props
}) => {
  const { name } = field;
  const { touched, errors } = form;

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? '' : field.value;
  props.value = field.value;

  const handleChange = e => {
    if (props.onChange) {
      field.onChange(e);
      props.onChange(e);
    }
    field.onChange(e);
  };

  return (
    <MuiTextField
      {...props}
      {...field}
      onChange={handleChange}
      error={touched[name] && !!errors[name]}
      helperText={
        touched[name] && errors[name] ? errors[name] : props.helperText
      }
    />
  );
};

TextField.displayName = 'FormikMaterialUITextField';

export default TextField;

// @flow

import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_ERROR
} from '../actions/types';

import type { Course, ValueListItem } from '../schemas';

import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Course>,
  +values: Array<ValueListItem>
};

type Action = {
  type:
    | typeof FETCH_COURSES
    | typeof FETCH_COURSES_SUCCESS
    | typeof FETCH_COURSES_ERROR,
  payload: Array<Course>
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_COURSES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: _.sortBy(action.payload, ['CatalogNumberId']),
        values: createValueList(action.payload)
      };
    case FETCH_COURSES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const createValueList = courses =>
  _.sortBy(
    courses.map(course => ({
      id: course.id,
      name: `${course.CatalogNumberId} | ${course.description}`
    })),
    ['name']
  );

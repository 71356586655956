
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllCourses = state => state.courses.items;
export const getAllCourseAttrs = state => state.courseAttrs.items;

export const getAllCourseValues = createSelector(
  getAllCourses,
  courses => {
    return _.map(_.orderBy(courses, ['name']), el => ({ id: el.CatalogNumberId, name: el.description }));
  }
);

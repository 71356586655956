// @flow

import _ from 'lodash';

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import CommonStyles from '../../styles/CommonStyles';

import type {SiteEnrollment} from '../../schemas';

type Props = {
  siteEnrollments: SiteEnrollment[]
};

const useStyles = makeStyles(theme => {
  return {
    siteContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 4,
      backgroundColor: 'rgba(125, 125, 125, 0.3)',
      paddingLeft: 12,
      color: theme.palette.text.secondary,
    },
    siteName: {
      lineHeight: theme.typography.pxToRem(26)
    },
    noneAssignedContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 4,
      backgroundColor: 'rgba(125, 125, 125, 0.3)',
      lineHeight: theme.typography.pxToRem(26),
      paddingLeft: 12,
      color: theme.palette.text.secondary,
      ...CommonStyles(theme).warn
    },
    additionalSites: {
      lineHeight: theme.typography.pxToRem(26),
      backgroundColor: theme.palette.text.hint,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      width: '24px',
      textAlign: 'center'
    }
  };
});

function SiteEnrollments(props: Props): React$Element<"div"> {
  const {siteEnrollments} = props;
  const classes = useStyles();

  if (_.isArray(siteEnrollments) && siteEnrollments.length > 0) {
    const additionalSites = siteEnrollments.length - 1;
    const {id,name} = siteEnrollments[0].Site;

    return (
      <div className={classes.siteContainer} key={id}>
        <div className={classes.siteName}>{
          name.length > 30
            ? name.substring(0,30) + '...'
            : name
        }</div>
        {!!additionalSites && (
          <div className={classes.additionalSites}>+ {additionalSites}</div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.noneAssignedContainer}>
      <div className={classes.siteName}>NO SITES ASSIGNED</div>
    </div>
  );
}

export default SiteEnrollments;

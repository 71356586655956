// @flow

import * as actions from '../actions/types';
import { updateItem } from './methods';
import type { Term } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Term>,
  +selected: Object,
};

type Action = {
  type:
    | typeof actions.FETCH_TERMS
    | typeof actions.FETCH_TERMS_SUCCESS
    | typeof actions.FETCH_TERMS_ERROR
    | typeof actions.FETCH_TERM
    | typeof actions.FETCH_TERM_SUCCESS
    | typeof actions.FETCH_TERM_ERROR
    | typeof actions.UPDATE_TERM
    | typeof actions.UPDATE_TERM_SUCCESS
    | typeof actions.UPDATE_TERM_ERROR,
  payload: Array<Term> | Object | string,
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case actions.FETCH_TERMS:
      return { ...state, isLoading: true };
    case actions.FETCH_TERMS_SUCCESS:
      return { ...state, isLoading: false, items: action.payload };
    case actions.FETCH_TERMS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case actions.FETCH_TERM:
      return { ...state, isLoading: true };
    case actions.FETCH_TERM_SUCCESS:
      return { ...state, isLoading: false, selected: action.payload };
    case actions.FETCH_TERM_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case actions.UPDATE_TERM:
      return { ...state, isLoading: true };
    case actions.UPDATE_TERM_SUCCESS:
      return { ...state,
        isLoading: false,
        items: updateItem(state.items, action.payload),
      };
    case actions.UPDATE_TERM_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

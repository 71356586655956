import axios from '../../api';
import * as actions from '../types';
import history from '../../containers/history';
import { notify } from 'reapop';
import { fetchUserMessages } from './userMessages';

export const loginUser = token => async dispatch =>  {
  try {
    dispatch({ type: actions.LOGIN_USER, payload: token });
    const res = await axios.get('/auth/profile', { params: { token } });
    dispatch({ type: actions.LOGIN_USER_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.LOGIN_USER_ERROR, payload: err.message });
  }
};

export const logoutUser = () => async dispatch =>  {
  try {
    dispatch({ type: actions.LOGOUT_USER, payload: null });
    const res = await axios.get('/auth/logout');
    dispatch({ type: actions.LOGOUT_USER_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.LOGOUT_USER_ERROR, payload: err.message });
  }
};

export const masquerade = id => async dispatch => {
  try {
    dispatch({ type: actions.BEGIN_MASQUERADE, payload: { id: id } });
    const res = await axios.get(`/auth/masquerade/${id}`);
    dispatch({ type: actions.FETCH_PLACEMENTS_SUCCESS, payload: [] });
    dispatch({ type: actions.BEGIN_MASQUERADE_SUCCESS, payload: res.data });
    history.push('/');
    dispatch(fetchUserMessages({ id: id }));
    dispatch(
      notify({
        title: 'Success',
        message: 'You are now masquerading',
        status: 'success',
      })
    );
  } catch (err) {
    dispatch({ type: actions.BEGIN_MASQUERADE_ERROR, payload: err.message });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not masquerade',
        status: 'error',
      })
    );
  }
};

export const endMasquerade = () => async dispatch => {
  try {
    dispatch({ type: actions.END_MASQUERADE, payload: null });
    const res = await axios.get('/auth/endMasquerade');
    dispatch({ type: actions.FETCH_PLACEMENTS_SUCCESS, payload: [] });
    dispatch({ type: actions.END_MASQUERADE_SUCCESS, payload: res.data });
    history.push('/');
    dispatch(fetchUserMessages({ id: res.data.id }));
    dispatch(
      notify({
        title: 'Success',
        message: 'You are no longer masquerading',
        status: 'success',
      })
    );
  } catch (err) {
    dispatch({ type: actions.END_MASQUERADE_ERROR, payload: err.response.data });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not end masquerade',
        status: 'error',
      })
    );
  }
};

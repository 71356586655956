import * as actions from '../types';
import axios from '../../api';
import {
  fetchSelectedPlacement,
  fetchStashedPlacementSlots
} from './placementDrawer';
import { fetchStash } from './stash';
import { notify } from 'reapop';

export const deletePlacementSlot = (siteEnrollment, placement) => async dispatch => {
  try {
    let result = await axios.delete(`/siteEnrollment/${siteEnrollment.id}`);
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(fetchStashedPlacementSlots(placement.CourseId));
      dispatch(
        notify({
          title: 'Success',
          message: 'Placement slot successfully deleted',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not delete placement slot.',
        status: 'error',
      })
    );
  }
};

export const stashPlacementSlot = (siteEnrollment, placement) => async dispatch => {
  siteEnrollment.PackageId = null;
  try {
    let result = await axios.put(
      `/siteEnrollment/${siteEnrollment.id}`,
      siteEnrollment
    );
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(
        notify({
          title: 'Success',
          message: 'Placement slot successfully stashed.',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not stash placement slot.',
        status: 'error',
      })
    );
  }
};

export const attachPlacementSlot = (siteEnrollment, placement) => async dispatch => {
  siteEnrollment.PackageId = placement.PackageId;

  try {
    let result = await axios.put(
      `/siteEnrollment/${siteEnrollment.id}`,
      siteEnrollment
    );
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(fetchStashedPlacementSlots(placement.CourseId));
      dispatch(
        notify({
          title: 'Success',
          message: 'Placement slot successfully added',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not attach placement slot.',
        status: 'error',
      })
    );
  }
};

export const enrollSite = (placement, workspaceItem, currentUser) => async dispatch => {
  const siteResult = await axios.get(`/site/${workspaceItem.foreignKey}`);
  const site = siteResult.data;

  const siteEnrollment = {
    siteHoursAvailable: 0,
    verbalCommitment: false,
    attachmentA: false,
    paidPlacement: false,
    SiteId: site.id,
    PackageId: placement.PackageId,
    CourseId: placement.CourseId,
    ManagerId: currentUser.username,
  };

  try {
    let result = await axios.post('/siteEnrollment', siteEnrollment);
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(
        notify({
          title: 'Success',
          message: 'Site successfully added to placement.',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not add site.',
        status: 'error',
      })
    );
  }
};

export const enrollPreceptor = (placement, siteEnrollment, workspaceItem) => async dispatch => {
  const preceptorEnrollment = {
    preceptorHoursAvailable: 0,
    SiteEnrollmentId: siteEnrollment.id,
    PreceptorId: workspaceItem.foreignKey
  };

  try {
    // update the preceptorEnrollment
    const result = await axios.post(
      '/preceptorEnrollment',
      preceptorEnrollment
    );
    // get the full preceptor w/ sites
    const preceptorResult = await axios.get(
      `/preceptor/${preceptorEnrollment.PreceptorId}`
    );
    let preceptor = preceptorResult.data;

    // check to see if the preceptor is already associated with the site
    let isAssociated = preceptor.Sites.find(
      site => siteEnrollment.Site.id === site.id
    );

    // if they aren't, add them
    if (isAssociated === undefined) {
      preceptor.Sites.push(siteEnrollment.Site);
    }

    // update the preceptor to attach the site
    let updatePreceptorResult = await axios.put(
      `/preceptor/${preceptor.id}`,
      preceptor
    );

    if (result.data && updatePreceptorResult.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(
        notify({
          title: 'Success',
          message: 'Preceptor successfully enrolled.',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not add preceptor to site enrollment.',
        status: 'error',
      })
    );
  }
};

export const updatePreceptor = (placement, preceptorEnrollment) => async dispatch => {
  try {
    preceptorEnrollment.preceptorHoursAvailable = parseInt(
      preceptorEnrollment.preceptorHoursAvailable,
      10
    );
    let result = await axios.put(
      `/preceptorEnrollment/${preceptorEnrollment.id}`,
      preceptorEnrollment
    );
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(
        notify({
          title: 'Success',
          message: 'Preceptor enrollment successfully updated',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update the preceptor enrollment.',
        status: 'error',
      })
    );
  }
};

export const removePreceptor = (placement, preceptorEnrollment) => async dispatch => {
  try {
    let result = await axios.delete(
      `/preceptorEnrollment/${preceptorEnrollment.id}`
    );
    if (result.data) {
      dispatch(fetchSelectedPlacement(placement.id));
      dispatch(
        notify({
          title: 'Success',
          message: 'Successfully removed the preceptor from the site enrollment.',
          status: 'success',
        })
      );
    }
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not remove preceptor from the site enrollment.',
        status: 'error',
      })
    );
  }
};

export const updatePlacementSlot = slot => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_STASH });
    const endpoint = `/siteEnrollment/${slot.id}`;
    await axios.put(endpoint, slot);
    dispatch({ type: actions.UPDATE_STASH_SUCCESS });
    dispatch(fetchStash());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_STASH_ERROR,
      payload: error.response.data
    });
  }
};

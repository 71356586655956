// @flow

import _ from 'lodash';

import type { Placement, PlacementStatusCode } from '../schemas';

const mapToBoard = (
  placements: Array<Placement>,
  statuses: Array<PlacementStatusCode>,
  theme: Object
): { lanes: any, ... } => {
  const boardStyle = {
    backgroundColor: theme.palette.common.faintBlack,
    color: theme.palette.grey[500]
  };

  // TODO: need to remove this later -- temporarily restrict the lanes to specific statuses
  const filteredStatuses = _.filter(statuses, status => {
    const STATUS_LIST = [
      'Required',
      'Pending Approval',
      'Approved',
      'Cancelled'
    ];
    return STATUS_LIST.indexOf(status.name) >= 0;
  });

  const lanes = _.sortBy(filteredStatuses, 'order').map(status => {
    return {
      id: status.id,
      title: status.name,
      style: boardStyle,
      cards: []
    };
  });

  const mapped = { lanes: lanes };

  const styler = () => ({
    cardStyle: {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: 'none',
      marginBottom: 15,
      padding: 0,
      overflow: 'visible'
    }
  });

  const mapToLanes = placement => {
    const PlacementStatusCodeId = _.get(placement, 'PlacementStatusCodeId', null);
    if (PlacementStatusCodeId) {
      const lane = _.find(mapped.lanes, {
        id: placement.PlacementStatusCodeId
      });

      const styles = styler();

      // The board expects the card IDs to be strings, instead of numbers
      const styledPlacement = {
        ...placement,
        ...styles,
        id: placement.id.toString()
      };

      if (lane !== undefined) {
        lane.cards.push(styledPlacement);
      }
    }
  };

  if (placements.length) {
    placements.forEach(placement => {
      mapToLanes(placement);
    });
  }
  return mapped;
};

export default mapToBoard;

// @flow

import * as React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';

import {
  Dialog,DialogActions,DialogContent,DialogTitle,Button,List,ListItem,
  ListItemSecondaryAction,Typography,Divider,ListItemText
} from '@material-ui/core';


import {getSelectedPlacement} from '../../../selectors/placementDrawer';

import AddToWorkspaceButton from '../AddToWorkspaceButton';

import type {PreceptorSearchResult} from '../../../schemas';
type Props = {
  open: boolean,
  handleClose: Function,
  item: PreceptorSearchResult
};

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      padding: 0
    },
    button: {
      marginTop: theme.spacing(1)
    },
    listText: {
      width: '50%'
    }
  };
});

function PreceptorListDialog(props: Props): React$Element<any> {
  const {open,handleClose,item} = props;
  const classes = useStyles();

  const selectedPlacement = useSelector(getSelectedPlacement);

  function renderWorkspaceButton(site) {
    const tempWorkspaceItem = {
      model: 'site',
      foreignKey: site.id,
      name: site.name,
      PlacementId: selectedPlacement.id
    };

    return (
      <div className={classes.button}>
        <AddToWorkspaceButton item={tempWorkspaceItem} />
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="sites-dialog">
      <DialogTitle id="sites-dialog">Sites for {item.name}</DialogTitle>
      <List>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {item.Sites.map(site => {
            return (
              <ListItem divider key={site.id}>
                <Typography className={classes.listText}>
                  {site.name}
                </Typography>
                <ListItemSecondaryAction className={classes.listButton}>
                  {renderWorkspaceButton(site)}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          {item.Sites.length === 0 && (
            <ListItem divider>
              <ListItemText
                className={classes.centered}
                secondary="There are no sites to display for this preceptor."
              />
            </ListItem>
          )}
        </DialogContent>
      </List>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreceptorListDialog;

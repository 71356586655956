// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { store } from '../index';
import { LOGOUT_USER, LOGOUT_USER_SUCCESS } from '../actions/types';

import type { Auth } from '../schemas';

type Props = {
  component: any,
  auth: Auth,
  location: Location,
  cookie: boolean,
};

const PrivateRoute = ({ component: Component, auth, cookie, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isLoggedIn && cookie) {
          return <Component {...props} />;
        } else {
          store.dispatch({ type: LOGOUT_USER, payload: null });
          store.dispatch({ type: LOGOUT_USER_SUCCESS, payload: null });
          return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
        }
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default (connect(mapStateToProps, {})(PrivateRoute): any);

import axios from '../../api';
import * as actions from '../types';

export const fetchUserSettingTypes = () => async dispatch => {

  try {
    dispatch({ type: actions.FETCH_USER_SETTING_TYPES, payload: undefined });
    let { data } = await axios.get('/userSettingType');
    dispatch({ type: actions.FETCH_USER_SETTING_TYPES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: actions.FETCH_USER_SETTING_TYPES_ERROR, payload: err });
  }
};

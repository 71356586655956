// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import type { Organization } from '../../../schemas';

import OrganizationSiteForm from './OrganizationSiteForm';
import { createSite, fetchSites } from '../../../actions/creators/sites';
import { fetchSelectedOrganization } from '../../../actions/creators/organizationDrawer';

type Classes = {
  centered: Object,
  dialogContent: Object,
  dialogActions: Object
};

type Props = {
  organization: Organization,
  isClone: boolean,
  searchText: string,
  open: boolean,
  onClose: Function,
  handleClose: Function,
  classes: Classes,
  fetchSites: Function,
  createSite: Function,
  fetchSelectedOrganization: Function
};

const styles = theme => ({
  centered: {
    textAlign: 'center'
  },
  dialogContent: {
    maxHeight: 435,
    padding: 0,
    marginBottom: 0
  },
  dialogActions: {
    paddingTop: theme.spacing(1),
    marginTop: 0
  }
});

class SiteAddDialog extends Component<Props> {

  handleSubmit = async values => {
    await this.props.createSite(values);
    await this.props.handleClose();
    await this.props.fetchSelectedOrganization({ id: this.props.organization.id });
  };

  render() {
    const { open, onClose, handleClose, organization, isClone, searchText } = this.props;
    let initialValues = {
      name: searchText,
      isActive: true,
      address: undefined,
      addressLine2: undefined,
      addressLine3: undefined,
      city: undefined,
      postalCode: undefined,
      contact: undefined,
      contactTitle: undefined,
      contactEmail: undefined,
      phone: undefined,
      fax: undefined,
      StateId: undefined,
      CountryId: 'US',
      OrganizationId: organization.id
    };
    if (isClone) {
      initialValues.address = organization.address;
      initialValues.addressLine2 = organization.addressLine2;
      initialValues.addressLine3 = organization.addressLine3;
      initialValues.city = organization.city;
      initialValues.postalCode = organization.postalCode;
      initialValues.contact = organization.contact;
      initialValues.contactTitle = organization.contactTitle;
      initialValues.contactEmail = organization.contactEmail;
      initialValues.phone = organization.phone;
      initialValues.fax = organization.fax;
      initialValues.StateId = organization.StateId;
      initialValues.CountryId = organization.CountryId;
      initialValues.OrganizationId = organization.id;
    }

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="list-dialog">
        <DialogTitle id="list-dialog">Create a Site</DialogTitle>
        <DialogContent>
          <OrganizationSiteForm
            handleClose={handleClose}
            canUpdate={true}
            canDelete={false}
            handleSubmit={this.handleSubmit}
            initialValues={initialValues}
            isClone={isClone}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default (compose(
  connect(null, {
    fetchSites, fetchSelectedOrganization, createSite
  }),
  withStyles(styles)
)(SiteAddDialog): any);

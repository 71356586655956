// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  createSpecialty,
  updateSpecialty,
  deleteSpecialty
} from '../../actions/creators/specialties';
import SpecialtyDetailForm from './SpecialtyDetailForm';
import type { Specialty } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Props = {
  createSpecialty: Function,
  updateSpecialty: Function,
  deleteSpecialty: Function,
  handleDelete: Function,
  handleClose: Function,
  selectedSpecialty: Specialty,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object
};

class SpecialtyDetail extends Component<Props> {

  async handleSubmit(specialty: Specialty) {
    specialty.id
      ? await this.props.updateSpecialty(specialty)
      : await this.props.createSpecialty(specialty);
    this.props.handleClose();
  }

  async handleDelete() {
    const confirm = window.confirm(
      `Are you sure you want to delete "${this.props.selectedSpecialty.name}"?`
    );
    if (confirm) {
      await this.props.deleteSpecialty(this.props.selectedSpecialty);
    }
    this.props.handleClose();
  }

  render() {
    const { classes, selectedSpecialty } = this.props;
    const template = {
      name: '',
      code: '',
      isActive: true
    };

    return (
      <div className={classes.root}>
        <SpecialtyDetailForm
          canUpdate={this.props.canUpdate}
          canDelete={this.props.canDelete}
          handleClose={this.props.handleClose}
          handleSubmit={values => this.handleSubmit(values)}
          handleDelete={() => this.handleDelete()}
          initialValues={selectedSpecialty || template}
        />
      </div>
    );
  }
}

export default (compose(
  withStyles(styles, {
    name: 'SpecialtyDetail'
  }),
  connect(null, {
    createSpecialty,
    updateSpecialty,
    deleteSpecialty
  })
)(SpecialtyDetail): any);


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllSites = state => state.sites.items;
export const getSelectedSite = state => state.sites.selected;

export const getAllSiteValues = createSelector(
  getAllSites,
  states => {
    return _.map(_.orderBy(states, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getSiteById = state => createSelector(
  getAllSites, sites => {
    return _.find(sites, { id: state.siteId });
  }
)(state);

// @flow

import _ from 'lodash';

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {Button,Dialog,DialogTitle,DialogContent} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import NoteAddIcon from '@material-ui/icons/NoteAddOutlined';

import {createNote} from '../../actions/creators/notes';
import {getCurrentUser} from '../../selectors/users';

import NoteForm from './NoteForm';

type Props = {
  mini?: boolean,
  edge?: string,
  color?: string,
  variant?: string,
  model: string,
  foreignKey: number,
  afterCreate?: Function
};

const useStyles = makeStyles(theme => {
  return {
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  };
});

function CreateNoteButton(props: Props): React$Element<"div"> {
  const {model,mini,edge,foreignKey,afterCreate} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dialogVisible, setDialogVisible] = React.useState(false);

  const currentUser = useSelector(getCurrentUser);

  let noteTemplate = {
    model,
    foreignKey,
    description: '',
    UserId: currentUser.id
  };

  function openDialog() {
    setDialogVisible(true);
  }

  function handleClose() {
    setDialogVisible(false);
  }

  async function handleSubmit(values) {
    let results = await dispatch(createNote(values));
    if (results) handleClose();
    if (afterCreate) afterCreate();
  }

  return (
    <div>
      {!mini && <Button
        color={_.get(props, 'color', 'primary')}
        variant={_.get(props, 'variant', 'contained')}
        className={classes.button}
        onClick={openDialog}
      >
        Create Note
      </Button>}
      {mini && <Tooltip title="Create Note" placement="top">
        <IconButton
          edge={edge ? edge : false}
          size="small"
          onClick={openDialog}
        >
          <NoteAddIcon fontSize="small" color={_.get(props, 'color', 'inherit')} />
        </IconButton>
      </Tooltip>}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogVisible}
        onClose={handleClose}
        aria-labelledby="create-note-dialog"
      >
        <DialogTitle id="create-note-dialog">Create Note</DialogTitle>
        <DialogContent>
          <NoteForm
            handleSubmit={handleSubmit}
            initialValues={noteTemplate}
            handleCancel={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateNoteButton;

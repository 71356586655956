// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { amendmentEditValidation } from '../../validation';

import {
  Grid, Button, MenuItem, Typography,
  Divider, ListItemText
} from '@material-ui/core';

import { fetchAmendmentStatusCodes } from '../../actions/creators/amendmentStatusCodes';

import type { Amendment, ValueListItem } from '../../schemas';

const styles = theme => ({
  divider: {
    marginBottom: theme.spacing(1)
  },
  inputContainer: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  }
});

type Props = {
  classes: Object,
  handleSubmit: Function,
  handleDelete: Function,
  amendmentStatusCodes: ValueListItem[],
  initialValues: Amendment,
  fetchAmendmentStatusCodes: Function
};

class EditAmendmentForm extends Component<Props> {

  constructor(props) {
    super(props);
    props.fetchAmendmentStatusCodes();
  }

  render() {
    const { classes } = this.props;
    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={amendmentEditValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ isSubmitting, isValid, }) => (
          <Form>
            <Typography variant="h6" gutterBottom>
              Amendment Details
            </Typography>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  select
                  name="AmendmentStatusCodeId"
                  label="Status"
                  component={TextField}
                >
                  {_.map(this.props.amendmentStatusCodes, el => (
                    <MenuItem key={el.id} value={el.id}>
                      <ListItemText primary={el.name} />
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  multiline
                  name="description"
                  label="Description"
                  type="text"
                  component={TextField}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.buttonContainer}>
              <Grid item xs={6}>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  Save Changes
                </Button>
                <Button
                  color="secondary"
                  className={classes.button}
                  onClick={this.props.handleDelete}
                >
                  Delete Amendment
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    amendmentStatusCodes: state.amendmentStatusCodes.values
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchAmendmentStatusCodes })
)(EditAmendmentForm): any);

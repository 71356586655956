import axios from '../../api';
import * as actions from '../types';

export const fetchCoordinators = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COORDINATORS, payload: undefined });
    const res = await axios.get('/user/coordinators/values');
    dispatch({
      type: actions.FETCH_COORDINATORS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COORDINATORS_ERROR,
      payload: error.response.data
    });
  }
};

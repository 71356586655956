// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Divider } from '@material-ui/core';

import { fetchStash } from '../../actions/creators/stash';

import { getCurrentUsersWorkingTerm } from '../../selectors/users';
import { getTermsWithAttrs } from '../../selectors/terms';

import PaginatedList from '../../components/PaginatedList';
import PlacementSlotListItem from '../../components/PlacementDrawer/PlacementSlots/PlacementSlotListItem';
import StashedPlacementSlotFilterForm from '../../components/PlacementDrawer/StashedPlacementSlots/StashedPlacementSlotFilterForm';

import type { SiteEnrollment, Term } from '../../schemas';

type Props = {
  classes: Object,
  stash: SiteEnrollment[],
  terms: Term[],
  workingTerm: Term,
  fetchStash: Function,
  filterValues: Object,
  stashedPlacementSlots: any[]
};

type State = {
  filteredPlacementSlots: SiteEnrollment[],
};

const initialFilterValues = {
  siteName: '',
  preceptorName: ''
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    flex: 3
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  }
});

class Stash extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      filteredPlacementSlots: props.stash
    };
  }

  componentDidMount() {
    return this.loadData();
  }

  // componentDidUpdate(prevProps) {
  //   const prev = (prevProps !== this.props);
  //   const stash = (prevProps.stashedPlacementSlots !== this.props.stash);

  //   if (prev && stash) this.handleFilterSlots();
  // }

  loadData = async () => {
    await this.props.fetchStash();

    return this.handleFilterSlots();
  };

  handleFilterSlots = values => {
    const {stash,workingTerm} = this.props;
    const filterValues = values ? values : initialFilterValues;
    const workingTermStartDate = _.get(workingTerm, 'TermAttr.startDate', '');

    let filteredPlacementSlots = stash.filter(el => {
      const slotCreatedDate = moment(el.createdAt);
      const slotDiff = moment(workingTermStartDate).diff(slotCreatedDate, 'months');
      return slotDiff <= 12 ? true : false;
    });

    if (filterValues.siteName !== initialFilterValues.siteName) {
      filteredPlacementSlots = filteredPlacementSlots.filter(placementSlot => {
        return (
          placementSlot.Site.name
            .toLowerCase()
            .indexOf(filterValues.siteName.toLowerCase()) >= 0
        );
      });
    }

    if (filterValues.preceptorName !== initialFilterValues.preceptorName) {
      filteredPlacementSlots = filteredPlacementSlots.filter(placementSlot => {
        let found = placementSlot.PreceptorEnrollments.filter(
          preceptorEnrollment => {
            if (preceptorEnrollment.Preceptor === null) {
              return false;
            }

            return (
              preceptorEnrollment.Preceptor.name
                .toLowerCase()
                .indexOf(filterValues.preceptorName.toLowerCase()) >= 0
            );
          }
        );

        return found.length > 0;
      });
    }

    this.setState({ filteredPlacementSlots });
  };

  render() {
    const { classes } = this.props;
    const { filteredPlacementSlots } = this.state;

    return (
      <div className={classes.root}>
        <h1>Stashed Placement Slots</h1>
        <div className={classes.container}>
          <div className={classes.content}>
            <PaginatedList
              items={filteredPlacementSlots}
              renderItem={item => {
                return (
                  <div key={item.id}>
                    <PlacementSlotListItem
                      item={item}
                      editable={false}
                      displayOnly={true}
                    />
                    <Divider />
                  </div>
                );
              }}
              renderEmpty={() => {
                return (
                  <div>
                    <Typography gutterBottom>
                      There are no stashed placement slots based on your filters.
                    </Typography>
                  </div>
                );
              }}
            />
          </div>
          <div className={classes.sidebar}>
            <StashedPlacementSlotFilterForm
              handleSubmit={this.handleFilterSlots}
              initialValues={initialFilterValues}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stash: state.stash.items,
  terms: getTermsWithAttrs(state),
  workingTerm: getCurrentUsersWorkingTerm(state),
});

export default (compose(
  withStyles(styles, { withTheme: true, name: 'Stash' }),
  connect(mapStateToProps, { fetchStash })
)(Stash): any);

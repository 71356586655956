// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { changeSection } from '../../actions/creators/siteDrawer';
import DrawerNavigation from '../../components/DrawerNavigation';

import type { DrawerNavigationItem } from '../../schemas';

const styles = theme => ({
  root: {
    width: '64px',
    backgroundColor: theme.palette.grey[700],
    height: '100%',
    position: 'absolute'
  }
});

type Props = {
  changeSection: Function,
  classes: Object,
  items: Array<DrawerNavigationItem>,
  currentSection: DrawerNavigationItem
};

class SiteDrawerNavigation extends Component<Props> {
  handleNavigationChange = item => {
    this.props.changeSection(item);
  };

  render() {
    const { items, currentSection } = this.props;
    return (
      <div className={this.props.classes.root}>
        <DrawerNavigation
          items={items}
          currentSection={currentSection}
          handleNavigationChange={this.handleNavigationChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSection: state.siteDrawer.currentSection
  };
};

export default (withStyles(styles, {
  name: 'SiteDrawerNavigation',
  withTheme: true
})(connect(mapStateToProps, { changeSection })(SiteDrawerNavigation)): any);

// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import _ from 'lodash';

import { createTodo } from '../../actions/creators/todos';
import TodoForm from './TodoForm';

import type { User } from '../../schemas';

type Props = {
  classes: Object,
  createTodo: Function,
  mini: ?boolean,
  color: ?string,
  currentUser: User,
  model: string,
  foreignKey: number,
  afterCreate: Function
};

type State = { dialogVisible: boolean, todoTemplate: Object };

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class CreateTodoButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { model, foreignKey, currentUser } = props;
    let todoTemplate = {
      model,
      foreignKey,
      description: '',
      step: 0,
      CreatedById: currentUser.id
    };

    this.state = {
      dialogVisible: false,
      todoTemplate
    };
  }

  openDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleSubmit = async values => {
    const { createTodo, afterCreate } = this.props;
    values.step = values.step === 0 ? null : values.step;
    let results = await createTodo(values);
    if (results && afterCreate) {
      this.handleClose();
      afterCreate();
    }
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, todoTemplate } = this.state;
    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          variant={_.get(this.props, 'variant', 'contained')}
          className={classes.button}
          onClick={this.openDialog}
        >
          Create Todo
        </Button>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="create-todo-dialog"
        >
          <DialogTitle id="create-todo-dialog">Create Todo</DialogTitle>
          <DialogContent>
            <TodoForm
              handleSubmit={this.handleSubmit}
              handleClose={this.handleClose}
              initialValues={todoTemplate}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default (compose(
  connect(mapStateToProps, { createTodo }),
  withStyles(styles)
)(CreateTodoButton): any);

// @flow
import React, { Component } from 'react';
import { TableCell, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = theme => ({
  cell: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  input: {
    width: '100%'
  }
});

type Props = {
  classes: Object,
  filter: Object,
  onFilter: Function,
  optionValues: Object[]
};

const DEFAULT_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  { label: 'No', value: false }
];

class BooleanFilterCellBase extends Component<Props> {
  render() {
    const { classes, filter, onFilter } = this.props;
    const optionItems = _.get(this.props, 'optionItems', DEFAULT_OPTIONS);

    return (
      <TableCell className={classes.cell}>
        <Select
          native
          className={classes.input}
          value={filter ? filter.value : ''}
          type="boolean"
          onChange={e =>
            onFilter(e.target.value ? { value: e.target.value } : '')
          }
          inputProps={{
            id: 'boolean-native-simple'
          }}
        >
          <option value="">All</option>
          {optionItems.map(item => {
            return (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Select>
      </TableCell>
    );
  }
}

export default (withStyles(styles)(BooleanFilterCellBase): any);

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { Typography, Grid } from '@material-ui/core';

import ContractList from '../../Contract/ContractList';
import CreateContractButton from '../../Contract/CreateContractButton';
import { fetchOrganizationContracts } from '../../../actions/creators/organizationDrawer';
import { fetchPermissions } from '../../../actions/creators/permissions';
import { hasAccess } from '../../../utils/permissionsCheck';

import type { User, Organization, Contract } from '../../../schemas';

type Props = {
  classes: Object,
  currentUser: User,
  permissions: Object,
  organization: Organization,
  contracts: Contract[],
  isLoading: boolean,
  fetchOrganizationContracts: Function,
  fetchPermissions: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }
});
class ContractSection extends Component<Props> {

  componentDidMount() {
    this.props.fetchOrganizationContracts(this.props.organization);
    this.props.fetchPermissions();
  }

  canCreateContract = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'create', []));
  };

  render() {
    const { classes, contracts, organization } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Organization Contracts
        </Typography>
        <ContractList contracts={contracts} />
        {this.canCreateContract() && (<Grid container>
          <Grid item>
            <CreateContractButton
              organization={organization}
            />
          </Grid>
        </Grid>)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizationDrawer.selectedOrganization,
    contracts: state.organizationDrawer.selectedOrganizationContracts,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchOrganizationContracts,
    fetchPermissions
  }),
  withStyles(styles)
)(ContractSection): any);

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const createAttachmentA = attachmentA => async dispatch => {
  try {
    let { data } = await axios.post(`/attachmentA`, attachmentA);
    dispatch({ type: actions.CREATE_ATTACHMENT_A_SUCCESS, payload: data });
    dispatch(
      notify({
        title: 'Success',
        message: 'Attachment A successfully created.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create attachment A.',
        status: 'error',
      })
    );
  }
};

export const fetchAttachmentAs = (isList) => async dispatch => {
  let url = '/attachmentA';
  if (isList) url = '/attachmentA/list';
  try {
    dispatch({ type: actions.FETCH_ATTACHMENTS_A, payload: undefined });
    const { data } = await axios.get(url);
    dispatch({
      type: actions.FETCH_ATTACHMENTS_A_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ATTACHMENTS_A_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchAttachmentA = (id) => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ATTACHMENT_A, payload: { id } });
    const { data } = await axios.get(`/attachmentA/${id}`);
    dispatch({
      type: actions.FETCH_ATTACHMENT_A_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: actions.FETCH_ATTACHMENT_A_ERROR,
      payload: error.response.data
    });
  }
};

export const updateAttachmentA = attachmentA => async dispatch => {
  try {
    let { data } = await axios.put(
      `/attachmentA/${attachmentA.id}`,
      attachmentA
    );
    dispatch({ type: actions.UPDATE_ATTACHMENT_A_SUCCESS, payload: data });
    dispatch(
      notify({
        title: 'Success',
        message: 'AttachmentA successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update attachment A.',
        status: 'error',
      })
    );
  }
};

export const fetchContractAttachmentAs = ContractId => async dispatch => {
  try {
    dispatch({
      type: actions.FETCH_SELECTED_CONTRACT_ATTACHMENTS_A,
      payload: undefined
    });
    const res = await axios.get('/attachmentA', { params: { ContractId } });
    dispatch({
      type: actions.FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR,
      payload: error.response.data
    });
  }
};

export const postAttachmentAWorkflowLogEntry = logEntry => async dispatch => {
  try {
    dispatch({ type: actions.POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY });
    const res = await axios.post('/attachmentAWorkflowLogEntry', logEntry);
    dispatch({
      type: actions.POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: actions.POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_ERROR,
      payload: err.response.data
    });
  }
};

// @flow

import *  as types from '../actions/types';
import type { Preceptor } from '../schemas';
import { updateItem } from './methods';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Preceptor>,
  +selected: Object
};

type Action = {
  type:
    | typeof types.FETCH_PRECEPTORS
    | typeof types.FETCH_PRECEPTORS_SUCCESS
    | typeof types.FETCH_PRECEPTORS_ERROR
    | typeof types.FETCH_PRECEPTOR
    | typeof types.FETCH_PRECEPTOR_SUCCESS
    | typeof types.FETCH_PRECEPTOR_ERROR,
  payload: Array<Preceptor> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {

    case types.FETCH_PRECEPTORS:
      return { ...state, isLoading: true };
    case types.FETCH_PRECEPTORS_SUCCESS:
      return { ...state, isLoading: false, items: action.payload };
    case types.FETCH_PRECEPTORS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.FETCH_PRECEPTOR:
      return { ...state, isLoading: true };
    case types.FETCH_PRECEPTOR_SUCCESS:
      return { ...state, isLoading: false, selected: action.payload };
    case types.FETCH_PRECEPTOR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.UPDATE_PRECEPTOR:
      return { ...state, isLoading: true };
    case types.UPDATE_PRECEPTOR_SUCCESS:
      return { ...state,
        isLoading: false,
        selected: action.payload,
        items: updateItem(state.items, action.payload),
      };
    case types.UPDATE_PRECEPTOR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

// @flow

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Formik,Form,Field} from 'formik';
import {Select} from '../Formik';
import {TextField} from 'formik-material-ui';
import {locationSearchValidation} from '../../validation';

import {Grid,Button,Typography} from '@material-ui/core';

type Props = {
  initialValues: Object,
  states: any[],
  handleSubmit: Function
};

const useStyles = makeStyles(theme => {
  return {
    root: {},
    inputContainer: {
      marginTop: theme.spacing(2)
    },
    buttons: {
      marginTop: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    adjustedInput: {
      paddingTop: theme.spacing(1)
    }
  };
});

const RADIUS_OPTIONS = [
  { id: 10, name: '10 mi' },
  { id: 25, name: '25 mi' },
  { id: 50, name: '50 mi' },
  { id: 100, name: '100 mi' },
  { id: 125, name: '125 mi' },
  { id: 150, name: '150 mi' },
  { id: 175, name: '175 mi' },
  { id: 200, name: '200 mi' }
];

const META_OPTIONS = [
  { id: 'postal_code', name: 'Postal Code' },
  { id: 'city', name: 'City & State' },
  { id: 'state', name: 'State' }
];

function LocationSearchForm(props: Props): React$Element<any> {
  const {states,handleSubmit,initialValues} = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={locationSearchValidation}
      onSubmit={values => handleSubmit(values)}
    >
      {({handleChange,values}) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={2} sm={6} xs={12}>
              <Field
                fullWidth
                name="searchKey"
                label="Search By"
                options={META_OPTIONS}
                onChange={handleChange}
                component={Select}
              />
            </Grid>
            {values.searchKey === 'postal_code' && <Grid item md={3} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="searchPostalCode"
                label="Postal Code"
                onChange={handleChange}
                component={TextField}
              />
            </Grid>}
            {values.searchKey === 'city' && <Grid item md={3} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="searchCity"
                label="City"
                onChange={handleChange}
                component={TextField}
              />
            </Grid>}
            {values.searchKey !== 'postal_code' && <Grid item md={2} sm={6} xs={12}>
              <Field
                fullWidth
                displayEmpty
                name="searchState"
                label="State"
                options={states}
                onChange={handleChange}
                component={Select}
              />
            </Grid>}
            {values.searchKey === 'postal_code' && <Grid item md={2} sm={6} xs={12}>
              <Field
                fullWidth
                name="searchRadius"
                label="Radius"
                options={RADIUS_OPTIONS}
                onChange={handleChange}
                component={Select}
              />
            </Grid>}
            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Search
              </Button>
            </div>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {values.searchKey !== 'postal_code' && <Typography color="primary" variant="caption">
                NOTE: City and/or State searches return exact matches and my take longer.
              </Typography>}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

// export default (withStyles(styles)(LocationSearchForm): any);
export default LocationSearchForm;

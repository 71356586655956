// @flow

import React, { Component } from 'react';

import OrganizationDetails from './OrganizationDetails/OrganizationDetails';
import OrganizationSites from './OrganizationSites/OrganizationSites';
import OrganizationContracts from './OrganizationContracts/OrganizationContracts';

import type { DrawerNavigationItem } from '../../schemas';

type Props = {
  currentSection: DrawerNavigationItem
};

class OrganizationDrawerSections extends Component<Props> {
  render(): React$Element<any> | React$Element<"div"> {
    const { currentSection } = this.props;

    switch (currentSection.slug) {
      case 'details':
        return <OrganizationDetails />;
      case 'sites':
        return <OrganizationSites />;
      case 'contracts':
        return <OrganizationContracts />;
      default:
        return <div />;
    }
  }
}

export default OrganizationDrawerSections;

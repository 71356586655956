import * as actions from '../types';
import axios from '../../api';

let cachedOpts = {};

export const searchLocation = opts => async dispatch => {
  if (cachedOpts !== opts) cachedOpts = opts;
  try {
    dispatch({ type: actions.FETCH_LOCATION_SEARCH, payload: cachedOpts });
    const { data } = await axios.get('/location/search', { params: cachedOpts });
    dispatch({ type: actions.FETCH_LOCATION_SEARCH_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_LOCATION_SEARCH_ERROR,
      payload: err
    });
  }
};

export const searchCachedLocation = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_LOCATION_SEARCH, payload: cachedOpts });
    const { data } = await axios.get('/location/search', { params: cachedOpts });
    dispatch({ type: actions.FETCH_LOCATION_SEARCH_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_LOCATION_SEARCH_ERROR,
      payload: err
    });
  }
};

export const locationsByConfidence = confidence => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_LOCATION_CONFIDENCE, payload: confidence });
    const { data } = await axios.get(`/location/confidence/${confidence}`);
    dispatch({ type: actions.FETCH_LOCATION_CONFIDENCE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_LOCATION_CONFIDENCE_ERROR,
      payload: err
    });
  }
};

// @flow

import React from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { HoursIcon } from '../Icons';
import { Tooltip } from '@material-ui/core';

import { hoursForPlacement } from '../../utils/placementHelpers';
import CommonStyles from '../../styles/CommonStyles';
import type { Placement } from '../../schemas';
import { Theme } from '@material-ui/core/styles';

type Props = {
  placement: Placement,
  theme: typeof Theme
};

function HoursStatusIcon(props: Props) {
  const { placement, theme } = props;
  const commonStyles = CommonStyles(theme);
  let minHrs = _.get(placement, 'Course.CatalogNumber.CourseAttr.minHrs', -1);
  let maxHrs = _.get(placement, 'Course.CatalogNumber.CourseAttr.maxHrs', -1);
  const hoursScheduled = hoursForPlacement(placement);

  // let iconColor = commonStyles.warn.backgroundColor;
  let statusMessage = `${hoursScheduled} hours scheduled. min is ${minHrs}, max is ${maxHrs}`;

  const iconColor =
    hoursScheduled >= minHrs && hoursScheduled <= maxHrs
      ? commonStyles.success.backgroundColor
      : commonStyles.warn.backgroundColor;

  return (
    <Tooltip placement="top" title={statusMessage}>
      <HoursIcon fontSize="small" style={{ color: iconColor }} />
    </Tooltip>
  );
}

export default (withTheme(HoursStatusIcon): any);

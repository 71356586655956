// @flow

import {
  FETCH_SPECIALTY_VALUES,
  FETCH_SPECIALTY_VALUES_SUCCESS,
  FETCH_SPECIALTY_VALUES_ERROR
} from '../actions/types';

import _ from 'lodash';

import type { ValueListItem } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<ValueListItem>
};

type Action = {
  type:
    | typeof FETCH_SPECIALTY_VALUES
    | typeof FETCH_SPECIALTY_VALUES_SUCCESS
    | typeof FETCH_SPECIALTY_VALUES_ERROR,
  payload: ?Array<ValueListItem>
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_SPECIALTY_VALUES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SPECIALTY_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: _.sortBy(action.payload, ['name'])
      };
    case FETCH_SPECIALTY_VALUES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Button, Dialog, DialogTitle, DialogContent
} from '@material-ui/core';

import CreateAmendmentForm from './CreateAmendmentForm';

import {
  createAmendment,
  postAmendmentWorkflowLogEntry
} from '../../actions/creators/amendments';

import type { User, Contract } from '../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  createAmendment: Function,
  mini: ?boolean,
  color: ?string,
  variant: ?string,
  currentUser: User,
  postAmendmentWorkflowLogEntry: Function
};

type State = {
  dialogVisible: boolean,
  amendmentTemplate: Object
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class CreateAmendmentButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { contract, currentUser } = props;

    let amendmentTemplate = {
      ContractId: contract.id,
      UserId: currentUser.id,
      dateRequested: Date.now(),
      AmendmentStatusCodeId: 'R',
      description: ''
    };

    this.state = {
      dialogVisible: false,
      amendmentTemplate
    };
  }

  openDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleSubmit = async values => {
    const {
      createAmendment, currentUser, postAmendmentWorkflowLogEntry
    } = this.props;

    let result = await createAmendment(values);
    if (result) {
      const amendment = result;

      let workflowItem = {
        AmendmentId: amendment.id,
        UserId: currentUser.id,
        StatusChangedToId: 'R'
      };

      await postAmendmentWorkflowLogEntry(workflowItem);
    }
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, amendmentTemplate } = this.state;

    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          variant={_.get(this.props, 'variant', 'contained')}
          className={classes.button}
          onClick={this.openDialog}
        >
          Create Amendment
        </Button>
        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="create-contract-dialog"
        >
          <DialogTitle id="create-contract-dialog">
            Create Amendment
          </DialogTitle>
          <DialogContent>
            <CreateAmendmentForm
              handleSubmit={this.handleSubmit}
              initialValues={amendmentTemplate}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default (compose(
  connect(mapStateToProps, { createAmendment, postAmendmentWorkflowLogEntry }),
  withStyles(styles)
)(CreateAmendmentButton): any);

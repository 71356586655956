// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Grow, Grid as UiGrid, Input, InputLabel, MenuItem, TableCell, Select, Paper,
  FormControl, Button
} from '@material-ui/core';

import { SuccessIcon } from '../../components/Icons';
import CommonStyles from '../../styles/CommonStyles';

import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting, IntegratedSelection, SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import SelectFilterCellBase from '../../components/ReactGrid/SelectFilterCellBase';

import LoadingIndicator from '../../components/LoadingIndicator';

import TermSelector from '../../components/PlacementsBoard/TermSelector';

import { getActiveTerm } from '../../selectors/terms';
import { getAllPlacements } from '../../selectors/placements';

import type { Placement, User, Permissions, Term } from '../../schemas';

import {
  fetchPlacements, updateSelectedTermId, updatePlacementsManager
} from '../../actions/creators/placements';
import { fetchPermissions } from '../../actions/creators/permissions';
import { fetchCoordinators } from '../../actions/creators/coordinators';
import { fetchTerms } from '../../actions/creators/terms';

import { userHasRole } from '../../utils/helpers';

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      padding: theme.spacing(2)
    },
    formControl: {
      width: '100%'
    },
    assignText: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      paddingTop: 8
    },
    bulkOpsRow: {
      alignItems: 'center',
      justifyContent: 'end'
    },
    bulkOpsPaper: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: 'auto'
    },
    bulkOpsContainer: {
      width: 'auto'
    },
    submitButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    successIcon: {
      color: commonStyles.success.backgroundColor
    }
  };
  return classes;
};

type Props = {
  activeTerm: Term,
  userSettingTypes: Object[],
  classes: Object,
  placements: Placement[],
  coordinators: any[],
  coordinatorsLoading: boolean,
  currentUser: User,
  permissions: Permissions,
  fetchPermissions: Function,
  fetchCoordinators: Function,
  fetchPlacements: Function,
  fetchTerms: Function,
  colSpan: number,
  placementsLoading: boolean,
  coordinatorsLoading: boolean,
  updateSelectedTermId: Function,
  selectedTermId: number ,
  updatePlacementsManager: Function
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  selectedPlacements: Placement[],
  rows: Placement[],
  loading: boolean,
  selectedCoordinatorId: string
};

class PlacementBulkOperations extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: 'id',
          title: 'ID'
        },
        {
          name: 'createdAt',
          title: 'Created',
          getCellValue: row => moment.parseZone(row.createdAt).format('MMMM DD, YYYY')
        },
        {
          name: 'Student',
          title: 'Student',
          getCellValue: row => _.get(row, 'Student.fullName')
        },
        {
          name: 'Course',
          title: 'Course',
          getCellValue: row => {
            const d = _.get(row, 'Course.description');
            const n = _.get(row, 'Course.CatalogNumberId');
            return `${n} | ${d}`;
          }
        },
        {
          name: 'City',
          title: 'City',
          getCellValue: row => _.get(row, 'Student.mailingCity')
        },
        {
          name: 'State',
          title: 'State',
          getCellValue: row => _.get(row, 'Student.mailingState')
        },
        {
          name: 'PostalCode',
          title: 'Zip Code',
          getCellValue: row => _.get(row, 'Student.mailingPostalCode')
        },
        {
          name: 'Coordinator',
          title: 'Coordinator',
          getCellValue: row => _.get(row, 'manager.name')
        },
        {
          name: 'isInState',
          title: 'In State?',
          getCellValue: row =>
            _.get(row, 'Student.mailingState') === 'NC' ? true : false
        }
      ],
      defaultFilters: [],
      defaultCurrentPage: 0,
      defaultPageSize: 10,
      defaultSorting: [{ columnName: 'id', direction: 'desc' }],
      pageSizes: [10, 20, 50, 100, 250],
      filteringStateColumnExtensions: [],
      selection: [],
      selectedPlacements: [],
      rows: [],
      loading: true,
      selectedCoordinatorId: ''
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.placements !== state.rows) {
      return { rows: props.placements };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchCoordinators();
    await this.props.fetchPermissions();
    await this.props.fetchPlacements(this.getDefaultTerm(), true);

    return this.setState({ loading: false });
  };

  canAssign() {
    const { currentUser, permissions } = this.props;
    const { selectedCoordinatorId, selectedPlacements } = this.state;

    return (
      userHasRole(currentUser, _.get(permissions, 'super')) &&
      selectedPlacements.length > 0 &&
      selectedCoordinatorId !== ''
    );
  }

  getDefaultTerm = () => {
    const {
      activeTerm, currentUser, userSettingTypes, selectedTermId,
      updateSelectedTermId
    } = this.props;

    const workingSemesterSettingTypeId = _.find(
      userSettingTypes, 'slug', 'working_semester'
    );

    // the term set as active by an admin user
    let defaultTerm = activeTerm.id;

    // the user's 'working term if set'
    const userSettingTerm = _.find(
      currentUser.UserSettings,
      'UserSettingTypeId',
      workingSemesterSettingTypeId
    );

    // the term chosen in placements filtering interface.
    const filterTerm = selectedTermId;

    if (userSettingTerm !== undefined || filterTerm !== undefined) {
      defaultTerm = filterTerm || userSettingTerm.value;
    }

    defaultTerm =
      typeof defaultTerm === 'string' ? parseInt(defaultTerm, 10) : defaultTerm;

    updateSelectedTermId(defaultTerm);

    return defaultTerm;
  };

  handleResetTerm = async () => {
    const { updateSelectedTermId, fetchPlacements } = this.props;
    await updateSelectedTermId(undefined);
    this.setState({ loading: true });
    await fetchPlacements(this.getDefaultTerm(), true);
    this.setState({ loading: false });
  };

  handleSelection = selection => {
    this.setState({ selectedPlacements: selection });
  };

  handleSelectedTermIdChange = async e => {
    const { updateSelectedTermId, fetchPlacements } = this.props;
    const { value } = e.target;

    updateSelectedTermId(value);
    this.setState({ loading: true });
    await fetchPlacements(value, true);
    this.setState({ loading: false });
  };

  handleSelectedCoordinatorChange = e => {
    const id = e.target.value;
    this.setState({
      selectedCoordinatorId: id
    });
  };

  handleSubmit = () => {
    const { placements, updatePlacementsManager, selectedTermId } = this.props;
    const { selectedPlacements, selectedCoordinatorId } = this.state;
    const placementIds = _.map(selectedPlacements, el => placements[el].id);
    updatePlacementsManager(
      selectedCoordinatorId,
      placementIds,
      selectedTermId
    ).then(() => this.setState({ selectedPlacements: [] }));
  };

  renderOptions(items) {
    if (items !== undefined) {
      return items.map(item => {
        const { id, name, username } = item;
        return (
          <MenuItem key={id.toString()} value={username}>
            {name}
          </MenuItem>
        );
      });
    }
  }

  render() {
    const {
      classes, selectedTermId, coordinators, coordinatorsLoading
    } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, pageSizes, filteringStateColumnExtensions,
      selectedPlacements, selectedCoordinatorId, rows, loading
    } = this.state;

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    const renderAssignText = () => {
      let text;
      if (this.canAssign()) {
        text = `Assign ${selectedPlacements.length} placements to`;
      } else {
        if (selectedCoordinatorId === '' && selectedPlacements.length < 1) {
          text = 'Choose a coordinator and one or more placements.';
        } else {
          if (selectedCoordinatorId === '') {
            text = 'Choose a coordinator. ';
          }
          if (selectedPlacements.length < 1) {
            text = 'Choose one or more placements';
          }
        }
      }

      return <p className={classes.assignText}>{text}</p>;
    };

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>
        </TableCell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'status') {
        const optionItems = coordinators;
        const newProps = { ...props, optionItems };
        return <SelectFilterCellBase {...newProps} />;
      }
      if (props.column.name === 'isInState') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'isInState') {
        return (
          <Table.Cell>
            {props.value && (
              <SuccessIcon className={classes.successIcon} />
            )}
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    }

    return (
      <div className={classes.root}>
        {loading && <LoadingIndicator loading={loading} overlay />}
        <UiGrid container spacing={2}>
          <UiGrid item xs={12}>
            <UiGrid container spacing={2}>
              <UiGrid item>
                <h1>Placements for</h1>
              </UiGrid>
              <UiGrid item xs>
                <TermSelector
                  selectedTermId={selectedTermId}
                  handleSelectedTermIdChange={this.handleSelectedTermIdChange}
                  handleResetTerm={this.handleResetTerm}
                />
              </UiGrid>
            </UiGrid>
          </UiGrid>

          <UiGrid item xs={12} className={classes.bulkOpsRow}>
            <Paper className={classes.bulkOpsPaper}>
              <UiGrid
                container
                spacing={2}
                className={classes.bulkOpsContainer}
              >
                <UiGrid item xs>
                  {renderAssignText()}
                </UiGrid>
                <UiGrid item xs={6}>
                  {coordinatorsLoading ? (
                    <LoadingIndicator loading={coordinatorsLoading} />
                  ) : (
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="coordinator-id">
                        Coordinator
                      </InputLabel>
                      <Select
                        value={selectedCoordinatorId}
                        onChange={e => this.handleSelectedCoordinatorChange(e)}
                        input={<Input id="coordinator-id" />}
                        // className={classes.selectedTerm}
                      >
                        {this.renderOptions(coordinators)}
                      </Select>
                    </FormControl>
                  )}
                </UiGrid>
                <UiGrid item className={classes.submitButtonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSubmit()}
                    disabled={!this.canAssign()}
                  >
                    Do it!
                  </Button>
                </UiGrid>
              </UiGrid>
            </Paper>
          </UiGrid>
          <Grow in={!loading} timeout={500}>
            <UiGrid item xs={12} className={classes.bulkOpsRow}>
              <Paper>
                <Grid rows={rows} columns={columns}>
                  <FilteringState
                    defaultFilters={defaultFilters}
                    columnExtensions={filteringStateColumnExtensions}
                  />
                  <PagingState
                    defaultCurrentPage={defaultCurrentPage}
                    defaultPageSize={defaultPageSize}
                  />
                  <SortingState defaultSorting={defaultSorting} />
                  <SelectionState
                    selection={selectedPlacements}
                    onSelectionChange={this.handleSelection}
                  />
                  <IntegratedFiltering />
                  <IntegratedSorting />
                  <IntegratedSelection />
                  <IntegratedPaging />

                  <Table
                    cellComponent={props => Cell(props)}
                    noDataCellComponent={props => NoDataCell(props)}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow cellComponent={props => FilterCell(props)} />

                  <TableSelection
                    selectByRowClick
                    showSelectionColumn
                    showSelectAll
                    highlightRow
                  />
                  <PagingPanel pageSizes={pageSizes} />
                </Grid>
              </Paper>
            </UiGrid>
          </Grow>
        </UiGrid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeTerm: getActiveTerm(state),
    coordinators: state.coordinators.items,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.placement,
    placements: getAllPlacements(state),
    selectedTermId: state.placements.selectedTermId,
    userSettingTypes: state.userSettingTypes.items,
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchCoordinators, fetchPlacements, fetchPermissions, updateSelectedTermId,
    updatePlacementsManager, fetchTerms
  })
)(PlacementBulkOperations): any);


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllCounties = state => state.counties.items;

export const getAllCountyValues = createSelector(
  getAllCounties,
  counties => {
    return _.map(_.orderBy(counties, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getCountyById = state => createSelector(
  getAllCounties, counties => {
    return _.find(counties, { id: state.countyId });
  }
)(state);

// @flow

import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';

import {Formik,Form,Field} from 'formik';
import {MultiSelect,Select,Checkbox,Autocomplete} from '../Formik';
import {TextField} from 'formik-material-ui';
import {preceptorValidation} from '../../validation';

import {Grid,Button,FormControlLabel,Typography} from '@material-ui/core';

import type {Preceptor} from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  formSection: {
    marginBottom: theme.spacing(2)
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  }
});

type Props = {
  classes: Object,
  initialValues: Preceptor,
  valueList: Object,
  canUpdate: boolean,
  handleSubmit: Function,
  handleCancel: Function,
};

class PreceptorForm extends Component<Props> {

  render() {
    const {classes} = this.props;
    const {states,sites,specialties,countries} = this.props.valueList;
    const isInstance = Boolean(this.props.initialValues.id);

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={preceptorValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Person Information
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="firstName"
                  label="First Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="lastName"
                  label="Last Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="credentials"
                  label="Credentials"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  name="Specialties"
                  label="Specialties"
                  options={specialties}
                  component={MultiSelect}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="previousSpecialty"
                  label="Previous Specialty"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="emailAddress"
                  label="Email Address"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="phone"
                  label="Phone"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="fax"
                  label="Fax"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="pager"
                  label="Pager"
                  component={TextField}
                />
              </Grid>
              <Grid item md={12} sm={6} xs={12}>
                <Field
                  fullWidth
                  name="Sites"
                  label="Sites"
                  options={sites}
                  component={Autocomplete}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Contact Information
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="address"
                  label="Address"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="addressLine2"
                  label="Address Line 2"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="city"
                  label="City"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  name="StateId"
                  label="State"
                  options={states}
                  component={Select}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  type="text"
                  name="postalCode"
                  label="Postal Code"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  id="CountryId"
                  name="CountryId"
                  label="Country"
                  options={countries}
                  component={Select}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="county"
                  label="County"
                  component={TextField}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Credentials & Certifications
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isAhecEligible}
                      name="isAhecEligible"
                      label="Is AHEC Eligible"
                      component={Checkbox}
                    />
                  }
                  label="Is AHEC Eligible"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="ahecLegacy"
                  label="AHEC Legacy"
                  component={TextField}
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="npiNumber"
                  label="NPI Number"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="license"
                  label="License"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="certifications"
                  label="Certifications"
                  component={TextField}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Additional Information
                </Typography>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="lastActiveSemester"
                  label="Last Active Semester"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="activeLegacy"
                  label="Active Legacy"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isAlumni}
                      name="isAlumni"
                      label="Is Alumni"
                      component={Checkbox}
                    />
                  }
                  label="Is Alumni"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isActive}
                      name="isActive"
                      label="Is Active"
                      component={Checkbox}
                    />
                  }
                  label="Is Active"
                />
              </Grid>

            </Grid>

            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isSubmitting || !isValid}
              >
                {isInstance ? 'Update Preceptor' : 'Create Preceptor'}
              </Button>
              <Button
                className={classes.button}
                onClick={this.props.handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

}

export default (withStyles(styles)(PreceptorForm): any);

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  createUser,
  updateUser,
  deleteUser
} from '../../actions/creators/users';

import UserDetailForm from './UserDetailForm';
import type { User } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Props = {
  updateUser: Function,
  handleDelete: Function,
  handleClose: Function,
  createUser: Function,
  deleteUser: Function,
  selectedUser: User,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object
};

class UserDetail extends Component<Props> {

  async handleSubmit(user: User) {
    user.id
      ? await this.props.updateUser(user)
      : await this.props.createUser(user);
    this.props.handleClose();
  }

  async handleDelete() {
    const confirm = window.confirm(
      `Are you sure you want to delete "${this.props.selectedUser.name}"?`
    );
    if (confirm) {
      await this.props.updateUser({
        id: this.props.selectedUser.id,
        isActive: false
      });
    }
    this.props.handleClose();
  }

  render() {
    const { classes, selectedUser } = this.props;
    const template = {
      name: '',
      username: '',
      Roles: []
    };

    return (
      <div className={classes.root}>
        <UserDetailForm
          canUpdate={this.props.canUpdate}
          canDelete={this.props.canDelete}
          handleClose={this.props.handleClose}
          handleSubmit={values => this.handleSubmit(values)}
          handleDelete={() => this.handleDelete()}
          initialValues={selectedUser || template}
        />
      </div>
    );
  }
}

export default (compose(
  withStyles(styles, { name: 'UserDetail' }),
  connect(null, { createUser, updateUser, deleteUser })
)(UserDetail): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {Divider,Paper,Typography,CircularProgress,Grid} from '@material-ui/core';

import {searchLocation,searchCachedLocation} from '../../../actions/creators/locations';
import {setSiteFilters} from '../../../actions/creators/placementDrawer';

import LocationSearchForm from '../../../components/PlacementDrawer/LocationSearchForm';
import SiteFilterForm from '../../../components/PlacementDrawer/SiteSearch/SiteFilterForm';
import PlacementDrawerWorkspace from '../../../components/PlacementDrawer/PlacementDrawerWorkspace';
import PaginatedList from '../../../components/PaginatedList';
import SiteListItem from '../../../components/PlacementDrawer/SiteSearch/SiteListItem';
import AddSiteButton from '../../../components/Site/AddSiteButton/';

import type { Site, Placement } from '../../../schemas';

type Classes = {
  root: Object,
  title: Object,
  subheading: Object,
  container: Object,
  content: Object,
  sidebar: Object,
  paper: Object,
  header: Object,
};

type FilterValues = {
  name: string,
  provider: string,
  specialties: []
};

type Props = {
  classes: Classes,
  placement: Placement,
  locationSites: Site[],
  searchLocation: Function,
  searchCachedLocation: Function,
  filterValues: FilterValues,
  isLoading: boolean,
  setSiteFilters: Function,
  stateValues: any[],
};

type State = {
  filteredSites: Site[],
  initialSearchValues: Object,
  initialFilterValues: FilterValues
};

const initialFilterValues = {
  name: '',
  provider: '',
  specialties: []
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    ...theme.typography.h5,
    marginRight: theme.spacing(1)
  },
  subheading: theme.typography.subheading,
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  content: {
    flex: 3
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2)
  }
});

class SiteSearchSection extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      filteredSites: props.locationSites,
      initialSearchValues: {
        searchKey: 'postal_code',
        searchPostalCode: props.placement.postalCode,
        searchCity: _.get(props.placement, 'Student.mailingCity', ''),
        searchState: _.get(props.placement, 'Student.mailingState', ''),
        searchRadius: 10
      },
      initialFilterValues: initialFilterValues
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      prevProps.locationSites !== this.props.locationSites
    ) {
      this.filterSites();
    }
  }

  filterSites = async values => {
    if (values) {
      await this.props.setSiteFilters(values);
    }

    let filterValues = this.props.filterValues;
    let filteredSites = this.props.locationSites;

    // site name
    if (filterValues.name !== '') {
      filteredSites = filteredSites.filter(site => {
        return (
          site.name.toLowerCase().indexOf(filterValues.name.toLowerCase()) >= 0
        );
      });
    }

    // provider/organization name
    if (filterValues.provider !== null) {
      filteredSites = filteredSites.filter(site => {
        return site.Organization !== null
          ? site.Organization.name
            .toLowerCase()
            .indexOf(filterValues.provider.toLowerCase()) >= 0
          : false;
      });
    }

    // site specialties
    if (filterValues.specialties.length > 0) {
      const specialtyIds = filterValues.specialties;

      filteredSites = filteredSites.filter(site => {
        let found = false;
        if (site.Specialties.length > 0) {
          const siteSpecilityIds = site.Specialties.map(el => el.id);
          const intersect = _.intersection(siteSpecilityIds, specialtyIds);
          found = intersect.length > 0;
        }

        return found;
      });
    }

    this.setState({ filteredSites: filteredSites });
  };

  render() {
    const {classes,searchLocation,isLoading,searchCachedLocation} = this.props;
    const {
      filteredSites,
      initialSearchValues,
      initialFilterValues
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.header}
            >
              <Grid item>
                <Typography component="span" className={classes.title}>Site Search</Typography>{' '}
                <Typography component="span" className={classes.subheading}>Find sites suitable for this placement</Typography>
              </Grid>
              <Grid item>
                <AddSiteButton
                  initialData={initialSearchValues}
                  afterClose={searchCachedLocation}
                />
              </Grid>
            </Grid>
            <Paper className={classes.paper}>
              <LocationSearchForm
                initialValues={initialSearchValues}
                handleSubmit={values => searchLocation(values)}
                states={this.props.stateValues}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1">Filters</Typography>
              <SiteFilterForm
                initialValues={initialFilterValues}
                handleSubmit={this.filterSites}
              />
            </Paper>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <PaginatedList
                items={filteredSites}
                title="Search Results"
                renderItem={item => {
                  return (
                    <div key={item.id}>
                      <SiteListItem item={item} />
                      <Divider />
                    </div>
                  );
                }}
                renderEmpty={() => {
                  return (
                    <Typography gutterBottom>
                      There are no sites that match your search and filters.
                    </Typography>
                  );
                }}
              />
            )}
          </div>
          <div className={classes.sidebar}>
            <PlacementDrawerWorkspace />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placement: state.placementDrawer.selectedPlacement,
    locationSites: state.locations.results.sites,
    filterValues: state.placementDrawer.siteFilters
      ? state.placementDrawer.siteFilters
      : initialFilterValues,
    isLoading: state.locations.isLoading,
    stateValues: state.states.values,
  };
};

export default (compose(
  connect(mapStateToProps, {
    searchLocation,searchCachedLocation,setSiteFilters
  }),
  withStyles(styles)
)(SiteSearchSection): any);

import axios from '../../api';
import * as actions from '../types';

export const fetchSpecialties = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SPECIALTIES, payload: undefined });
    const res = await axios.get('/specialty');
    dispatch({ type: actions.FETCH_SPECIALTIES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SPECIALTIES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSpecialty = specialty => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SPECIALTY, payload: undefined });
    const res = await axios.get(`/specialty/${specialty.id}`);
    dispatch({ type: actions.FETCH_SPECIALTY_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SPECIALTY_ERROR,
      payload: error.response.data
    });
  }
};

export const createSpecialty = specialty => async dispatch => {
  try {
    await axios.post('/specialty', specialty);
    dispatch({ type: actions.CREATE_SPECIALTY_SUCCESS });
    dispatch(fetchSpecialties());
  } catch (error) {
    dispatch({
      type: actions.CREATE_SPECIALTY_ERROR,
      payload: error.response.data
    });
  }
};

export const updateSpecialty = specialty => async dispatch => {
  try {
    const endpoint = `/specialty/${specialty.id}`;
    await axios.put(endpoint, specialty);
    dispatch({ type: actions.UPDATE_SPECIALTY_SUCCESS });
    dispatch(fetchSpecialties());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_SPECIALTY_ERROR,
      payload: error.response.data
    });
  }
};

export const deleteSpecialty = specialty => async dispatch => {
  try {
    const endpoint = `/specialty/${specialty.id}`;
    await axios.delete(endpoint);
    dispatch({ type: actions.DELETE_SPECIALTY_SUCCESS });
    dispatch(fetchSpecialties());
  } catch (error) {
    dispatch({
      type: actions.DELETE_SPECIALTY_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSpecialtyValues = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SPECIALTY_VALUES, payload: undefined });
    const res = await axios.get('/specialty/values');
    dispatch({
      type: actions.FETCH_SPECIALTY_VALUES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SPECIALTY_VALUES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, Badge, Tooltip } from '@material-ui/core';
import { NotificationsIcon } from '../../components/Icons';

import { fetchUserMessages } from '../../actions/creators/userMessages';
import type { UserMessage, User } from '../../schemas';

const styles = () => ({
  root: { width: '340px' }
});

type State = {
  messages: UserMessage[]
};

type Props = {
  classes: Object,
  theme: Object,
  currentUser: User,
  messages: UserMessage[],
  fetchUserMessages: Function
};

class CurrentUserMessages extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchUserMessages(this.props.currentUser);
  };

  renderNotificationsIcon = () => {
    const { messages } = this.props;
    const pluralWord = messages.length === 1 ? 'message' : 'messages';
    return messages.length > 0 ? (
      <Badge badgeContent={messages.length} color="secondary">
        <Tooltip
          id="new-messages"
          title={`You have ${messages.length} unread ${pluralWord}`}
        >
          <NotificationsIcon />
        </Tooltip>
      </Badge>
    ) : (
      <Tooltip id="new-messages" title={`You have no new messages`}>
        <NotificationsIcon />
      </Tooltip>
    );
  };

  render() {
    const NotificationIcon = withRouter(({ history }) => (
      <IconButton color="inherit" aria-label="user messages"
        onClick={() => { history.push('/users/messages'); }}
      >
        {this.renderNotificationsIcon()}
      </IconButton>
    ));

    return (
      <NotificationIcon />
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: _.filter(state.userMessages.items, {read: false})
  };
};

export default (compose(
  withStyles(styles, {
    withTheme: true,
    name: 'CurrentUserMessages'
  }),
  connect(mapStateToProps, {
    fetchUserMessages
  })
)(CurrentUserMessages): any);

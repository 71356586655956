// @flow

import _ from 'lodash-getpath';
import moment from 'moment';

import { hasAccess } from './permissionsCheck';
import type {
  Permissions, User, SiteEnrollment, PreceptorEnrollment, Placement, Contract
} from '../schemas';

// possible groups are 'active', 'requested', 'inactive'
const filterContractsForGroup = (
  contracts: Contract[],
  contractGroup: string
) => _.filter(contracts, contract => contract.contractGroup === contractGroup);

const getContractsFromSiteEnrollment = (siteEnrollment: SiteEnrollment) =>
  _.get(siteEnrollment, 'Site.Organization.Contracts', []);

// @todo deprecated in favor of getContractsForTypeAndGroup
export const getValidContracts = (
  contractTypeId: string,
  siteEnrollment: SiteEnrollment
): any => getContractsForTypeAndGroup(contractTypeId, siteEnrollment, 'active');

// contracts that match a given type
export const filterContractsForType = (
  contracts: Contract[],
  contractTypeId: string
): any => {
  return _.filter(contracts, contract => {
    let isAvailable = false;
    contract.ContractTypes.forEach(contractType => {
      const isValidType = _.isEqual(contractType.id, contractTypeId);
      if (isValidType) {
        isAvailable = true;
      }
    });
    return isAvailable;
  });
};

const getPlacementContractTypeId = (placement: Placement) =>
  _.get(placement, 'Course.CatalogNumber.CourseAttr.ContractTypeId', '');

export const getContractsForTypeAndGroup = (
  contractTypeId: string,
  siteEnrollment: SiteEnrollment,
  contractGroup: string
): any => {
  let contracts = getContractsFromSiteEnrollment(siteEnrollment);
  contracts = filterContractsForType(contracts, contractTypeId);
  contracts = filterContractsForGroup(contracts, contractGroup);

  return contracts;
};

export const hasActiveContract = (placement: Placement, siteEnrollment: SiteEnrollment): boolean => {
  const placementContractTypeId = getPlacementContractTypeId(placement);
  const contracts = getContractsForTypeAndGroup(
    placementContractTypeId,
    siteEnrollment,
    'active'
  );
  return contracts.length > 0;
};

export const getActiveContracts = (placement: Placement, siteEnrollment: SiteEnrollment): any[] => {
  const placementContractTypeId = getPlacementContractTypeId(placement);
  const contracts = getContractsForTypeAndGroup(
    placementContractTypeId,
    siteEnrollment,
    'active'
  );
  return contracts.length > 0 ? contracts : [];
};

// @todo deprecate hasContract.  Too generic
// export const hasActiveContract = hasActiveContract;

export const hasRequestedContract = (placement: Placement, siteEnrollment: SiteEnrollment): boolean => {
  const placementContractTypeId = getPlacementContractTypeId(placement);
  const contracts = getContractsForTypeAndGroup(
    placementContractTypeId,
    siteEnrollment,
    'request'
  );
  return contracts.length > 0;
};

export const getRequestedContracts = (placement: Placement, siteEnrollment: SiteEnrollment): any[] => {
  const placementContractTypeId = getPlacementContractTypeId(placement);
  const contracts = getContractsForTypeAndGroup(
    placementContractTypeId,
    siteEnrollment,
    'request'
  );
  return contracts.length > 0 ? contracts : [];
};

export const hasAllContracts = (placement: Placement): void | boolean => {
  if (!placement.Package || placement.Package.SiteEnrollments.length < 1) {
    return false;
  }

  return placement.Package.SiteEnrollments.reduce(
    (acc: ?boolean, siteEnrollment: SiteEnrollment) => {
      acc = acc !== false && hasActiveContract(placement, siteEnrollment);
      return acc;
    },
    undefined
  );
};

export const hasAllAttachmentAs = (placement: Placement): any | boolean => {

  const siteEnrollments = _.get(placement, 'Package.SiteEnrollments', []);
  if (siteEnrollments.length === 0 || !hasAllContracts(placement)) {
    return false;
  }

  return _.reduce(siteEnrollments, (acc: ?boolean, siteEnrollment: SiteEnrollment) =>
    (acc !== false && siteAttachmentAsSigned(placement, siteEnrollment)), undefined
  );
};

export const siteAttachmentAsSigned = (placement: Placement, siteEnrollment: SiteEnrollment): any | boolean => {
  const placementContractTypeId = _.get(
    placement, 'Course.CatalogNumber.CourseAttr.ContractTypeId', ''
  );
  const siteId = _.get(siteEnrollment, 'SiteId', undefined);
  const preceptorEnrollments = _.get(siteEnrollment, 'PreceptorEnrollments', []);
  if (preceptorEnrollments.length === 0) {
    return false;
  }

  let attachmentAs = [];
  let attachmentContractTypeIds = [];
  _.each(preceptorEnrollments, preceptorEnrollment => {
    attachmentContractTypeIds = _.getPath(
      preceptorEnrollment, 'AttachmentA.Contract.ContractTypes[].id'
    );
    attachmentAs.push(preceptorEnrollment.AttachmentA);
  });

  const isSigned = el => {
    if (!el) { return false; }
    return _.isEqual(el.AttachmentAStatusCodeId, 'CA') &&
      _.isEqual(el.SiteId, siteId) &&
      _.includes(attachmentContractTypeIds, placementContractTypeId);
  };

  return _.every(attachmentAs, isSigned);
};

export const hasSite = (item: Object): any => {
  return _.get(item, 'Site', false);
};

export const hasOrganization = (item: Object): any => {
  return hasSite(item) && _.get(item, 'Site.Organization', false);
};

export const hasSpecialties = (item: Object): any => {
  return hasSite(item) && _.get(item, 'Site.Specialties', false);
};

export const hasPreceptorEnrollments = (item: Object): any => {
  return _.get(item, 'PreceptorEnrollments', false);
};

export const userCanMovePlacement = (
  currentUser: User,
  currentStatus: number,
  targetStatus: number,
  permissions: Permissions
): boolean => {
  if (currentStatus === targetStatus) {
    return false;
  }

  let canMovePlacement = false;
  const placementsAcl = permissions.placement;

  const REQUIRED = 8;
  const PENDING = 9;
  const APPROVED = 10;
  const CANCELLED = 11;

  const statusName = [];

  statusName[REQUIRED] = 'Required';
  statusName[PENDING] = 'Pending';
  statusName[APPROVED] = 'Approved';
  statusName[CANCELLED] = 'Cancelled';

  let currentStatusName = statusName[currentStatus];
  let targetStatusName = statusName[targetStatus];
  let permissionKey = `move${currentStatusName}${targetStatusName}`;

  canMovePlacement = hasAccess(currentUser.Roles, placementsAcl[permissionKey]);
  return canMovePlacement;
};

export const formatValueListItem = (item: Placement): { id: number, name: string, ... } => {
  const catalogNbr = _.get(item, 'Course.CatalogNumberId', 'N/A');
  const studentName = _.get(item, 'Student.fullName', 'N/A');
  const termName = _.get(item, 'Term.name', 'N/A');
  const displayValue = `${catalogNbr} | ${studentName} | ${termName}`;

  return { id: item.id, name: displayValue };
};

export const getValidContractsForPlacement = (placement: Placement): any[] => {
  const siteEnrollments = _.get(placement, 'Package.SiteEnrollments', []);
  const contractTypeForCourse = _.get(
    placement,
    'Course.CatalogNumber.CourseAttr.ContractTypeId',
    undefined
  );

  let contracts = [];
  // get all of the valid contracts for the placement
  if (!_.isNil(contractTypeForCourse)) {
    siteEnrollments.forEach(siteEnrollment => {
      const valid = getContractsForTypeAndGroup(
        contractTypeForCourse,
        siteEnrollment,
        'active'
      );
      if (valid.length > 0) {
        contracts = [...contracts, ...valid];
      }
    });
  }

  return contracts;
};

export const hoursForSiteEnrollment = (siteEnrollment: SiteEnrollment): number => {
  if (!siteEnrollment || !_.isArray(siteEnrollment.PreceptorEnrollments)) {
    return 0;
  }

  return siteEnrollment.PreceptorEnrollments.reduce(
    (acc, preceptorEnrollment: PreceptorEnrollment) => {
      acc += preceptorEnrollment.preceptorHoursAvailable;
      return acc;
    },
    0
  );
};

export const hoursForPlacement = (placement: Placement): number => {
  if (!placement.Package) {
    return 0;
  }

  return placement.Package.SiteEnrollments.reduce(
    (acc, siteEnrollment: SiteEnrollment) => {
      acc += hoursForSiteEnrollment(siteEnrollment);
      return acc;
    },
    0
  );
};

export const contractExpiring = (placement: Placement): boolean => {
  const contracts = getValidContractsForPlacement(placement);
  const contract = contracts[0];
  if (!contract) return false;
  const startDate = _.get(placement, 'Term.TermAttr.startDate', undefined);
  const endDate = _.get(placement, 'Term.TermAttr.endDate', undefined);
  const termStart = startDate ? moment(startDate) : undefined;
  const termEnd = endDate ? moment(endDate) : undefined;
  const contractEnd = contract.endDate ? moment(contract.endDate) : undefined;
  if (contractEnd && contractEnd.isBetween(termStart, termEnd)) return true;
  return false;
};

// @flow

import _ from 'lodash';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {Grid,Typography} from '@material-ui/core';
import {SelectBox} from 'devextreme-react';
import Notify from 'devextreme/ui/notify';

import {fetchPlacementAudits} from '../../../actions/creators/placementAudits';
import {fetchTerms} from '../../../actions/creators/terms';

import {getAllPlacementAudits} from '../../../selectors/placementAudits';
import {getAllCulledTermValues,getActiveTermId} from '../../../selectors/terms';

import PlacementAuditGrid from './PlacementAuditGrid';

type WithStylesProps = {|
  classes: {
    root: Object,
  }
|};

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
});

const PlacementAudit = ({ classes }: WithStylesProps) => {
  const dispatch = useDispatch();

  const [selectedAcademicTermId, setSelectedAcademicTermId] = React.useState(undefined);

  const data = useSelector(getAllPlacementAudits);
  const activeAcademicTermId = useSelector(getActiveTermId);
  const academicTerms = useSelector(getAllCulledTermValues);

  React.useEffect(() => {
    Promise.all([
      dispatch(fetchTerms()),
      setSelectedAcademicTermId(activeAcademicTermId),
      dispatch(fetchPlacementAudits(selectedAcademicTermId)),
    ]);
    // @see https://github.com/facebook/create-react-app/issues/6880#issuecomment-486640921
    // eslint-disable-next-line
  }, []);

  const handleTermChange = props => {
    setSelectedAcademicTermId(props.value);
    dispatch(fetchPlacementAudits(props.value));
    const academicTerm = _.find(academicTerms, {id: props.value});
    const toastSettings = {
      message: `Academic Term was Changed to ${academicTerm.name}.`,
      type: 'success', closeOnClick: true,
      position: 'bottom center'
    };

    return Notify(toastSettings);
  };

  return (
    <Grid
      container
      spacing={1}
      alignContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography variant="h5" paragraph>
          Placement Audit Report
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <SelectBox
          items={academicTerms}
          displayExpr="name"
          valueExpr="id"
          value={selectedAcademicTermId || activeAcademicTermId}
          onValueChanged={handleTermChange}
          searchEnabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <PlacementAuditGrid data={data} />
      </Grid>
    </Grid>
  );
};

export default (withStyles(styles)(PlacementAudit): any);

// @flow

import _ from 'lodash';
import { updateItem } from './methods';

import {
  FETCH_SITES,
  FETCH_SITES_SUCCESS,
  FETCH_SITES_ERROR,
  FETCH_SITE,
  FETCH_SITE_SUCCESS,
  FETCH_SITE_ERROR,
  UPDATE_SITE,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_ERROR
} from '../actions/types';

import type { Site, ValueListItem } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Site>,
  +values: Array<ValueListItem>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_SITES
    | typeof FETCH_SITES_SUCCESS
    | typeof FETCH_SITES_ERROR
    | typeof FETCH_SITE
    | typeof FETCH_SITE_SUCCESS
    | typeof FETCH_SITE_ERROR
    | typeof UPDATE_SITE
    | typeof UPDATE_SITE_SUCCESS
    | typeof UPDATE_SITE_ERROR,
  payload: Array<Site> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_SITES:
      return {...state,
        isLoading: true
      };
    case FETCH_SITES_SUCCESS:
      return {...state,
        isLoading: false,
        items: action.payload,
        values: getValues(action.payload)
      };
    case FETCH_SITES_ERROR:
      return {...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_SITE:
      return {...state,
        isLoading: true
      };
    case FETCH_SITE_SUCCESS:
      return {...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_SITE_ERROR:
      return {...state,
        isLoading: false,
        error: action.payload
      };

    case UPDATE_SITE:
      return { ...state, isLoading: true };
    case UPDATE_SITE_SUCCESS:
      return { ...state,
        isLoading: false,
        selected: action.payload,
        items: updateItem(state.items, action.payload),
      };
    case UPDATE_SITE_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

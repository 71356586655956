// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { SelectLookup } from '../Formik/';
import { TextField } from 'formik-material-ui';

import { Grid, Button } from '@material-ui/core';

import { createPlacementValidation } from '../../validation';

import type { ValueListItem, PlacementApp } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  inputContainer: {
    margin: theme.spacing(1),
    width: '100%'
  },
  hidden: {
    display: 'none'
  },
  dropdownList: {
    zIndex: theme.zIndex.tooltip
  }
});

type Props = {
  placementApp: PlacementApp,
  classes: Object,
  initialValues: Object,
  change: Function,
  courseOptions: ValueListItem[],
  termOptions: ValueListItem[],
  studentOptions: ValueListItem[],
  handleSubmit: Function
};

type State = {};

class CreatePlacementForm extends Component<Props, State> {

  render() {
    const {
      placementApp, studentOptions, termOptions, courseOptions
    } = this.props;

    return(
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={createPlacementValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          handleReset, touched, isSubmitting
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="StudentId"
                  label="Student"
                  options={studentOptions}
                  disabled={!!placementApp}
                  component={SelectLookup}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="postalCode"
                  label="Postal Code"
                  component={TextField}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="TermId"
                  label="Term"
                  options={termOptions}
                  disabled={!!placementApp}
                  component={SelectLookup}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="CourseId"
                  label="Course"
                  options={courseOptions}
                  disabled={!!placementApp}
                  component={SelectLookup}
                />
              </Grid>
            </Grid>
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Save Placement
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles, { withTheme: true, name: 'CreatePlacementForm' })(CreatePlacementForm): any);

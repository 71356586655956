// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';

import {
  Grid,
  Button,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
  Avatar
} from '@material-ui/core';
import { RolesIcon as AvatarIcon } from '../../components/Icons';
import { TextField } from 'formik-material-ui';
import { userValidation } from '../../validation';
import { fetchRoles } from '../../actions/creators/roles';
import type { Role } from '../../schemas';

type Props = {
  classes: Object,
  initialValues: Object,
  roles: Role[],
  canUpdate: Function,
  canDelete: Function,
  handleClose: Function,
  handleSubmit: Function,
  handleDelete: Function,
  fetchRoles: Function,
  onChange: Function,
  formik: Object,
};

type State = {
  user: Object
};

const styles = theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    height: theme.spacing(2.5),
    marginTop: theme.spacing(1) / 4,
    marginRight: theme.spacing(1) / 4,
    marginLeft: theme.spacing(1) / 4,
  },
  avatar: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  avitarIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  }
});

class UserDetailForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const Roles = props.initialValues
      ? _.map(props.initialValues.Roles, role => role.id)
      : [];
    const initialValues = _.cloneDeep(props.initialValues);
    delete initialValues.Roles;
    initialValues.Roles = Roles;
    this.state = {
      user: initialValues
    };
  }

  componentDidMount() {
    this.props.fetchRoles();
  }

  renderValue = values => {
    const { classes, roles } = this.props;

    return (
      <div className={classes.chips}>
        {
          _.map(values, value => {
            const el = _.find(roles, { id: value });
            return (
              <Chip
                key={el.id}
                label={el.name}
                avatar={
                  <Avatar className={classes.avatar}>
                    <AvatarIcon className={classes.avatarIcon}/>
                  </Avatar>
                }
                className={classes.chip}
              />
            );
          })
        }
      </div>
    );
  };

  render() {
    return(
      <Formik
        validateOnMount={true}
        initialValues={this.state.user}
        validationSchema={userValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          handleReset, touched, isSubmitting, isValid, values
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="name"
                  label="Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="email"
                  name="username"
                  label="Email Address"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  select
                  name="Roles"
                  label="Roles"
                  SelectProps={{
                    multiple: true,
                    renderValue: this.renderValue
                  }}
                  component={TextField}
                >
                  {_.map(this.props.roles, role => (
                    <MenuItem key={role.id} value={role.id}>
                      <Checkbox checked={values.Roles.indexOf(role.id) > -1} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.props.canUpdate || isSubmitting || !isValid}
              >
                Save
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.props.canDelete || !values.id}
                onClick={this.props.handleDelete}
              >
                Delete
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    roles: state.roles.items,
  };
};

export default (compose(
  withStyles(styles, { withTheme: true, name: 'UserDetailForm' }),
  connect(mapStateToProps, { fetchRoles })
)(UserDetailForm): any);

// @flow

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography, Checkbox, FormControlLabel, Grid,
  Button, Card, CardHeader, CardContent,
} from '@material-ui/core';

import type { Organization, Site, User } from '../../../schemas';

import OrganizationSiteSearchDialog from './OrganizationSiteSearchDialog';
import OrganizationSiteAddDialog from './OrganizationSiteAddDialog';

import { fetchPermissions } from '../../../actions/creators/permissions';
import { mergeSites } from '../../../actions/creators/sites';
import {
  fetchSelectedOrganization
} from '../../../actions/creators/organizationDrawer';
import { hasAccess } from '../../../utils/permissionsCheck';
import DeepLink from '../../DeepLink';

type State = {
  searchDialogOpen: boolean,
  addDialogOpen: boolean,
  isClone: boolean,
  searchText: string,
  master: number,
  duplicates: number[]
};

type Props = {
  classes: Object,
  currentUser: User,
  permissions: Object,
  organization: Organization,
  isLoading: boolean,
  Sites: Array<Site>,
  fetchSelectedOrganization: Function,
  showOrganizationDrawer: Function,
  fetchPermissions: Function,
  mergeSites: Function
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  siteContainer: {
    paddingLeft: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1)
  },
  chipContainer: {
    textAlign: 'right'
  },
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
});

class OrganizationDetails extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      searchDialogOpen: false,
      addDialogOpen: false,
      isClone: false,
      searchText: '',
      master: 0,
      duplicates: []
    };
  }

  componentDidMount() {
    this.props.fetchPermissions();
  }

  handleDialogShow = props => {
    this.setState({
      addDialogOpen: _.isEmpty(props) ? false : true,
      searchDialogOpen: _.isEmpty(props) ? true : false,
      isClone: !_.isEmpty(props) && props.type === 'clone' ? true : false,
      searchText: !_.isEmpty(props) && props.name ? props.name : ''
    });
  };

  handleDialogClose = () => {
    this.setState({
      searchDialogOpen: false,
      addDialogOpen: false,
      isClone: false,
      searchText: ''
    });
  };

  handleSearchResponse = props => {
    this.handleDialogClose();
    setTimeout(function() { //Start the timer
      this.handleDialogShow(props);
    }.bind(this), 500);
  };

  canCreateSite = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'create', []));
  };

  canViewMergeSites = () => {
    const { organization, currentUser, permissions } = this.props;
    const sites = _.get(organization, 'Sites', []);
    const haveMergeAccess = hasAccess(currentUser.Roles, _.get(permissions, 'merge', []));
    const haveSites = sites.length > 1;
    return (haveMergeAccess && haveSites);
  };

  canMergeSites = () => {
    const { organization, currentUser, permissions } = this.props;
    const { master, duplicates } = this.state;
    const sites = _.get(organization, 'Sites', []);
    const haveMergeAccess = hasAccess(currentUser.Roles, _.get(permissions, 'merge', []));
    const haveSelections = (duplicates.length && master);
    const haveSites = sites.length > 1;
    return (haveMergeAccess && haveSelections && haveSites);
  };

  renderCreateSiteButton = () => {
    const { classes } = this.props;

    return (
      <Button
        disabled={!this.canCreateSite()}
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={() => this.handleDialogShow({})}
      >
        Create New Site
      </Button>
    );
  };

  renderMergeSiteButton = () => {
    const { classes } = this.props;

    return (
      <Button
        disabled={!this.canMergeSites()}
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={this.handleMerge}
      >
        Merge Sites
      </Button>
    );
  };

  handleMasterChange = site => {
    const { master, duplicates } = this.state;
    if (_.includes(duplicates, site.id)) {
      duplicates.splice(duplicates.indexOf(site.id), 1);
    }
    if (site.id !== master) {
      this.setState({ master: site.id, duplicates });
    } else {
      this.setState({ master: 0, duplicates });
    }
  };

  handleDuplicateChange = async site => {
    const { master, duplicates } = this.state;
    if (site.id === master) {
      await this.setState({ master: 0, duplicates });
    }
    if (!_.includes(duplicates, site.id)) {
      duplicates.push(site.id);
    } else {
      duplicates.splice(duplicates.indexOf(site.id), 1);
    }
    this.setState({ duplicates });
  };

  handleMerge = async () => {
    const { organization } = this.props;
    const { master, duplicates } = this.state;
    const confirmation = window.confirm(
      `Are you sure you want to merge ${duplicates.length} Duplicate Site(s) into the selected Master Site?`
    );
    if (confirmation) {
      await this.props.mergeSites({
        mergeSite: master,
        mergeSites: duplicates
      });
      await this.setState({ master: 0, duplicates: [] });
      this.props.fetchSelectedOrganization(organization);
    }
  };

  handleFetchSelectedOrganization = () => {
    const {
      organization,fetchSelectedOrganization
    } = this.props;
    if (organization) return fetchSelectedOrganization(organization);
  };

  renderMergeControls = site => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.master === site.id}
                onChange={() => this.handleMasterChange(site)}
                value="master"
              />
            }
            label="Master"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={_.includes(this.state.duplicates, site.id)}
                onChange={() => this.handleDuplicateChange(site)}
                value="duplicate"
              />
            }
            label="Duplicate"
          />
        </Grid>
      </Grid>
    );
  };

  renderSiteList = () => {
    const { organization, classes } = this.props;
    const sites = _.get(organization, 'Sites', []);

    return(
      <Grid container spacing={2}>
        {sites.length === 0 && (
          <Typography className={classes.emptyMessage}>
            There are no sites associated with this organization.
          </Typography>
        )}
        {sites.length > 0 &&
          sites.map(site => {
            const addressLink = `${site.address},${site.addressLine2},${site.city},${site.StateId},${site.postalCode}`;
            const website = site.website
              ? (<a href="{site.website}" target="_blank">{site.website}</a>)
              : null;
            const phone = site.phone
              ? (<a href="tel:{site.phone}" target="_top">{site.phone}</a>)
              : null;
            const address = (site.city && site.StateId && site.postalCode)
              ? (
                <a href={`http://maps.google.com/?q=${addressLink}`} rel="noopener noreferrer" target="_blank">
                  {site.address}<br/>
                  {site.addressLine2 && <div>{site.addressLine2}</div>}
                  {site.city}, {site.StateId} {site.postalCode}
                </a>
              )
              : null;
            return (
              <Grid item xs={12} sm={6} md={4} key={site.id}>
                <Card className={classes.card}>
                  <CardHeader
                    title={
                      <DeepLink
                        id={site.id}
                        model="site"
                        afterClose={this.handleFetchSelectedOrganization}
                      >
                        <Typography variant="h5" component="h2">
                          {site.name}
                        </Typography>
                      </DeepLink>
                    }
                    subheader={
                      <div>
                        <Typography variant="caption" gutterBottom>
                          {_.get(site.Organization, 'name', null)}<br/>
                          {website}
                        </Typography>
                      </div>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Address</strong>
                        </Typography>
                        <Typography component="div">
                          {address && <div>{address}</div>}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Contact Info</strong>
                        </Typography>
                        <Typography component="div">
                          {phone && <div><strong>Phone:</strong> {phone}</div>}
                        </Typography>
                        <Typography component="div">
                          {site.fax && <div><strong>Fax:</strong> {site.fax}</div>}
                        </Typography>
                      </Grid>
                    </Grid>
                    {this.canViewMergeSites() ? this.renderMergeControls(site) : null}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  render() {
    const { classes, organization } = this.props;
    const { searchDialogOpen, addDialogOpen, isClone, searchText } = this.state;
    const sites = _.get(organization, 'Sites', []);

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Organization Sites
        </Typography>
        {this.renderSiteList()}
        <Grid container>
          <Grid item>
            {this.renderCreateSiteButton()}
          </Grid>
          {sites.length > 1
            ? <Grid item>
              {this.renderMergeSiteButton()}
            </Grid>
            : null}
        </Grid>
        <OrganizationSiteSearchDialog
          open={searchDialogOpen}
          onClose={this.handleDialogClose}
          handleClose={this.handleDialogClose}
          handleResponse={this.handleSearchResponse}
        />
        <OrganizationSiteAddDialog
          open={addDialogOpen}
          organization={organization}
          isClone={isClone}
          searchText={searchText}
          onClose={this.handleDialogClose}
          handleClose={this.handleDialogClose}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizationDrawer.selectedOrganization,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.site
  };
};

export default (compose(connect(mapStateToProps, {
  fetchSelectedOrganization,
  fetchPermissions,
  mergeSites
}), withStyles(styles))(
  OrganizationDetails
): any);

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, AutoSizer } from 'react-virtualized';

import {
  ListItem,
  ListItemText,
  Typography,
  Badge,
  Grid,
  Paper,
  Divider
} from '@material-ui/core';

import { fetchCourses } from '../../../actions/creators/courses';
import { fetchStudentValues } from '../../../actions/creators/students';
import { fetchTerms } from '../../../actions/creators/terms';
import CommonStyles from '../../../styles/CommonStyles';
import CreatePlacementButton from '../../Placement/CreatePlacementButton';
import type { PlacementApp } from '../../../schemas';
import _ from 'lodash';

type Props = {
  orphans: PlacementApp[],
  classes: Object,
  fetchCourses: Function,
  fetchStudentValues: Function,
  fetchTerms: Function,
  mini: ?boolean
};

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingBottom: theme.spacing(1) + 'px',
    minHeight: '160px'
  },
  paperNoOrphans: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(1) + 'px',
    paddingBottom: theme.spacing(1) + 'px',
    ...CommonStyles(theme).success
    // minHeight: '250px'
  },
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(1) + 'px'
  },
  list: CommonStyles(theme).list,
  listItem: {
    borderBottom: '1px solid #cccccc'
  },
  badge: {
    margin: theme.spacing(1) + 'px'
  }
});

class OrphanPlacementAppsWidget extends Component<Props> {
  componentDidMount() {
    this.props.fetchCourses();
    this.props.fetchStudentValues();
    this.props.fetchTerms();
  }

  renderChild = (placementApps, style, key, index) => {
    const placementApp = placementApps[index];
    const displayName =
      placementApp.Student && placementApp.Student.fullName
        ? placementApp.Student.fullName
        : 'No Student';
    return (
      <ListItem key={placementApp.id} className={this.props.classes.listItem}>
        <Grid container spacing={2}>
          <Grid item xs={12} md container justifyContent="center" alignItems="center">
            <Grid item xs={6} md>
              <ListItemText primary={displayName} />
            </Grid>
            <Grid item>
              <ListItemText primary={placementApp.Term.name} />
            </Grid>
          </Grid>
          <Grid item>
            <CreatePlacementButton
              placementApp={placementApp}
              mini
              variant="text"
            />
          </Grid>
        </Grid>
        <Divider />
      </ListItem>
    );
  };

  render() {
    const { classes, orphans, mini } = this.props;
    const orphanCount = orphans && _.isArray(orphans) ? orphans.length : 0;

    const rowRenderer = ({ key, index, style }) => {
      return this.renderChild(orphans, style, key, index);
    };

    if (!orphanCount) {
      return (
        <Paper className={classes.paperNoOrphans}>
          <h2> No Applications without a Placement </h2>
        </Paper>
      );
    } else {
      return (
        <div className={classes.root}>
          <h4 className={classes.title}>
            Applications without a Placement
            <Badge
              color="secondary"
              badgeContent={orphanCount}
              className={classes.badge}
            >
              <Typography>&nbsp;</Typography>
            </Badge>
          </h4>
          <AutoSizer>
            {({ height, width }) =>
              mini ? (
                <List
                  className={classes.list}
                  width={width}
                  height={height - 80}
                  rowCount={orphans ? orphans.length : 0}
                  rowHeight={80}
                  rowRenderer={rowRenderer}
                />
              ) : (
                'not mini'
              )
            }
          </AutoSizer>
        </div>
      );
    }
  }
}

export default (compose(
  connect(undefined, {
    fetchCourses,
    fetchStudentValues,
    fetchTerms
  }),
  withStyles(styles)
)(OrphanPlacementAppsWidget): any);

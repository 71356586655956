// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { Grid, Typography, Paper, Fade, Chip, Avatar, Divider } from '@material-ui/core';
import { SpecialtiesIcon, TodayIcon, DateRangeIcon } from '../../components/Icons';

import type { PlacementApp } from '../../schemas';

type Props = {
  classes: Object,
  application: PlacementApp
};

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(1),
  },
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  }
});

class ApplicationDetail extends Component<Props> {

  renderKeyValue = (key, value, width=3) => {
    return (
      <Grid item xs={width}>
        {key ? <Typography variant="body1">
          {key}:
        </Typography> : null}
        <Fade in={true} timeout={{enter: 1500}}>
          <Typography variant="subtitle1" gutterBottom>
            <strong>{value}</strong>
          </Typography>
        </Fade>
      </Grid>
    );
  };

  renderSpecialtyChips = list => {
    const { classes } = this.props;
    return _.map(list, el =>
      <Chip
        key={el.id}
        avatar={<Avatar><SpecialtiesIcon /></Avatar>}
        label={el.name}
        className={classes.chip}
      />
    );
  };

  renderPlacementAppDays = () => {
    const { application, classes } = this.props;
    const chips = [];
    const excludeKeys = ['id', 'createdAt', 'updatedAt'];
    _.forIn(application.PlacementAppDay, function(value, key) {
      if (!_.includes(excludeKeys, key) && value) {
        chips.push((<Chip
          key={key}
          avatar={<Avatar><TodayIcon /></Avatar>}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          className={classes.chip}
        />));
      }
    });
    return chips;
  };

  renderPlacementAppShifts = () => {
    const { application, classes } = this.props;
    const chips = [];
    const excludeKeys = ['id', 'createdAt', 'updatedAt'];
    _.forIn(application.PlacementAppShift, function(value, key) {
      if (!_.includes(excludeKeys, key) && value) {
        chips.push((<Chip
          key={key}
          avatar={<Avatar><DateRangeIcon /></Avatar>}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          className={classes.chip}
        />));
      }
    });
    return chips;
  };

  render() {
    const { application, classes } = this.props;
    const app = application;

    if (!app) {
      return (
        <Typography className={classes.emptyMessage}>
          There is no student application.
        </Typography>
      );
    }

    return (
      <Paper className={classes.root} elevation={1}>
        <Grid container spacing={2}>
          {this.renderKeyValue('Maiden Name', application.maidenName, 4)}
          {this.renderKeyValue('Place of Employment', application.employer, 4)}
          {this.renderKeyValue('Current State License(s)', application.licensures, 4)}
          {this.renderKeyValue('Active Military', application.activeMilitary ? 'Yes' : 'No', 4)}
          {this.renderKeyValue('Participating in OGACHI', application.ogachi ? 'Yes' : 'No', 8)}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Term / APRN Major & Specialty
            </Typography>
            <Divider />
          </Grid>
          {this.renderKeyValue('Term', application.Term.name, 4)}
          {this.renderKeyValue('Course', application.Course.description, 4)}
          {this.renderKeyValue('Course Hours', application.courseHours, 4)}
          {this.renderKeyValue('Specialties', this.renderSpecialtyChips(application.Specialties), 12)}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              List any areas you are able to travel to outside of the 120 mile radius of your address.
            </Typography>
            <Typography variant="caption">
              (this information is used in the rare case a site cannot be secured within the travel radius)
            </Typography>
            <Divider />
          </Grid>
          {this.renderKeyValue('1st Choice', application.travel1, 4)}
          {this.renderKeyValue('2nd Choice', application.travel2, 4)}
          {this.renderKeyValue('3rd Choice', application.travel3, 4)}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Provide the days you are unavailable for clinicals (if any)
            </Typography>
            <Divider />
          </Grid>
          {this.renderKeyValue(null, this.renderPlacementAppDays(), 12)}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Acute Care majors only: provide the shift times you are unavailable for clinicals (if any)
            </Typography>
            <Divider />
          </Grid>
          {this.renderKeyValue(null, this.renderPlacementAppShifts(), 12)}
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    application: state.placementDrawer.application
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  ApplicationDetail
): any);

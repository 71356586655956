import axios from '../../api';
import * as actions from '../types';

export const fetchUsers = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_USERS, payload: undefined });
    const res = await axios.get('/user');
    dispatch({ type: actions.FETCH_USERS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_USERS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchUsersByRole = role => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_USERS, payload: undefined });
    const res = await axios.get(`/user/role/${role.slug}`);
    dispatch({ type: actions.FETCH_USERS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_USERS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchUser = user => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_USER, payload: user });
    const res = await axios.get(`/user/${user.id}`);
    dispatch({ type: actions.FETCH_USER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.FETCH_USER_ERROR, payload: error.response.data });
  }
};

export const createUser = user => async dispatch => {
  try {
    user.isActive = true;
    dispatch({ type: actions.CREATE_USER, payload: user });
    const res = await axios.post('/user', user);
    dispatch({ type: actions.CREATE_USER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.CREATE_USER_ERROR, payload: error.response.data });
  }
};

export const updateUser = user => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_USER, payload: user });
    const res = await axios.put(`/user/${user.id}`, user);
    dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.UPDATE_USER_ERROR, payload: error.response.data });
  }
};

export const deleteUser = user => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_USER, payload: user });
    const res = await axios.delete(`/user/${user.id}`);
    dispatch({ type: actions.DELETE_USER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: actions.DELETE_USER_ERROR, payload: error.response.data });
  }
};

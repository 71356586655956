// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  Drawer,
  Typography,
  TableCell,
  Select,
  AppBar,
  Toolbar,
  Button
} from '@material-ui/core';
import { CheckBox, CheckBoxBlank} from '../../components/Icons';

import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  IntegratedSelection,
  SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import LoadingIndicator from '../../components/LoadingIndicator';
import SpecialtyDetail from '../../components/Specialty/SpecialtyDetail';

import { fetchSpecialties, fetchSpecialty } from '../../actions/creators/specialties';
import { fetchPermissions } from '../../actions/creators/permissions';
import { hasAccess } from '../../utils/permissionsCheck';

import type { Specialty, User } from '../../schemas';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  flex: { flex: 1 },
  cell: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  cellCenter: {
    width: '100%',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  input: { width: '100%' },
  icon: { margin: theme.spacing(1) },
});

type Props = {
  classes: Object,
  colSpan: number,
  currentUser: User,
  permissions: Object,
  specialties: Specialty[],
  specialtiesLoading: boolean,
  fetchSpecialties: Function,
  fetchSpecialty: Function,
  fetchPermissions: Function
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedSpecialty: ?Specialty,
  rows: Specialty[],
  loading: boolean
};

class SpecialtyPage extends Component<Props, State> {
  state = {
    columns: [
      { name: 'name', title: 'Name' },
      { name: 'code', title: 'Code' },
      { name: 'isActive', title: 'Active?' }
    ],
    defaultFilters: [],
    defaultCurrentPage: 0,
    defaultPageSize: 10,
    defaultSorting: [{ columnName: 'id', direction: 'desc' }],
    pageSizes: [10, 20, 50, 100, 250],
    filteringStateColumnExtensions: [],
    selection: [],
    selectedSpecialty: undefined,
    showDrawer: false,
    rows: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.specialties !== state.rows) {
      return { rows: props.specialties };
    }
    if (props.specialtiesLoading !== state.loading) {
      return { loading: props.specialtiesLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchSpecialties();
    await this.props.fetchPermissions();
  };

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.create);
  }

  canDelete() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  }

  handleSelection = selection => {
    const { specialties } = this.props;
    const selectedSpecialty = specialties[selection];
    if (selection[0]) {
      this.setState({ showDrawer: true, selectedSpecialty });
    } else {
      this.setState({ showDrawer: true, selectedSpecialty: undefined });
    }
  };

  handleClose = () => {
    this.setState({
      selection: [],
      selectedSpecialty: undefined,
      showDrawer: false
    });
  };

  renderAppBar = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.flex} />
          <Button
            size="small"
            disabled={!this.canCreate()}
            type="submit"
            variant="contained"
            onClick={props => this.handleSelection(props)}
          >
            New Specialty
          </Button>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      defaultFilters,
      defaultCurrentPage,
      defaultPageSize,
      defaultSorting,
      pageSizes,
      filteringStateColumnExtensions,
      selection,
      selectedSpecialty,
      showDrawer,
      rows,
      loading
    } = this.state;

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Select>
        </TableCell>
      );
    }

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'isActive') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'isActive') {
        return (
          <Table.Cell className={classes.cellCenter}>
            {props.value ? (
              <CheckBox className={classes.icon} />
            ) : (
              <CheckBoxBlank className={classes.icon} />
            )}
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          Specialties
        </Typography>
        <Paper>
          {this.renderAppBar()}
          <Grid rows={rows} columns={columns}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState defaultSorting={defaultSorting} />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table
              cellComponent={props => Cell(props)}
              noDataCellComponent={props => NoDataCell(props)}
            />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => FilterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer open={showDrawer} anchor="bottom" onClose={this.handleClose}>
          <SpecialtyDetail
            handleClose={this.handleClose}
            selectedSpecialty={selectedSpecialty}
            canUpdate={this.canUpdate()}
            canDelete={this.canDelete()}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    specialties: state.specialties.items,
    specialtiesLoading: state.specialties.isLoading,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.specialty
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchSpecialties,
    fetchSpecialty,
    fetchPermissions,
  })
)(SpecialtyPage): any);

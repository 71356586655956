// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, List, Typography } from '@material-ui/core';

import TodoDetail from './TodoDetail';

import type { Todo, User } from '../../schemas';

type Props = {
  classes: Object,
  currentUser: User,
  todos: Todo[],
  afterAction: Function
};

const styles = theme => ({
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  },
  list: {
    minWidth: '100%'
  }
});

class TodoList extends Component<Props> {
  render() {
    const { todos, afterAction, classes } = this.props;

    return (
      <Grid container>
        <List dense className={classes.list}>
          {todos.length === 0 && (
            <Typography className={classes.emptyMessage}>
              There are no todos.
            </Typography>
          )}
          {todos.length > 0 &&
            todos.map(todo => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={6} key={todo.id}>
                  <TodoDetail afterAction={afterAction} currentUser={this.props.currentUser} todo={todo} />
                </Grid>
              );
            })}
        </List>
      </Grid>
    );
  }
}

export default (withStyles(styles)(TodoList): any);


export default {
  columnAutoWidth: true,
  filterPanel: { visible: true },
  filterRow: { visible: true },
  filterSyncEnabled: true,
  selection: { mode: 'multiple', allowSelectAll: true, },
  columnChooser: { enabled: true, height: 500, width: 222, },
  groupPanel: { visible: false },
  showBorders: true,
  pager: {
    allowedPageSizes: [5, 10, 20, 50, 100],
    showInfo: true,
    showNavigationButtons: true,
    showPageSizeSelector: true
  },
  rowAlternationEnabled: true,
  export: {
    enabled: true,
    allowExportSelectedData: true,
    fileName: 'data-grid-export',
    excelFilterEnabled: true,
  },
  headerFilter: { visible: false },
  allowColumnReordering: true,
  allowColumnResizing: true,
  columnResizingMode: 'nextColumn',
  columnMinWidth: 75,
  grouping: { allowCollapsing: true, contextMenuEnabeled: true },
  showColumnLines: true,
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import PreceptorForm from './PreceptorForm';

import { hasAccess } from '../../utils/permissionsCheck';
import type { Preceptor, User, ValueListItem } from '../../schemas';

import {
  updatePreceptor,
  createPreceptor
} from '../../actions/creators/preceptors';

import { hidePreceptorDrawer } from '../../actions/creators/preceptorDrawer';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  preceptor: Preceptor,
  classes: Object,
  currentUser: User,
  permissions: Object,
  specialties: ValueListItem[],
  sites: ValueListItem[],
  states: ValueListItem[],
  countries: ValueListItem[],
  updatePreceptor: Function,
  createPreceptor: Function,
  hidePreceptorDrawer: Function,
  fetchCountries: Function,
};

class PreceptorDetail extends Component<Props> {

  getPermission = permission => {
    return hasAccess(
      this.props.currentUser.Roles,
      this.props.permissions[permission]
    );
  };

  handleSubmit = async values => {
    const {
      updatePreceptor, createPreceptor, hidePreceptorDrawer
    } = this.props;

    if (values.id) {
      values.GeocodeStatusId = null;
      await updatePreceptor(values);
    } else {
      await createPreceptor(values);
    }

    hidePreceptorDrawer();
  };

  generateInitialValues = () => {
    return _.get(this.props, 'preceptor', { CountryId: 'US' });
  };

  render() {
    return (
      <PreceptorForm
        initialValues={this.generateInitialValues()}
        valueList={{
          specialties: this.props.specialties,
          sites: this.props.sites,
          states: this.props.states,
          countries: this.props.countries,
        }}
        canUpdate={this.getPermission('update')}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.hidePreceptorDrawer}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.preceptor,
    specialties: state.specialties.values,
    sites: state.sites.values,
    states: state.states.values,
    countries: state.countries.values
  };
};

export default (compose(
  connect(mapStateToProps, {
    updatePreceptor, createPreceptor, hidePreceptorDrawer,
  }),
  withStyles(styles)
)(PreceptorDetail): any);

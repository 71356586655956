// @flow

import _ from 'lodash';

import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

import {fetchSite,updateSite} from '../../../actions/creators/sites';

import {fetchSiteTypes} from '../../../actions/creators/siteTypes';
import {fetchCounties} from '../../../actions/creators/counties';
import {fetchCountries} from '../../../actions/creators/countries';
import {fetchStates} from '../../../actions/creators/states';
import {fetchSpecialties} from '../../../actions/creators/specialties';
import {fetchOrganizationList} from '../../../actions/creators/organizations';

import {getSelectedSite} from '../../../selectors/sites';

import {getAllSiteTypeValues} from '../../../selectors/siteTypes';
import {getAllCountyValues} from '../../../selectors/counties';
import {getAllCountryValues} from '../../../selectors/countries';
import {getAllStateValues} from '../../../selectors/states';
import {getAllSpecialtyValues} from '../../../selectors/specialties';
import {getAllOrganizationValues} from '../../../selectors/organizations';

import {useCached} from '../../../hooks';
import {hasAccess} from '../../../utils/permissionsCheck';

import SiteForm from './SiteForm';

type Props = {
  id: number,
  model: string,
  handleClose: Function
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const SiteDialog = (props: Props): React$Element<React$FragmentType> => {
  const {id,model,handleClose} = props;
  const dispatch = useDispatch();

  const site = useSelector(getSelectedSite);
  const permissions = useSelector(state => state.permissions.site);
  const currentUser = useSelector(state => state.auth.currentUser);

  const states = useCached(useSelector(getAllStateValues));
  const specialties = useCached(useSelector(getAllSpecialtyValues));
  const counties = useCached(useSelector(getAllCountyValues));
  const countries = useCached(useSelector(getAllCountryValues));
  const siteTypes = useCached(useSelector(getAllSiteTypeValues));
  const organizations = useCached(useSelector(getAllOrganizationValues));

  let haveAssociations = Boolean(
    states.length && specialties.length && counties.length && countries.length
    && siteTypes.length && organizations.length
  );

  useEffect(() => {
    dispatch(fetchSite({ id: id }));
  }, [dispatch,id]);

  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchStates());
      dispatch(fetchSpecialties());
      dispatch(fetchCounties());
      dispatch(fetchCountries());
      dispatch(fetchSiteTypes());
      dispatch(fetchOrganizationList());
    }
  }, [dispatch,haveAssociations]);

  const handleSubmit = async values => {
    await dispatch(updateSite(values));
    handleClose(true);
  };

  const canUpdate = hasAccess(currentUser.Roles, permissions.update);
  const objectMatch = _.get(site, 'id', undefined) === id;

  return (
    <React.Fragment>
      {(!objectMatch || !haveAssociations) &&  <DialogTitle id={`${model}-${id}-dialog`}>
        <LinearProgress variant="query" color="primary" />
      </DialogTitle>}
      {objectMatch && site && haveAssociations && <DialogTitle id={`${model}-${id}-dialog`} onClose={() => handleClose()}>
        Site: {site.name}
      </DialogTitle>}
      {objectMatch && haveAssociations && <DialogContent dividers>
        {<SiteForm
          initialValues={site}
          canUpdate={canUpdate}
          handleSubmit={handleSubmit}
          associations={{states,specialties,counties,countries,siteTypes,organizations}}
        />}
      </DialogContent>}
    </React.Fragment>
  );
};

export default SiteDialog;

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Typography } from '@material-ui/core';

import OrganizationDetail from '../../Organization/OrganizationDetail';
import type { Organization } from '../../../schemas';

type Props = {
  classes: Object,
  organization: Organization,
  isLoading: boolean
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
});

class OrganizationDetails extends Component<Props> {

  render() {
    const { classes, organization } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Edit Organization
        </Typography>
        <OrganizationDetail organization={organization} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizationDrawer.selectedOrganization
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  OrganizationDetails
): any);

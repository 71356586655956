// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper, Drawer, Typography, TableCell,
  Select, AppBar, Toolbar, Button
} from '@material-ui/core';
import { SuccessIcon, ErrorIcon } from '../../components/Icons';
import CommonStyles from '../../styles/CommonStyles';

import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting, IntegratedSelection, SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel,
  TableFilterRow, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import {
  fetchApplications, clearApplications, fetchApplication
} from '../../actions/creators/studentApplications';
import { fetchStudent } from '../../actions/creators/students';
import { fetchTerms } from '../../actions/creators/terms';
import { fetchPermissions } from '../../actions/creators/permissions';

import { hasAccess } from '../../utils/permissionsCheck';

import LoadingIndicator from '../../components/LoadingIndicator';
import ApplicationDetail from '../../components/Students/ApplicationDetail';

import { getActiveTerm } from '../../selectors/terms';

import type { PlacementApp, User, Student } from '../../schemas';

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      padding: theme.spacing(2)
    },
    flex: { flex: 1 },
    cell: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    cellCenter: {
      width: '100%',
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    input: { width: '100%' },
    icon: { margin: theme.spacing(1) },
    chip: { margin: theme.spacing(1) },
    chipWarn: {
      margin: theme.spacing(1),
      backgroundColor: commonStyles.warn.backgroundColor,
      color: commonStyles.warn.color
    },
    chipRow: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  };
  return classes;
};

type Props = {
  classes: Object,
  colSpan: number,
  currentUser: User,
  currentStudent: Student,
  permissions: Object,
  applications: PlacementApp[],
  dataLoading: boolean,
  fetchStudent: Function,
  fetchTerms: Function,
  fetchPermissions: Function,
  fetchApplications: Function,
  fetchApplication: Function,
  clearApplications: Function,
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  sortingStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedApplication: ?PlacementApp,
  rows: PlacementApp[],
  loading: boolean
};

class StudentApplicationsPage extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: 'CourseId',
          title: 'Course ID',
          getCellValue: row => row.Course ? `N.${row.Course.CatalogNumberId}` : 'N/A'
        },
        {
          name: 'Course',
          title: 'Course Name',
          getCellValue: row => (row.Course ? `${row.Course.description}` : 'N/A')
        },
        {
          name: 'Term',
          title: 'Term',
          getCellValue: row => (row.Term ? row.Term.name : 'N/A')
        },
        {
          name: 'hasPlacement',
          title: 'Linked to Placement?',
          getCellValue: row => {
            const placementId = _.get(row.Placement, 'id', null);
            return placementId ? true : false;
          }
        }
      ],
      defaultFilters: [],
      defaultCurrentPage: 0,
      defaultPageSize: 10,
      defaultSorting: [
        { columnName: 'Term', direction: 'desc' }
      ],
      pageSizes: [10, 20, 50, 100, 250],
      filteringStateColumnExtensions: [],
      sortingStateColumnExtensions: [
        { columnName: 'hasPlacement', sortingEnabled: false },
      ],
      selection: [],
      selectedApplication: undefined,
      showDrawer: false,
      rows: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.applications !== state.rows) {
      return { rows: props.applications };
    }

    return null;
  }

  loadData = async () => {
    const studentId = _.get(this.props.currentUser, 'StudentId', null);

    await this.props.clearApplications();
    await this.props.fetchTerms();
    await this.props.fetchPermissions();
    if (studentId) {
      await this.props.fetchStudent({ id: studentId });
      await this.props.fetchApplications({ id: studentId });
    }
    this.setState({ loading: false });
  };

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.create);
  }

  canDelete() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  }

  handleSelection = selection => {
    const { applications } = this.props;
    const selectedApplication = _.find(applications, { id: selection[0] });
    this.setState({ showDrawer: true, selectedApplication });
  };

  handleClose = () => {
    this.setState({
      selection: [],
      selectedApplication: undefined,
      showDrawer: false
    });
  };

  renderAppBar = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.flex} />
          <Button
            size="small"
            disabled={!this.canCreate()}
            type="submit"
            variant="contained"
            onClick={props => this.handleSelection(props)}
          >
            New Application
          </Button>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, pageSizes, filteringStateColumnExtensions,
      sortingStateColumnExtensions, selection, selectedApplication,
      showDrawer, rows, loading
    } = this.state;

    function BooleanFilterCellBase(props) {
      return (
        <TableCell className={props.classes.cell}>
          <Select
            native
            className={props.classes.input}
            value={props.filter ? props.filter.value : ''}
            type="boolean"
            onChange={e =>
              props.onFilter(e.target.value ? { value: e.target.value } : '')
            }
            inputProps={{
              id: 'boolean-native-simple'
            }}
          >
            <option value="">All</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>
        </TableCell>
      );
    }

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    function FilterCell(props) {
      if (props.column.name === 'hasPlacement') {
        const newProps = { ...props, classes };
        return <BooleanFilterCellBase {...newProps} />;
      }
      return <TableFilterRow.Cell {...props} />;
    }

    function Cell(props) {
      if (props.column.name === 'hasPlacement') {
        return (
          <Table.Cell className={classes.cellCenter}>
            {props.value ? (
              <SuccessIcon className={classes.icon} color="primary" />
            ) : (
              <ErrorIcon className={classes.icon} color="secondary" />
            )}
          </Table.Cell>
        );
      }
      return <Table.Cell {...props} />;
    }

    function getRowId(row) {
      return row.id;
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          Applications
        </Typography>
        <Paper>
          {this.renderAppBar()}
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState
              defaultSorting={defaultSorting}
              columnExtensions={sortingStateColumnExtensions}

            />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <Table
              cellComponent={props => Cell(props)}
              noDataCellComponent={props => NoDataCell(props)}
            />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => FilterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer open={showDrawer} anchor="bottom" onClose={this.handleClose}>
          <ApplicationDetail
            handleClose={this.handleClose}
            selectedApplication={selectedApplication}
            canUpdate={this.canUpdate()}
            canDelete={this.canDelete()}
            currentUser={this.props.currentUser}
            currentStudent={this.props.currentStudent}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    applications: state.studentApplications.items,
    activeTerm: getActiveTerm(state),
    currentUser: state.auth.currentUser,
    currentStudent: state.students.selected,
    permissions: state.permissions.placementApp,
    dataLoading: state.studentApplications.isLoading,
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchStudent, fetchTerms, fetchPermissions,
    clearApplications, fetchApplications, fetchApplication,
  })
)(StudentApplicationsPage): any);

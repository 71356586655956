import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const fetchContractAttachments = ContractId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_ATTACHMENTS, payload: undefined });
    const res = await axios.get('/attachment', { params: { ContractId } });
    dispatch({
      type: actions.FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_ATTACHMENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPreceptorAttachments = preceptor => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PRECEPTOR_ATTACHMENTS, payload: preceptor });
    const res = await axios.get(`/attachment/preceptor/${ preceptor.id }`);
    dispatch({ type: actions.FETCH_PRECEPTOR_ATTACHMENTS_SUCCESS, payload: res.data });
    return res.data;
  } catch (error) {
    dispatch({ type: actions.FETCH_PRECEPTOR_ATTACHMENTS_ERROR, payload: error.response.data });
  }
};

export const clearContractAttachments = () => {
  return { type: actions.CLEAR_CONTRACT_ATTACHMENTS };
};

export const createAttachment = attachment => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_ATTACHMENT, payload: attachment });
    const { data } = await axios.post(`/attachment`, attachment);
    dispatch({ type: actions.CREATE_ATTACHMENT_SUCCESS, payload: data });
    switch (data.model) {
      case 'ContractRequest':
        dispatch(fetchContractAttachments(data.foreignKey));
        break;
      case 'Preceptor':
        dispatch(fetchPreceptorAttachments({ id: data.foreignKey }));
        break;
      default: // do nothing
    }
    dispatch(
      notify({
        title: 'Success',
        message: 'Attachment successfully created.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create attachment.',
        status: 'error',
      })
    );
  }
};

export const deleteAttachment = attachment => async dispatch => {
  try {
    let { data } = await axios.delete(`/attachment/${attachment.id}`);
    dispatch({ type: actions.DELETE_ATTACHMENT_SUCCESS, payload: data });
    switch (attachment.model) {
      case 'ContractRequest':
        dispatch(fetchContractAttachments(attachment.foreignKey));
        break;
      case 'Preceptor':
        dispatch(fetchPreceptorAttachments({ id: attachment.foreignKey }));
        break;
      default: // do nothing
    }
    dispatch(
      notify({
        title: 'Success',
        message: 'Attachment successfully deleted.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not delete attachment.',
        status: 'error',
      })
    );
  }
};

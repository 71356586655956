// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Button, Dialog, DialogTitle, DialogContent
} from '@material-ui/core';

import CreateAttachmentAForm from './CreateAttachmentAForm';

import {
  createAttachmentA, fetchAttachmentAs, postAttachmentAWorkflowLogEntry
} from '../../actions/creators/attachmentAs';
import { fetchUsers } from '../../actions/creators/users';
import { createUserMessage } from '../../actions/creators/userMessages';

import type { User } from '../../schemas';

type Props = {
  classes: Object,
  mini: ?boolean,
  color: ?string,
  size: ?string,
  currentUser: User,
  users: User[],
  afterCreate: Function,
  createAttachmentA: Function,
  fetchAttachmentAs: Function,
  postAttachmentAWorkflowLogEntry: Function,
  createUserMessage: Function,
  fetchUsers: Function
};

type State = {
  dialogVisible: boolean,
  template: Object
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class CreateAttachmentAButton extends Component<Props, State> {

  constructor(props) {
    super(props);
    let template = {
      StartTermId: _.get(props, 'StartTermId', undefined),
      CourseId: undefined,
      description: undefined,
      SiteId: undefined,
      ContractId: _.get(props, 'ContractId', undefined),
      AttachmentAStatusCodeId: 'R',
      PlacementIds: [],
      startDate: undefined,
      endDate: undefined,
      dateRequested: moment(),
      willSend: false
    };

    this.state = {
      dialogVisible: false,
      template: template
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  usersForRole = role => {
    const { users } = this.props;
    return _.filter(users, user => _.includes(_.map(user.Roles, 'slug'), role));
  };

  handleOpen = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleNotification = async props => {
    const { createUserMessage, currentUser } = this.props;
    const usersForRole = this.usersForRole('contracts_manager');

    let userMessage = `You created AttachmentA #${props.attachmentA.id}.`;
    let roleMessage = `${currentUser.name} created AttachmentA #${props.attachmentA.id} and requested that you send it.`;
    if (props.willSend) {
      userMessage = `You created AttachmentA #${props.attachmentA.id} that you agreed to send.`;
      roleMessage = `${currentUser.name} created AttachmentA #${props.attachmentA.id}.`;
    }

    createUserMessage({
      message: userMessage, tag: 'attachment-a', SenderId: currentUser.id,
      RecipientId: currentUser.id
    });

    for (const user of usersForRole) {
      createUserMessage({
        message: roleMessage, tag: 'attachment-a', SenderId: currentUser.id,
        RecipientId: user.id
      });
    }

    return;
  };

  handleSubmit = async values => {
    const {
      createAttachmentA,
      fetchAttachmentAs,
      postAttachmentAWorkflowLogEntry,
      currentUser,
      afterCreate
    } = this.props;

    const willSend = values.willSend;
    delete values.willSend;

    let result = await createAttachmentA(values);

    if (result) {
      const attachmentA = result;
      let workFlowItem = {
        AttachmentAId: attachmentA.id,
        UserId: currentUser.id,
        StatusChangedToId: 'R'
      };

      await postAttachmentAWorkflowLogEntry(workFlowItem);
      await fetchAttachmentAs();
      await this.handleNotification({ attachmentA, willSend });

      this.handleClose();

      if (!_.isNil(afterCreate)) {
        afterCreate();
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, template } = this.state;
    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          size={_.get(this.props, 'size', 'medium')}
          variant={_.get(this.props, 'variant', 'contained')}
          className={classes.button}
          onClick={this.handleOpen}
        >
          Create Attachment A
        </Button>
        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="creat-attachment-a-dialog"
        >
          <DialogTitle id="create-attachment-a-dialog">
            Create Attachment A
          </DialogTitle>
          <DialogContent>
            <CreateAttachmentAForm
              handleSubmit={this.handleSubmit}
              initialValues={template}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.users.items
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchUsers, createUserMessage, createAttachmentA, fetchAttachmentAs,
    postAttachmentAWorkflowLogEntry
  }),
  withStyles(styles, { withTheme: true, name: 'CreateAttachmentAButton'})
)(CreateAttachmentAButton): any);

// @flow

import _ from 'lodash';
import axios from '../../api';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Select, Checkbox } from '../Formik';

import { TextField } from 'formik-material-ui';
import {DatePicker} from '@material-ui/pickers';

import {
  Grid, Button, FormControl, FormControlLabel
} from '@material-ui/core';

import { attachementAValidation } from '../../validation';

import { getAllCulledTermValues } from '../../selectors/terms';

import type { ValueListItem } from '../../schemas';

type CourseOption = {
  id: string,
  name: string,
  ContractTypeId: string
};

type ContractOption = {
  id: string,
  name: string,
  OrganizationId: string
};

type Props = {
  classes: Object,
  initialValues: Object,
  handleSubmit: Function,
  terms: ValueListItem[],
};

type State = {
  courseOptions: CourseOption[],
  siteOptions: ValueListItem[],
  contractOptions: ContractOption[],
  filteredContractOptions: ContractOption[],
  filterQuery: string
};

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  buttons: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  inputContainer: {
    margin: theme.spacing(1),
    width: '100%'
  },
  hidden: {
    display: 'none'
  }
});

class CreateAttachmentAForm extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      courseOptions: [],
      siteOptions: [],
      contractOptions: [],
      filteredContractOptions: [],
      filterQuery: '',
      description: '',
    };
  }

  componentDidMount() {
    this.setDefaults();
  }

  setDefaults = async () => {
    const { initialValues } = this.props;
    const { StartTermId, ContractId } = initialValues;
    if (StartTermId) {
      await this.handleTermChange(StartTermId, undefined);
    }
    if (ContractId) {
      await this.handleContractChange(ContractId, undefined);
    }
  };

  updateFilterQuery = e => {
    const filterQuery = e.target.value;
    const { contractOptions } = this.state;

    let filteredContractOptions = contractOptions;

    if (filterQuery !== '') {
      filteredContractOptions = filteredContractOptions.filter(
        option =>
          option.name.toLowerCase().indexOf(filterQuery.toLowerCase()) >= 0
      );
    }

    this.setState({ filterQuery, filteredContractOptions });
  };

  handleInputChange = (e, values, setFieldValue) => {
    const { name, value } = e.target;

    switch (name) {
      case 'StartTermId':
        this.handleTermChange(value, values.StartTermId);
        break;
      case 'CourseId':
        this.handleCourseChange(value, values.CourseId, setFieldValue);
        break;
      case 'ContractId':
        this.handleContractChange(value, values.ContractId);
        break;
      default:
      // do nothing
    }
  };

  handleTermChange = (value, previousValue) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      this.setState({ courseOptions: [] });
    }
    if (!_.isEqual(previousValue, value)) {
      this.fetchCourseOptions();
    }
  };

  handleCourseChange = async (newValue, previousValue, setFieldValue) => {
    const { initialValues } = this.props;

    if (_.isEmpty(newValue) || _.isNil(newValue)) {
      this.setState({ contractOptions: [] });
    }
    if (!_.isEqual(previousValue, newValue)) {
      await this.fetchContractOptions(newValue, setFieldValue);

      if (!_.isNil(initialValues.ContractId)) {
        this.fetchSiteOptions(initialValues.ContractId);
      }
    }
  };

  handleContractChange = (newValue, previousValue) => {
    if (_.isEmpty(newValue) || _.isNil(newValue)) {
      this.setState({ siteOptions: [] });
    }
    if (!_.isEqual(previousValue, newValue)) {
      this.fetchSiteOptions(newValue);
    }
  };

  fetchCourseOptions = async () => {
    let { data } = await axios.get(`/course/clinical/value`);
    this.setState({ courseOptions: data });
  };

  fetchContractOptions = async (CourseId, setFieldValue) => {
    const { courseOptions } = this.state;

    const selectedCourse = courseOptions.find(course =>
      _.isEqual(course.id, CourseId)
    );

    if (selectedCourse) {
      let { data } = await axios.get('/contract/contractList', {
        params: { ContractTypeId: selectedCourse.ContractTypeId }
      });

      this.setState({
        contractOptions: data,
        filteredContractOptions: data
      });

      setFieldValue('description', selectedCourse.name);
    }
  };

  fetchSiteOptions = async ContractId => {
    const { contractOptions } = this.state;
    const selectedContract = contractOptions.find(contract =>
      _.isEqual(contract.id, ContractId)
    );

    if (selectedContract) {
      let { data } = await axios.get('/site/siteList', {
        params: { OrganizationId: selectedContract.OrganizationId }
      });

      this.setState({ siteOptions: data });
    }
  };

  render() {
    const { classes, terms,initialValues } = this.props;
    const {
      courseOptions, filteredContractOptions, filterQuery, siteOptions
    } = this.state;

    return(
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        validationSchema={attachementAValidation}
        onSubmit={values => {
          this.props.handleSubmit(values);
        }}
      >
        {({
          handleBlur,handleReset,setFieldValue,isSubmitting,isValid,values
        }) => (
          <Form className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="StartTermId"
                  label="Starting Term"
                  onChange={e => this.handleInputChange(e, values, setFieldValue)}
                  disabled={terms.length === 0}
                  options={terms}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="CourseId"
                  label="Course"
                  onChange={e => this.handleInputChange(e, values, setFieldValue)}
                  disabled={courseOptions.length === 0}
                  options={courseOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="filterQuery"
                  label="Contract Filter"
                  type="text"
                  value={filterQuery}
                  onChange={this.updateFilterQuery}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="ContractId"
                  label="Contract"
                  onChange={e => this.handleInputChange(e, values, setFieldValue)}
                  disabled={filteredContractOptions.length === 0}
                  options={filteredContractOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="SiteId"
                  label="Site"
                  disabled={siteOptions.length === 0}
                  options={siteOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth key="startDate" className={classes.inputContainer}>
                  <Field
                    fullWidth
                    required
                    variant="inline"
                    disableToolbar
                    name="startDate"
                    label="Start Date"
                    format="MMMM DD, YYYY"
                    autoOk
                    value={values.startDate}
                    onChange={date => {
                      const value = date !== null ? date : initialValues.startDate;
                      setFieldValue('startDate', value);
                    }}
                    onBlur={handleBlur}
                    component={DatePicker}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth key="endDate" className={classes.inputContainer}>
                  <Field
                    fullWidth
                    required
                    variant="inline"
                    disableToolbar
                    name="endDate"
                    label="End Date"
                    format="MMMM DD, YYYY"
                    autoOk
                    value={values.endDate}
                    onChange={date => {
                      const value = date !== null ? date : initialValues.endDate;
                      setFieldValue('endDate', value);
                    }}
                    onBlur={handleBlur}
                    component={DatePicker}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.willSend}
                      name="willSend"
                      label="I will send this Attachment A"
                      component={Checkbox}
                    />
                  }
                  label="I will send this Attachment A"
                />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                color="primary"
                className={classes.button}
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
                className={classes.button}
              >
                Create Attachment A
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    terms: getAllCulledTermValues(state)
  };
};

export default (compose(
  withStyles(styles, { withTheme: true, name: 'CreateAttachmentAForm'}),
  connect(mapStateToProps, {}),
)(CreateAttachmentAForm): any);

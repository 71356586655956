// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  Switch,
  FormControlLabel
} from '@material-ui/core';

import IconUsage from './IconUsage';
import { placement } from '../../mockData/PlacementDrawer/selectedPlacement';

import PlacementSlotList from '../../components/PlacementDrawer/PlacementSlots/PlacementSlotList';

import { mockSelectedPlacement } from '../../actions/creators/placementDrawer';

import ReactJson from 'react-json-view';

import type { Placement } from '../../schemas/index';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '64px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    overflow: 'auto'
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2) + 'px',
    paddingBottom: theme.spacing(2) + 'px'
  }
});

const loremParagraph: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras bibendum ex sed ante convallis, sed sollicitudin metus imperdiet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi efficitur velit at tellus lacinia consequat. Aliquam erat volutpat. Etiam in metus sagittis, consectetur nisl eu, gravida felis. Pellentesque interdum velit mauris, eu mattis lectus scelerisque at. Vestibulum vehicula nisl quis quam faucibus, id lacinia quam cursus. Vestibulum molestie gravida velit, ac lacinia massa rutrum et. Integer non bibendum nibh. Fusce et orci nibh. Fusce neque orci, vehicula ac condimentum id, rhoncus pretium mi. Phasellus non consequat ex. Quisque porta, magna et sagittis tempus, diam nibh scelerisque massa, quis dictum sapien urna eu ex.`;
const loremPhrase = loremParagraph.slice(0, 30);

const typographyVariants = [
  'h5',
  'title',
  'subheading',
  'body2',
  'body1',
  'caption',
  'button'
];

type Props = {
  classes: Object,
  theme: Object,
  selectedPlacement: Placement,
  mockSelectedPlacement: Function
};

type State = {
  placementEditable: boolean,
  placementDisplayOnly: boolean
};

class StyleGuide extends Component<Props, State> {
  state = { placementEditable: true, placementDisplayOnly: false };
  constructor(props) {
    super(props);
    props.mockSelectedPlacement(placement);
  }
  handleChange = name => event => {
    this.setState({ [name.toString()]: event.target.checked });
  };
  render() {
    const { classes, theme, selectedPlacement } = this.props;
    const { placementDisplayOnly, placementEditable } = this.state;
    const jsonViewThemeName =
      theme.palette.type === 'dark' ? 'monokai' : 'rjv-default';
    const jsonViewStyles = {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2) + 'px',
      boxShadow: theme.shadows[2]
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Style Guide</h1>
            <Typography variant="subtitle1">
              The application is styled using React Material UI
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <h2>React Material UI theme object</h2>
            <Typography variant="body1">
              When styling components, wrap the component in the `withStyles`
              HOC, and use the styles function to set your styles. The following
              is reference for the `theme` argument that will be provided to
              your styles function by the `withStyles` HOC.
            </Typography>
            <ReactJson
              src={this.props.theme}
              theme={jsonViewThemeName}
              collapsed={true}
              name="theme"
              iconStyle="circle"
              style={jsonViewStyles}
            />
          </Grid>
          <Grid item xs={12}>
            <h2>Headings</h2>
            <Paper className={classes.paper}>
              <h1>Heading One</h1>
              <h2>Heading Two</h2>
              <h3>Heading Three</h3>
              <h4>Heading Four</h4>
              <h5>Heading Five</h5>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <h2>Typography</h2>
            <Typography variant="body1">
              The following <em>Typograpy</em> variants are used. Those omitted
              are not part of our style.
            </Typography>
            <Paper className={classes.paper}>
              {typographyVariants.map((variant: string) => (
                <Typography key={variant} variant={variant}>
                  {variant}: {loremPhrase}
                </Typography>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <IconUsage {...this.props} />
          </Grid>
          <Grid item xs={12}>
            <h2>Component Demos</h2>
            <h3>Placement Slot List</h3>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={placementEditable}
                      onChange={this.handleChange('placementEditable')}
                      value="placementEditable"
                      color="primary"
                      disabled={false}
                    />
                  }
                  label="Placement Editable"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={placementDisplayOnly}
                      onChange={this.handleChange('placementDisplayOnly')}
                      disabled={false}
                      color="primary"
                    />
                  }
                  label="Placement Display Only"
                />
              </Grid>
            </Grid>
            {selectedPlacement && (
              <PlacementSlotList
                items={selectedPlacement.Package.SiteEnrollments}
                editable={placementEditable}
                displayOnly={placementDisplayOnly}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedPlacement: state.placementDrawer.selectedPlacement
});

export default (compose(
  withStyles(styles, {
    withTheme: true,
    name: 'StyleGuide'
  }),
  connect(mapStateToProps, {
    mockSelectedPlacement
  })
)(StyleGuide): any);

// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Backdrop } from '@material-ui/core';
import { LinearProgress, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    textAlign: 'center',
    flex: '1 0 auto'
  },
  circularProgress: {
    margin: `0 ${theme.spacing(2)}px`,
    color: '#FFFFFF',
    zIndex: 2003
  },
  progressContainer: {
    display: 'flex',
    width: '100%'
  },
  overlayContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2000
  },
  overlay: {
    zIndex: 2001
  },
  overlayProgressContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2002
  },
  progressText: {
    margin: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 1.3,
    zIndex: 2003
  }
});

type Props = {
  loading: boolean,
  overlay: boolean,
  classes: Object
};

const LoadingIndicator = ({
  loading = true,
  overlay = false,
  classes
}: Props) => (
  <div className={classes.progressContainer}>
    {loading &&
      !overlay && (
      <LinearProgress
        className={classes.progress}
        variant="query"
        color="primary"
      />
    )}
    {loading &&
      overlay && (
      <div className={classes.overlayContainer}>
        <Backdrop
          open
          classes={{
            root: classes.overlay
          }}
        />
        <div className={classes.overlayProgressContent}>
          <CircularProgress
            className={classes.circularProgress}
            size={50}
            thickness={8}
          />
          <div className={classes.progressText}> Loading</div>
        </div>
      </div>
    )}
  </div>
);

export default (
  withStyles(styles, { name: 'LoadingIndicator' })(LoadingIndicator): any
);

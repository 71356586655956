
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllPreceptors = state => state.preceptors.items;
export const getSelectedPreceptor = state => state.preceptors.selected;

export const getAllPreceptorValues = createSelector(
  getAllPreceptors,
  users => _.chain(users).map(el => ({ id: el.id, name: el.name })).value()
);

export const getPreceptorById = state => createSelector(
  getAllPreceptors, preceptors => {
    return _.find(preceptors, { id: state.preceptorId });
  }
)(state);

// @flow

import _ from 'lodash';

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {TableCell,TableRow,Typography,IconButton,Grid,Chip} from '@material-ui/core';
import {SuccessIcon,ErrorIcon,UpdateIcon,DeleteIcon} from '../../../components/Icons';
import {deepOrange} from '@material-ui/core/colors';
import CommonStyles from '../../../styles/CommonStyles';
import {AlumniIcon} from '../../Icons';

import {
  fetchAttachmentAStatusCodes
} from '../../../actions/creators/attachmentAStatusCodes';
import {
  removePreceptor,updatePreceptor
} from '../../../actions/creators/placementSlots';
import {
  fetchSelectedPlacement,showPlacementDrawer
} from '../../../actions/creators/placementDrawer';

import {
  hasActiveContract,hasRequestedContract
} from '../../../utils/placementHelpers';

import {getSelectedPlacement} from '../../../selectors/placementDrawer';

import PreceptorEnrollmentForm from './PreceptorEnrollmentItemForm';
import CreatePlacementAttachmentAButton from '../../AttachmentA/CreatePlacementAttachmentAButton';
import DeepLink from '../../DeepLink';

import type {PreceptorEnrollment,SiteEnrollment} from '../../../schemas';

type Props = {
  item: PreceptorEnrollment,
  siteEnrollment: SiteEnrollment,
  editable: boolean,
  displayOnly: boolean
};

const useStyles = makeStyles(theme => {
  const commonStyles = CommonStyles(theme);
  return {
    warn: commonStyles.warn,
    warnAvatar: commonStyles.warnEmphasis,
    error: commonStyles.error,
    success: commonStyles.success,
    successAvatar: commonStyles.successEmphasis,
    container: {
      paddingLeft: theme.spacing(1)
    },
    warning: {
      color: deepOrange[500]
    },
    button: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    alignRight: {
      textAlign: 'right'
    },
    textContainer: {
      marginTop: theme.spacing(1.5),
      borderRight: '1px solid #ccc'
    },
    centered: {
      textAlign: 'center'
    },
    actions: {
      textAlign: 'right'
    }
  };
});

function PreceptorEnrollmentItem(props: Props): React$Element<any> | React$Element<(props: Props) => React$Element<any>> {
  const {item,editable,displayOnly,siteEnrollment} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = React.useState(false);

  const attachmentAStatusCodes = useSelector(state => state.attachmentAStatusCodes.items);
  const selectedPlacement = useSelector(getSelectedPlacement);

  React.useEffect(() => {
    dispatch(fetchAttachmentAStatusCodes());
    // @see https://github.com/facebook/create-react-app/issues/6880#issuecomment-486640921
    // eslint-disable-next-line
  }, []);

  function handleSubmit(values) {
    dispatch(updatePreceptor(selectedPlacement,values));
    setIsEditing(false);
  }

  function handleEditClick() {
    setIsEditing(true);
  }

  function handleDeleteClick() {
    const confirmation = window.confirm(
      `Are you sure you want to remove "${item.Preceptor.name}"?`
    );
    if (confirmation)
      dispatch(removePreceptor(selectedPlacement,item));
  }

  function renderCreateAttachmentAButton() {
    const activeContract = hasActiveContract(selectedPlacement,siteEnrollment);
    const requestedContract = hasRequestedContract(selectedPlacement,siteEnrollment);
    const attachmentA = _.get(item, 'AttachmentA', {});

    if (!activeContract && !requestedContract) return (<div/>);
    else if ((activeContract || requestedContract) && _.isEmpty(attachmentA))
      return (
        <CreatePlacementAttachmentAButton
          mini
          placement={selectedPlacement}
          preceptorEnrollment={item}
          siteEnrollment={siteEnrollment}
          displayOnly={displayOnly || !editable}
        />
      );
    else if ((activeContract || requestedContract) && !_.isEmpty(attachmentA)) {
      const statusCode = _.find(attachmentAStatusCodes, { id: attachmentA.AttachmentAStatusCodeId });
      return attachmentA.AttachmentAStatusCodeId === 'CA'
        ? (<Chip
          label={_.get(statusCode, 'name', '')} className={classes.success}
        />)
        : (<Chip
          label={_.get(statusCode, 'name', '')} className={classes.warn}
        />);
    }
  }

  function handleFetchSelectedPlacement() {
    if (selectedPlacement) {
      dispatch(fetchSelectedPlacement(selectedPlacement.id,false))
        .then(() => dispatch(showPlacementDrawer()));
    }
  }

  const hasPreceptor = item.PreceptorId;
  const preceptorName = hasPreceptor ? item?.Preceptor?.name : 'N/A';

  if (isEditing)
    return (
      <PreceptorEnrollmentForm
        item={item}
        initialValues={item}
        handleSubmit={handleSubmit}
      />
    );
  else
    return (
      <TableRow>
        <TableCell className={classes.actions}>
          {editable && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <IconButton onClick={handleEditClick}>
                  <UpdateIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <IconButton onClick={handleDeleteClick}>
                  <DeleteIcon color="action" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </TableCell>
        <TableCell>
          {hasPreceptor &&
            <DeepLink
              id={item?.Preceptor?.id}
              model="preceptor"
              afterClose={handleFetchSelectedPlacement}
            >
              {item.Preceptor.isAlumni && <AlumniIcon color="primary" fontSize="small" className={classes.leftIcon} />}
              <Typography variant="subtitle1">{preceptorName}</Typography>
            </DeepLink>
          }
          {!hasPreceptor && <Typography variant="subtitle1">{preceptorName}</Typography>}
        </TableCell>
        <TableCell>
          <Typography>{item.preceptorHoursAvailable} Hours</Typography>
        </TableCell>
        <TableCell className={classes.centered}>
          {item.hasVerbalCommitment ? (
            <SuccessIcon className={classes.success} />
          ) : (
            <ErrorIcon className={classes.warning} />
          )}
        </TableCell>
        <TableCell className={classes.center}>
          {renderCreateAttachmentAButton()}
        </TableCell>
      </TableRow>
    );
}

export default PreceptorEnrollmentItem;

// @flow

import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_ERROR,
  RESET_PERMISSIONS
} from '../actions/types';

const initialState = {
  isLoading: false,
  error: ''
};

type State = {
  +isLoading: boolean,
  +error: string
};

type Action = {
  type:
    | typeof FETCH_PERMISSIONS
    | typeof FETCH_PERMISSIONS_SUCCESS
    | typeof FETCH_PERMISSIONS_ERROR
    | typeof RESET_PERMISSIONS,
  payload: ?Object
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case FETCH_PERMISSIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RESET_PERMISSIONS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Grow, AppBar, Button, Paper, Typography, Toolbar as MaterialToolbar,
  Grid as UiGrid
} from '@material-ui/core';
import {
  FilteringState, IntegratedFiltering, PagingState, IntegratedPaging,
  SortingState, IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';

import { CheckBox, CheckBoxBlank } from '../../components/Icons';

import {
  fetchPlacements, updateSelectedTermId, mergePlacements
} from '../../actions/creators/placements';
import { fetchPermissions } from '../../actions/creators/permissions';
import { fetchTerms } from '../../actions/creators/terms';
import { hasAccess } from '../../utils/permissionsCheck';
import { getActiveTerm, getAllTermValuesWithAttrs } from '../../selectors/terms';
import { getAllPlacements, getPlacementSelectedTermId } from '../../selectors/placements';
import { getCurrentUsersWorkingTerm } from '../../selectors/users';

import TermSelector from '../../components/PlacementsBoard/TermSelector';
import BooleanFilterCellBase from '../../components/ReactGrid/BooleanFilterCellBase';
import LoadingIndicator from '../../components/LoadingIndicator';

import type { Placement, Term, User } from '../../schemas';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  appBar: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  button: {
    margin: theme.spacing(1),
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

type Props = {
  classes: Object,
  placements: Placement[],
  currentUser: User,
  permissions: Object,
  academicTerms: Term[],
  activeAcademicTerm: Term,
  workingAcademicTerm: Term,
  selectedAcademicTermId: number,
  fetchPlacements: Function,
  fetchPermissions: Function,
  fetchTerms: Function,
  mergePlacements: Function,
  updateSelectedTermId: Function,
  colSpan: number
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  integratedFilteringColumnExtensions: any[],
  defaultColumnWidths: Object[],
  master: number,
  duplicate: number,
  tabValue: any,
  rows: Placement[],
  loading: boolean,
  selectedAcademicTermId: number,
};

class PlacementsMerge extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'isMaster', title: 'Master' },
        { name: 'isDuplicate', title: 'Duplicate' },
        { name: 'id', title: 'ID' },
        {
          name: 'studentName',
          title: 'Student',
          getCellValue: row => _.get(row, 'Student.fullName', null)
        },
        {
          name: 'courseName',
          title: 'Course',
          getCellValue: row => {
            const d = _.get(row, 'Course.description');
            const n = _.get(row, 'Course.CatalogNumberId');
            return `${n} | ${d}`;
          }
        },
        {
          name: 'studentCity',
          title: 'City',
          getCellValue: row => _.get(row, 'Student.mailingCity', null)
        },
        {
          name: 'studentState',
          title: 'State',
          getCellValue: row => _.get(row, 'Student.mailingState', null)
        },
        {
          name: 'studentPostalCode',
          title: 'Zip Code',
          getCellValue: row => _.get(row, 'Student.mailingPostalCode', null)
        },
        {
          name: 'placementOrigin',
          title: 'Origin',
          getCellValue: row => _.get(row, 'PlacementOrigin.name', null)
        },
      ],
      defaultColumnWidths: [
        { columnName: 'isMaster', width: 110 },
        { columnName: 'isDuplicate', width: 110 },
        { columnName: 'id', width: 80 },
        { columnName: 'studentName', width: 180 },
        { columnName: 'courseName', width: 360 },
        { columnName: 'studentCity', width: 140 },
        { columnName: 'studentState', width: 80 },
        { columnName: 'studentPostalCode', width: 120 },
        { columnName: 'placementOrigin', width: 160 },
      ],
      defaultFilters: [],
      defaultCurrentPage: 0,
      defaultPageSize: 10,
      defaultSorting: [
        { columnName: 'courseName', direction: 'asc' },
        { columnName: 'studentName', direction: 'asc' }
      ],
      pageSizes: [10, 20, 50, 100, 250],
      filteringStateColumnExtensions: [
        { columnName: 'isMaster', filteringEnabled: false },
        { columnName: 'isDuplicate', filteringEnabled: false }
      ],
      integratedFilteringColumnExtensions: [],
      master: 0,
      duplicate: 0,
      tabValue: null,
      rows: [],
      loading: true,
      selectedAcademicTermId: props.selectedAcademicTermId
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.placements !== state.rows) {
      return { rows: props.placements };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchPermissions();

    return this.handleAcademicTermChange();
  };

  handleAcademicTermChange = async id => {
    const {
      activeAcademicTerm, fetchPlacements,updateSelectedTermId,
      selectedAcademicTermId, workingAcademicTerm
    } = this.props;

    const academicTerm = workingAcademicTerm
      ? workingAcademicTerm : activeAcademicTerm;
    const academicTermId = id ? id : academicTerm.id;

    if (selectedAcademicTermId === academicTerm) {
      return this.setState({ loading: false });
    }

    if (fetchPlacements) {
      this.setState({ loading: true, selectedAcademicTermId: academicTermId });
      await updateSelectedTermId(academicTermId);
      await fetchPlacements(academicTermId, true);
      this.setState({ loading: false });
    }
  };

  handleResetTerm = async () => {
    const {updateSelectedTermId} = this.props;
    await updateSelectedTermId(undefined);
    await this.handleAcademicTermChange();
  };

  handleSwitchAcademicTerm = event => {
    const {value} = event.target;
    this.handleAcademicTermChange(value);
  };

  componentFilterCell = props => {
    if (props.column.name === 'isActive') {
      return <BooleanFilterCellBase {...props} />;
    }

    return <TableFilterRow.Cell {...props} />;
  };

  componentNoDataCell = props => {
    const { classes } = this.props;
    const { loading } = this.state;
    const { colSpan } = props;
    return (
      <Table.Cell colSpan={colSpan}>
        {loading ? (
          <LoadingIndicator />
        ) :
          <div className={classes.centerContainer}>
            No data to view...
          </div>
        }
      </Table.Cell>
    );
  };

  componentCell = props => {
    const { classes } = this.props;
    const { master, duplicate } = this.state;
    if (props.column.name === 'isMaster') {
      return (
        <Table.Cell>
          <div className={classes.centerContainer}>
            {props.row.id === master ? (
              <CheckBox
                color="secondary"
                onClick={() => this.handleMasterSelection({...props})}
                className={classes.icon}
              />
            ) : (
              <CheckBoxBlank
                onClick={() => this.handleMasterSelection({...props})}
                className={classes.icon}
              />
            )}
          </div>
        </Table.Cell>
      );
    }
    if (props.column.name === 'isDuplicate') {
      return (
        <Table.Cell>
          <div className={classes.centerContainer}>
            {duplicate === props.row.id ? (
              <CheckBox
                color="primary"
                onClick={() => this.handleDuplicateSelection({...props})}
                className={classes.icon}
              />
            ) : (
              <CheckBoxBlank
                onClick={() => this.handleDuplicateSelection({...props})}
                className={classes.icon}
              />
            )}
          </div>
        </Table.Cell>
      );
    }

    return <Table.Cell {...props} />;
  };

  canMerge = () => {
    const { currentUser, permissions } = this.props;
    const { master, duplicate } = this.state;
    const hasMergeAccess = hasAccess(currentUser.Roles, _.get(permissions, 'merge', []));
    const hasSelections = (duplicate && master);
    return (hasMergeAccess && hasSelections);
  };

  handleMasterSelection = props => {
    const { master, duplicate } = this.state;
    if (duplicate === props.row.id) {
      this.setState({ duplicate: 0 });
    }
    if (props.row.id !== master) {
      this.setState({ master: props.row.id });
    } else {
      this.setState({ master: 0 });
    }
  };

  handleDuplicateSelection = async props => {
    const { master, duplicate } = this.state;
    if (props.row.id === master) {
      this.setState({ master: 0, duplicate });
    }
    if (props.row.id !== duplicate) {
      this.setState({ duplicate: props.row.id });
    } else {
      this.setState({ duplicate: 0 });
    }
  };

  handleMerge = async () => {
    const { master, duplicate } = this.state;
    const confirmation = window.confirm(
      `Are you sure you want to merge this Duplicate into the selected Master?`
    );
    if (confirmation) {
      const mergeObject = {
        targetId: master, duplicateId: duplicate
      };
      await this.props.mergePlacements(mergeObject);
      this.setState({ master: 0, duplicate: 0 });
    }
  };

  renderMergeButton = () => {
    const { classes } = this.props;
    return (
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={this.handleMerge}
        className={classes.button}
      >
        Merge Placements
      </Button>
    );
  };

  renderTermSelector = () => {
    const {selectedAcademicTermId} = this.state;
    return (
      <UiGrid item xs={12}>
        <UiGrid container spacing={2}>
          <UiGrid item>
            <h1>Placements for</h1>
          </UiGrid>
          <UiGrid item xs>
            <TermSelector
              selectedTermId={selectedAcademicTermId}
              handleSelectedTermIdChange={this.handleSwitchAcademicTerm}
              handleResetTerm={this.handleResetTerm}
            />
          </UiGrid>
        </UiGrid>
      </UiGrid>
    );
  };

  renderAppBar = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <MaterialToolbar>
          <div className={classes.flex} />
          {this.canMerge()
            ? this.renderMergeButton()
            : null
          }
        </MaterialToolbar>
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      columns, defaultFilters, defaultCurrentPage, defaultPageSize,
      defaultSorting, defaultColumnWidths, pageSizes, rows, loading,
      filteringStateColumnExtensions, integratedFilteringColumnExtensions
    } = this.state;

    return (
      <div className={classes.root}>
        {this.renderTermSelector()}
        <Typography variant="h5" component="h1">
          Merge Placements
        </Typography>
        <Typography paragraph variant="subtitle1" component="em">
          Select a Master Placement and one Duplicate to merge into the selected Master Placement.
        </Typography>
        {loading && <LoadingIndicator loading={loading} overlay />}
        <Grow in={!loading} timeout={500}>
          <Paper>
            {this.renderAppBar()}
            <Grid rows={rows} columns={columns}>
              <FilteringState
                defaultFilters={defaultFilters}
                columnExtensions={filteringStateColumnExtensions}
              />
              <PagingState
                defaultCurrentPage={defaultCurrentPage}
                defaultPageSize={defaultPageSize}
              />
              <SortingState defaultSorting={defaultSorting} />

              <IntegratedFiltering
                columnExtensions={integratedFilteringColumnExtensions}
              />
              <IntegratedSorting />
              <IntegratedPaging />

              <Table
                cellComponent={props => this.componentCell(props)}
                noDataCellComponent={props => this.componentNoDataCell(props)}
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow cellComponent={props => this.componentFilterCell(props)} />

              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          </Paper>
        </Grow>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    academicTerms: getAllTermValuesWithAttrs(state),
    selectedAcademicTermId: getPlacementSelectedTermId(state),
    workingAcademicTerm: getCurrentUsersWorkingTerm(state),
    activeAcademicTerm: getActiveTerm(state),
    placements: getAllPlacements(state),
    currentUser: state.auth.currentUser,
    permissions: state.permissions.placement
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchPlacements, updateSelectedTermId, fetchTerms, mergePlacements,
    fetchPermissions
  })
)(PlacementsMerge): any);

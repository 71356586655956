// @flow

import { PreceptorIcon, StudentIcon, AttachmentIcon } from '../components/Icons';

export default [
  {
    slug: 'edit',
    label: 'Preceptor',
    icon: PreceptorIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'students',
    label: 'Students',
    icon: StudentIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'attachments',
    label: 'Attachments',
    icon: AttachmentIcon,
    allowedRoles: ['*']
  }
];

import Color from 'color';

const gradient = color => {
  const a = Color(color);
  const b = a.darken(0.2);
  return `linear-gradient(${a.toString()}, ${b.toString()}, ${a.toString()})`;
};

const boxShadowMixin = color => {
  let a = Color(color);
  a = a.fade(0.72);
  let b = Color(color);
  b = b.fade(0.8);

  return `0 12px 20px -10px ${a.toString()}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${b.toString()}`;
};

export {
  gradient,
  boxShadowMixin
};

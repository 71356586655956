// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';

import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import {
  DeleteIcon,
  CheckBox,
  CheckBoxBlank,
  CreateIcon
} from '../Icons';

import TodoForm from './TodoForm';
import { deleteTodo, updateTodo } from '../../actions/creators/todos';
import type { Todo, User } from '../../schemas';

import { createUserMessage } from '../../actions/creators/userMessages';

type Props = {
  todo: Todo,
  users: User[],
  classes: Object,
  currentUser: User,
  afterAction: Function,
  deleteTodo: Function,
  updateTodo: Function,
  createUserMessage: Function
};

type State = {
  dialogVisible: boolean
};

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: theme.typography.pxToRem(16)
  },
  contentContainer: {
    flex: 1
  },
  actionsContainer: {
    justifyContent: 'flex-end'
  },
  completeText: {
    textDecoration: 'line-through',
    whiteSpace: 'normal'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  error: {
    color: theme.palette.secondary.main
  }
});

class TodoDetail extends Component<Props, State> {
  state = {
    dialogVisible: false
  };

  handleOpenDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogVisible: false });
  };

  handleUpdate = async todo => {
    const { currentUser, updateTodo, afterAction } = this.props;
    todo.step = todo.step === 0 ? null : todo.step;
    let result = await updateTodo(todo);
    if (result) {
      if (result.isComplete && result.sendNotification) {
        this.handleNotification(todo, {
          message: `${currentUser.name} completed todo "${todo.description}" for Contract ID: ${todo.foreignKey}`
        });
      }
      this.setState({ dialogVisible: false });
      afterAction();
    }
  };

  handleDelete = async () => {
    const { deleteTodo, afterAction, todo } = this.props;
    let confirmation = window.confirm(
      'Are you sure you want to delete this todo?'
    );
    if (confirmation) {
      let result = await deleteTodo(todo);
      if (result) {
        afterAction();
      }
    }
  };

  handleNotification = async (todo, el) => {
    const { createUserMessage, currentUser } = this.props;
    const messageUsers = todo.CreatedById && todo.CompletedById && (todo.CreatedById !== todo.CompletedById)
      ? [{id: todo.CreatedById}, {id: todo.CompletedById}]
      : [{id: todo.CreatedById}];

    if (todo.model === 'Contract') {
      const usersForRole = this.usersForRole('contracts_manager');
      const users = _.concat(messageUsers, usersForRole);
      _.each(users, async user => {
        await createUserMessage({
          message: el.message,
          tag: 'contract',
          SenderId: currentUser.id,
          RecipientId: user.id
        });
      });
    }

    if (todo.model === 'Placement') {
      _.each(messageUsers, async user => {
        await createUserMessage({
          message: el.message,
          tag: 'placement',
          SenderId: currentUser.id,
          RecipientId: user.id
        });
      });
    }

  };

  usersForRole = role => {
    const { users } = this.props;
    return _.filter(users, user => {
      const roles = _.map(user.Roles, 'slug');
      return _.includes(roles, role);
    });
  };

  handleComplete = todo => {
    todo.CompletedById = null;
    todo.completedAt = null;
    if (!todo.isComplete) {
      todo.CompletedById = this.props.currentUser.id;
      todo.completedAt = moment();
    }
    this.handleUpdate(todo);
  };

  renderPrimaryText = todo => {
    const { classes } = this.props;
    const stepText = todo.step ? `STEP ${todo.step}: ` : '';
    return(
      <div
        className={todo.isComplete ? classes.completeText : null}
      >
        <strong>{stepText}</strong>{todo.description}
      </div>
    );
  };

  renderSecondaryText = todo => {
    return todo.isComplete
      ? <small>Completed {moment(todo.completedAt).format('MM/DD/YY @ hh:mm A')} by {todo.CompletedBy.name}</small>
      : <small>Created {moment(todo.createdAt).format('MM/DD/YY @ hh:mm A')} by {todo.CreatedBy.name}</small>;
  };

  render() {
    const { todo } = this.props;
    const { dialogVisible } = this.state;

    return (
      <div>
        <ListItem divider disableGutters>
          <Checkbox
            icon={<CheckBoxBlank />}
            checkedIcon={<CheckBox />}
            checked={todo.isComplete}
            tabIndex={-1}
            disableRipple
            color="primary"
            onClick={() => this.handleComplete(todo)}
          />
          <ListItemText
            inset
            primary={this.renderPrimaryText(todo)}
            secondary={this.renderSecondaryText(todo)}
          />
          {!todo.isComplete && (
            <ListItemSecondaryAction>
              <IconButton aria-label="Delete">
                <DeleteIcon color="primary" onClick={() => this.handleDelete()} />
              </IconButton>
              <IconButton aria-label="Edit">
                <CreateIcon color="primary" onClick={() => this.handleOpenDialog()} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogVisible}
          onClose={this.handleCloseDialog}
          aria-labelledby={`update-todo-${todo.id}-dialog`}
        >
          <DialogTitle id={`update-todo-${todo.id}-dialog`}>Update Todo</DialogTitle>
          <DialogContent>
            <TodoForm
              handleSubmit={this.handleUpdate}
              handleClose={this.handleCloseDialog}
              initialValues={todo}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.users.items
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, { deleteTodo, updateTodo, createUserMessage })
)(TodoDetail): any);

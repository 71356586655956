// @flow
import * as React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';

import {
  Dialog,DialogActions,DialogContent,DialogTitle,Button,List,ListItem,
  ListItemText,ListItemSecondaryAction,Switch,Divider
} from '@material-ui/core';

import {getSelectedPlacement} from '../../../selectors/placementDrawer';

import axios from '../../../api';
import AddToWorkspaceButton from '../AddToWorkspaceButton';
import Snackbar from '../../Snackbar';

import type {Site} from '../../../schemas';

type Props = {
  open: boolean,
  handleClose: Function,
  site: Site
};

const useStyles = makeStyles(theme => {
  return {
    centered: {
      textAlign: 'center'
    },
    dialogContent: {
      maxHeight: 435,
      padding: 0,
      marginBottom: 0
    },
    dialogActions: {
      paddingTop: theme.spacing(1),
      marginTop: 0
    },
    list: {
      paddingBottom: 0
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
  };
});

function PreceptorListDialog(props: Props): React$Element<any> {
  const {open,handleClose,site} = props;
  const classes = useStyles();

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [preceptorSites, setPreceptorSites] = React.useState([]);
  const [error, setError] = React.useState();
  const [includeInactive, setIncludeInactive] = React.useState(false);

  const selectedPlacement = useSelector(getSelectedPlacement);

  async function fetchPreceptors(SiteId: number) {
    try {
      const {data} = await axios.get('/preceptorSite', { params: { SiteId } });
      setPreceptorSites(data);
    } catch (err) {
      await setError(err.data);
      setSnackBarOpen(true);
    }
  }

  function toggleActiveFilter() {
    setIncludeInactive(!includeInactive);
  }

  function renderWorkspaceButton(item) {
    const workspaceItem = {
      model: 'preceptor',
      foreignKey: item.id,
      name: item.name,
      PlacementId: selectedPlacement.id
    };

    return <AddToWorkspaceButton item={workspaceItem} />;
  }

  React.useEffect(() => {
    fetchPreceptors(site.id);
    // @see https://github.com/facebook/create-react-app/issues/6880#issuecomment-486640921
    // eslint-disable-next-line
  }, []);

  const filteredPreceptorSites = preceptorSites.filter(preceptorSite =>
    includeInactive ? true : preceptorSite.isActive === true
  );

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="list-dialog">
      <DialogTitle id="list-dialog">Preceptors for {site.name}</DialogTitle>

      <List className={classes.list}>
        <Divider />
        <ListItem divider>
          <ListItemText primary="Include inactive preceptors" />
          <ListItemSecondaryAction>
            <Switch
              checked={includeInactive}
              onChange={toggleActiveFilter}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <DialogContent className={classes.dialogContent}>
          {filteredPreceptorSites.map(preceptorSite => {
            const {Preceptor} = preceptorSite;
            let secondaryText = preceptorSite.isActive ? 'active' : 'inactive';
            if (Preceptor.isAlumni) secondaryText += ', alumni';
            return (
              <ListItem divider key={Preceptor.id}>
                <ListItemText
                  primary={Preceptor.name}
                  secondary={secondaryText}
                />
                <ListItemSecondaryAction>
                  {renderWorkspaceButton(Preceptor)}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          {filteredPreceptorSites.length === 0 && (
            <ListItem divider>
              <ListItemText
                className={classes.centered}
                secondary={
                  includeInactive
                    ? 'There are no preceptors at this location.'
                    : 'There are no active preceptors to display.'
                }
              />
            </ListItem>
          )}
        </DialogContent>
      </List>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <Snackbar
        open={snackBarOpen}
        handleClose={() => setSnackBarOpen(false)}
        message={{
          title: 'Error', body: error, type: 'error'
        }}
      />
    </Dialog>
  );
}

export default PreceptorListDialog;

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Typography } from '@material-ui/core';
import AttachmentAList from '../../AttachmentA/AttachmentAList';
import CreateAttachmentAButton from '../../../components/AttachmentA/CreateAttachmentAButton';

import { fetchContractAttachmentAs } from '../../../actions/creators/attachmentAs';

import type { Contract, AttachmentA } from '../../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  isLoading: boolean,
  attachmentAs: AttachmentA[],
  fetchContractAttachmentAs: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }
});
class AttachmentASection extends Component<Props> {
  afterCreate = () => {
    const { fetchContractAttachmentAs, contract } = this.props;
    fetchContractAttachmentAs(contract.id);
  };
  render() {
    const { classes, attachmentAs, contract } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Attachment A
        </Typography>
        <CreateAttachmentAButton
          ContractId={contract.id}
          StartTermId={contract.StartTermId}
          afterCreate={this.afterCreate}
        />
        <AttachmentAList attachmentAs={attachmentAs} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.contractDrawer.selectedContract,
    attachmentAs: state.contractDrawer.attachmentAs
  };
};

export default (compose(
  connect(mapStateToProps, { fetchContractAttachmentAs }),
  withStyles(styles)
)(AttachmentASection): any);

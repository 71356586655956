// @flow


import * as types from '../actions/types';
import { createItem, updateItem, deleteItem } from './methods';
import type { CourseAttr } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<CourseAttr>,
  +selected: Object
};

type Action = {
  type:
    | typeof types.FETCH_COURSE_ATTRS
    | typeof types.FETCH_COURSE_ATTRS_SUCCESS
    | typeof types.FETCH_COURSE_ATTRS_ERROR
    | typeof types.FETCH_COURSE_ATTR
    | typeof types.FETCH_COURSE_ATTR_SUCCESS
    | typeof types.FETCH_COURSE_ATTR_ERROR
    | typeof types.CREATE_COURSE_ATTR
    | typeof types.CREATE_COURSE_ATTR_SUCCESS
    | typeof types.CREATE_COURSE_ATTR_ERROR
    | typeof types.UPDATE_COURSE_ATTR
    | typeof types.UPDATE_COURSE_ATTR_SUCCESS
    | typeof types.UPDATE_COURSE_ATTR_ERROR
    | typeof types.DELETE_COURSE_ATTR
    | typeof types.DELETE_COURSE_ATTR_SUCCESS
    | typeof types.DELETE_COURSE_ATTR_ERROR,
  payload: Array<CourseAttr> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case types.FETCH_COURSE_ATTRS:
      return { ...state, isLoading: true };
    case types.FETCH_COURSE_ATTRS_SUCCESS:
      return { ...state,
        isLoading: false,
        items: action.payload
      };
    case types.FETCH_COURSE_ATTRS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.CREATE_COURSE_ATTR:
      return { ...state, isLoading: true };
    case types.CREATE_COURSE_ATTR_SUCCESS:
      return { ...state,
        isLoading: false,
        items: createItem(state.items, action.payload)
      };
    case types.CREATE_COURSE_ATTR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.FETCH_COURSE_ATTR:
      return { ...state, isLoading: true };
    case types.FETCH_COURSE_ATTR_SUCCESS:
      return { ...state, isLoading: false, selected: action.payload };
    case types.FETCH_COURSE_ATTR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.UPDATE_COURSE_ATTR:
      return { ...state, isLoading: true };
    case types.UPDATE_COURSE_ATTR_SUCCESS:
      return { ...state,
        isLoading: false,
        items: updateItem(state.items, action.payload)
      };
    case types.UPDATE_COURSE_ATTR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.DELETE_COURSE_ATTR:
      return { ...state, isLoading: true };
    case types.DELETE_COURSE_ATTR_SUCCESS:
      return { ...state,
        isLoading: false,
        items: deleteItem(state.items, action.payload)
      };
    case types.DELETE_COURSE_ATTR_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

import axios from '../../api';
import * as actions from '../types';

export const fetchCounties = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COUNTIES, payload: undefined });
    const res = await axios.get('/county');
    dispatch({ type: actions.FETCH_COUNTIES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COUNTIES_ERROR,
      payload: error.response.data
    });
  }
};

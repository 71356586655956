// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { Grid, Button } from '@material-ui/core';


import type { Attachment } from '../../schemas';

type Props = {
  classes: Object,
  handleSubmit: Function,
  initialValues: ?Attachment,
  handleCancel: Function
};

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  buttons: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  }
});

class AttachmentForm extends Component<Props> {
  render() {
    const { classes } = this.props;
    return(
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ setFieldValue, values }) => (
          <Form encType="multipart/form-data" className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="name"
                  label="Name"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  id="file"
                  name="file"
                  type="file"
                  onChange={(event) => {
                    setFieldValue('file', event.currentTarget.files[0]);
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!values.file}
                className={classes.button}
              >
                Create Attachment
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles, { withTheme: true, name: 'AttachmentForm' })(AttachmentForm): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { MultiSelect, Select, Checkbox } from '../../Formik';
import { TextField } from 'formik-material-ui';
import { siteValidation } from '../../../validation';

import { FormControlLabel, Grid, Button } from '@material-ui/core';

import { fetchStates } from '../../../actions/creators/states';
import { fetchCountries } from '../../../actions/creators/countries';
import { fetchCounties } from '../../../actions/creators/counties';
import { fetchSpecialties } from '../../../actions/creators/specialties';
import { fetchSiteTypes } from '../../../actions/creators/siteTypes';

import type { ValueListItem } from '../../../schemas';

type Props = {
  initialValues: Array<any>,
  handleSubmit: Function,
  canUpdate: boolean,
  canDelete: boolean,
  isClone: boolean,
  handleDelete: Function,
  classes: Object,
  fetchStates: Function,
  fetchCountries: Function,
  fetchCounties: Function,
  fetchSpecialties: Function,
  fetchSiteTypes: Function,
  stateOptions: ValueListItem[],
  countryOptions: ValueListItem[],
  countyOptions: ValueListItem[],
  specialtyOptions: ValueListItem[],
  siteTypeOptions: ValueListItem[],
}

const styles = theme => ({
  formSection: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  }
});

class OrganizationSiteForm extends Component<Props> {

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchStates();
    await this.props.fetchCounties();
    await this.props.fetchCountries();
    await this.props.fetchSpecialties();
    this.props.fetchSiteTypes();
  };

  render() {
    const {
      stateOptions, countryOptions, countyOptions,
      specialtyOptions, siteTypeOptions
    } = this.props;

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={siteValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          handleReset, touched, isSubmitting, isValid, values
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={8} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="name"
                  label="Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  name="SiteTypeId"
                  label="Site Type"
                  options={siteTypeOptions}
                  component={Select}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contact"
                  label="Contact"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contactTitle"
                  label="Contact Title"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contactEmail"
                  label="Contact Email"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="address"
                  label="Address"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="addressLine2"
                  label="Address 2"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="addressLine3"
                  label="Address 3"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="city"
                  label="City"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  name="StateId"
                  label="State"
                  options={stateOptions}
                  component={Select}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  type="text"
                  name="postalCode"
                  label="Postal Code"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="CountyId"
                  label="County"
                  options={countyOptions}
                  component={Select}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="CountryId"
                  label="Country"
                  options={countryOptions}
                  component={Select}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="phone"
                  label="Phone"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="fax"
                  label="FAX"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="website"
                  label="Website"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isRural}
                      name="isRural"
                      label="Rural?"
                      component={Checkbox}
                    />
                  }
                  label="Rural?"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isUnderserved}
                      name="isUnderserved"
                      label="Underserved?"
                      component={Checkbox}
                    />
                  }
                  label="Underserved?"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isAhec}
                      name="isAhec"
                      label="AHEC?"
                      component={Checkbox}
                    />
                  }
                  label="AHEC?"
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  name="Specialties"
                  label="Specialties"
                  options={specialtyOptions}
                  component={MultiSelect}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="siteRequirements"
                  label="Requirements"
                  component={TextField}
                />
              </Grid>
            </Grid>

            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.props.canUpdate || isSubmitting || !isValid}
              >
                Save Site
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
              &nbsp;
              {values.id && (<Button
                variant="contained"
                color="secondary"
                disabled={!this.props.canDelete || !values.id}
                onClick={this.props.handleDelete}
              >
                Delete Site
              </Button>)}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    stateOptions: state.states.values,
    countryOptions: state.countries.values,
    countyOptions: state.counties.values,
    specialtyOptions: state.specialties.values,
    siteTypeOptions: state.siteTypes.values,
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchStates, fetchCounties, fetchCountries, fetchSpecialties,
    fetchSiteTypes
  }),
  withStyles(styles, { withTheme: true, name: 'OrganizationSiteForm'}),
)(OrganizationSiteForm): any);

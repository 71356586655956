// @flow
import React, { Component } from 'react';

import ContractDetails from './ContractDetails/ContractDetails';
import ContractAmendments from './ContractAmendments/ContractAmendments';
import AttachmentA from './AttachmentA/AttachmentA';
import Notes from './Notes/Notes';
import Todos from './Todos/Todos';
import Attachments from './Attachments/Attachments';

import type { DrawerNavigationItem } from '../../schemas';

type Props = {
  currentSection: DrawerNavigationItem
};

class PlacementDrawerSections extends Component<Props> {
  render(): React$Element<any> | React$Element<"div"> {
    const { currentSection } = this.props;

    switch (currentSection.slug) {
      case 'details':
        return <ContractDetails />;
      case 'amendments':
        return <ContractAmendments />;
      case 'attachment_a':
        return <AttachmentA />;
      case 'notes':
        return <Notes />;
      case 'todos':
        return <Todos />;
      case 'attachments':
        return <Attachments />;
      default:
        return <div />;
    }
  }
}

export default PlacementDrawerSections;


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllStates = state => state.states.items;

export const getAllStateValues = createSelector(
  getAllStates,
  states => {
    return _.map(_.orderBy(states, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

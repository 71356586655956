// @flow

import {
  FETCH_PLACEMENT_ORIGINS,
  FETCH_PLACEMENT_ORIGINS_SUCCESS,
  FETCH_PLACEMENT_ORIGINS_ERROR,
  FETCH_PLACEMENT_ORIGIN,
  FETCH_PLACEMENT_ORIGIN_SUCCESS,
  FETCH_PLACEMENT_ORIGIN_ERROR
} from '../actions/types';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: any[]
};

type Action = {
  type:
    | typeof FETCH_PLACEMENT_ORIGINS
    | typeof FETCH_PLACEMENT_ORIGINS_SUCCESS
    | typeof FETCH_PLACEMENT_ORIGINS_ERROR
    | typeof FETCH_PLACEMENT_ORIGIN
    | typeof FETCH_PLACEMENT_ORIGIN_SUCCESS
    | typeof FETCH_PLACEMENT_ORIGIN_ERROR,
  payload: ?any[] | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_PLACEMENT_ORIGINS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PLACEMENT_ORIGINS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_PLACEMENT_ORIGINS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_PLACEMENT_ORIGIN:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PLACEMENT_ORIGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_PLACEMENT_ORIGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';
import { fetchSelectedContract } from './contractDrawer';

export const fetchContracts = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACTS });
    const res = await axios.get('/contract');
    dispatch({ type: actions.FETCH_CONTRACTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchContract = contract => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT, payload: contract });
    const res = await axios.get(`/contract/${contract.id}`);
    dispatch({ type: actions.FETCH_CONTRACT_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_CONTRACT_ERROR,
      payload: err.message ? err.message : err
    });
  }
};

export const createContract = contract => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_CONTRACT });
    const res = await axios.post('/contract', contract);
    dispatch({ type: actions.CREATE_CONTRACT_SUCCESS, payload: res.data });
    dispatch(fetchContracts());
    dispatch(
      notify({
        title: 'Success',
        message: 'Contract request successfully created.',
        status: 'success',
      })
    );
    return res;
  } catch (err) {
    dispatch({ type: actions.CREATE_CONTRACT_ERROR, payload: err });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create contract request.',
        status: 'error',
      })
    );
  }
};

export const updateContract = contract => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_CONTRACT, payload: contract });
    const res = await axios.put(`/contract/${contract.id}`, contract);
    dispatch({ type: actions.UPDATE_CONTRACT_SUCCESS, payload: res.data });
    dispatch(fetchContracts());
    dispatch(fetchSelectedContract(contract.id));
    dispatch(
      notify({
        title: 'Success',
        message: 'Contract successfully updated.',
        status: 'success',
      })
    );
  } catch (err) {
    dispatch({ type: actions.UPDATE_CONTRACT_ERROR, payload: err });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update contract.',
        status: 'error',
      })
    );
  }
};

export const deleteContract = contract => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_CONTRACT, payload: contract });
    const res = await axios.delete(`/contract/${contract.id}`);
    dispatch({ type: actions.DELETE_CONTRACT_SUCCESS, payload: res.data });
    dispatch(fetchContracts());
    dispatch(
      notify({
        title: 'Success',
        message: 'Contract successfully deleted.',
        status: 'success',
      })
    );
  } catch (err) {
    dispatch({ type: actions.DELETE_CONTRACT_ERROR, payload: err });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not delete contract.',
        status: 'error',
      })
    );
  }
};

export const postContractWorkflowLogEntry = logEntry => async dispatch => {
  try {
    dispatch({
      type: actions.POST_CONTRACT_WORKFLOW_LOG_ENTRY,
      payload: logEntry
    });
    const res = await axios.post('/contractWorkflowLogEntry', logEntry);
    dispatch({
      type: actions.POST_CONTRACT_WORKFLOW_LOG_ENTRY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: actions.POST_CONTRACT_WORKFLOW_LOG_ENTRY_ERROR,
      payload: err
    });
  }
};

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const fetchAmendments = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_AMENDMENTS, payload: undefined });
    const res = await axios.get('/amendment');
    dispatch({ type: actions.FETCH_AMENDMENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_AMENDMENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchContractAmendments = ContractId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_AMENDMENTS, payload: undefined });
    const res = await axios.get('/amendment', { params: { ContractId } });
    dispatch({
      type: actions.FETCH_CONTRACT_AMENDMENTS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_AMENDMENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const createAmendment = amendment => async dispatch => {
  try {
    let { data } = await axios.post(`/amendment`, amendment);
    dispatch({ type: actions.CREATE_AMENDMENT_SUCCESS, payload: data });
    dispatch(fetchAmendments());
    dispatch(fetchContractAmendments(amendment.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Amendment successfully created.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create amendment.',
        status: 'success',
      })
    );
  }
};

export const updateAmendment = amendment => async dispatch => {
  try {
    let { data } = await axios.put(`/amendment/${amendment.id}`, amendment);
    dispatch({ type: actions.UPDATE_AMENDMENT_SUCCESS, payload: data });
    dispatch(fetchAmendments());
    dispatch(fetchContractAmendments(amendment.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Amendment successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update amendment.',
        status: 'success',
      })
    );
  }
};

export const deleteAmendment = amendment => async dispatch => {
  try {
    let { data } = await axios.delete(`/amendment/${amendment.id}`);
    dispatch({ type: actions.DELETE_AMENDMENT_SUCCESS });
    dispatch(fetchAmendments());
    dispatch(fetchContractAmendments(amendment.ContractId));
    dispatch(
      notify({
        title: 'Success',
        message: 'Amendment successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update amendment.',
        status: 'success',
      })
    );
  }
};

export const postAmendmentWorkflowLogEntry = logEntry => async dispatch => {
  try {
    dispatch({ type: actions.POST_AMENDMENT_WORKFLOW_LOG_ENTRY });
    const res = await axios.post('/amendmentWorkflowLogEntry', logEntry);
    dispatch({
      type: actions.POST_AMENDMENT_WORKFLOW_LOG_ENTRY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: actions.POST_AMENDMENT_WORKFLOW_LOG_ENTRY_ERROR,
      payload: err.response.data
    });
  }
};

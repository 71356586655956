// @flow

import {
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_ERROR
} from '../actions/types';

import type { State as StateType, ValueListItem } from '../schemas';

import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<StateType>,
  +values: Array<ValueListItem>
};

type Action = {
  type:
    | typeof FETCH_STATES
    | typeof FETCH_STATES_SUCCESS
    | typeof FETCH_STATES_ERROR,
  payload: Array<StateType>
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_STATES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: _.sortBy(action.payload, ['name']),
        values: getValues(_.sortBy(action.payload, ['name']))
      };
    case FETCH_STATES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

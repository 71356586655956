// @flow

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {Grid,Button} from '@material-ui/core';

import {Formik,Form,Field} from 'formik';
import {MultiSelect} from '../../Formik/';
import {TextField} from 'formik-material-ui';

import {fetchSpecialties} from '../../../actions/creators/specialties';
import {getAllSpecialtyValues} from '../../../selectors/specialties';

type Props = {
  initialValues: Object,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {},
    inputContainer: {
      marginTop: theme.spacing(2)
    },
    button: {
      margin: theme.spacing(1)
    }
  };
});

function SiteFilterForm(props: Props): React$Element<any> {
  const {initialValues,handleSubmit} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const specialtyValues = useSelector(getAllSpecialtyValues);

  React.useEffect(() => {
    dispatch(fetchSpecialties());
    // @see https://github.com/facebook/create-react-app/issues/6880#issuecomment-486640921
    // eslint-disable-next-line
  }, []);

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
    >
      {({handleReset}) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                fullWidth
                name="name"
                label="Name"
                type="text"
                component={TextField}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                name="provider"
                label="Provider Entity"
                type="text"
                component={TextField}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                name="specialties"
                label="Specialties"
                options={specialtyValues}
                component={MultiSelect}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              className={classes.button}
              onClick={handleReset}
            >
              Clear Filters
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Filter Sites
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SiteFilterForm;

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { Grid, Typography, Badge } from '@material-ui/core';

import TodoList from '../../../components/Todo/TodoList';
import CreateTodoButton from '../../../components/Todo/CreateTodoButton';

import {
  deleteTodo,
  fetchPlacementTodos
} from '../../../actions/creators/todos';

import type { Placement, Todo, User } from '../../../schemas';

type Props = {
  currentUser: User,
  classes: Object,
  placement: Placement,
  isLoading: boolean,
  todos: Todo[],
  deleteTodo: Function,
  fetchPlacementTodos: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  padding: {
    padding: `0 ${theme.spacing(2)}px`,
  },
});

class Todos extends Component<Props> {

  fetchTodos = () => {
    const { placement, fetchPlacementTodos } = this.props;
    fetchPlacementTodos(placement);
  };

  handleDelete = async todo => {
    const { deleteTodo, fetchPlacementTodos, placement } = this.props;
    let result = await deleteTodo(todo);
    if (result) {
      fetchPlacementTodos(placement);
    }
  };

  render() {
    const { classes, todos, placement } = this.props;
    const incomplete = _.filter(todos, {isComplete: false});

    return (
      <div className={classes.root}>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              { incomplete.length
                ? (<Badge className={classes.padding} color="secondary" badgeContent={incomplete.length}>Todos</Badge>)
                : (<span>Todos</span>)
              }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.buttonContainer}>
            <CreateTodoButton
              model="Placement"
              foreignKey={placement.id}
              afterCreate={this.fetchTodos}
            />
          </Grid>
        </Grid>
        {todos && <TodoList currentUser={this.props.currentUser} todos={todos} afterAction={this.fetchTodos} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placement: state.placementDrawer.selectedPlacement,
    todos: state.placementDrawer.todos,
    currentUser: state.auth.currentUser
  };
};

export default (compose(
  connect(mapStateToProps, { deleteTodo, fetchPlacementTodos }),
  withStyles(styles)
)(Todos): any);

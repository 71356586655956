import axios from '../../api';
import * as actions from '../types';

export const fetchTerms = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_TERMS, payload: undefined });
    const res = await axios.get('/term');
    dispatch({ type: actions.FETCH_TERMS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.FETCH_TERMS_ERROR, payload: err });
  }
};

export const fetchTerm = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_TERM, payload: undefined });
    const res = await axios.get(`/term/${term.id}`);
    dispatch({ type: actions.FETCH_TERM_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.FETCH_TERM_ERROR, payload: err });
  }
};

export const createTerm = term => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_TERM, payload: term });
    const res = await  axios.post('/term', term);
    dispatch({ type: actions.CREATE_TERM_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.CREATE_TERM_ERROR, payload: err });
  }
};

export const updateTerm = term => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_TERM, payload: term });
    const res = await axios.put(`/term/${term.id}`, term);
    dispatch({ type: actions.UPDATE_TERM_SUCCESS, payload: res.data });
    dispatch(fetchTerms());
  } catch (err) {
    dispatch({ type: actions.UPDATE_TERM_ERROR, payload: err });
  }
};

export const deleteTerm = term => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_TERM, payload: term });
    const res = await axios.delete(`/term/${term.id}`, term);
    dispatch({ type: actions.DELETE_TERM_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.DELETE_TERM_ERROR, payload: err });
  }
};

// @flow
import React, { Component } from 'react';
import { TableCell, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import type { ValueListItem } from '../../schemas';

const styles = theme => ({
  cell: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  input: {
    width: '100%'
  }
});

type Props = {
  classes: Object,
  filter: Object,
  onFilter: Function,
  optionItems: ValueListItem[]
};

class SelectFilterCellBase extends Component<Props> {
  render() {
    const { classes, filter, onFilter, optionItems } = this.props;

    return (
      <TableCell className={classes.cell}>
        <Select
          native
          className={classes.input}
          value={filter ? filter.value : ''}
          type="boolean"
          onChange={e =>
            onFilter(e.target.value ? { value: e.target.value } : '')
          }
          inputProps={{
            id: 'select-native-simple'
          }}
        >
          <option value="">All</option>
          {_.map(optionItems, option => {
            return (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </Select>
      </TableCell>
    );
  }
}

export default (withStyles(styles)(SelectFilterCellBase): any);

// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { Snackbar, SnackbarContent, IconButton, Typography, Grid } from '@material-ui/core';
import { green, amber } from '@material-ui/core/colors';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

type Variant = 'success' | 'warning' | 'error' | 'info';
type Message = {
  title: string,
  type: Variant,
  body: string,
};

type Props = {
  classes: Object,
  open: boolean,
  message: Message,
  variant: Variant,
  className: string,
  autoHideDuration?: number,
  handleClose: Function
};

type State = {};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const styles2 = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class Snackbars extends Component<Props, State> {

  render() {
    const {message,open,handleClose,autoHideDuration} = this.props;

    function MySnackbarContent(props) {
      const {classes,className,message,handleClose,...other } = props;
      const variant = message.type ? message.type : 'info';
      const Icon = variantIcon[variant];

      return (
        <SnackbarContent
          className={classNames(classes[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <Grid container id="message-id">
              <Grid item>
                <Icon className={classNames(classes.icon, classes.iconVariant)} />
              </Grid>
              <Grid item>
                {message.title && <Typography variant="button" color="inherit">
                  {message.title}&nbsp;
                </Typography>}
                {message.body && <Typography id="message-id" variant="caption" color="inherit">
                  {message.body}
                </Typography>}
              </Grid>
            </Grid>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
          {...other}
        />
      );
    }

    const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={autoHideDuration || 3000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          handleClose={handleClose}
          message={message}
        />
      </Snackbar>
    );
  }
}

export default (withStyles(styles2)(Snackbars): any);

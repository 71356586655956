// @flow

import {
  FETCH_ATTACHMENTS_A,
  FETCH_ATTACHMENTS_A_SUCCESS,
  FETCH_ATTACHMENTS_A_ERROR,
  FETCH_ATTACHMENT_A,
  FETCH_ATTACHMENT_A_SUCCESS,
  FETCH_ATTACHMENT_A_ERROR,
  FETCH_CONTRACT_ATTACHMENTS,
  FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
  FETCH_CONTRACT_ATTACHMENTS_ERROR,
  CLEAR_CONTRACT_ATTACHMENTS
} from '../actions/types';

import type { AttachmentA } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: AttachmentA[]
};

type Action = {
  type:
  | typeof FETCH_ATTACHMENTS_A
  | typeof FETCH_ATTACHMENTS_A_SUCCESS
  | typeof FETCH_ATTACHMENTS_A_ERROR
  | typeof FETCH_ATTACHMENT_A
  | typeof FETCH_ATTACHMENT_A_SUCCESS
  | typeof FETCH_ATTACHMENT_A_ERROR
  | typeof FETCH_CONTRACT_ATTACHMENTS
  | typeof FETCH_CONTRACT_ATTACHMENTS_SUCCESS
  | typeof FETCH_CONTRACT_ATTACHMENTS_ERROR
  | typeof CLEAR_CONTRACT_ATTACHMENTS,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selectedAttachments: [],
  selected: null
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_ATTACHMENTS_A:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ATTACHMENTS_A_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_ATTACHMENTS_A_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_ATTACHMENT_A:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ATTACHMENT_A_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_ATTACHMENT_A_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_ATTACHMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedAttachments: filterAttachments(action.payload, 'Attachment A')
      };
    case FETCH_CONTRACT_ATTACHMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CLEAR_CONTRACT_ATTACHMENTS:
      return {
        ...state,
        selectedAttachments: initialState.selectedAttachments
      };
    default:
      return state;
  }
};

const filterAttachments = (items, name) => {
  const query = name.toLowerCase();
  return items.filter(item => {
    return item.name.toLowerCase().indexOf(query) >= 0;
  });
};

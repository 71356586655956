
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllSiteTypes = state => state.siteTypes.items;

export const getAllSiteTypeValues = createSelector(
  getAllSiteTypes,
  states => {
    return _.map(_.orderBy(states, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getSiteTypeById = state => createSelector(
  getAllSiteTypes, siteTypes => {
    return _.find(siteTypes, { id: state.siteTypeId });
  }
)(state);

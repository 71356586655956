import axios from '../../api';
import * as actions from '../types';

export const fetchPlacementAudits = (termId=null) => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENT_AUDITS, payload: undefined });
    const uri = termId ? `/placement/audit/${termId}` : '/placement/audit';
    const res = await axios.get(uri);
    dispatch({ type: actions.FETCH_PLACEMENT_AUDITS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.FETCH_PLACEMENT_AUDITS_ERROR, payload: err });
  }
};

import axios from '../../api';
import * as actions from '../types';

export const fetchCourseAttrs = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COURSE_ATTRS, payload: undefined });
    const courses = await axios.get('/courseAttr');
    dispatch({
      type: actions.FETCH_COURSE_ATTRS_SUCCESS,
      payload: courses.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COURSE_ATTRS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchCourseAttr = attr => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COURSE_ATTR, payload: attr });
    const res = await axios.get(`/courseAttr/${attr.id}`);
    dispatch({ type: actions.FETCH_COURSE_ATTR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COURSE_ATTR_ERROR,
      payload: error.response.data
    });
  }
};

export const createCourseAttr = attr => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_COURSE_ATTR, payload: attr });
    const res = await axios.post('/courseAttr', attr);
    dispatch({ type: actions.CREATE_COURSE_ATTR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.CREATE_COURSE_ATTR_ERROR,
      payload: error.response.data
    });
  }
};

export const updateCourseAttr = attr => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_COURSE_ATTR, payload: attr });
    const res = await axios.put(`/courseAttr/${attr.id}`, attr);
    dispatch({ type: actions.UPDATE_COURSE_ATTR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.UPDATE_COURSE_ATTR_ERROR,
      payload: error.response.data
    });
  }
};

export const deleteCourseAttr = attr => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_COURSE_ATTR, payload: attr });
    const res = await axios.delete(`/courseAttr/${attr.id}`);
    dispatch({ type: actions.DELETE_COURSE_ATTR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.DELETE_COURSE_ATTR_ERROR,
      payload: error.response.data
    });
  }
};

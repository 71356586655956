// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider
} from '@material-ui/core';
import { ExpandIcon } from './Icons';
import moment from 'moment';

const styles = theme => ({
  divider: {
    marginBottom: theme.spacing(1)
  },
  panelDetails: {
    flexDirection: 'column'
  },
  listItem: {
    marginBottom: theme.spacing(1),
    width: '100%'
  }
});

type Props = {
  entries: any[],
  classes: Object
};

class WorkflowEntriesPanel extends Component<Props> {
  render() {
    const { entries, classes } = this.props;
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="h6" gutterBottom>
            Workflow History
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          {entries.map(entry => {
            if (entry.StatusChangedFromId) {
              return (
                <div key={`entry-${entry.id}`} className={classes.listItem}>
                  <Divider className={classes.divider} />
                  <Typography>
                    <strong>Status changed</strong> to{' '}
                    <strong>{entry.StatusChangedTo.name}</strong>
                  </Typography>
                  <Typography>
                    from <strong>{entry.StatusChangedFrom.name}</strong>
                  </Typography>
                  <Typography>
                    by <strong>{_.get(entry, 'User.name', 'N/A')}</strong>
                  </Typography>
                  <Typography variant="caption">
                    {moment(entry.createdAt).format('lll')}
                  </Typography>
                </div>
              );
            }
            return (
              <div key={`entry-${entry.id}`} className={classes.listItem}>
                <Divider className={classes.divider} />
                <Typography>
                  <strong>Status changed</strong> to{' '}
                  <strong>{entry.StatusChangedTo.name}</strong>
                </Typography>
                <Typography>
                  by <strong>{_.get(entry, 'User.name', 'N/A')}</strong>
                </Typography>
                <Typography variant="caption" align="right">
                  {moment(entry.createdAt).format('lll')}
                </Typography>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default (withStyles(styles)(WorkflowEntriesPanel): any);


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllSpecialties = state => state.specialties.items;
export const getAllSpecialtyValues = createSelector(
  getAllSpecialties,
  states => {
    return _.map(_.orderBy(states, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

import axios from '../../api';
import * as actions from '../types';

export const fetchPlacementOrigins = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENT_ORIGINS, payload: undefined });
    const res = await axios.get('/placementOrigin');
    dispatch({ type: actions.FETCH_PLACEMENT_ORIGINS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.FETCH_PLACEMENT_ORIGINS_ERROR, payload: err });
  }
};

// @flow

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import type { DrawerNavigationItem } from '../schemas';

type Props = {
  items: DrawerNavigationItem[],
  currentSection: DrawerNavigationItem,
  handleNavigationChange: Function,
  classes: Object
};

type Classes ={
  icon: Object,
  activeBkg: Object,
  inActiveBkg: Object
}

const styles = (theme: Object) => {
  const classes: Classes = {
    icon: {
      padding: theme.typography.pxToRem(20),
      color: theme.palette.common.white,
      cursor: 'pointer'
    },
    activeBkg: {
      maxHeight: 64,
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    inActiveBkg: {
      maxHeight: 64,
    }
  };
  return classes;
};

function DrawerNavigation(props: Props) {
  const { currentSection, classes, items, handleNavigationChange } = props;

  return items.map(item => {
    return (
      <div className={
        currentSection.slug === item.slug ? classes.activeBkg : classes.inActiveBkg
      } key={item.slug}>
        <Tooltip title={item.label} placement="right">
          {
            <item.icon
              className={classes.icon}
              color="inherit"
              aria-label={item.label}
              onClick={() => handleNavigationChange(item)}
            />
          }
        </Tooltip>
      </div>
    );
  });
}

export default (withStyles(styles, {name: 'DrawerNavigation', withTheme: true })(DrawerNavigation): any);

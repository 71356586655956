// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { MultiSelect, Checkbox, Select } from '../Formik';
import { TextField } from 'formik-material-ui';
import { applicationValidation } from '../../validation';

import {
  FormControlLabel, Grid,
  Divider, Typography, Button,
} from '@material-ui/core';

import { fetchCourseOfferings } from '../../actions/creators/courseOfferings';
import { fetchCourses } from '../../actions/creators/courses';
import { fetchTerms } from '../../actions/creators/terms';

import { getAllTermValuesWithAttrs, getActiveTerm } from '../../selectors/terms';

import type { ValueListItem, CourseOffering, Term } from '../../schemas';

type State = {
  termId: number,
  courses: Array<ValueListItem>
};

type Props = {
  initialValues: Object,
  canUpdate: boolean,
  canDelete: boolean,
  handleSubmit: Function,
  handleDelete: Function,
  handleCancel: Function,

  classes: Object,
  courses: Array<ValueListItem>,
  specialties: Array<ValueListItem>,
  terms: Array<ValueListItem>,
  courseOfferings: Array<CourseOffering>,
  defaultTerm: Term,
  fetchCourseOfferings: Function,
  fetchTerms: Function,
  fetchCourses: Function,
};

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  inputContainer: {
    marginTop: 0,
    marginBottom: 0
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  },
});

class StudentApplicationForm extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      termId: _.get(props.initialValues, 'TermId', null),
      courses: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchCourses();
    await this.props.fetchCourseOfferings();
    await this.setCourseValues();
  };

  mapOfferings = offerings => {
    return _.map(offerings, el => {
      return {
        id: el.CourseId,
        name: `${el.Course.CatalogNumberId} | ${el.Course.description}`
      };
    });
  };

  setCourseValues = () => {
    const { termId } = this.state;
    const { courseOfferings, defaultTerm, courses } = this.props;

    const selectedValues = _.filter(courseOfferings, { TermId: termId });
    const defaultValues = _.filter(courseOfferings, {
      TermId: defaultTerm.id
    });

    let values = [];

    if (selectedValues.length !== 0) {
      values = this.mapOfferings(selectedValues);
    } else if (defaultValues.length !== 0) {
      values = this.mapOfferings(defaultValues);
    } else {
      values = courses;
    }

    this.setState({ courses: _.sortBy(values, ['name']) });
  };

  render() {
    const { classes, terms, specialties } = this.props;
    const { courses } = this.state;
    const isInstance = Boolean(_.get(this.props.initialValues, 'id', false));

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={applicationValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="maidenName"
                  label="Maiden Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="employer"
                  label="Place of Employment"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="licensures"
                  label="Current State license(s)"
                  component={TextField}
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.activeMilitary}
                      name="activeMilitary"
                      label="Active Military"
                      component={Checkbox}
                    />
                  }
                  label="Active Military"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.ogachi}
                      name="ogachi"
                      label="Participating in OGACHI?"
                      component={Checkbox}
                    />
                  }
                  label="Participating in OGACHI?"
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Term / APRN Major & Specialty
                </Typography>
                <Divider />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  name="TermId"
                  label="Term"
                  options={terms}
                  component={Select}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  name="CourseId"
                  label="Course"
                  options={courses}
                  component={Select}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="courseHours"
                  label="Course Hours"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Field
                  fullWidth
                  name="Specialties"
                  label="Specialties"
                  options={specialties}
                  component={MultiSelect}
                />
              </Grid>


              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  List any areas you are able to travel to outside of the 120 mile radius of your address
                </Typography>
                <Typography variant="caption">
                  (this information is used in the rare case a site cannot be secured within the travel radius)
                </Typography>
                <Divider />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="travel1"
                  label="1st Choice"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="travel2"
                  label="2nd Choice"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="travel3"
                  label="3rd Choice"
                  component={TextField}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Provide the days you are unavailable for clinicals (if any):
                </Typography>
                <Divider />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.monday}
                      name="PlacementAppDay.monday"
                      label="Monday"
                      component={Checkbox}
                    />
                  }
                  label="Monday"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.tuesday}
                      name="PlacementAppDay.tuesday"
                      label="Tuesday"
                      component={Checkbox}
                    />
                  }
                  label="Tuesday"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.wednesday}
                      name="PlacementAppDay.wednesday"
                      label="Wednesday"
                      component={Checkbox}
                    />
                  }
                  label="Wednesday"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.thursday}
                      name="PlacementAppDay.thursday"
                      label="Thursday"
                      component={Checkbox}
                    />
                  }
                  label="Thursday"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.friday}
                      name="PlacementAppDay.friday"
                      label="Friday"
                      component={Checkbox}
                    />
                  }
                  label="Friday"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.saturday}
                      name="PlacementAppDay.saturday"
                      label="Saturday"
                      component={Checkbox}
                    />
                  }
                  label="Saturday"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.PlacementAppDay.sunday}
                      name="PlacementAppDay.sunday"
                      label="Sunday"
                      component={Checkbox}
                    />
                  }
                  label="Sunday"
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography type="subtitle1" className={classes.groupLabel}>
                  Acute Care majors only: provide the shift times you are unavailable for clinicals (if any):
                </Typography>
                <Divider />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={_.get(values, 'PlacementAppShift.days', null)}
                      name="PlacementAppShift.days"
                      label="Days"
                      component={Checkbox}
                    />
                  }
                  label="Days"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={_.get(values, 'PlacementAppShift.nights', null)}
                      name="PlacementAppShift.nights"
                      label="Nights"
                      component={Checkbox}
                    />
                  }
                  label="Nights"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={_.get(values, 'PlacementAppShift.weekends', null)}
                      name="PlacementAppShift.weekends"
                      label="Weekends"
                      component={Checkbox}
                    />
                  }
                  label="Weekends"
                />
              </Grid>

            </Grid>

            <div className={classes.buttons}>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid}
                className={classes.button}
              >
                {isInstance ? 'Update Application' : 'Create Application'}
              </Button>
              {isInstance && (<Button
                color="secondary"
                onClick={this.props.handleDelete}
                disabled={isSubmitting || !this.props.canDelete}
                className={classes.button}
              >
                Delete
              </Button>)}
              <Button
                onClick={this.props.handleCancel}
                disabled={isSubmitting}
                className={classes.button}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

}

const mapStateToProps = state => {
  return {
    courses: state.courses.values,
    terms: getAllTermValuesWithAttrs(state),
    defaultTerm: getActiveTerm(state),
    courseOfferings: state.courseOfferings.items,
    specialties: state.specialties.values
  };
};

export default (compose(
  withStyles(styles, { name: 'StudentApplicationForm' }),
  connect(mapStateToProps, { fetchCourses, fetchTerms, fetchCourseOfferings }),
)(StudentApplicationForm): any);

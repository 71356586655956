// @flow

import React from 'react';
import {Formik,Form,Field} from 'formik';

import {Grid,Button,FormControlLabel,makeStyles} from '@material-ui/core';

import {MultiSelect,Select,Checkbox,TextField} from '../../Formik';
import {siteValidation} from '../../../validation';

type Props = {
  classes?: Object,
  initialValues: Object,
  associations: Object,
  canUpdate: boolean,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  formSection: {
    marginBottom: theme.spacing(2)
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const SiteForm = (props: Props): React$Element<any> => {
  const {initialValues,canUpdate,associations,handleSubmit} = props;
  const {
    states,specialties,counties,countries,siteTypes,organizations
  } = associations;
  const classes = useStyles();

  return(
    <Formik
      initialValues={initialValues}
      validationSchema={siteValidation}
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, isSubmitting, isValid, dirty, handleReset }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={8} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="name"
                label="Name"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required
                name="SiteTypeId"
                label="Site Type"
                options={siteTypes}
                component={Select}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="contact"
                label="Contact"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="contactTitle"
                label="Contact Title"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="contactEmail"
                label="Contact Email"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="address"
                label="Address"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="addressLine2"
                label="Address 2"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="addressLine3"
                label="Address 3"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="city"
                label="City"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required={values.CountryId === 'US'}
                name="StateId"
                label="State"
                options={states}
                component={Select}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required={values.CountryId === 'US'}
                type="text"
                name="postalCode"
                label="Postal Code"
                component={TextField}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="CountyId"
                label="County"
                options={counties}
                component={Select}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Field
                fullWidth
                required
                type="text"
                name="CountryId"
                label="Country"
                options={countries}
                component={Select}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="phone"
                label="Phone"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="fax"
                label="FAX"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="website"
                label="Website"
                component={TextField}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isRural}
                    name="isRural"
                    label="Rural?"
                    component={Checkbox}
                  />
                }
                label="Rural?"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isUnderserved}
                    name="isUnderserved"
                    label="Underserved?"
                    component={Checkbox}
                  />
                }
                label="Underserved?"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Field
                    checked={values.isAhec}
                    name="isAhec"
                    label="AHEC?"
                    component={Checkbox}
                  />
                }
                label="AHEC?"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                name="Specialties"
                label="Specialties"
                options={specialties}
                component={MultiSelect}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                type="text"
                name="siteRequirements"
                label="Requirements"
                component={TextField}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Field
                fullWidth
                required
                name="OrganizationId"
                label="Organization"
                options={organizations}
                component={Select}
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!canUpdate || isSubmitting || !isValid || !dirty}
            >
              Update Site
            </Button>
            <Button
              className={classes.button}
              disabled={!dirty}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SiteForm;

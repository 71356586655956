// @flow

import 'react-virtualized/styles.css';
import DxThemes from 'devextreme/ui/themes';

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import history from './history';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withCookies } from 'react-cookie';
import { NotificationComponent } from './NotificationsContainer';

import {
  Login, Logout, Dashboard, NotFound, Auth, Users, Messages, Roles, Sites,
  Specialties, StyleGuide, Placements, PlacementsManage, Terms,
  Contracts, Addenda, Amendments, StudentApplications, StudentPlacements,
  Stash, AttachmentAs, Preceptors, PreceptorsMerge, Organizations,
  OrganizationsMerge, Locations, PlacementReports, CourseAttrs, PlacementsMerge,
  PlacementAudit
} from '../pages';

import MenuItems from '../config/menuItems';
import { withStyles } from '@material-ui/core/styles';

import { loadAppInfo } from '../actions/creators/appInfo';
import { endMasquerade } from '../actions/creators/auth';

import PrivateRouteComponent from '../components/PrivateRoute';
import AppDrawer from '../components/AppDrawer';
import AppBar from '../components/AppBar';

import '../styles/App.css';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

type Props = {
  cookies: any,
  appInfo: Object,
  auth: Object,
  permissions: Object,
  classes: Object,
  theme: Object,
  loadAppInfo: Function,
  appInfo: Object,
  endMasquerade: Function
};

type State = {
  mobileOpen: boolean
};

const styles = theme => ({
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    overflow: 'hidden',
    color: theme.palette.text.secondary
  },
  contentFrame: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    color: theme.palette.text.secondary
  }
});

class PrivateCookieRoute extends Component<Props> {
  render() {
    const { cookies } = this.props;
    const cookie = Boolean(cookies.get('cps.sid'));
    return <PrivateRouteComponent {...this.props} cookie={cookie} />;
  }
}

const PrivateRoute = withCookies(PrivateCookieRoute);

class AppContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: true
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.loadAppInfo();
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, theme, permissions } = this.props;
    const colorway = theme.palette.type === 'dark'
      ? 'dark-theme' : 'light-theme';
    const dxTheme = colorway === 'dark-theme'
      ? 'material.blue.dark' : 'material.blue.light';

    DxThemes.current(dxTheme);

    return (
      <BrowserRouter>
        <NotificationComponent />
        <div className={classNames(classes.appFrame, colorway)}>
          <AppBar
            handleDrawerToggle={this.handleDrawerToggle}
            handleEndMasquerade={this.props.endMasquerade}
            mobileOpen={this.state.mobileOpen}
            auth={this.props.auth}
          />
          <AppDrawer
            className={classes.drawer}
            handleDrawerToggle={this.handleDrawerToggle}
            mobileOpen={this.state.mobileOpen}
            menuItems={MenuItems(permissions)}
            auth={this.props.auth}
            appInfo={this.props.appInfo}
          />
          <div className={classes.contentFrame}>
            <Switch>
              <Route exact path="/authservice" component={Auth} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />

              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/contracts" component={Contracts} />
              <PrivateRoute exact path="/contracts/addenda" component={Addenda} />
              <PrivateRoute exact path="/contracts/amendments" component={Amendments} />
              <PrivateRoute exact path="/contracts/attachmentAs" component={AttachmentAs} />
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/users/messages" component={Messages} />
              <PrivateRoute exact path="/preceptors" component={Preceptors} />
              <PrivateRoute exact path="/preceptors/merge" component={PreceptorsMerge} />
              <PrivateRoute exact path="/roles" component={Roles} />
              <PrivateRoute exact path="/specialties" component={Specialties} />
              <PrivateRoute exact path="/sites" component={Sites} />
              <PrivateRoute exact path="/terms" component={Terms} />
              <PrivateRoute exact path="/styleguide" component={StyleGuide} />
              <PrivateRoute exact path="/placements" component={Placements} />
              <PrivateRoute exact path="/stash" component={Stash} />
              <PrivateRoute exact path="/organizations" component={Organizations} />
              <PrivateRoute exact path="/organizations/merge" component={OrganizationsMerge} />
              <PrivateRoute exact path="/student/applications" component={StudentApplications} />
              <PrivateRoute exact path="/student/placements" component={StudentPlacements} />
              <PrivateRoute exact path="/placements/manage" component={PlacementsManage} />
              <PrivateRoute exact path="/placements/merge" component={PlacementsMerge} />
              <PrivateRoute exact path="/locations" component={Locations} />
              <PrivateRoute exact path="/courses" component={CourseAttrs} />

              <PrivateRoute exact path="/reports/placements" component={PlacementReports} />
              <PrivateRoute exact path="/reports/placementAudit" component={PlacementAudit} />

              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  permissions: state.permissions,
  appInfo: state.appInfo
});

export default (compose(
  withStyles(styles, { withTheme: true, name: 'AppContainer' }),
  connect(mapStateToProps, { loadAppInfo, endMasquerade }),
  DragDropContext(HTML5Backend)
)(AppContainer): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';

import { Grid, Button, FormControlLabel } from '@material-ui/core';

import { MultiSelect, Select, SelectLookup, Checkbox } from '../Formik/';
import { TextField } from 'formik-material-ui';
import { siteValidation } from '../../validation';

type Props = {
  classes: Object,
  initialValues: Object,
  canCreate: Function,
  canUpdate: Function,
  canDelete: Function,
  handleClose: Function,
  handleSubmit: Function,
  handleDelete: Function,
  formik: Object,
  states: any[],
  counties: any[],
  countries: any[],
  specialties: any[],
  organizations: any[],
  siteTypes: any[]
};

type State = {};

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
});

class SiteDetailForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      states, counties, countries, specialties, organizations, siteTypes
    } = this.props;

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={siteValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          handleReset, touched, isSubmitting, isValid, values
        }) => (
          <Form>
            <Grid container spacing={2}>

              <Grid item md={8} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="name"
                  label="Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  name="SiteTypeId"
                  label="Site Type"
                  options={siteTypes}
                  component={Select}
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contact"
                  label="Contact"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contactTitle"
                  label="Contact Title"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="contactEmail"
                  label="Contact Email"
                  component={TextField}
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="address"
                  label="Address"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="addressLine2"
                  label="Address 2"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="addressLine3"
                  label="Address 3"
                  component={TextField}
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="city"
                  label="City"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  name="StateId"
                  label="State"
                  options={states}
                  component={Select}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  type="text"
                  name="postalCode"
                  label="Postal Code"
                  component={TextField}
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="CountyId"
                  label="County"
                  options={counties}
                  component={Select}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  type="text"
                  name="CountryId"
                  label="Country"
                  options={countries}
                  component={Select}
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="phone"
                  label="Phone"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="fax"
                  label="FAX"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="website"
                  label="Website"
                  component={TextField}
                />
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isRural}
                      name="isRural"
                      label="Rural?"
                      component={Checkbox}
                    />
                  }
                  label="Rural?"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isUnderserved}
                      name="isUnderserved"
                      label="Underserved?"
                      component={Checkbox}
                    />
                  }
                  label="Underserved?"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isAhec}
                      name="isAhec"
                      label="AHEC?"
                      component={Checkbox}
                    />
                  }
                  label="AHEC?"
                />
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  name="Specialties"
                  label="Specialties"
                  options={specialties}
                  component={MultiSelect}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="siteRequirements"
                  label="Requirements"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  required
                  name="OrganizationId"
                  label="Organization"
                  options={organizations}  // TODO: load the organizations async
                  component={SelectLookup}
                />
              </Grid>

            </Grid>

            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.props.canUpdate || isSubmitting || !isValid}
              >
                Save
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
              &nbsp;
              {values.id && (<Button
                variant="contained"
                color="secondary"
                disabled={!this.props.canDelete || !values.id}
                onClick={this.props.handleDelete}
              >
                Delete
              </Button>)}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.states.values,
    counties: state.counties.values,
    countries: state.countries.values,
    specialties: state.specialties.values,
    organizations: state.organizations.values,
    siteTypes: state.siteTypes.values
  };
};

export default (compose(
  withStyles(styles, { withTheme: true, name: 'SiteDetailForm' }),
  connect(mapStateToProps, {}),
)(SiteDetailForm): any);

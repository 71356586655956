// @flow

import {
  FETCH_SPECIALTIES,
  FETCH_SPECIALTIES_SUCCESS,
  FETCH_SPECIALTIES_ERROR,
  FETCH_SPECIALTY,
  FETCH_SPECIALTY_SUCCESS,
  FETCH_SPECIALTY_ERROR
} from '../actions/types';
import type { Specialty, ValueListItem } from '../schemas';
import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Specialty>,
  +values: Array<ValueListItem>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_SPECIALTIES
    | typeof FETCH_SPECIALTIES_SUCCESS
    | typeof FETCH_SPECIALTIES_ERROR
    | typeof FETCH_SPECIALTY
    | typeof FETCH_SPECIALTY_SUCCESS
    | typeof FETCH_SPECIALTY_ERROR,
  payload: ?Array<Specialty> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_SPECIALTIES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SPECIALTIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: getValues(action.payload)
      };
    case FETCH_SPECIALTIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_SPECIALTY:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SPECIALTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_SPECIALTY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

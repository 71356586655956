
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllTerms = state => state.terms.items;

export const getTermsWithAttrs = createSelector(
  getAllTerms,
  terms => _.filter(terms, el => el.TermAttr)
);

export const getActiveTerm = createSelector(
  getTermsWithAttrs,
  terms => {
    const activeTerms = _.filter(terms, { TermAttr: { isActive: true } });
    return activeTerms[0];
  }
);

export const getActiveTermId = createSelector(
  getActiveTerm,
  activeTerm => _.get(activeTerm, 'id', null)
);

export const getTermById = (state, termId) => createSelector(
  getTermsWithAttrs,
  terms =>  _.find(terms, { id: termId })
)(state);

export const getAllTermValues = createSelector(
  getAllTerms,
  terms => _.chain(terms).map(el => ({ id: el.id, name: el.name })).value()
);

export const getAllTermValuesWithAttrs = createSelector(
  getTermsWithAttrs,
  terms => _.chain(terms).map(el => ({ id: el.id, name: el.name })).value()
);

export const getAllCulledTerms = createSelector(
  getTermsWithAttrs,
  getActiveTermId,
  (terms, activeTermId) => {
    return _.filter(terms, term => term.id >= (activeTermId - 80));
  }
);

export const getAllCulledTermValues = createSelector(
  getAllCulledTerms,
  terms => _.chain(terms).map(el => ({ id: el.id, name: el.name })).value()
);

// @flow

import _ from 'lodash';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';

import {Grid,IconButton,Typography,Badge} from '@material-ui/core';
import {FilterListIcon} from '../../components/Icons';

import {
  enableFilters,disableFilters,toggleFilters,updateFilters,
  clearFilters,filterPlacements,updateSelectedTermId,fetchPlacementBoard
} from '../../actions/creators/placements';
import {fetchTerms} from '../../actions/creators/terms';
import {fetchPlacementStatuses} from '../../actions/creators/placementStatuses';
import {
  fetchSelectedPlacement,showPlacementDrawer
} from '../../actions/creators/placementDrawer';
import {fetchContracts} from '../../actions/creators/contracts';
import {fetchCoordinators} from '../../actions/creators/coordinators';
import {fetchUserSettingTypes} from '../../actions/creators/userSettingTypes';
import {fetchPlacementTodos} from '../../actions/creators/todos';
import {fetchPlacementApplication} from '../../actions/creators/studentApplications';
import {fetchAttachmentAStatusCodes} from '../../actions/creators/attachmentAStatusCodes';


import PlacementDrawer from '../../components/PlacementDrawer/PlacementDrawer';
import LoadingIndicator from '../../components/LoadingIndicator';

import PlacementFilters from '../../components/PlacementsBoard/PlacementFilters';
import PlacementsBoard from '../../components/PlacementsBoard';
import TermSelector from '../../components/PlacementsBoard/TermSelector';

import CreatePlacementButton from '../../components/Placement/CreatePlacementButton';

import mapToBoard from '../../utils/kanbanBuilder';
import {hasAccess} from '../../utils/permissionsCheck';

import {getAllPlacements} from '../../selectors/placements';
import {getActiveTermId} from '../../selectors/terms';
import {getCurrentUserSetting} from '../../selectors/users';

import type {Placement,Permissions,User} from '../../schemas';

type Props = {
  workingTermId: Number,
  placements: Placement[],
  enableFilters: Function,
  disableFilters: Function,
  toggleFilters: Function,
  updateFilters: Function,
  clearFilters: Function,
  fetchPlacementBoard: Function,
  filtersVisible: boolean,
  filtersEnabled: boolean,
  isLoadingData: boolean,
  placementFilters: Object,
  placements: Object,
  classes: Object,
  fetchSelectedPlacement: Function,
  fetchPlacementStatuses: Function,
  fetchTerms: Function,
  fetchCoordinators: Function,
  fetchUserSettingTypes: Function,
  showPlacementDrawer: Function,
  placementStatuses: Array<Object>,
  placementDrawer: Object,
  activeTermId: number,
  theme: Object,
  title: string,
  currentUser: User,
  userSettingTypes: Object[],
  filterPlacements: Function,
  updateSelectedTermId: Function,
  permissions: Permissions,
  selectedTermId?: number,
  fetchCourses: Function,
  fetchStudentValues: Function,
  fetchPlacementTodos: Function,
  fetchPlacementApplication: Function,
  fetchAttachmentAStatusCodes: Function,
  fetchContracts: Function,
};

type State = {
  boardData: Object,
  drawerLoading: boolean,
  isLoading: boolean,
  termId?: number
};

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    flexGrow: 1,
    marginTop: '64px',
    overflow: 'auto'
  },
  filterToggler: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-center'
  },
  progress: {
    margin: `0 ${theme.spacing(2)}px`
  },
  boardContainer: {
    maxHeight: `calc(100vh - 225px)`
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    margin: theme.spacing(2)
  }
});

class PlacementsPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      boardData: {},
      isLoading: true,
      drawerLoading: false,
      termId: undefined,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.props.disableFilters();
    this.setState({ boardData: {} });
  }

  static getDerivedStateFromProps(props, state) {
    const boardData = mapToBoard(
      props.filteredItems,
      props.placementStatuses,
      props.theme
    );

    if (boardData !== state.boardData) {
      return { boardData: boardData };
    }

    return null;
  }

  loadData = async () => {
    this.setManagerId();

    this.props.fetchPlacementStatuses();
    this.props.fetchAttachmentAStatusCodes();
    this.props.fetchCoordinators();
    this.props.fetchContracts();
    await this.props.enableFilters();
    await this.props.fetchTerms();
    await this.props.fetchUserSettingTypes();

    return this.handleResetTerm();
  };

  getDefaultTermId = () => {
    const { selectedTermId, activeTermId, workingTermId } = this.props;
    const usersWorkingTermId = workingTermId ? workingTermId : selectedTermId;
    const defaultTermId = usersWorkingTermId ? usersWorkingTermId : activeTermId;
    this.props.updateSelectedTermId(parseInt(defaultTermId, 10));

    return defaultTermId;
  };

  setManagerId = () => {
    const { managerId } = this.props.placementFilters;

    if (managerId.value === '') {
      const isCpoStaff = !!_.find(this.props.currentUser.Roles, { slug: 'cpo_staff' });
      if (isCpoStaff) {
        this.props.updateFilters({
          attrName: 'managerId',
          value: this.props.currentUser.username
        });
      }
    }
  };

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, _.get(permissions, 'create'));
  }

  handlePlacementFiltersChange = e => {
    this.props.updateFilters({
      attrName: e.target.name,
      value: e.target.value
    });
  };

  handleClearFilters = () => {
    this.props.clearFilters();
  };

  handleSelectedTermIdChange = async e => {
    this.props.updateSelectedTermId(e.target.value);
    this.setState({ isLoading: true });
    await this.props.fetchPlacementBoard(e.target.value);
    this.setState({ isLoading: false });
  };

  handleResetTerm = async () => {
    const defaultTermId = this.getDefaultTermId();
    this.props.updateSelectedTermId(defaultTermId);
    this.setState({ isLoading: true });
    await this.props.fetchPlacementBoard(defaultTermId);
    return this.setState({ isLoading: false });
  };

  showPlacementDrawer = async cardId => {
    this.setState({ drawerLoading: true });
    await this.props.fetchSelectedPlacement(cardId, true);
    this.props.fetchPlacementTodos({id: cardId});
    this.props.fetchPlacementApplication(
      { id: this.props.placementDrawer.selectedPlacement.StudentId },
      this.props.placementDrawer.selectedPlacement
    );
    this.setState({ drawerLoading: false });
    this.props.showPlacementDrawer();
  };

  renderFooter() {
    const { classes } = this.props;

    return (
      <div className={classes.footer}>
        <CreatePlacementButton mini variant="contained" />
      </div>
    );
  }

  getEnabledFilterCount() {
    const {placementFilters} = this.props;
    let enabledFilterCount = 0;
    _.each(placementFilters, filter => {
      if (filter.value) enabledFilterCount += 1;
    });

    return enabledFilterCount;
  }

  render() {
    const {classes,selectedTermId,workingTermId} = this.props;
    const {boardData,isLoading,drawerLoading} = this.state;
    const termSelectorTermId = selectedTermId
      ? parseInt(selectedTermId, 10) : parseInt(workingTermId, 10);
    const enabledFilterCount = this.getEnabledFilterCount();

    return (
      <div className={classes.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="h6">
              Placements for...
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TermSelector
              selectedTermId={termSelectorTermId}
              handleSelectedTermIdChange={this.handleSelectedTermIdChange}
              handleResetTerm={this.handleResetTerm}
            />
          </Grid>
          <Grid item xs={2} className={classes.filterToggler}>
            <IconButton label="show filters" onClick={this.props.toggleFilters}>
              <Badge badgeContent={enabledFilterCount} color="primary">
                <FilterListIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.boardContainer}>
            {!isLoading && boardData && (
              <PlacementsBoard data={boardData} onCardClick={this.showPlacementDrawer} />
            )}
          </Grid>
        </Grid>
        {!isLoading && (
          <PlacementFilters
            isVisible={this.props.filtersVisible}
            isEnabled={this.props.filtersEnabled}
            handleClose={this.props.toggleFilters}
            handleClearFilters={this.handleClearFilters}
            onHide={this.props.toggleFilters}
            handlePlacementFiltersChange={this.handlePlacementFiltersChange}
            handleSelectedTermIdChange={this.handleSelectedTermIdChange}
            placementFilters={this.props.placementFilters}
          />
        )}
        {this.renderFooter()}
        <PlacementDrawer />
        <LoadingIndicator loading={isLoading || drawerLoading} overlay={true} />
      </div>
    );
  }
}

function reducePlacementFilters(placementFilters) {
  return placementFilters.reduce((acc, placementFilter) => {
    acc[placementFilter.attrName] = {
      value: placementFilter.value,
      matchingMethod: placementFilter.matchingMethod
    };
    return acc;
  }, {});
}

const mapStateToProps = state => ({
  placements: getAllPlacements(state),
  placementFilters: reducePlacementFilters(state.placements.filters),
  filteredItems: state.placements.filteredItems,
  filtersVisible: state.placements.filtersVisible,
  filtersEnabled: state.placements.filtersEnabled,
  selectedTermId: state.placements.selectedTermId,
  placementStatuses: state.placementStatuses.items,
  userSettingTypes: state.userSettingTypes.items,
  placementDrawer: state.placementDrawer,
  currentUser: state.auth.currentUser,
  workingTermId: getCurrentUserSetting(state, 'working_semester'),
  permissions: state.permissions.placement,
  activeTermId: getActiveTermId(state),
});

export default (compose(
  withStyles(styles, { name: 'PlacementsPage', withTheme: true }),
  connect(mapStateToProps, {
    enableFilters, disableFilters, toggleFilters, updateFilters, clearFilters,
    fetchSelectedPlacement, fetchPlacementStatuses, fetchPlacementApplication,
    fetchTerms, fetchCoordinators, fetchUserSettingTypes, showPlacementDrawer,
    filterPlacements, fetchPlacementTodos, updateSelectedTermId,
    fetchPlacementBoard, fetchAttachmentAStatusCodes, fetchContracts
  })
)(PlacementsPage): any);

// @flow

import {
  FETCH_AMENDMENT_STATUS_CODES,
  FETCH_AMENDMENT_STATUS_CODES_SUCCESS,
  FETCH_AMENDMENT_STATUS_CODES_ERROR
} from '../actions/types';

import type { AmendmentStatusCode, ValueListItem } from '../schemas';
import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: AmendmentStatusCode[],
  +values: ValueListItem[]
};

type Action = {
  type:
    | typeof FETCH_AMENDMENT_STATUS_CODES
    | typeof FETCH_AMENDMENT_STATUS_CODES_SUCCESS
    | typeof FETCH_AMENDMENT_STATUS_CODES_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: []
};

const formatValueList = items => {
  const sorted = _.sortBy(items, ['order', 'ASC']);

  return sorted.map(({ id, name }) => {
    return { id, name };
  });
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_AMENDMENT_STATUS_CODES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_AMENDMENT_STATUS_CODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: formatValueList(action.payload)
      };
    case FETCH_AMENDMENT_STATUS_CODES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

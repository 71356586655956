import axios from '../../api';
import * as actions from '../types';

export const fetchAttachmentAStatusCodes = () => async dispatch => {
  try {
    dispatch({
      type: actions.FETCH_ATTACHMENT_A_STATUS_CODES
    });
    const res = await axios.get('/attachmentAStatusCode');
    dispatch({
      type: actions.FETCH_ATTACHMENT_A_STATUS_CODES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ATTACHMENT_A_STATUS_CODES_ERROR,
      payload: error.response.data
    });
  }
};

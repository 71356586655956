// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import PlacementSlotList from '../../../components/PlacementDrawer/PlacementSlots/PlacementSlotList';
import StashedPlacementSlotFilterForm from '../../../components/PlacementDrawer/StashedPlacementSlots/StashedPlacementSlotFilterForm';
import { fetchStashedPlacementSlots } from '../../../actions/creators/placementDrawer';

import type { SiteEnrollment } from '../../../schemas';

type Props = {
  classes: Object,
  courseId: number,
  fetchStashedPlacementSlots: Function,
  stashedPlacementSlots: Array<SiteEnrollment>,
  isLoading: boolean,
  filterValues: Object
};

type State = {
  filteredPlacementSlots: Array<SiteEnrollment>
};

const initialFilterValues = {
  siteName: '',
  preceptorName: ''
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    ...theme.typography.h5,
    marginRight: theme.spacing(1)
  },
  subheading: theme.typography.subheading,
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  content: {
    flex: 3
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  }
});

class StashedPlacementSlotsSection extends Component<Props, State> {
  state = {
    filteredPlacementSlots: []
  };

  componentDidMount() {
    const {
      fetchStashedPlacementSlots,
      courseId,
      stashedPlacementSlots
    } = this.props;
    fetchStashedPlacementSlots(courseId);

    this.setState({
      filteredPlacementSlots: stashedPlacementSlots
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      prevProps.stashedPlacementSlots !== this.props.stashedPlacementSlots
    ) {
      this.filterSlots();
    }
  }

  filterSlots = values => {
    const { stashedPlacementSlots } = this.props;
    let filteredPlacementSlots = stashedPlacementSlots;
    const filterValues = values ? values : initialFilterValues;

    if (filterValues.siteName !== initialFilterValues.siteName) {
      filteredPlacementSlots = filteredPlacementSlots.filter(placementSlot => {
        return (
          placementSlot.Site.name
            .toLowerCase()
            .indexOf(filterValues.siteName.toLowerCase()) >= 0
        );
      });
    }

    if (filterValues.preceptorName !== initialFilterValues.preceptorName) {
      filteredPlacementSlots = filteredPlacementSlots.filter(placementSlot => {
        let found = placementSlot.PreceptorEnrollments.filter(
          preceptorEnrollment => {
            if (preceptorEnrollment.Preceptor === null) {
              return false;
            }

            return (
              preceptorEnrollment.Preceptor.name
                .toLowerCase()
                .indexOf(filterValues.preceptorName.toLowerCase()) >= 0
            );
          }
        );

        return found.length > 0;
      });
    }

    this.setState({ filteredPlacementSlots });
  };
  render() {
    const { classes, isLoading } = this.props;
    const { filteredPlacementSlots } = this.state;
    return (
      <div className={classes.root}>
        <div>
          <span className={classes.title}>Stashed Placement Slots</span>
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            {!isLoading && (
              <PlacementSlotList
                items={filteredPlacementSlots}
                editable={false}
              />
            )}
          </div>

          <div className={classes.sidebar}>
            <StashedPlacementSlotFilterForm
              handleSubmit={this.filterSlots}
              initialValues={initialFilterValues}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    courseId: state.placementDrawer.selectedPlacement.CourseId,
    stashedPlacementSlots: state.placementDrawer.stashedPlacementSlots,
    isLoading: state.placementDrawer.isLoading
  };
};

export default (compose(
  withStyles(styles, { withTheme: true, name: 'StashedPlacementSlotsSection'}),
  connect(mapStateToProps, { fetchStashedPlacementSlots })
)(StashedPlacementSlotsSection): any);

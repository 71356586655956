// @flow

import _ from 'lodash';

import * as types from '../actions/types';
import type { Placement, FilterObject } from '../schemas';
import { updateItem } from './methods';
import matching, { MATCH_EXACT, MATCH_IN } from '../utils/matching';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Placement>,
  +filteredItems: Array<Placement>,
  +filtersEnabled: boolean,
  +filtersVisible: boolean,
  +filters: Array<FilterObject>
};

type Action = {
  type:
    | typeof types.FETCH_PLACEMENTS
    | typeof types.FETCH_PLACEMENTS_SUCCESS
    | typeof types.FETCH_PLACEMENTS_ERROR
    | typeof types.FILTER_PLACEMENTS
    | typeof types.ENABLE_PLACEMENT_FILTERS
    | typeof types.DISABLE_PLACEMENT_FILTERS
    | typeof types.TOGGLE_PLACEMENT_FILTERS
    | typeof types.UPDATE_PLACEMENT_FILTERS
    | typeof types.CLEAR_PLACEMENT_FILTERS
    | typeof types.UPDATE_SELECTED_PLACEMENT_SUCCESS,
  payload: any
};

function PlacementFilter({ attrName, value, matchingMethod }) {
  this.attrName = attrName;
  this.value = value;
  this.matchingMethod = matchingMethod;
}

const initialFilters = [
  new PlacementFilter({
    attrName: 'managerId',
    value: '',
    matchingMethod: MATCH_EXACT
  }),
  new PlacementFilter({
    attrName: 'Course.CatalogNumberId',
    value: '',
    matchingMethod: MATCH_IN
  }),
  new PlacementFilter({
    attrName: 'Student.fullName',
    value: '',
    matchingMethod: MATCH_IN
  })
];

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  filteredItems: [],
  filtersEnabled: false,
  filtersVisible: false,
  filters: initialFilters,
  selectedTermId: undefined
};

export default (state: State = initialState, action: Action): Function => {

  switch (action.type) {
    case types.FETCH_PLACEMENTS:
      return {
        ...state,
        isLoading: true
      };
    case types.FETCH_PLACEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case types.FETCH_PLACEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case types.TOGGLE_PLACEMENT_FILTERS:
      return {
        ...state,
        filtersVisible: !state.filtersVisible
      };

    case types.ENABLE_PLACEMENT_FILTERS:
      return {
        ...state,
        filtersEnabled: true
      };

    case types.DISABLE_PLACEMENT_FILTERS:
      return {
        ...state,
        filtersEnabled: false
      };

    case types.UPDATE_PLACEMENT_FILTERS:
      return {
        ...state,
        ...updateFilterByAttrName(state, (action.payload: FilterObject))
      };

    case types.FILTER_PLACEMENTS:
      return {
        ...state,
        filteredItems: filterPlacements(state.items, state.filters)
      };

    case types.CLEAR_PLACEMENT_FILTERS:
      return {
        ...state,
        filters: initialFilters
      };

    case types.UPDATE_SELECTED_TERM_ID:
      return {
        ...state,
        selectedTermId: action.payload
      };

    case types.UPDATE_PLACEMENTS_MANAGER_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.UPDATE_SELECTED_PLACEMENT_SUCCESS:
      return {...state,
        items: updateItem(state.items, action.payload),
        filteredItems: updateItem(state.filteredItems, action.payload),
        isLoading: false
      };

    default:
      return state;
  }
};

/**
 * @description Filter `placements` based on passed `filters`
 * @param {Array<Placement>} placements
 * @param {Array<FilterObject>} filters
 * @return {Array<Placement>}
 */
function filterPlacements(
  placements: Array<Placement>,
  filters: Array<FilterObject>
) {
  if (placements.length) {
    // if there are placements to filter
    // return an array of filtered placements
    return _.filter(placements, el => filtersMatch(el, filters));
  }
  return placements; // return original items if
}

/**
 * @description Recursively find the attribute we're looking for
 * @param {Placement} item
 * @param {string} attribute
 * @return {string}
 */

function findAttribute(item, attribute) {
  let keys = attribute.split('.');

  if (keys.length > 1) {
    let attr = keys[0];
    // remove the first item
    keys.shift();

    if (item[attr] !== null) {
      return findAttribute(item[attr], keys.join('.'));
    } else {
      return '';
    }
  } else if (keys.length === 1) {
    let result = item[attribute] !== null ? item[attribute] : '';
    return result;
  } else {
    return item[attribute];
  }
}

/**
 * @description Return a Boolean indicating whether all `filters` for the given `placement` match
 * @param {Placement} placement
 * @param {Array<FilterObject>} filters
 * @return {Boolean}
 */
function filtersMatch(placement: Placement, filters: Array<FilterObject>) {
  let matches = true; // assume filters match - if any don't we flip this
  filters.forEach((filterObject: FilterObject) => {
    // if the attribue we're looking for is nested, we need to get the root attribute to filter against
    let filterAttribute = findAttribute(placement, filterObject.attrName);

    // check each `filterObject` against our `placement`
    if (
      !matching(
        filterAttribute,
        filterObject.value,
        filterObject.matchingMethod,
        true
      )
    ) {
      matches = false;
    }
    // we don't filter placements in the UI based on TermId - that is done in the API
    if (filterObject.attrName === 'TermId') {
      matches = true;
    }
  });
  return matches;
}

function updateFilterByAttrName(state, incomingFilterObject: FilterObject) {
  let exists = false;
  let { filters } = state;
  filters = _.map(filters, filterObject => {
    if (filterObject.attrName === incomingFilterObject.attrName) {
      exists = true;
      return { ...filterObject, ...incomingFilterObject };
    }
    return filterObject;
  });
  if (!exists) {
    filters.push(incomingFilterObject);
  }
  return { filters };
}

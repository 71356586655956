import {
  PlacementIcon,
  SiteIcon,
  PreceptorIcon,
  // LegalIcon,
  StashIcon,
  ApplicationIcon,
  RevertIcon,
  NoteIcon,
  TodoIcon
} from '../components/Icons';
export default [
  {
    slug: 'placement_slots',
    label: 'Placement Slots',
    icon: PlacementIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'site_search',
    label: 'Site Search',
    icon: SiteIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'preceptor_search',
    label: 'Preceptor Search',
    icon: PreceptorIcon,
    allowedRoles: ['system_admin']
  },
  // {
  //   slug: 'legal',
  //   label: 'Legal',
  //   icon: LegalIcon,
  //   allowedRoles: ['system_admin']
  // },
  {
    slug: 'placement_history',
    label: 'Student Placement History',
    icon: RevertIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'stashed_placement_slots',
    label: 'Stashed Placement Slots',
    icon: StashIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'notes',
    label: 'Notes',
    icon: NoteIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'application',
    label: 'Student Application',
    icon: ApplicationIcon,
    allowedRoles: ['system_admin']
  },
  {
    slug: 'todos',
    label: 'Todos',
    icon: TodoIcon,
    allowedRoles: ['system_admin']
  }
];

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Grid, Typography } from '@material-ui/core';

import LoadingIndicator from '../../../components/LoadingIndicator';
import ApplicationDetail from '../../Students/ApplicationDetail';
import { fetchPlacementApplication } from '../../../actions/creators/studentApplications';

import type { Placement } from '../../../schemas';

type Props = {
  classes: Object,
  isLoading: boolean,
  placement: Placement,
  fetchPlacementApplication: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  container: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
});

class ApplicationSection extends Component<Props> {

  fetchApplication = () => {
    const { placement, fetchPlacementApplication } = this.props;
    fetchPlacementApplication(
      {id: placement.StudentId},
      placement
    );
  };

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              Student Application
            </Typography>
          </Grid>
        </Grid>
        {isLoading && <LoadingIndicator />}
        {!isLoading && <ApplicationDetail />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placement: state.placementDrawer.selectedPlacement,
    isLoading: state.placementDrawer.isLoading,
  };
};

export default (compose(connect(mapStateToProps, {
  fetchPlacementApplication
}), withStyles(styles))(
  ApplicationSection
): any);

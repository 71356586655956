// @flow
import React from 'react';
import { Grid } from '@material-ui/core';

import type { PlacementApp } from '../../../schemas';
import OrphanPlacementApps from './OrphanPlacementApps';

type Props = {
  orphans: PlacementApp[]
};

function OrphansWidget(props: Props): React$Element<any> {
  const { orphans } = props;
  return (
    <Grid item xs={12} md={6}>
      <OrphanPlacementApps orphans={orphans} />
    </Grid>
  );
}

export default OrphansWidget;

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { createSite, updateSite, deleteSite } from '../../actions/creators/sites';
import { fetchStates } from '../../actions/creators/states';
import { fetchCounties } from '../../actions/creators/counties';
import { fetchCountries } from '../../actions/creators/countries';
import { fetchSpecialties } from '../../actions/creators/specialties';
import { fetchOrganizations } from '../../actions/creators/organizations';
import { fetchSiteTypes } from '../../actions/creators/siteTypes';

import { hideSiteDrawer } from '../../actions/creators/siteDrawer';

import SiteDetailForm from './SiteDetailForm';
import type { Site } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Props = {
  createSite: Function,
  updateSite: Function,
  deleteSite: Function,
  fetchStates: Function,
  fetchCounties: Function,
  fetchCountries: Function,
  fetchSpecialties: Function,
  fetchOrganizations: Function,
  fetchSiteTypes: Function,
  handleDelete: Function,
  hideSiteDrawer: Function,
  site: Site,
  canCreate: boolean,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object
};

class SiteDetail extends Component<Props> {

  componentDidMount() {
    const {
      fetchCounties,
      fetchStates,
      fetchCountries,
      fetchSpecialties,
      fetchOrganizations,
      fetchSiteTypes
    } = this.props;
    fetchCounties();
    fetchStates();
    fetchCountries();
    fetchSpecialties();
    fetchOrganizations();
    fetchSiteTypes();
  }

  handleSubmit = async site => {
    if (site.id) {
      site.GeocodeStatusId = null;
      await this.props.updateSite(site);
      this.props.hideSiteDrawer();
    } else {
      await this.props.createSite(site);
      this.props.hideSiteDrawer();
    }
  };

  handleDelete = async () => {
    const { site } = this.props;
    const confirmation = window.confirm(
      `Are you sure you want to delete the site "${site.name}"?`
    );
    if (confirmation) {
      await this.props.deleteSite(site);
      this.props.hideSiteDrawer();
    }
  };

  generateInitialValues = () => {
    return _.get(this.props, 'site', {
      CountryId: 'US',
      isRural: false,
      isUnderserved: false,
      isAhec: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <SiteDetailForm
          initialValues={this.generateInitialValues()}
          canCreate={this.props.canCreate}
          canUpdate={this.props.canUpdate}
          canDelete={this.props.canDelete}
          handleClose={() => this.props.hideSiteDrawer()}
          handleSubmit={values => this.handleSubmit(values)}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

export default (compose(
  withStyles(styles, { name: 'SiteDetail' }),
  connect(null, {
    createSite, updateSite, deleteSite,
    fetchStates, fetchCounties, fetchCountries,
    fetchSpecialties, fetchOrganizations, fetchSiteTypes,
    hideSiteDrawer
  })
)(SiteDetail): any);

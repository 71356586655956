// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Divider,
  Toolbar,
  IconButton,
  Typography,
  Select,
  Input,
  MenuItem
} from '@material-ui/core';

import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon, FirstPageIcon, LastPageIcon} from '../../components/Icons';

const styles = theme => ({
  toolbar: {
    display: 'flex'
  },
  title: {
    flex: 2
  },
  actions: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowsPerPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(2)
  }
});

type Classes = {
  root: Object,
  toolbar: Object,
  title: Object,
  actions: Object,
  rowsPerPage: Object,
  input: Object
};
type Props = {
  classes: Classes,
  rowsPerPage: number,
  handleRowsPerPageChange: Function,
  handlePageChange: Function,
  calculateItemRange: Function,
  currentPageIndex: number,
  lastPageIndex: number,
  collectionLength: number,
  title: string
};

class PaginatedListToolbar extends Component<Props> {
  render() {
    const {
      classes,
      rowsPerPage,
      handleRowsPerPageChange,
      handlePageChange,
      currentPageIndex,
      collectionLength,
      lastPageIndex,
      calculateItemRange,
      title
    } = this.props;

    const range = calculateItemRange(
      currentPageIndex,
      rowsPerPage,
      collectionLength
    );

    return (
      <div>
        <Divider />
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.actions}>
            <div className={classes.rowsPerPage}>
              <Typography>Rows per page: </Typography>
              <Select
                className={classes.input}
                value={rowsPerPage}
                onChange={e => {
                  handleRowsPerPageChange(e.target.value);
                }}
                input={<Input name="rowsPerPage" id="rowsPerPage" />}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </div>
            <Typography>
              {range.min + 1}-{range.max} of {collectionLength} items
            </Typography>
            <div>
              <IconButton
                onClick={() => {
                  handlePageChange(0);
                }}
              >
                <FirstPageIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  const prevPage =
                    currentPageIndex === 0 ? 0 : currentPageIndex - 1;
                  handlePageChange(prevPage);
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  const nextPage =
                    currentPageIndex === lastPageIndex
                      ? lastPageIndex
                      : currentPageIndex + 1;
                  handlePageChange(nextPage);
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handlePageChange(lastPageIndex);
                }}
              >
                <LastPageIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>
        <Divider />
      </div>
    );
  }
}

export default (compose(withStyles(styles))(PaginatedListToolbar): any);

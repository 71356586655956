// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { updateTerm } from '../../actions/creators/terms';
import AcademicTermDetailForm from './AcademicTermForm';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Term = {
  id: number,
  name: string,
  TermAttr: {
    startDate: ?string,
    endDate: ?string,
    numberOfWeeks: string,
    placementStartDate: ?string,
    placementEndDate: ?string,
    approvalDeadline: ?string,
    isActive: boolean
  }
};

type Props = {
  updateTerm: Function,
  handleDelete: Function,
  handleClose: Function,
  selectedTerm: Term,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object
};

class AcademicTermDetail extends Component<Props> {

  async handleSubmit(term: Term) {
    if (term.TermAttr.startDate && term.TermAttr.endDate) {
      term.TermAttr.numberOfWeeks = moment(term.TermAttr.endDate).diff(moment(term.TermAttr.startDate), 'weeks');
    }
    await this.props.updateTerm(term);
    this.props.handleClose();
  }

  render() {
    const { classes, selectedTerm } = this.props;
    const dateAttrs = ['endDate','startDate','placementStartDate','placementEndDate','approvalDeadline'];

    if (selectedTerm && !selectedTerm.TermAttr) {
      selectedTerm.TermAttr = {
        startDate: undefined,
        endDate: undefined,
        numberOfWeeks: '0',
        placementStartDate: undefined,
        placementEndDate: undefined,
        approvalDeadline: undefined,
        isActive: false
      };
    }

    if (selectedTerm && selectedTerm.TermAttr) {
      _.forIn(selectedTerm.TermAttr, function(value, key) {
        if (_.includes(dateAttrs, key)) {
          if (selectedTerm.TermAttr[key] != null)
            selectedTerm.TermAttr[key] = moment.parseZone(value).format('YYYY-MM-DD');
        }
      });
    }

    return (
      <div className={classes.root}>
        <AcademicTermDetailForm
          canUpdate={this.props.canUpdate}
          handleClose={this.props.handleClose}
          handleSubmit={values => this.handleSubmit(values)}
          initialValues={selectedTerm}
        />
      </div>
    );
  }
}

export default (compose(
  withStyles(styles, { name: 'AcademicTermDetail' }),
  connect(null, { updateTerm })
)(AcademicTermDetail): any);

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';

import type { Site, Placement } from '../../../schemas';

type State = {};

type Props = {
  classes: Object,
  site: Site,
  sitePlacements: Placement[]
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  siteContainer: {
    paddingLeft: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1)
  },
  chipContainer: {
    textAlign: 'right'
  },
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
});

class SiteDetails extends Component<Props, State> {

  renderPlacements = () => {
    const { sitePlacements, classes } = this.props;

    return(
      <Grid container spacing={2}>
        {sitePlacements.length === 0 && (
          <Typography className={classes.emptyMessage}>
            There are no placements associated with this site.
          </Typography>
        )}
        {sitePlacements.length > 0 &&
          sitePlacements.map(placement => {

            const studentName = _.get(placement, 'Student.fullName', 'N/A');
            const courseNumber = _.get(placement, 'Course.CatalogNumberId', 'N/A');
            const courseName = _.get(placement, 'Course.description', 'N/A');
            const termName = _.get(placement, 'Term.name', 'N/A');
            const manager = _.get(placement, 'managerId', 'N/A');

            return (
              <Grid item xs={12} sm={6} md={4} key={placement.id}>
                <Card className={classes.card}>
                  <CardHeader
                    title={
                      <Typography variant="h5" component="h2">
                        {studentName}
                      </Typography>
                    }
                    subheader={
                      <div>
                        <Typography variant="caption" gutterBottom>
                          N.{courseNumber} &bull; {courseName}
                        </Typography>
                      </div>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          <strong>Term:</strong> {termName}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Manager:</strong> {manager}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Site Placement History
        </Typography>
        {this.renderPlacements()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    site: state.siteDrawer.selectedSite,
    sitePlacements: _.orderBy(state.siteDrawer.selectedSitePlacements, ['Term.name'], ['desc'])
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  SiteDetails
): any);

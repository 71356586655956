import * as actions from '../types';
import axios from '../../api';
import { handleError } from '../../utils/helpers';
import {fetchPlacementAudits} from './placementAudits';

let cachedTermId;

export const fetchPlacementBoard = termId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENTS, payload: { TermId: termId } });
    const res = await axios.get(`/placement/board/${termId}`);
    dispatch({ type: actions.FETCH_PLACEMENTS_SUCCESS, payload: res.data });
    return dispatch({ type: actions.FILTER_PLACEMENTS, payload: res.data });
  } catch (err) {
    dispatch({ type: actions.FETCH_PLACEMENTS_ERROR, payload: err.response.data });
  }
};

export const fetchPlacements = (termId, sparse=false) => async dispatch => {
  if (termId) cachedTermId = termId;
  else termId = cachedTermId;
  try {
    dispatch({ type: actions.FETCH_PLACEMENTS, payload: { termId, sparse } });

    const uri = sparse ? '/placement/sparse' : '/placement/cardList';
    const res = await axios.get(uri, { params: { TermId: termId } });

    dispatch({ type: actions.FETCH_PLACEMENTS_SUCCESS, payload: res.data });

    return dispatch({ type: actions.FILTER_PLACEMENTS, payload: res.data });
  } catch (err) {
    handleError(dispatch, actions.FETCH_PLACEMENTS_ERROR, err);
  }
};

export const createPlacement = placement => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_PLACEMENT, payload: placement });
    const res = await axios.post('/placement', placement);
    dispatch({ type: actions.CREATE_PLACEMENT_SUCCESS, payload: res.data });
    dispatch(fetchPlacementBoard(placement.TermId));
    dispatch(fetchPlacementAudits(placement.TermId));
  } catch (err) {
    dispatch({ type: actions.CREATE_PLACEMENT_ERROR, payload: err });
  }
};

export const updateSelectedPlacement = (placement, termId) => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_SELECTED_PLACEMENT, payload: {placement, termId} });
    const endpoint = `/placement/${placement.id}`;
    const res = await axios.put(endpoint, placement);
    dispatch({ type: actions.UPDATE_SELECTED_PLACEMENT_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.UPDATE_SELECTED_PLACEMENT_ERROR,
      payload: error.response.data
    });
  }
};

export const toggleFilters = () => {
  return { type: actions.TOGGLE_PLACEMENT_FILTERS };
};

export const enableFilters = () => {
  return { type: actions.ENABLE_PLACEMENT_FILTERS };
};

export const disableFilters = () => {
  return { type: actions.DISABLE_PLACEMENT_FILTERS };
};

export const filterPlacements = () => async dispatch => {
  dispatch({ type: actions.FILTER_PLACEMENTS });
};

export const updateFilters = filterObject => async dispatch => {
  dispatch({ type: actions.UPDATE_PLACEMENT_FILTERS, payload: filterObject });
  dispatch({ type: actions.FILTER_PLACEMENTS });
};

export const clearFilters = () => dispatch => {
  dispatch({ type: actions.CLEAR_PLACEMENT_FILTERS });
  dispatch({ type: actions.FILTER_PLACEMENTS });
};

export const updateSelectedTermId = termId => dispatch => {
  dispatch({ type: actions.UPDATE_SELECTED_TERM_ID, payload: termId });
};

export const updatePlacementsManager = (managerId, placementIds, selectedTermId) => async dispatch => {
  try {
    const endpoint = `/placement/bulkAssign`;
    await axios.put(endpoint, {
      managerId: managerId,
      placementIds: placementIds
    });
    dispatch({ type: actions.UPDATE_PLACEMENTS_MANAGER_SUCCESS });
    return dispatch(fetchPlacements(selectedTermId,true));
  } catch (error) {
    dispatch({
      type: actions.UPDATE_PLACEMENTS_MANAGER_ERROR,
      payload: error.response.data
    });
  }
};

export const mergePlacements = options => async dispatch => {
  try {
    dispatch({ type: actions.MERGE_PLACEMENTS, payload:  options });
    await axios.post('/placement/merge', options);
    dispatch({ type: actions.MERGE_PLACEMENTS_SUCCESS });
    dispatch(fetchPlacements(cachedTermId, true));
  } catch (err) {
    dispatch({
      type: actions.MERGE_PLACEMENTS_ERROR,
      payload: err.response.data
    });
  }
};

// @flow

import * as React from 'react';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { Select as MuiSelect } from '@material-ui/core';
import { FieldProps } from 'formik';

import {
  MenuItem, FormControl, FormHelperText, InputLabel, Input
} from '@material-ui/core';

type FieldPropTypes = typeof FieldProps;
type MuiSelectPropTypes = typeof MuiSelectProps;

type Props = {
  ...FieldPropTypes,
  ...MuiSelectPropTypes
}

const Select: React.ComponentType<Props> = ({
  field,
  form,
  ...props
}) => {
  const { name } = field;
  const { options, label } = props;
  const { touched, errors } = form;

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? '' : field.value;
  props.value = field.value;

  const handleChange = e => {
    if (props.onChange) {
      field.onChange(e);
      props.onChange(e);
    }
    field.onChange(e);
  };

  return (
    <FormControl error={touched[name] && !!errors[name]} {...props}>
      <InputLabel htmlFor={`${name}-select`}>{label}</InputLabel>
      <MuiSelect
        {...props}
        {...field}
        onChange={handleChange}
        input={<Input id={`${name}-select`} />}
        error={touched[name] && !!errors[name]}
      >
        {options && options.map(el => (
          <MenuItem key={el.id} value={el.id}>
            {el.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {!!errors[name] && !!touched[name] && (<FormHelperText id={`${name}-select`}>{errors[name]}</FormHelperText>)}
    </FormControl>
  );
};

Select.displayName = 'FormikMuiSelect';

export default Select;

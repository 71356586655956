import axios from '../../api';
import * as actions from '../types';

export const fetchCurrentUserSettings = () => async dispatch => {
  dispatch({ type: actions.FETCH_CURRENT_USER_SETTINGS });
  try {
    let { data } = await axios.get('/userSetting/current');
    return dispatch({
      type: actions.FETCH_CURRENT_USER_SETTINGS_SUCCESS,
      payload: data
    });
  } catch (err) {
    return dispatch({
      type: actions.FETCH_CURRENT_USER_SETTINGS_ERROR,
      payload: err
    });
  }
};

export const updateCurrentUserSettings = userSetting => async dispatch => {
  try {
    const endpoint = `/userSetting/current`;
    await axios.put(endpoint, userSetting);
    dispatch({ type: actions.UPDATE_CURRENT_USER_SETTING_SUCCESS });
    dispatch(fetchCurrentUserSettings());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_CURRENT_USER_SETTING_ERROR,
      payload: error.response.data
    });
  }
};

import axios from '../../api';
import * as actions from '../types';

export const fetchTouchpoints = data => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_TOUCHPOINTS, payload: data });
    const res = await axios.get(`/touchpoint/${data.model}/${data.TouchableId}`);
    dispatch({ type: actions.FETCH_TOUCHPOINTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_TOUCHPOINTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchTouchpoint = data => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_TOUCHPOINT, payload: data });
    const res = await axios.get(`/touchpoint/${data.id}`);
    dispatch({ type: actions.FETCH_TOUCHPOINT_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_TOUCHPOINT_ERROR,
      payload: error.response.data
    });
  }
};

export const createTouchpoint = data => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_TOUCHPOINT, payload: data });
    const res = await axios.post('/touchpoint', data);
    dispatch({ type: actions.CREATE_TOUCHPOINT_SUCCESS, payload: res.data });
    dispatch(fetchTouchpoints(data));
  } catch (error) {
    dispatch({
      type: actions.CREATE_TOUCHPOINT_ERROR,
      payload: error.response.data
    });
  }
};

export const updateTouchpoint = log => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_TOUCHPOINT, payload: log });
    const endpoint = `/touchpoint/${log.id}`;
    const res = await axios.put(endpoint, log);
    dispatch({ type: actions.UPDATE_TOUCHPOINT_SUCCESS, payload: res.data });
    dispatch(fetchTouchpoints({id: log.SiteId}));
  } catch (error) {
    dispatch({
      type: actions.UPDATE_TOUCHPOINT_ERROR,
      payload: error.response.data
    });
  }
};

export const deleteTouchpoint = log => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_TOUCHPOINT, payload: log });
    const endpoint = `/touchpoint/${log.id}`;
    const res = await axios.delete(endpoint);
    dispatch({ type: actions.DELETE_TOUCHPOINT_SUCCESS, payload: res.data });
    dispatch(fetchTouchpoints({id: log.SiteId}));
  } catch (error) {
    dispatch({
      type: actions.DELETE_TOUCHPOINT_ERROR,
      payload: error.response.data
    });
  }
};

export const clearTouchpoints = () => {
  return { type: actions.CLEAR_TOUCHPOINTS, payload: null };
};

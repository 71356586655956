// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Grid,TableCell,TableRow,Typography,Button} from '@material-ui/core';

import {Formik,Form,Field} from 'formik';
import {Checkbox} from '../../Formik/';
import {TextField} from 'formik-material-ui';

import type { PreceptorEnrollment } from '../../../schemas';

type Props = {
  initialValues: Object,
  item: PreceptorEnrollment,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {},
    centered: {
      textAlign: 'center'
    },
    button: {
      marginLeft: theme.spacing(1)
    },
    form: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  };
});

function PreceptorEnrollmentItemForm(props: Props): React$Element<any> {
  const {item,initialValues,handleSubmit} = props;
  const classes = useStyles();

  const preceptorName = _.has(item, 'Preceptor')
    ? item.Preceptor.name : 'N/A';

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
    >
      {({ values }) => (
        <TableRow>
          <TableCell colSpan={5}>
            <Form className={classes.form}>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={3}>
                  <Typography variant="subtitle1">{preceptorName}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <Field
                    fullWidth
                    required
                    name="preceptorHoursAvailable"
                    label="Hours Available"
                    type="number"
                    component={TextField}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Field
                    checked={values.hasVerbalCommitment}
                    name="hasVerbalCommitment"
                    label="Verbal Commitment"
                    component={Checkbox}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Button
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </TableCell>
        </TableRow>
      )}
    </Formik>
  );
}

export default PreceptorEnrollmentItemForm;

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Grid, Typography } from '@material-ui/core';

import NoteList from '../../Note/NoteList';

import CreateNoteButton from '../../../components/Note/CreateNoteButton';

import {
  deleteNote,
  fetchContractNotes
} from '../../../actions/creators/notes';

import type { Contract, Note } from '../../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  isLoading: boolean,
  notes: Note[],
  deleteNote: Function,
  fetchContractNotes: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  }
});

class Notes extends Component<Props> {
  fetchNotes = () => {
    const { contract, fetchContractNotes } = this.props;
    fetchContractNotes(contract.id);
  };
  handleDelete = async note => {
    const { deleteNote, fetchContractNotes, contract } = this.props;
    let result = await deleteNote(note);
    if (result) {
      fetchContractNotes(contract.id);
    }
  };
  render() {
    const { classes, notes, contract } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              Notes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.buttonContainer}>
            <CreateNoteButton
              model="ContractRequest"
              foreignKey={contract.id}
              afterCreate={this.fetchNotes}
            />
          </Grid>
        </Grid>
        {notes && <NoteList notes={notes} afterAction={this.fetchNotes} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.contractDrawer.selectedContract,
    notes: state.contractDrawer.notes
  };
};

export default (compose(
  connect(mapStateToProps, { deleteNote, fetchContractNotes }),
  withStyles(styles)
)(Notes): any);

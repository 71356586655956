import axios from '../../api';
import * as actions from '../types';

export const fetchRoles = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ROLES, payload: undefined });
    const res = await axios.get('/role');
    dispatch({ type: actions.FETCH_ROLES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ROLES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchRole = role => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ROLE, payload: undefined });
    const res = await axios.get(`/role/${role.id}`);
    dispatch({ type: actions.FETCH_ROLE_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ROLE_ERROR,
      payload: error.response.data
    });
  }
};

export const createRole = role => async dispatch => {
  try {
    await axios.post('/role', role);
    dispatch({ type: actions.CREATE_ROLE_SUCCESS });
    dispatch(fetchRoles());
  } catch (error) {
    dispatch({
      type: actions.CREATE_ROLE_ERROR,
      payload: error.response.data
    });
  }
};

export const updateRole = role => async dispatch => {
  try {
    const endpoint = `/role/${role.id}`;
    await axios.put(endpoint, role);
    dispatch({ type: actions.UPDATE_ROLE_SUCCESS });
    dispatch(fetchRoles());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_ROLE_ERROR,
      payload: error.response.data
    });
  }
};

export const deleteRole = role => async dispatch => {
  try {
    const endpoint = `/role/${role.id}`;
    await axios.delete(endpoint);
    dispatch({ type: actions.DELETE_ROLE_SUCCESS });
    dispatch(fetchRoles());
  } catch (error) {
    dispatch({
      type: actions.DELETE_ROLE_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Grid} from '@material-ui/core';

import PlacementLane from './PlacementLane';
import PlacementCard from './PlacementCard';

type Props = {
  data: Object,
  onCardClick: Function
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default
    },
    lanesContainer: {
      height: 'calc(100vh - 265px)',
      overflowX: 'auto',
      overflowY: 'hidden',
      boxSizing: 'border-box',
      width: 1260
    }
  };
});

function PlacementsBoard(props: Props): React$Element<"div"> {
  const {data,onCardClick} = props;
  const classes = useStyles();

  const lanes = _.get(data, 'lanes', []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.lanesContainer}>
            {lanes.map(lane => (
              <PlacementLane key={lane.id} data={lane} onCardClick={onCardClick}>
                <PlacementCard />
              </PlacementLane>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PlacementsBoard;

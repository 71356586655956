// @flow

import {
  FETCH_ADDENDA,
  FETCH_ADDENDA_SUCCESS,
  FETCH_ADDENDA_ERROR
} from '../actions/types';

import type { Addendum } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Addendum[]
};

type Action = {
  type:
    | typeof FETCH_ADDENDA
    | typeof FETCH_ADDENDA_SUCCESS
    | typeof FETCH_ADDENDA_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_ADDENDA:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ADDENDA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_ADDENDA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

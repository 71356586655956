// @flow

import _ from 'lodash';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {Grid,Typography,Button} from '@material-ui/core';
import DataGrid, {Column} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import GridDefaults from '../../utils/data-grid-defaults';
import OrganizationAddDialog from '../../components/OrganizationDrawer/OrganizationAddDialog';
import OrganizationDrawer from '../../components/OrganizationDrawer/OrganizationDrawer';
import {SearchIcon} from '../../components/Icons';

import {fetchPermissions} from '../../actions/creators/permissions';
import {fetchOrganizationList} from '../../actions/creators/organizations';
import {fetchStates} from '../../actions/creators/states';
import {fetchCountries} from '../../actions/creators/countries';
import {
  showOrganizationDrawer,fetchSelectedOrganization
} from '../../actions/creators/organizationDrawer';

import {getAllOrganizations} from '../../selectors/organizations';
import {getAllStateValues} from '../../selectors/states';
import {getAllCountryValues} from '../../selectors/countries';
import {getCurrentUserRoles} from '../../selectors/users';
import {getOrganizationPermissions} from '../../selectors/permissions';

import {hasAccess} from '../../utils/permissionsCheck';

type WithStylesProps = {|
  classes: {
    root: Object,
    smallIcon: Object
  }
|};

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  smallIcon: {
    paddingLeft: 0,
    marginBottom: -4,
    width: 16,
    height: 16
  },
});

const Organizations = ({classes}: WithStylesProps) => {
  const dispatch = useDispatch();
  window.onresize = () => dataGrid.updateDimensions();

  const [dataGrid, setDataGrid] = React.useState({});
  const [searchValue, setSearchValue] = React.useState(undefined);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const currentUserRoles = useSelector(getCurrentUserRoles);
  const permissions = useSelector(getOrganizationPermissions);
  const organizations = useSelector(getAllOrganizations);
  const states = useSelector(getAllStateValues);
  const countries = useSelector(getAllCountryValues);
  const dataSource = new DataSource({ store: organizations, reshapeOnPush: true });

  React.useEffect(() => {
    Promise.all([
      dispatch(fetchPermissions()),
      dispatch(fetchStates()),
      dispatch(fetchCountries()),
      dispatch(fetchOrganizationList()),
    ]);
    // eslint-disable-next-line
  }, []);

  const canCreateOrganization = () =>
    hasAccess(currentUserRoles, _.get(permissions, 'create', []));

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleRowClick = async props => {
    if (props.rowType === 'data' || !props.data)
      if (props.data) {
        await dispatch(fetchSelectedOrganization(props.data));
        dispatch(showOrganizationDrawer());
      }
      else dispatch(showOrganizationDrawer());
  };

  const handleContentReady = async props => {
    const count = await props.component.totalCount();
    if (count > 0) setButtonDisabled(true);
    else setButtonDisabled(false);
  };

  const handleOptionsChanges = props => {
    if(props.fullName === 'searchPanel.text') setSearchValue(props.value);
  };

  return (
    <Grid
      container
      spacing={1}
      alignContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography variant="h5" paragraph>
          Organizations
        </Typography>
      </Grid>
      {canCreateOrganization() && <Grid item xs={12}>
        <Typography
          gutterBottom={true}
          variant="caption"
          color="textSecondary"
        >
          To create a new Organization, you must first ( {<SearchIcon className={classes.smallIcon} />} Search Organizations... ) to determine if one already exists.
        </Typography>
      </Grid>}
      {canCreateOrganization() && <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          style={{ width: '100%', height: 64 }}
          onClick={handleDialogOpen}
          disabled={buttonDisabled}
        >
          New Organization
        </Button>
      </Grid>}
      <Grid item xs={12}>
        <DataGrid
          {...GridDefaults}
          onInitialized={el => setDataGrid(el.component)}
          dataSource={dataSource}
          width="100%"
          selection={{ mode: 'none' }}
          groupPanel={{ visible: true }}
          grouping={{ contextMenuEnabled: true }}
          loadPanel={{ enabled: true }}
          allowColumnResizing={true}
          columnAutoWidth={true}
          searchPanel={{
            visible: true,
            width: 240,
            placeholder: 'Search Organizations...',
            highlightSearchText: false,
          }}
          export={{
            enabled: true,
            allowExportSelectedData: false,
            fileName: 'organizations-list',
          }}
          hoverStateEnabled={true}
          onContentReady={handleContentReady}
          onOptionChanged={handleOptionsChanges}
          onRowClick={handleRowClick}
        >
          <Column
            dataField="id"
            dataType="number"
            alignment="center"
            allowSearch={false}
            width={80}
          />
          <Column
            dataField="name"
            width={400}
          />
          <Column
            dataField="city"
            allowSearch={false}
            alignment="center"
          />
          <Column
            dataField="StateId"
            caption="State"
            allowSearch={false}
            alignment="center"
            lookup={{
              dataSource: () => states,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            dataField="postalCode"
            allowSearch={false}
            alignment="center"
          />
          <Column
            dataField="CountryId"
            caption="Country"
            allowSearch={false}
            alignment="center"
            lookup={{
              dataSource: () => countries,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            dataField="isActive"
            dataType="boolean"
            allowSearch={false}
            trueText="Active"
            falseText="Inactive"
          />
        </DataGrid>
      </Grid>
      <OrganizationDrawer />
      <OrganizationAddDialog
        open={dialogOpen}
        searchValue={searchValue}
        onClose={handleDialogClose}
        handleClose={handleDialogClose}
      />
    </Grid>
  );
};

export default (withStyles(styles)(Organizations): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { indigo } from '@material-ui/core/colors';
import AppContainer from './AppContainer';
import { CookiesProvider } from 'react-cookie';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { fetchUserSettingTypes } from '../actions/creators/userSettingTypes';
import type { UserSetting, UserSettingType } from '../schemas';

type State = {
  theme: Object,
};

type Props = {
  fetchUserSettingTypes: Function,
  userSettings: Array<UserSetting>,
  userSettingTypes: Array<UserSettingType>
};

class ThemeContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    let themeName = 'light';
    if (props.userSettings && props.userSettings.length > 0) {
      const themeUserSetting = _.find(
        props.userSettings,
        o =>
          o.UserSettingType &&
          o.UserSettingType.slug &&
          o.UserSettingType.slug === 'theme'
      );
      themeName =
        themeUserSetting && themeUserSetting.value
          ? themeUserSetting.value
          : 'light';
    }
    this.state = {
      theme: createTheme({
        typography: {
          useNextVariants: true,
        },
        palette: {
          type: themeName,
          indigo: indigo
        }
      })
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const userSettingsChanged = this.props.userSettings &&
      this.props.userSettings !== prevProps.userSettings;
    const userSettingTypesChanged = this.props.userSettingTypes &&
      this.props.userSettingTypes !== prevProps.userSettingTypes;
    const userSettings = userSettingsChanged
      ? this.props.userSettings
      : prevProps.userSettings;
    const userSettingTypes = userSettingTypesChanged
      ? this.props.userSettingTypes
      : prevProps.userSettingTypes;
    if (userSettingsChanged || userSettingTypesChanged) {
      this.setTheme(userSettings, userSettingTypes);
    }
  }

  loadData = async () => {
    await this.props.fetchUserSettingTypes();
  };

  getUserThemeSettingValue(userSettings, userSettingTypes) {
    let themeSettingValue = 'light';
    const userSettingType = _.find(userSettingTypes, {
      slug: 'theme'
    });
    const userSettingTypeId = userSettingType && userSettingType.id;
    if (userSettingTypeId) {
      const themeSetting = _.find(userSettings, {
        UserSettingTypeId: userSettingTypeId
      });
      if (themeSetting) {
        themeSettingValue = themeSetting.value;
      }
    }
    return themeSettingValue;
  }

  setTheme(userSettings, userSettingTypes) {
    const themeUserSetting = this.getUserThemeSettingValue(
      userSettings,
      userSettingTypes
    );
    const themeName = themeUserSetting ? themeUserSetting : 'light';
    this.setState({
      theme: createTheme({
        typography: {
          useNextVariants: true,
        },
        palette: {
          type: themeName,
          indigo: indigo
        }
      })
    });
  }

  render() {
    return (
      <CookiesProvider>
        <ThemeProvider theme={this.state.theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppContainer />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </CookiesProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSettings: _.get(state.auth.currentUser, 'UserSettings', []),
    userSettingTypes: state.userSettingTypes.items
  };
}

// export default ThemeContainer;
export default (connect(mapStateToProps, { fetchUserSettingTypes })(ThemeContainer): any);

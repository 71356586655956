import * as actions from '../types';
import { searchLocation } from './locations';
import { getValidContractsForPlacement } from '../../utils/placementHelpers';
import axios from '../../api';
import _ from 'lodash';
import { updateSelectedPlacement } from './placements';
import { notify } from 'reapop';

export const showPlacementDrawer = () => async dispatch => {
  dispatch({ type: actions.SHOW_PLACEMENT_DRAWER });
};

export const hidePlacementDrawer = selectedPlacement => async dispatch => {
  dispatch({ type: actions.HIDE_PLACEMENT_DRAWER, payload: selectedPlacement });
  dispatch(updateSelectedPlacement(selectedPlacement));
};

export const changeSection = section => {
  return { type: actions.CHANGE_PLACEMENT_DRAWER_SECTION, payload: section };
};

export const addToWorkspace = item => async dispatch => {
  try {
    let { data } = await axios.post(`/workspaceItem`, item);
    dispatch(fetchSelectedPlacement(data.PlacementId, false));
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: `Could not add ${item.name} to the workspace.`,
        status: 'error',
      })
    );
  }
};

export const removeFromWorkspace = item => async dispatch => {
  try {
    await axios.delete(`/workspaceItem/${item.id}`);
    dispatch(fetchSelectedPlacement(item.PlacementId, false));
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: `Could not remove ${item.name} from the workspace.`,
        status: 'error',
      })
    );
  }
};

export const fetchSelectedPlacement = (id, includeSearch) => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_PLACEMENT, payload: {id,includeSearch} });
    const res = await axios.get(`/placement/${id}`);
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_SUCCESS,
      payload: res.data
    });
    dispatch(fetchPlacementNotes(res.data));
    dispatch(fetchPlacementAttachmentAs(res.data.id));
    dispatch(fetchPlacementAttachments(res.data));
    if (includeSearch) {
      const defaultSearchOptions = {
        searchKey: 'postal_code',
        searchPostalCode: res.data.postalCode,
        searchCity: _.get(res.data, 'Student.mailingCity', ''),
        searchState: _.get(res.data, 'Student.mailingState', ''),
        searchRadius: 10
      };
      dispatch(searchLocation(defaultSearchOptions));
      dispatch(fetchStudentHistory(res.data));
    }
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_ERROR,
      payload: error.response.data
    });
  }
};

export const mockSelectedPlacement = placementData => dispatch => {
  try {
    dispatch({ type: actions.MOCK_SELECTED_PLACEMENT, payload: placementData });
    const selectedPlacement = placementData;
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_SUCCESS,
      payload: selectedPlacement
    });

    dispatch(fetchPlacementNotes(selectedPlacement));
    dispatch(fetchPlacementAttachmentAs(selectedPlacement.id));
    dispatch(fetchPlacementAttachments(selectedPlacement));
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStashedPlacementSlots = courseId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STASHED_PLACEMENT_SLOTS });
    let res = await axios.get('/siteEnrollment/stash', {
      params: {
        CourseId: courseId
      }
    });
    dispatch({
      type: actions.FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STASHED_PLACEMENT_SLOTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudentHistory = selectedPlacement => async dispatch => {
  try {
    const { StudentId } = selectedPlacement;

    dispatch({ type: actions.FETCH_STUDENT_HISTORY });
    let res = await axios.get('/placement', {
      params: { StudentId }
    });

    // filter out the current placement
    // and any placements that don't have a package
    // and any placements that don't have a course
    let history = res.data.filter(
      placement =>
        placement.id !== selectedPlacement.id &&
        placement.PackageId !== null &&
        placement.Course !== null &&
        placement.TermId < selectedPlacement.TermId
    );

    history = _.orderBy(history, ['TermId', 'desc']);
    history = _.groupBy(history, 'Term.name');

    dispatch({
      type: actions.FETCH_STUDENT_HISTORY_SUCCESS,
      payload: history
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENT_HISTORY_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPlacementNotes = placement => async dispatch => {
  try {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_NOTES,
      payload: undefined
    });

    // placement notes
    const { data } = await axios.get(`/note/Placement/${placement.id}`);
    // site notes for any attached sites (via SiteEnrollments)
    const siteNotes = await fetchSiteNotes(placement);
    const notes = [...data, ...siteNotes];

    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS,
      payload: notes
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_NOTES_ERROR,
      payload: error.response.data
    });
  }
};

// fetch the site notes for a placement
const fetchSiteNotes = async placement => {
  // get the unique attached sites from the placement's siteEnrollments
  let placementSites = _.compact(
    _.uniq(
      _.get(placement, 'Package.SiteEnrollments', []).map(enrollment => {
        return enrollment.Site;
      })
    )
  );

  // fetch all the notes for all of the placementSites
  const results = await Promise.all(
    placementSites.map(site => axios.get(`/note/Site/${site.id}`))
  );

  // enhance the note with the site name
  const detailedSiteNotes = results.map(result => {
    const notes = result.data;
    return notes.map(note => enhanceNote(note, placementSites));
  });

  // return a flattened array with the enhanced notes
  return _.flatten(detailedSiteNotes);
};

// adds the site name to the meta attribute of the note (for display purposes)
const enhanceNote = (note, placementSites) => {
  let site = _.find(placementSites, { id: note.foreignKey });
  note.meta = site.name;
  return note;
};

export const fetchPlacementAttachmentAs = PlacementId => async dispatch => {
  try {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A,
      payload: undefined
    });
    const res = await axios.get('/attachmentA', { params: { PlacementId } });
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPlacementAttachments = placement => async dispatch => {
  const contracts = getValidContractsForPlacement(placement);
  const contractIDs = _.uniq(_.map(contracts, 'id'));

  // if we've got valid contracts, fetch their attachments
  if (contractIDs.length > 0) {
    try {
      dispatch({
        type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS,
        payload: undefined
      });
      const promises = contractIDs.map(ContractId => {
        return axios.get('/attachment', {
          params: {
            ContractId
          }
        });
      });

      const results = await Promise.all(promises);

      const attachments = _.flatten(results.map(result => result.data));
      // put the attachments into the store
      dispatch({
        type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS,
        payload: attachments
      });
    } catch (error) {
      dispatch({
        type: actions.FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR,
        payload: error.response.data
      });
    }
  }
};

export const setSiteFilters = values => async dispatch => {
  try {
    dispatch({ type: actions.SET_SITE_FILTERS, payload: values });
  } catch (err) {
    dispatch({ type: actions.SET_SITE_FILTERS_ERROR, payload: err.message });
  }
};

export const setPreceptorFilters = values => async dispatch => {
  try {
    dispatch({ type: actions.SET_PRECEPTOR_FILTERS, payload: values });
  } catch (err) {
    dispatch({ type: actions.SET_PRECEPTOR_FILTERS_ERROR, payload: err.message });
  }
};

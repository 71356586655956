// @flow

import _ from 'lodash';

import * as React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {DropTarget} from 'react-dnd';
import * as DNDTypes from '../../../schemas/dndTypes';

import {removeFromWorkspace} from '../../../actions/creators/placementDrawer';
import {enrollSite} from '../../../actions/creators/placementSlots';

import LoadingIndicator from '../../LoadingIndicator';
import CommonStyles from '../../../styles/CommonStyles';

type Props = {
  canDrop: boolean,
  isOver: boolean,
  connectDropTarget: Function,
};

const useStyles = makeStyles(theme => {
  const commonStyles = CommonStyles(theme);
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    },
    dropZone: commonStyles.dropZone,
    dropZoneCanDrop: commonStyles.dropZoneCanDrop,
    dropZoneActive: commonStyles.dropZoneActive,
    blankMessage: {
      padding: theme.spacing(2)
    },
    fakeSiteEnrollment: {
      opacity: 0.3,
      alignSelf: 'flex-start',
      padding: theme.spacing(2)
    }
  };
});

const canEnrollSite = (placement,workspaceItem) => {
  const enrolledSiteIDs = _.map(placement.Package.SiteEnrollments, 'SiteId');
  let found = enrolledSiteIDs.find(enrolledId => {
    return enrolledId === workspaceItem.foreignKey;
  });

  return found === undefined;
};

// Drag-and-drop spec/collect functions needed for the DragSource
let loading: boolean = false;
const dropTargetSpec = {
  drop(props, monitor) {
    const workspaceItem = monitor.getItem();
    const {currentUser,enrollSite,removeFromWorkspace,selectedPlacement} = props;

    if (workspaceItem.model === 'site') {
      if (canEnrollSite(selectedPlacement,workspaceItem)) {
        loading = true;
        enrollSite(selectedPlacement,workspaceItem,currentUser)
          .then(() => removeFromWorkspace(workspaceItem))
          .then(() => { loading = false; })
          .then(() => { return undefined; });
      }
    }
  },
  canDrop(props, monitor) {
    const workspaceItem = monitor.getItem();
    return workspaceItem.model === 'site';
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    isOver: monitor.isOver(),
    workspaceItem: monitor.getItem()
  };
}

function PlacementSlotDropzone(props: Props) {
  const {connectDropTarget,canDrop,isOver} = props;
  const classes = useStyles();

  const isActive = isOver && canDrop;

  let dropZoneClass = 'dropZone';
  if (isActive) dropZoneClass = 'dropZoneActive';
  else if (canDrop) dropZoneClass = 'dropZoneCanDrop';

  return connectDropTarget(
    <div className={classes.root}>
      <div className={classes[dropZoneClass]}>
        <p className={classes.blankMessage}>
          Drag a site here to create a Placement Slot.
        </p>
        <LoadingIndicator loading={loading} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  selectedPlacement: state.placementDrawer.selectedPlacement
});

export default (compose(
  connect(mapStateToProps, {enrollSite,removeFromWorkspace}),
  DropTarget(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dropTargetSpec, collect)
)(PlacementSlotDropzone): any);

// @flow

import _ from 'lodash';

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {Grid,Paper,Typography,CircularProgress,Divider,} from '@material-ui/core';

import {searchLocation,searchCachedLocation} from '../../../actions/creators/locations';
import {setPreceptorFilters} from '../../../actions/creators/placementDrawer';

import LocationSearchForm from '../../../components/PlacementDrawer/LocationSearchForm';
import PreceptorFilterForm from '../../../components/PlacementDrawer/PreceptorSearch/PreceptorFilterForm';
import PlacementDrawerWorkspace from '../../../components/PlacementDrawer/PlacementDrawerWorkspace';
import PreceptorListItem from '../../../components/PlacementDrawer/PreceptorSearch/PreceptorListItem';
import PaginatedList from '../../../components/PaginatedList';
import AddPreceptorButton from '../../../components/Preceptor/AddPreceptorButton/';

import type { Preceptor, Placement, ValueListItem } from '../../../schemas';

type FilterValues = {
  name: string,
  credentials: string,
  specialties: []
};

type Props = {
  classes: Object,
  placement: Placement,
  locationPreceptors: Preceptor[],
  searchLocation: Function,
  searchCachedLocation: Function,
  filterValues: FilterValues,
  isLoading: boolean,
  specialtyValues: ValueListItem[],
  setPreceptorFilters: Function,
  stateValues: any[],
};

type State = {
  filteredPreceptors: Preceptor[],
  initialSearchValues: Object,
  initialFilterValues: FilterValues,
};

const initialFilterValues = {
  name: '',
  credentials: '',
  specialties: []
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    ...theme.typography.h5,
    marginRight: theme.spacing(1)
  },
  subheading: theme.typography.subheading,
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  content: {
    flex: 3
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2)
  }
});

class PreceptorSearchSection extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      filteredPreceptors: props.locationPreceptors,
      initialSearchValues: {
        searchKey: 'postal_code',
        searchPostalCode: props.placement.postalCode,
        searchCity: _.get(props.placement, 'Student.mailingCity', ''),
        searchState: _.get(props.placement, 'Student.mailingState', ''),
        searchRadius: 10
      },
      initialFilterValues: initialFilterValues,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      prevProps.locationPreceptors !== this.props.locationPreceptors
    ) {
      this.filterPreceptors();
    }
  }

  filterPreceptors = async values => {
    if (values) {
      await this.props.setPreceptorFilters(values);
    }

    let filterValues = this.props.filterValues;
    let filteredPreceptors = this.props.locationPreceptors;

    // preceptor name
    if (filterValues.name !== '') {
      filteredPreceptors = filteredPreceptors.filter(preceptor => {
        return (
          preceptor.name
            .toLowerCase()
            .indexOf(filterValues.name.toLowerCase()) >= 0
        );
      });
    }

    // preceptor credentials
    if (filterValues.credentials !== '') {
      filteredPreceptors = filteredPreceptors.filter(preceptor => {
        return (
          preceptor.credentials
            .toLowerCase()
            .indexOf(filterValues.credentials.toLowerCase()) >= 0
        );
      });
    }

    // preceptor specialties
    if (filterValues.specialties.length > 0) {
      const { specialtyValues } = this.props;
      const specialtyIds = filterValues.specialties;

      const foundValues = specialtyValues.filter(specialtyValue => {
        const found = specialtyIds.find(id => id === specialtyValue.id);
        return found !== undefined ? true : false;
      });


      filteredPreceptors = filteredPreceptors.filter(preceptor => {
        let found = false;

        // legacy specialty filter
        if (preceptor.specialties) {
          const specialty = preceptor.specialties ? preceptor.specialties.trim() : '';
          found = foundValues.find(item => item.name.trim() === specialty);
        }

        // new specialty filter
        if (preceptor.Specialties.length > 0 && !found) {
          const preceptorSpecilityIds = preceptor.Specialties.map(el => el.id);
          const intersect = _.intersection(preceptorSpecilityIds, specialtyIds);
          found = intersect.length > 0;
        }

        return found;
      });
    }

    this.setState({ filteredPreceptors: filteredPreceptors });
  };

  render() {
    const {classes,searchLocation,isLoading,searchCachedLocation} = this.props;
    const {
      filteredPreceptors,
      initialSearchValues,
      initialFilterValues,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.header}
            >
              <Grid item>
                <Typography component="span" className={classes.title}>
                  Preceptor Search
                </Typography>{' '}
                <Typography component="span" className={classes.subheading}>
                  Find preceptors suitable for this placement
                </Typography>
              </Grid>
              <Grid item>
                <AddPreceptorButton
                  initialData={initialSearchValues}
                  afterClose={searchCachedLocation}
                />
              </Grid>
            </Grid>
            <Paper className={classes.paper}>
              <LocationSearchForm
                initialValues={initialSearchValues}
                handleSubmit={values => searchLocation(values)}
                states={this.props.stateValues}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1">Filters</Typography>
              <PreceptorFilterForm
                initialValues={initialFilterValues}
                handleSubmit={this.filterPreceptors}
              />
            </Paper>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <PaginatedList
                items={filteredPreceptors}
                title="Search Results"
                renderItem={item => {
                  return (
                    <div key={item.id}>
                      <PreceptorListItem item={item} />
                      <Divider />
                    </div>
                  );
                }}
                renderEmpty={() => {
                  return (
                    <div>
                      <Typography gutterBottom>
                        There are no preceptors based on your search and
                        filters.
                      </Typography>
                    </div>
                  );
                }}
              />
            )}
          </div>
          <div className={classes.sidebar}>
            <PlacementDrawerWorkspace />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placement: state.placementDrawer.selectedPlacement,
    locationPreceptors: state.locations.results.preceptors,
    filterValues: state.placementDrawer.preceptorFilters
      ? state.placementDrawer.preceptorFilters
      : initialFilterValues,
    isLoading: state.locations.isLoading,
    specialtyValues: state.specialtyValues.items,
    stateValues: state.states.values,
  };
};

export default (compose(
  connect(mapStateToProps, {
    searchLocation, searchCachedLocation, setPreceptorFilters
  }),
  withStyles(styles)
)(PreceptorSearchSection): any);

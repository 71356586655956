// @flow

import {
  FETCH_PLACEMENT_STATUSES,
  FETCH_PLACEMENT_STATUSES_SUCCESS,
  FETCH_PLACEMENT_STATUSES_ERROR
} from '../actions/types';

import type { PlacementStatusCode } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<PlacementStatusCode>
};

type Action = {
  type:
    | typeof FETCH_PLACEMENT_STATUSES
    | typeof FETCH_PLACEMENT_STATUSES_SUCCESS
    | typeof FETCH_PLACEMENT_STATUSES_ERROR,
  payload: ?Object
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_PLACEMENT_STATUSES:
      return { ...state, isLoading: true };
    case FETCH_PLACEMENT_STATUSES_SUCCESS:
      return { ...state, isLoading: false, items: action.payload };
    case FETCH_PLACEMENT_STATUSES_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

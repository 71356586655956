// @flow

import * as React from 'react';
import {connect,useSelector} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {ListItem,Grid,Chip,Button,Typography} from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {ReadIcon,NearMeIcon,DragHandleIcon} from '../../../components/Icons';

import { ContractIcon } from '../../../components/Icons';
import CommonStyles from '../../../styles/CommonStyles';

import {DragSource} from 'react-dnd';
import * as DNDTypes from '../../../schemas/dndTypes';

import {searchCachedLocation} from '../../../actions/creators/locations';

import { getAllActiveContracts } from '../../../selectors/contracts';

import PreceptorListDialog from './PreceptorListDialog';
import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TouchpointDialog from '../../Touchpoint';
import DeepLink from '../../DeepLink';

import type {Site,Placement} from '../../../schemas';

type Props = {
  item: Site,
  selectedPlacement: Placement,
  isDragging: boolean,
  connectDragSource: Function,
  deletePlacementSlot: Function,
};

const useStyles = makeStyles(theme => {
  const commonStyles = CommonStyles(theme);
  return {
    chip: {
      margin: theme.spacing(1)
    },
    chipContainer: {
      textAlign: 'right'
    },
    siteContainer: {
      paddingLeft: theme.spacing(1)
    },
    nearMeContainer: {
      textAlign: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    dragging: {
      opacity: 0.6
    },
    dragIcon: {
      marginRight: theme.spacing(3)
    },
    workspaceButton: {
      textAlign: 'right'
    },
    contractIcon: {
      color: commonStyles.success.backgroundColor,
      paddingRight: theme.spacing(0.5)
    }
  };
});

// Drag-and-drop source/collect functions needed for the DragSource
const dragSource = {
  beginDrag(props) {
    const {id,name} = props.item;
    const {selectedPlacement} = props;

    return {
      model: 'site',
      foreignKey: id,
      name: name,
      PlacementId: selectedPlacement.id
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function SiteListItem(props: Props) {
  const {item,connectDragSource,isDragging,selectedPlacement} = props;
  const classes = useStyles();

  const [visiblePreceptorDialog, setVisiblePreceptorDialog] = React.useState(false);
  const [visibleTouchpointDialog, setVisibleTouchpointDialog] = React.useState(false);

  const activeContracts = useSelector(getAllActiveContracts);

  function handlePreceptorsDialogOpen() {
    setVisiblePreceptorDialog(true);
  }

  function handlePreceptorDialogClose() {
    setVisiblePreceptorDialog(false);
  }

  function handleTouchpointsDialogOpen() {
    setVisibleTouchpointDialog(true);
  }

  function handleTouchpointDialogClose() {
    setVisibleTouchpointDialog(false);
  }

  function renderPreceptorDialog() {
    if (visiblePreceptorDialog) {
      return (
        <PreceptorListDialog
          open={true}
          site={item}
          handleClose={handlePreceptorDialogClose}
        />
      );
    }
  }

  function renderTouchpointDialog() {
    if (visibleTouchpointDialog) {
      return (
        <TouchpointDialog
          open={true}
          handleClose={handleTouchpointDialogClose}
          data={{ TouchableId: item.id, name: item.name, model: 'site' }}
        />
      );
    }
  }

  function getDistance(value) {
    if (isNaN(value)) return 0;
    return Math.round(parseFloat(value) / 1609.34);
  }

  function hasActiveContract() {
    const OrganizationId = item?.Organization?.id ?? null;
    if (!OrganizationId) return false;

    const foundContracts = activeContracts
      .filter(o => o.OrganizationId === OrganizationId);

    return Boolean(foundContracts.length > 0);
  }
  hasActiveContract();

  const draggingClass = isDragging ? classes.dragging : '';

  const workspaceItem = {
    model: 'site',
    foreignKey: item.id,
    name: item.name,
    PlacementId: selectedPlacement.id
  };

  return connectDragSource(
    <div className={draggingClass}>
      <ListItem>
        <DragHandleIcon className={classes.dragIcon} />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <Grid item>
                    <DeepLink id={item.id} model="site" afterClose={searchCachedLocation}>
                      <Typography variant="h6">
                        {hasActiveContract() && <ContractIcon fontSize="small" className={classes.contractIcon} />}
                        {item.name}
                      </Typography>
                    </DeepLink>
                  </Grid>
                  {item.Organization &&
                    <Grid item>
                      <DeepLink id={item.Organization.id} model="organization" afterClose={searchCachedLocation}>
                        <Typography variant="caption">
                          {item.Organization.name}
                        </Typography>
                      </DeepLink>
                    </Grid>}
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes.nearMeContainer}>
                <Typography>{getDistance(item.Location.distance)} mi</Typography>
                <NearMeIcon />
              </Grid>
              <Grid item xs={4}>
                <Typography>{item.address}</Typography>
                <Typography>{item.addressLine2}</Typography>
                <Typography>{item.addressLine3}</Typography>
                <Typography>
                  {item.city}, {item.StateId} {item.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.chipContainer}>
                {item.Specialties.map(specialty => {
                  return (
                    <Chip
                      key={specialty.id}
                      label={specialty.code}
                      className={classes.chip}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Button color="primary" onClick={handlePreceptorsDialogOpen}>
                  Preceptors <ReadIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button color="primary" onClick={handleTouchpointsDialogOpen}>
                  Touchpoints <ContactPhoneIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={4} className={classes.workspaceButton}>
                <AddToWorkspaceButton item={workspaceItem} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      {renderPreceptorDialog()}
      {renderTouchpointDialog()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedPlacement: state.placementDrawer.selectedPlacement
  };
};

export default (compose(
  connect(mapStateToProps, {}),
  DragSource(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dragSource, collect)
)(SiteListItem): any);

import axios from '../../api';
import * as actions from '../types';

export const fetchCourses = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COURSES, payload: undefined });
    const courses = await axios.get('/course');
    dispatch({
      type: actions.FETCH_COURSES_SUCCESS,
      payload: courses.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COURSES_ERROR,
      payload: error.response.data
    });
  }
};

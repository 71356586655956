// @flow

import * as React from 'react';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { Select as MuiSelect } from '@material-ui/core';
import { FieldProps } from 'formik';

import {
  MenuItem, Chip, FormControl, InputLabel,
  Input, ListItemText, Checkbox,
} from '@material-ui/core';

type FieldPropTypes = typeof FieldProps;
type MuiSelectPropTypes = typeof MuiSelectProps;

type Props = {
  ...FieldPropTypes,
  ...MuiSelectPropTypes
}

const MultiSelect: React.ComponentType<Props> = ({
  field,
  form,
  ...props
}) => {
  const { name } = field;
  const { options, label } = props;
  const { touched, errors } = form;

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? [] : field.value;
  if (field.value[0] && typeof field.value[0] === 'object') {
    field.value = field.value.map(el => el.id);
  }
  props.value = field.value;

  const handleChange = e => {
    if (props.onChange) {
      field.onChange(e);
      props.onChange(e);
    }
    field.onChange(e);
  };

  return (
    <FormControl {...props}>
      <InputLabel htmlFor={`${name}-select-multiple`}>{label}</InputLabel>
      <MuiSelect
        {...props}
        {...field}
        multiple
        onChange={handleChange}
        input={<Input id={`${name}-select-multiple`} />}
        renderValue={selected => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map(value => (
              <Chip
                key={value}
                style={{ height: 18, margin: 1 }}
                label={options.find(el => el.id === value).name}
              />
            ))}
          </div>
        )}
        error={touched[name] && !!errors[name]}
      >
        {options.map(el => (
          <MenuItem key={el.id} value={el.id}>
            <Checkbox checked={props.value.indexOf(el.id) > -1} color="primary" />
            <ListItemText primary={el.name} />
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

MultiSelect.displayName = 'FormikMuiMultiSelect';

export default MultiSelect;

// @flow

import * as React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {Button,Grid,Chip,ListItem,Typography} from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {AlumniIcon} from '../../Icons';
import {ReadIcon,NearMeIcon,DragHandleIcon} from '../../../components/Icons';

import { DragSource } from 'react-dnd';
import * as DNDTypes from '../../../schemas/dndTypes';

import {searchCachedLocation} from '../../../actions/creators/locations';

import SiteListDialog from './SiteListDialog';
import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TouchpointDialog from '../../Touchpoint';
import DeepLink from '../../DeepLink';

import type {PreceptorSearchResult,Placement} from '../../../schemas';

type Props = {
  item: PreceptorSearchResult,
  isDragging: boolean,
  selectedPlacement: Placement,
  connectDragSource: Function,
};

const useStyles = makeStyles(theme => {
  return {
    chip: {
      margin: theme.spacing(1)
    },
    chipContainer: {
      textAlign: 'right'
    },
    siteContainer: {
      paddingLeft: theme.spacing(1)
    },
    nearMeContainer: {
      textAlign: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    shiftLeft: {
      marginLeft: theme.spacing(1) * -2
    },
    dragging: {
      opacity: 0.6
    },
    dragIcon: {
      marginRight: theme.spacing(3)
    },
    siteListItem: {
      padding: theme.spacing(2)
    },
    workspaceButton: {
      textAlign: 'right'
    }
  };
});

// Drag-and-drop source/collect functions needed for the DragSource
const dragSource = {
  beginDrag(props) {
    const {id,name} = props.item;
    const {selectedPlacement} = props;

    return {
      model: 'preceptor',
      foreignKey: id,
      name: name,
      PlacementId: selectedPlacement.id
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function PreceptorListItem(props: Props) {
  const {item,connectDragSource,isDragging,selectedPlacement} = props;
  const classes = useStyles();

  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [visibleTouchpointDialog, setVisibleTouchpointDialog] = React.useState(false);

  function handleSitesDialogOpen() {
    setVisibleDialog(true);
  }

  function handleSitesDialogClose() {
    setVisibleDialog(false);
  }

  function handleTouchpointsDialogOpen() {
    setVisibleTouchpointDialog(true);
  }

  function handleTouchpointDialogClose() {
    setVisibleTouchpointDialog(false);
  }

  function renderSitesDialog() {
    if (visibleDialog) {
      return (
        <SiteListDialog
          open={true}
          handleClose={handleSitesDialogClose}
          item={item}
        />
      );
    }
  }

  function renderTouchpointDialog() {
    if (visibleTouchpointDialog) {
      return (
        <TouchpointDialog
          open={true}
          handleClose={handleTouchpointDialogClose}
          data={{ TouchableId: item.id, name: item.name, model: 'preceptor' }}
        />
      );
    }
  }

  function getDistance(value) {
    if (isNaN(value)) return 0;
    return Math.round(parseFloat(value) / 1609.34);
  }

  const draggingClass = isDragging ? classes.dragging : '';

  const workspaceItem = {
    model: 'preceptor',
    foreignKey: item.id,
    PlacementId: selectedPlacement.id,
    name: item.name
  };

  return connectDragSource(
    <div className={draggingClass}>
      <ListItem>
        <DragHandleIcon className={classes.dragIcon} />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <div className={classes.siteContainer}>
                  <DeepLink id={item.id} model="preceptor" afterClose={searchCachedLocation}>
                    {item.isAlumni && <AlumniIcon color="primary" fontSize="small" className={classes.leftIcon} />}
                    <Typography variant="h6">{item.name}, {item.credentials}</Typography>
                  </DeepLink>
                </div>
              </Grid>
              <Grid item xs={1} className={classes.nearMeContainer}>
                <Typography>{getDistance(item.Location.distance)} mi</Typography>
                <NearMeIcon />
              </Grid>
              <Grid item xs={4}>
                <Typography>{item.address}</Typography>
                <Typography>{item.addressLine2}</Typography>
                <Typography>
                  {item.city}, {item.State && item.State.id} {item.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.chipContainer}>
                {item.Specialties.length > 0 &&
                  item.Specialties.map(el => (
                    <Chip key={el.id} label={el.name} className={classes.chip} />
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Button
                  color="primary"
                  className={classes.shiftLeft}
                  onClick={handleSitesDialogOpen}
                >
                  Sites <ReadIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button color="primary" onClick={handleTouchpointsDialogOpen}>
                  Touchpoints <ContactPhoneIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={4} className={classes.workspaceButton}>
                <AddToWorkspaceButton item={workspaceItem} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      {renderSitesDialog()}
      {renderTouchpointDialog()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedPlacement: state.placementDrawer.selectedPlacement
  };
};

export default (compose(
  connect(mapStateToProps, {}),
  DragSource(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dragSource, collect)
)(PreceptorListItem): any);

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';

import { createAttachment } from '../../actions/creators/attachments';
import AttachmentForm from './AttachmentForm';

import type { User } from '../../schemas';

type Props = {
  classes: Object,
  createAttachment: Function,
  mini: ?boolean,
  color: ?string,
  currentUser: User,
  model: string,
  foreignKey: number,
  attachmentName: ?string
};

type State = { dialogVisible: boolean, template: Object };

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class AddAttachmentButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { model, foreignKey, currentUser } = props;
    let template = {
      model,
      foreignKey,
      name: _.get(props, 'attachmentName', ''),
      attachment: undefined,
      UserId: currentUser.id
    };

    this.state = {
      dialogVisible: false,
      template
    };
  }

  openDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleSubmit = async values => {
    const { createAttachment } = this.props;
    let formData = new FormData();
    _.forOwn(values, (value, key) => {
      formData.append(key, value);
    });
    formData.append('File', values.file[0]);
    await createAttachment(formData);

    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, template } = this.state;

    const displayLabel = _.get(this.props, 'label', 'Create Attachment');
    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          variant={_.get(this.props, 'variant', 'contained')}
          className={classes.button}
          onClick={this.openDialog}
        >
          {displayLabel}
        </Button>
        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="create-attachment-dialog"
        >
          <DialogTitle id="create-attachment-dialog">
            {displayLabel}
          </DialogTitle>
          <DialogContent>
            {/* TODO: need to make the attachment button allow for a pre-defined description for the attachment. */}
            <AttachmentForm
              handleSubmit={this.handleSubmit}
              initialValues={template}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default (compose(
  connect(mapStateToProps, { createAttachment }),
  withStyles(styles)
)(AddAttachmentButton): any);

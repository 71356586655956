// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  List,
  Typography
} from '@material-ui/core';

import AttachmentAItem from './AttachmentAItem';

import type { AttachmentA } from '../../schemas';

type Props = {
  classes: Object,
  attachmentAs: AttachmentA[]
};

const styles = theme => ({
  list: {
    marginTop: theme.spacing(2)
  }
});

class AttachmentAList extends Component<Props> {
  render() {
    const { attachmentAs, classes } = this.props;
    const items = attachmentAs.filter(
      item => item.AttachmentAStatusCode.id !== 'D'
    );
    return (
      <div>
        {items.length === 0 && (
          <Typography>There are no attachments A</Typography>
        )}
        {items.length > 0 && (
          <Paper className={classes.list}>
            <List>
              {items.map(item =>
                (<AttachmentAItem key={item.id} item={item} />)
              )}
            </List>
          </Paper>
        )}
      </div>
    );
  }
}

export default (withStyles(styles)(AttachmentAList): any);

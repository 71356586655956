
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllCountries = state => state.countries.items;

export const getAllCountryValues = createSelector(
  getAllCountries,
  countries => {
    return _.map(_.orderBy(countries, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getCountryById = state => createSelector(
  getAllCountries, countries => {
    return _.find(countries, { id: state.countyId });
  }
)(state);

import axios from '../../api';
import * as actions from '../types';

export const fetchStash = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STASH });
    const res = await axios.get('/siteEnrollment/stash');
    dispatch({
      type: actions.FETCH_STASH_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STASH_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import _ from 'lodash';

import {
  SHOW_CONTRACT_DRAWER,
  HIDE_CONTRACT_DRAWER,
  CHANGE_CONTRACT_DRAWER_SECTION,
  FETCH_SELECTED_CONTRACT,
  FETCH_SELECTED_CONTRACT_SUCCESS,
  FETCH_SELECTED_CONTRACT_ERROR,
  FETCH_ALTERNATE_CONTRACTS,
  FETCH_ALTERNATE_CONTRACTS_SUCCESS,
  FETCH_ALTERNATE_CONTRACTS_ERROR,
  FETCH_CONTRACT_ADDENDA,
  FETCH_CONTRACT_ADDENDA_SUCCESS,
  FETCH_CONTRACT_ADDENDA_ERROR,
  FETCH_CONTRACT_AMENDMENTS,
  FETCH_CONTRACT_AMENDMENTS_SUCCESS,
  FETCH_CONTRACT_AMENDMENTS_ERROR,
  FETCH_CONTRACT_NOTES,
  FETCH_CONTRACT_NOTES_SUCCESS,
  FETCH_CONTRACT_NOTES_ERROR,
  FETCH_CONTRACT_TODOS,
  FETCH_CONTRACT_TODOS_SUCCESS,
  FETCH_CONTRACT_TODOS_ERROR,
  FETCH_CONTRACT_ATTACHMENTS,
  FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
  FETCH_CONTRACT_ATTACHMENTS_ERROR,
  FETCH_SELECTED_CONTRACT_ATTACHMENTS_A,
  FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS,
  FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR
} from '../actions/types';
import type {
  Contract,
  DrawerNavigationItem,
  Addendum,
  Amendment,
  Note,
  Todo,
  Attachment,
  AttachmentA
} from '../schemas';
import navItems from '../config/contractDrawerNavigationItems';

type State = {
  +isVisible: boolean,
  +selectedContract: ?Contract,
  +addenda: Addendum[],
  +amendments: Amendment[],
  +notes: Note[],
  +notes: Todo[],
  +attachments: Attachment[],
  +alternateContracts: Contract[],
  +currentSection: DrawerNavigationItem,
  +sections: Array<DrawerNavigationItem>,
  +isLoading: boolean,
  +error: ?string,
  +attachmentAs: AttachmentA[]
};

type Action = {
  type:
    | typeof SHOW_CONTRACT_DRAWER
    | typeof HIDE_CONTRACT_DRAWER
    | typeof CHANGE_CONTRACT_DRAWER_SECTION
    | typeof FETCH_SELECTED_CONTRACT
    | typeof FETCH_SELECTED_CONTRACT_SUCCESS
    | typeof FETCH_SELECTED_CONTRACT_ERROR
    | typeof FETCH_ALTERNATE_CONTRACTS
    | typeof FETCH_ALTERNATE_CONTRACTS_SUCCESS
    | typeof FETCH_ALTERNATE_CONTRACTS_ERROR
    | typeof FETCH_CONTRACT_ADDENDA
    | typeof FETCH_CONTRACT_ADDENDA_SUCCESS
    | typeof FETCH_CONTRACT_ADDENDA_ERROR
    | typeof FETCH_CONTRACT_AMENDMENTS
    | typeof FETCH_CONTRACT_AMENDMENTS_SUCCESS
    | typeof FETCH_CONTRACT_AMENDMENTS_ERROR
    | typeof FETCH_CONTRACT_NOTES
    | typeof FETCH_CONTRACT_NOTES_SUCCESS
    | typeof FETCH_CONTRACT_NOTES_ERROR
    | typeof FETCH_CONTRACT_TODOS
    | typeof FETCH_CONTRACT_TODOS_SUCCESS
    | typeof FETCH_CONTRACT_TODOS_ERROR
    | typeof FETCH_CONTRACT_ATTACHMENTS
    | typeof FETCH_CONTRACT_ATTACHMENTS_SUCCESS
    | typeof FETCH_CONTRACT_ATTACHMENTS_ERROR
    | typeof FETCH_SELECTED_CONTRACT_ATTACHMENTS_A
    | typeof FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS
    | typeof FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR,
  payload: Object
};

const initialState = {
  isVisible: false,
  selectedContract: undefined,
  alternateContracts: [],
  addenda: [],
  amendments: [],
  notes: [],
  todos: [],
  attachments: [],
  currentSection: navItems[0],
  sections: navItems,
  isLoading: false,
  error: undefined,
  attachmentAs: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case SHOW_CONTRACT_DRAWER:
      return {
        ...state,
        isVisible: true,
        currentSection: initialState.currentSection
      };
    case HIDE_CONTRACT_DRAWER:
      return initialState;

    case CHANGE_CONTRACT_DRAWER_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    case FETCH_SELECTED_CONTRACT:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SELECTED_CONTRACT_SUCCESS:
      return {
        ...state,
        selectedContract: action.payload,
        isLoading: false
      };
    case FETCH_SELECTED_CONTRACT_ERROR:
      return {
        ...state,
        selectedContract: initialState.selectedContract,
        isLoading: false,
        error: action.payload
      };

    case FETCH_ALTERNATE_CONTRACTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ALTERNATE_CONTRACTS_SUCCESS:
      return {
        ...state,
        alternateContracts: action.payload,
        isLoading: false
      };
    case FETCH_ALTERNATE_CONTRACTS_ERROR:
      return {
        ...state,
        addenda: initialState.addenda,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_ADDENDA:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_ADDENDA_SUCCESS:
      return {
        ...state,
        addenda: action.payload,
        isLoading: false
      };
    case FETCH_CONTRACT_ADDENDA_ERROR:
      return {
        ...state,
        addenda: initialState.addenda,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_AMENDMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_AMENDMENTS_SUCCESS:
      return {
        ...state,
        amendments: action.payload,
        isLoading: false
      };
    case FETCH_CONTRACT_AMENDMENTS_ERROR:
      return {
        ...state,
        amendments: initialState.amendments,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_NOTES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        isLoading: false
      };
    case FETCH_CONTRACT_NOTES_ERROR:
      return {
        ...state,
        notes: initialState.notes,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_TODOS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_TODOS_SUCCESS:
      return {
        ...state,
        todos: _.orderBy(action.payload, ['step'], ['asc']),
        isLoading: false
      };
    case FETCH_CONTRACT_TODOS_ERROR:
      return {
        ...state,
        todos: initialState.todos,
        isLoading: false,
        error: action.payload
      };

    case FETCH_CONTRACT_ATTACHMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: action.payload,
        isLoading: false
      };
    case FETCH_CONTRACT_ATTACHMENTS_ERROR:
      return {
        ...state,
        attachments: initialState.notes,
        isLoading: false,
        error: action.payload
      };

    case FETCH_SELECTED_CONTRACT_ATTACHMENTS_A:
      return {
        ...state,
        isLoading: true,
        attachmentAs: initialState.attachmentAs
      };
    case FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS:
      return {
        ...state,
        isLoading: false,
        attachmentAs: action.payload
      };
    case FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        attachmentAs: initialState.attachmentAs
      };

    default:
      return state;
  }
};

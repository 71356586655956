// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {DropTarget} from 'react-dnd';
import * as DNDTypes from '../../../schemas/dndTypes';

import CommonStyles from '../../../styles/CommonStyles';

import {removeFromWorkspace} from '../../../actions/creators/placementDrawer';
import {enrollPreceptor} from '../../../actions/creators/placementSlots';

import type {Placement,SiteEnrollment} from '../../../schemas';

type Props = {
  selectedPlacement: Placement,
  canDrop: boolean,
  isOver: boolean,
  siteEnrollment: SiteEnrollment,
  connectDropTarget: Function,
};

const useStyles = makeStyles(theme => {
  const commonStyles = CommonStyles(theme);
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    dropZone: commonStyles.dropZone,
    dropZoneCanDrop: commonStyles.dropZoneCanDrop,
    dropZoneActive: commonStyles.dropZoneActive,
    blankMessage: {
      padding: theme.spacing(1)
    },
    fakeSiteEnrollment: {
      opacity: 0.3,
      alignSelf: 'flex-start',
      padding: theme.spacing(2)
    }
  };
});

const canEnrollPreceptor = (siteEnrollment,workspaceItem) => {
  const enrolledPreceptorIDs = _.map(
    siteEnrollment.PreceptorEnrollments, 'PreceptorId'
  );
  let found = enrolledPreceptorIDs.find(enrolledId => {
    return enrolledId === workspaceItem.foreignKey;
  });

  return found === undefined;
};

// Drag-and-drop spec/collect functions needed for the DragSource
const dropTargetSpec = {
  drop(props, monitor) {
    const {
      siteEnrollment,selectedPlacement,enrollPreceptor,removeFromWorkspace
    } = props;
    const workspaceItem = monitor.getItem();
    if (workspaceItem.model === 'preceptor') {
      const canEnroll = canEnrollPreceptor(siteEnrollment,workspaceItem);
      if (canEnroll) {
        enrollPreceptor(selectedPlacement,siteEnrollment,workspaceItem);
        removeFromWorkspace(workspaceItem);
      }
    }
  },
  canDrop(props, monitor) {
    const workspaceItem = monitor.getItem();
    return workspaceItem.model === 'preceptor';
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    isOver: monitor.isOver(),
    placeholderItem: monitor.getItem()
  };
}

function PreceptorEnrollmentDropzone(props: Props) {
  const {connectDropTarget,isOver,canDrop} = props;
  const classes = useStyles();

  const isActive = isOver && canDrop;

  let dropZoneClass = 'dropZone';
  if (isActive) dropZoneClass = 'dropZoneActive';
  else if (canDrop) dropZoneClass = 'dropZoneCanDrop';

  return connectDropTarget(
    <div className={classes.root}>
      <div className={classes[dropZoneClass]}>
        <p className={classes.blankMessage}>
          Drag a preceptor here to add them to this Placement Slot.
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedPlacement: state.placementDrawer.selectedPlacement
});

export default (compose(
  connect(mapStateToProps, {enrollPreceptor,removeFromWorkspace}),
  DropTarget(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dropTargetSpec, collect)
)(PreceptorEnrollmentDropzone): any);

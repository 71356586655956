// @flow

import * as types from '../actions/types';
import type { Preceptor, DrawerNavigationItem, Attachment } from '../schemas';
import navItems from '../config/preceptorDrawerNavigationItems';

type State = {
  +isVisible: boolean,
  +selectedPreceptor: ?Preceptor,
  +currentSection: DrawerNavigationItem,
  +sections: DrawerNavigationItem[],
  +attachments: Attachment[],
  +isLoading: boolean,
  +error: ?string
};

type Action = {
  type:
    | typeof types.SHOW_PRECEPTOR_DRAWER
    | typeof types.HIDE_PRECEPTOR_DRAWER
    | typeof types.CHANGE_PRECEPTOR_DRAWER_SECTION
    | typeof types.FETCH_SELECTED_PRECEPTOR
    | typeof types.FETCH_SELECTED_PRECEPTOR_SUCCESS
    | typeof types.FETCH_SELECTED_PRECEPTOR_ERROR
    | typeof types.FETCH_PRECEPTOR_ATTACHMENTS
    | typeof types.FETCH_PRECEPTOR_ATTACHMENTS_SUCCESS
    | typeof types.FETCH_PRECEPTOR_ATTACHMENTS_ERROR,
  payload: Object
};

const initialState = {
  isVisible: false,
  selectedPreceptor: undefined,
  currentSection: navItems[0],
  sections: navItems,
  attachments: [],
  isLoading: false,
  error: undefined
};

export default (state: State = initialState, action: Action): Function => {

  const { payload } = action;

  switch (action.type) {
    case types.SHOW_PRECEPTOR_DRAWER:
      return { ...state, isVisible: true, currentSection: initialState.currentSection };

    case types.HIDE_PRECEPTOR_DRAWER:
      return initialState;

    case types.CHANGE_PRECEPTOR_DRAWER_SECTION:
      return { ...state, currentSection: payload };

    case types.FETCH_SELECTED_PRECEPTOR:
      return { ...state, isLoading: true };
    case types.FETCH_SELECTED_PRECEPTOR_SUCCESS:
      return { ...state, selectedPreceptor: payload, isLoading: false };
    case types.FETCH_SELECTED_PRECEPTOR_ERROR:
      return { ...state, selectedPreceptor: initialState.selectedPreceptor, isLoading: false, error: payload };

    case types.FETCH_PRECEPTOR_ATTACHMENTS:
      return { ...state, isLoading: true };
    case types.FETCH_PRECEPTOR_ATTACHMENTS_SUCCESS:
      return { ...state, attachments: payload, isLoading: false };
    case types.FETCH_PRECEPTOR_ATTACHMENTS_ERROR:
      return { ...state, attachments: initialState.attachments, isLoading: false, error: payload };

    default:
      return state;
  }
};

// @flow
import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Grid,Typography} from '@material-ui/core';

import NoteDetail from './NoteDetail';

import type {Note} from '../../schemas';

type Props = {
  notes: Note[],
  afterAction: Function
};

const useStyles = makeStyles(theme => {
  return {
    emptyMessage: {
      marginLeft: theme.spacing(1.5)
    }
  };
});

function NoteList(props: Props): React$Element<any> {
  const {notes,afterAction} = props;
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {notes.length === 0 && (
        <Typography className={classes.emptyMessage}>
          There are no notes.
        </Typography>
      )}
      {notes.length > 0 && notes.map(note => (
        <Grid item sm={6} key={note.id}>
          <NoteDetail afterAction={afterAction} note={note} />
        </Grid>
      ))}
    </Grid>
  );
}

export default NoteList;

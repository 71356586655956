import * as actions from '../types';
import axios from '../../api';

export const showContractDrawer = () => async dispatch => {
  dispatch({ type: actions.SHOW_CONTRACT_DRAWER });
};

export const hideContractDrawer = () => {
  return { type: actions.HIDE_CONTRACT_DRAWER };
};

export const changeSection = section => {
  return { type: actions.CHANGE_CONTRACT_DRAWER_SECTION, payload: section };
};

export const fetchSelectedContract = id => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_CONTRACT });
    const res = await axios.get(`/contract/${id}`);
    dispatch({
      type: actions.FETCH_SELECTED_CONTRACT_SUCCESS,
      payload: res.data
    });

    await dispatch(fetchAlternateContracts(res.data));
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_CONTRACT_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchAlternateContracts = contract => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ALTERNATE_CONTRACTS });
    const res = await axios.get(`/contract`, {
      params: {
        OrganizationId: contract.OrganizationId
      }
    });
    dispatch({
      type: actions.FETCH_ALTERNATE_CONTRACTS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ALTERNATE_CONTRACTS_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import _ from 'lodash';

import {
  FETCH_STUDENTS,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_ERROR,
  FETCH_STUDENT_VALUES,
  FETCH_STUDENT_VALUES_SUCCESS,
  FETCH_STUDENT_VALUES_ERROR,
  FETCH_STUDENT,
  FETCH_STUDENT_SUCCESS,
  FETCH_STUDENT_ERROR
} from '../actions/types';

import type { Student, ValueListItem } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Student>,
  +values: Array<ValueListItem>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_STUDENTS
    | typeof FETCH_STUDENTS_SUCCESS
    | typeof FETCH_STUDENTS_ERROR
    | typeof FETCH_STUDENT_VALUES
    | typeof FETCH_STUDENT_VALUES_SUCCESS
    | typeof FETCH_STUDENT_VALUES_ERROR
    | typeof FETCH_STUDENT
    | typeof FETCH_STUDENT_SUCCESS
    | typeof FETCH_STUDENT_ERROR,
  payload: Array<Student> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_STUDENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: getValues(action.payload)
      };
    case FETCH_STUDENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_STUDENT_VALUES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        values: getValues(action.payload)
      };
    case FETCH_STUDENT_VALUES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_STUDENT:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_STUDENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.fullName };
  });
};

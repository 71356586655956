// @flow

import React from 'react';

import { Dialog, Button, Chip, Avatar } from '@material-ui/core';
import Components from './Components';

type Props = {
  id: number,
  model: string,
  children?: any,
  chip?: boolean,
  label?: string,
  avatar?: string | typeof Avatar,
  afterClose?: Function
};

const DeepLink = (props: Props): React$Element<React$FragmentType> => {
  const {id,model,children,chip,label,avatar,afterClose} = props;

  const Component = Components[model];
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = shouldUpdate => {
    setOpen(false);
    afterClose && shouldUpdate && afterClose();
  };

  return (
    <React.Fragment>
      {chip && <Chip
        avatar={avatar && typeof avatar !== 'string'
          ? avatar : avatar ? <Avatar>{avatar}</Avatar> : <Avatar>DL</Avatar>
        }
        label={label || id}
        onClick={handleClickOpen}
        variant="outlined"
      />}
      {!chip && <Button
        disableRipple
        disableFocusRipple
        disableElevation
        style={{ textTransform: 'none', textAlign: 'left' }}
        onClick={handleClickOpen}
      >{children}</Button>}
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose()}
        aria-labelledby={`${model}-${id}-dialog`}
        open={open}
      >
        <Component
          id={id}
          model={model}
          handleClose={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default DeepLink;

// @flow

import _ from 'lodash';

import * as React from 'react';
// $FlowFixMe
import ReactSelect from 'react-select';
// $FlowFixMe
import { Props as ReactSelectProps,  } from 'react-select';
// $FlowFixMe
// import { OptionProps as Option  } from 'react-select';
import { FieldProps } from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';

type FieldPropTypes = typeof FieldProps;

type Props = {
  ...FieldPropTypes,
  ...ReactSelectProps
}

const SelectLookup: React.ComponentType<Props> = ({
  field, form, ...props
}) => {
  const { name } = field;
  const {label,required,options} = props;
  const {touched,errors} = form;

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? '' : field.value;
  props.value = field.value;

  return (
    <FormControl style={{ marginTop: 12 }} error={touched[name] && !!errors[name]} {...props}>
      <ReactSelect
        {...props}
        {...field}
        isMulti
        isClearable
        placeholder={`${label} ${required ? '*' : ''}`}
        name={name}
        options={options}
        // value={options ? options.find(option => option.value === field.value) : null}
        // onChange={(option: Option) => form.setFieldValue(field.name, option ? option.value : null)}
        value={options.filter(option => _.some(field.value, { id: option.value }))}
        onChange={option => form.setFieldValue(field.name, option.map(o => o.value))}
        onBlur={field.onBlur}
      />
      {!!errors[name] && (<FormHelperText id={`${name}-select-lookup`}>{errors[name]}</FormHelperText>)}
    </FormControl>
  );
};

SelectLookup.displayName = 'FormikReactSelect';

export default SelectLookup;

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import moment from 'moment';
import FileDownload from 'js-file-download';
import axios from '../../api';

import {
  Typography,
  Button,
  Grid
} from '@material-ui/core';

import type { AttachmentA } from '../../schemas';

type Props = {
  classes: Object,
  item: AttachmentA
};

const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(1)
  }
});

class AttachmentAItem extends Component<Props> {
  downloadFile = async (id: number) => {
    const endpoint = `/attachmentA/${id}/generate`;
    let { data } = await axios.get(endpoint, { responseType: 'blob' });
    const timestamp = moment().format('YYYYMMDD');
    const fileName = `${timestamp}-attachmentA-${id}.docx`;
    FileDownload(data, fileName);
  };
  render() {
    const { item, classes } = this.props;

    const siteName = _.get(item, 'Site.name', 'No Data Available');
    const orgName = _.get(
      item,
      'Contract.Organization.name',
      'No Data Available'
    );
    const startDate = item.startDate
      ? moment(item.startDate).format('ll')
      : 'N/A';
    const endDate = item.endDate ? moment(item.endDate).format('ll') : 'N/A';

    const status = _.get(
      item,
      'AttachmentAStatusCode.name',
      'No Data Available'
    );
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle1">{orgName}</Typography>
          <Typography variant="body1">{siteName}</Typography>
          <Typography variant="caption" gutterBottom>
            {startDate} - {endDate}
          </Typography>
          <Typography>
            <strong>Status: </strong> {status}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.buttonContainer}>
          {status === 'Requested' && (
            <Button
              size="small"
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                this.downloadFile(item.id);
              }}
            >
              Generate Word Doc
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default (withStyles(styles)(AttachmentAItem): any);

// @flow

import _ from 'lodash';
import type { Role } from '../schemas/';

export const hasAccess = (
  userRoles: Role[],
  allowableRoles: string[]
): boolean => {
  const roles = _.map(userRoles, 'slug');
  const found = _.intersection(allowableRoles, roles);
  if (_.includes(allowableRoles, '*')) {
    return true;
  }
  return found.length > 0;
};

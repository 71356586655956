// @flow

import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import type { ValueListItem } from '../../schemas';

type Props = {
  value: any,
  attachmentAStatusCodes: ValueListItem[]
};

class AttachmentAStatusCodeFormatter extends Component<Props> {
  render() {
    const { value, attachmentAStatusCodes } = this.props;

    const item = _.find(attachmentAStatusCodes, { id: value });
    const displayValue = _.get(item, 'name', 'No Data Available');

    return displayValue;
  }
}

const mapStateToProps = state => {
  return {
    attachmentAStatusCodes: state.attachmentAStatusCodes.values
  };
};

export default (connect(mapStateToProps, {})(AttachmentAStatusCodeFormatter): any);

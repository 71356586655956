// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  createRole,
  updateRole,
  deleteRole
} from '../../actions/creators/roles';

import RoleDetailForm from './RoleDetailForm';
import type { Role } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Props = {
  createRole: Function,
  updateRole: Function,
  deleteRole: Function,
  handleDelete: Function,
  handleClose: Function,
  selectedRole: Role,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object
};

class RoleDetail extends Component<Props> {

  async handleSubmit(role: Role) {
    role.id
      ? await this.props.updateRole(role)
      : await this.props.createRole(role);
    this.props.handleClose();
  }

  async handleDelete() {
    const confirm = window.confirm(
      `Are you sure you want to delete "${this.props.selectedRole.name}"?`
    );
    if (confirm) {
      await this.props.deleteRole(this.props.selectedRole);
    }
    this.props.handleClose();
  }

  render() {
    const { classes, selectedRole } = this.props;
    const template = { name: '' };

    return (
      <div className={classes.root}>
        <RoleDetailForm
          canUpdate={this.props.canUpdate}
          canDelete={this.props.canDelete}
          handleClose={this.props.handleClose}
          handleSubmit={values => this.handleSubmit(values)}
          handleDelete={() => this.handleDelete()}
          initialValues={selectedRole || template}
        />
      </div>
    );
  }
}

export default (compose(
  withStyles(styles, {
    name: 'RoleDetail'
  }),
  connect(null, {
    createRole,
    updateRole,
    deleteRole
  })
)(RoleDetail): any);

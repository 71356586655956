// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  Grid,
  Typography
} from '@material-ui/core';

import AmendmentDetail from '../../Amendment/AmendmentDetail';
import CreateAmendmentButton from '../../Amendment/CreateAmendmentButton';

import { hasAccess } from '../../../utils/permissionsCheck';

import type { Contract, User, Amendment } from '../../../schemas';

type Props = {
  classes: Object,
  contract: Contract,
  isLoading: boolean,
  currentUser: User,
  permissions: Object,
  amendments: Amendment[]
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    paddingLeft: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1)
  }
});

class ContractAmendments extends Component<Props> {
  canCreate = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  };
  render() {
    const { classes, amendments, contract } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.grid}>
          {amendments.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Contract Amendments
              </Typography>
              <Typography gutterBottom>
                This contract does not have any amendments
              </Typography>
              {this.canCreate() && (
                <CreateAmendmentButton contract={contract} />
              )}
            </Grid>
          )}
        </Grid>
        {amendments.map(amendment => {
          return (
            <AmendmentDetail
              key={amendment.id}
              amendment={amendment}
              handleClose={() => {
                /*noop*/
              }}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.contractDrawer.selectedContract,
    amendments: state.contractDrawer.amendments,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  ContractAmendments
): any);

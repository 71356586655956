import axios from '../../api';
import * as actions from '../types';

export const fetchSites = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SITES, payload: undefined });
    const res = await axios.get('/site');
    dispatch({ type: actions.FETCH_SITES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SITES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSiteList = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SITES, payload: undefined });
    const res = await axios.get('/site/list');
    dispatch({ type: actions.FETCH_SITES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SITES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSiteValues = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SITES, payload: undefined });
    const res = await axios.get('/site/values');
    dispatch({ type: actions.FETCH_SITES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SITES_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchSite = site => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SITE, payload: site });
    const res = await axios.get(`/site/${site.id}`);
    dispatch({ type: actions.FETCH_SITE_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SITE_ERROR,
      payload: error.response.data
    });
  }
};

export const mergeSites = options => async dispatch => {
  try {
    await axios.post('/site/merge', options);
    dispatch({ type: actions.MERGE_SITES_SUCCESS });
    dispatch(fetchSites());
  } catch (error) {
    dispatch({
      type: actions.MERGE_SITES_ERROR,
      payload: error.response.data
    });
  }
};

export const createSite = site => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_SITE, payload: site });
    const res = await axios.post('/site', site);
    dispatch({ type: actions.CREATE_SITE_SUCCESS, payload: res.data });
    dispatch(fetchSites());
  } catch (error) {
    dispatch({
      type: actions.CREATE_SITE_ERROR,
      payload: error.response.data
    });
  }
};

export const updateSite = site => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_SITE, payload: site });
    const res = await axios.put(`/site/${site.id}`, site);
    dispatch({ type: actions.UPDATE_SITE_SUCCESS, payload: res.data });
    dispatch(fetchSites());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_SITE_ERROR,
      payload: error.response.data
    });
  }
};

export const deleteSite = site => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_SITE, payload: site });
    const res = await axios.delete(`/site/${site.id}`);
    dispatch({ type: actions.DELETE_SITE_SUCCESS, payload: res.data });
    dispatch(fetchSites());
  } catch (error) {
    dispatch({
      type: actions.DELETE_SITE_ERROR,
      payload: error.response.data
    });
  }
};

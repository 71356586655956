// @flow

import _ from 'lodash';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {Grid,Typography,Button} from '@material-ui/core';
import DataGrid, {Column,Button as GridButton} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import GridDefaults from '../../utils/data-grid-defaults';
import SiteDrawer from '../../components/SiteDrawer/SiteDrawer';
import TouchpointDialog from '../../components/Touchpoint';
import {SearchIcon} from '../../components/Icons';

import {fetchPermissions} from '../../actions/creators/permissions';
import {fetchSiteList} from '../../actions/creators/sites';
import {fetchSiteTypes} from '../../actions/creators/siteTypes';
import {fetchStates} from '../../actions/creators/states';
import {fetchCountries} from '../../actions/creators/countries';
import {
  showSiteDrawer,fetchSelectedSite
} from '../../actions/creators/siteDrawer';

import {getAllSites} from '../../selectors/sites';
import {getAllSiteTypeValues} from '../../selectors/siteTypes';
import {getAllStateValues} from '../../selectors/states';
import {getAllCountryValues} from '../../selectors/countries';
import {getCurrentUserRoles} from '../../selectors/users';
import {getSitePermissions} from '../../selectors/permissions';

import {hasAccess} from '../../utils/permissionsCheck';

type WithStylesProps = {|
  classes: {
    root: Object,
    smallIcon: Object
  }
|};

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  smallIcon: {
    paddingLeft: 0,
    marginBottom: -4,
    width: 16,
    height: 16
  },
});

const SitesController = ({classes}: WithStylesProps) => {
  const dispatch = useDispatch();
  window.onresize = () => dataGrid.updateDimensions();

  const [dataGrid, setDataGrid] = React.useState({});
  const [selectedSite, setSelectedSite] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const currentUserRoles = useSelector(getCurrentUserRoles);
  const permissions = useSelector(getSitePermissions);
  const sites = useSelector(getAllSites);
  const siteTypes = useSelector(getAllSiteTypeValues);
  const states = useSelector(getAllStateValues);
  const countries = useSelector(getAllCountryValues);
  const dataSource = new DataSource({ store: sites, reshapeOnPush: true });

  React.useEffect(() => {
    Promise.all([
      dispatch(fetchPermissions()),
      dispatch(fetchStates()),
      dispatch(fetchCountries()),
      dispatch(fetchSiteTypes()),
      dispatch(fetchSiteList()),
    ]);
    // eslint-disable-next-line
  }, []);

  const canCreate = () =>
    hasAccess(currentUserRoles, _.get(permissions, 'create', []));

  const handleDialogOpen = async props => {
    await setSelectedSite(props.row.data);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedSite({});
  };

  const handleRowClick = async props => {
    if (props.rowType === 'data' || !props.data)
      if (props.data) {
        await dispatch(fetchSelectedSite(props.data));
        dispatch(showSiteDrawer());
      }
      else dispatch(showSiteDrawer());
  };

  const handleContentReady = async props => {
    const count = await props.component.totalCount();
    if (count > 0) setButtonDisabled(true);
    else setButtonDisabled(false);
  };

  return (
    <Grid
      container
      spacing={1}
      alignContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography variant="h5" paragraph>
          Sites
        </Typography>
      </Grid>
      {canCreate() && <Grid item xs={12}>
        <Typography
          gutterBottom={true}
          variant="caption"
          color="textSecondary"
        >
          To create a new Site, you must first ( {<SearchIcon className={classes.smallIcon} />} Search Sites... ) to determine if one already exists.
        </Typography>
      </Grid>}
      {canCreate() && <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          style={{ width: '100%', height: 64 }}
          onClick={handleRowClick}
          disabled={buttonDisabled}
        >
          New Site
        </Button>
      </Grid>}
      <Grid item xs={12}>
        <DataGrid
          {...GridDefaults}
          onInitialized={el => setDataGrid(el.component)}
          dataSource={dataSource}
          width="100%"
          selection={{ mode: 'none' }}
          groupPanel={{ visible: true }}
          grouping={{ contextMenuEnabled: true }}
          loadPanel={{ enabled: true }}
          allowColumnResizing={true}
          columnAutoWidth={true}
          searchPanel={{
            visible: true,
            width: 240,
            placeholder: 'Search Sites...',
            highlightSearchText: false,
          }}
          export={{
            enabled: true,
            allowExportSelectedData: false,
            fileName: 'sites-list',
          }}
          hoverStateEnabled={true}
          onContentReady={handleContentReady}
          onRowClick={handleRowClick}
        >
          <Column type="buttons">
            <GridButton
              text="Touchpoint"
              icon="tel"
              hint="View/Add Touchpoints"
              onClick={handleDialogOpen}
            />
          </Column>
          <Column
            dataField="id"
            dataType="number"
            alignment="center"
            allowSearch={false}
            width={80}
          />
          <Column
            dataField="name"
            width={400}
          />
          <Column
            dataField="city"
            allowSearch={false}
            alignment="center"
          />
          <Column
            dataField="StateId"
            caption="State"
            allowSearch={false}
            alignment="center"
            lookup={{
              dataSource: () => states,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            dataField="postalCode"
            allowSearch={false}
            alignment="center"
          />
          <Column
            dataField="CountryId"
            caption="Country"
            allowSearch={false}
            alignment="center"
            lookup={{
              dataSource: () => countries,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            dataField="SiteTypeId"
            caption="Type"
            allowSearch={false}
            alignment="center"
            lookup={{
              dataSource: () => siteTypes,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            dataField="isActive"
            dataType="boolean"
            allowSearch={false}
            trueText="Active"
            falseText="Inactive"
          />
        </DataGrid>
      </Grid>
      <SiteDrawer />
      {!_.isEmpty(selectedSite) && <TouchpointDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        handleClose={handleDialogClose}
        data={{ TouchableId: selectedSite.id, name: selectedSite.name, model: 'site' }}
      />}
    </Grid>
  );
};

export default (withStyles(styles)(SitesController): any);

// @flow

import _ from 'lodash';

import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { Typography } from '@material-ui/core';

type Props = {
  data: Object,
}

type State = {
  dataSource: any,
}

class DetailTemplate extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const enrollments = _.get(props.data.data, 'Package.SiteEnrollments', []);
    this.state = {
      dataSource: new DataSource({
        store: new ArrayStore({
          data: this.getAllPreceptors(enrollments),
          key: 'id'
        })
      })
    };
  }

  getAllPreceptors: ((enrollments: any) => any) = enrollments => {
    const preceptors = [];
    _.each(enrollments, enrollment => {
      _.each(enrollment.PreceptorEnrollments, preceptorEnrollment => {
        const { Preceptor: el } = preceptorEnrollment;
        const site = {};
        const preceptor = {};
        site.id = enrollment.id;
        site.name = _.get(enrollment, 'Site.name', '');
        site.address = _.get(enrollment, 'Site.address', '');
        site.city = _.get(enrollment, 'Site.city', '');
        site.state = _.get(enrollment, 'Site.StateId', '');
        preceptor.preceptor = _.get(el, 'name', '');
        preceptor.credentials = _.get(el, 'credentials', '');
        preceptor.email = _.get(el, 'emailAddress', '');
        preceptors.push({ ...site, ...preceptor });
      });
    });

    return _.uniqBy(preceptors, 'id');
  };

  render(): React$Element<React$FragmentType> {
    const { dataSource } = this.state;

    return (
      <React.Fragment>
        <Typography variant="h6">
          Preceptors
        </Typography>
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          noDataText="No Sites"
          export={{
            enabled: true,
            allowExportSelectedData: false,
            fileName: 'placement-report-sites',
          }}
        >
          <Column
            name={'name'}
            caption={'Site'}
            dataField={'name'}
            dataType={'string'}
          />
          <Column
            name={'address'}
            caption={'Address'}
            dataField={'address'}
            dataType={'string'}
          />
          <Column
            name={'city'}
            caption={'City'}
            dataField={'city'}
            dataType={'string'}
          />
          <Column
            name={'state'}
            caption={'State'}
            dataField={'state'}
            dataType={'string'}
          />
          <Column
            name={'preceptor'}
            caption={'Preceptor'}
            dataType={'string'}
            calculateCellValue={row => {
              return row.credentials
                ? `${row.preceptor}, ${row.credentials}`
                : row.preceptor;
            }}
          />
          <Column
            name={'email'}
            caption={'Email'}
            dataField={'email'}
            dataType={'string'}
          />
        </DataGrid>
      </React.Fragment>
    );
  }

}

export default DetailTemplate;

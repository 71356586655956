
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllContracts = state => state.contracts.items;
export const getSelectedContract = state => state.contracts.selectedItem;

export const getAllActiveContracts = createSelector(
  getAllContracts,
  contracts => contracts.filter(o => o.contractGroup === 'active')
);

export const getAllContractTypes = state => state.contractTypes.items;
export const getAllContractTypeValues = createSelector(
  getAllContractTypes,
  types => {
    return _.map(_.orderBy(types, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getAllContractStatusCodes = state => state.contractStatusCodes.items;
export const getAllContractStatusCodeValues = createSelector(
  getAllContractStatusCodes,
  statusCodes => {
    return _.map(_.orderBy(statusCodes, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

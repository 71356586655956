import axios from '../../api';
import * as actions from '../types';

export const fetchCourseOfferings = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COURSE_OFFERINGS, payload: undefined });
    const courses = await axios.get('/courseOffering');
    dispatch({
      type: actions.FETCH_COURSE_OFFERINGS_SUCCESS,
      payload: courses.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COURSE_OFFERINGS_ERROR,
      payload: error.response.data
    });
  }
};

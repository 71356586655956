// @flow

import _ from 'lodash';
import * as React from 'react';

type Props = {
  values: Object,
  debounce: number,
  onSave: Function,
  render: Function,
}

type State = {
  isSaving: boolean,
  lastSaved: Date,
  saveError: string,
}

class FormikAutoSave extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      lastSaved: new Date(),
      saveError: '',
    };
  }

  // TODO: need a better solution for this...
  UNSAFE_componentWillReceiveProps(props: Props) {
    if (!_.isEqual(props.values, this.props.values)) {
      this.save();
    }
  }

  // TODO: handle errors here...
  save: any = _.debounce(async () => {
    const { onSave, values } = this.props;
    const now = new Date();
    await this.setState({ isSaving: true });
    await onSave(values).then(); // NOTE: must return a resolved promise...
    await this.setState({ isSaving: false, lastSaved: now });
  }, this.props.debounce);

  render(): any {
    return this.props.render( this.state );
  }

}

export default FormikAutoSave;

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { amendmentCreateValidation } from '../../validation';

import { Button } from '@material-ui/core';

import type { Amendment } from '../../schemas';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  }
});

type Props = {
  classes: Object,
  handleSubmit: Function,
  pristine: boolean,
  submitting: boolean,
  initialValues: ?Amendment,
  valid: boolean
};

class CreateAmendmentForm extends Component<Props> {

  render() {
    const { classes } = this.props;
    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={amendmentCreateValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          isSubmitting, isValid,
        }) => (
          <Form>
            <Field
              fullWidth
              required
              multiline
              name="description"
              label="Description"
              type="text"
              component={TextField}
            />
            <Button
              disabled={isSubmitting || !isValid}
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Create Amendment
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles)(CreateAmendmentForm): any);

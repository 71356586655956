// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Grid, Popover, IconButton } from '@material-ui/core';

import { SettingsIcon } from '../Icons';
import { updateCurrentUserSettings } from '../../actions/creators/currentUserSettings';
import { fetchUserSettingTypes } from '../../actions/creators/userSettingTypes';
import { fetchTerms } from '../../actions/creators/terms';
import { fetchPermissions } from '../../actions/creators/permissions';

import { getAllTermValuesWithAttrs } from '../../selectors/terms';

import type { User, UserSetting, ValueListItem, UserSettingType } from '../../schemas';

import UserSettingForm from './CurrentUserSettingsForm';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2) + 'px',
    paddingBottom: theme.spacing(2) + 'px'
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2) + 'px',
    paddingBottom: theme.spacing(2) + 'px'
  },
  settingItem: {
    width: '100px'
  },
  settingsTitle: theme.typography.subheading
});

type State = {
  pageTitle: string,
  loading: boolean,
  userSettings: Array<UserSetting>,
  pageTitle: string,
  availableUserSettingTypes: Array<UserSettingType>,
  anchorEl: any,
  open: boolean
};

type Props = {
  classes: Object,
  theme: Object,
  user: User,
  userSettings: Array<UserSetting>,
  userSettingTypes: Array<UserSettingType>,
  terms: ValueListItem[],
  fetchUserSettingTypes: Function,
  fetchPermissions: Function,
  fetchTerms: Function,
  updateCurrentUserSettings: Function
};

class CurrentUserSettingsPopover extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'User Settings',
      open: false,
      loading: true,
      userSettings: [],
      availableUserSettingTypes: [],
      anchorEl: undefined
    };
  }

  componentDidMount() {
    this.loadData();
  }

  handleIconClick = event => {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSubmit = async userSetting => {
    await this.props.updateCurrentUserSettings(userSetting);
    await this.handleClose();
    return Promise.resolve();
  };

  loadData() {
    this.props
      .fetchUserSettingTypes()
      .then(() => this.props.fetchTerms())
      .then(() => this.props.fetchPermissions());
  }

  parseInitialValue(userSetting) {
    return userSetting.UserSettingType.slug === 'working_semester'
      ? parseInt(userSetting.value, 10)
      : userSetting.value;
  }

  render() {
    const { classes, userSettings } = this.props;
    let initialValues = userSettings.reduce((acc, userSetting) => {
      acc[userSetting.UserSettingType.slug] = this.parseInitialValue(
        userSetting
      );
      return acc;
    }, {});

    return (
      <div>
        <IconButton
          color="inherit"
          aria-label="edit user settings"
          onClick={this.handleIconClick}
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleClose}
        >
          <div className={classes.root}>
            <Grid container spacing={2}>
              <h4 className={classes.settingsTitle}>My Settings</h4>

              <Grid className={classes.settingItem} item xs={12}>
                <UserSettingForm
                  userSettings={userSettings}
                  initialValues={initialValues}
                  handleSubmit={values => this.handleSubmit(values)}
                />
              </Grid>
            </Grid>
          </div>
        </Popover>
      </div>
    );
  }
}

const mapSettings = (currentUserSettings, settingTypes) => {
  if (currentUserSettings.length && settingTypes.length) {
    return currentUserSettings.map(setting => {
      setting.UserSettingType = _.find(
        settingTypes,
        o => o.id === setting.UserSettingTypeId
      );
      return setting;
    });
  }
  return [];
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    userSettings: mapSettings(
      _.get(state.auth, 'currentUser.UserSettings', []),
      state.userSettingTypes.items
    ),
    userSettingTypes: state.userSettingTypes.items,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.userSetting,
    terms: getAllTermValuesWithAttrs(state)
  };
};

export default (compose(
  withStyles(styles, {
    withTheme: true,
    name: 'CurrentUserSettingsPopover'
  }),
  connect(mapStateToProps, {
    fetchUserSettingTypes,
    fetchPermissions,
    fetchTerms,
    updateCurrentUserSettings
  })
)(CurrentUserSettingsPopover): any);

// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {AppBar,Toolbar,Typography} from '@material-ui/core';

import SpecialtyChips from '../../components/Specialty/SpecialtyChips';
import HoursStatusIcon from '../Placement/HoursStatusIcon';

import type {Placement} from '../../schemas';

const navDrawerWidth = 64;

type Props = {
  placement: Placement
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginLeft: navDrawerWidth
    },
    flex: {
      flex: 1
    },
    metadata: {
      flex: 3
    },
    requiredHours: {
      flex: 1,
      textAlign: 'center'
    },
    term: {
      flex: 1,
      textAlign: 'center'
    },
    chip: {
      margin: theme.spacing(1)
    }
  };
});

function PlacementDrawerHeader(props: Props): React$Element<"div"> {
  const {placement} = props;
  const classes = useStyles();

  const studentName = _.get(placement, 'Student.fullName', 'N/A');
  const courseNumber = _.get(placement, 'Course.CatalogNumberId', 'N/A');
  const courseName = _.get(placement, 'Course.description', 'N/A');
  const termName = _.get(placement, 'Term.name', 'N/A');
  const specialties = _.get(placement, 'Course.Specialties', []);
  const instructorName = getInstructorName();

  function getInstructorName() {
    const term = _.get(placement, 'Term', null);
    const offerings = _.get(placement, 'Course.CourseOfferings', []);
    const offeringsFiltered = _.filter(offerings, { TermId: term.id});
    const instructor = _.get(offeringsFiltered[0], 'Instructor', null);
    if (!term || !offeringsFiltered.length > 0 || !instructor)
      return 'No Instructor Assigned';
    return `${instructor.firstName} ${instructor.lastName}`;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.metadata}
          >
            {studentName} &bull; N.{courseNumber} &bull; {courseName} &bull; {instructorName}
          </Typography>
          <HoursStatusIcon placement={placement} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.term}
          >
            {termName}
          </Typography>
          <div>
            <SpecialtyChips specialties={specialties} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default PlacementDrawerHeader;

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import CreateContractForm from '../Contract/CreateContractForm';
import _ from 'lodash';

import { CreateIcon } from '../Icons';

import {
  createContract,
  postContractWorkflowLogEntry
} from '../../actions/creators/contracts';
import { fetchContractTypes } from '../../actions/creators/contractTypes';
import { fetchTerms } from '../../actions/creators/terms';
import { fetchUsers } from '../../actions/creators/users';

import { createUserMessage } from '../../actions/creators/userMessages';

import type { Organization, User, Placement } from '../../schemas';

type Props = {
  classes: Object,
  organization: Organization,
  placement: ?Placement,
  mini: ?boolean,
  color: ?string,
  variant: ?string,
  currentUser: User,
  users: User[],
  createContract: Function,
  postContractWorkflowLogEntry: Function,
  fetchContractTypes: Function,
  fetchTerms: Function,
  createUserMessage: Function,
  fetchUsers: Function,
};

type State = {
  dialogVisible: boolean,
  contractRequestTemplate: Object
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  icon: {
    fontSize: 18,
    marginLeft: theme.spacing(1)
  }
});

class CreateContractButton extends Component<Props, State> {
  constructor(props) {
    props.fetchContractTypes();
    props.fetchTerms();
    super(props);
    const { id, phone, fax, contact, contactEmail } = props.organization;
    const startTermId = _.get(props, 'placement.TermId', '');
    const contractType = _.get(
      props,
      'placement.Course.CatalogNumber.CourseAttr.ContractType',
      undefined
    );
    const contractTypes = contractType ? [contractType] : [];

    let contractRequestTemplate = {
      StartTermId: startTermId,
      ContractTypes: contractTypes.map(el => el.id),
      contact,
      contactEmail,
      contactPhone: phone,
      contactFax: fax,
      OrganizationId: id,
      dateRequested: Date.now(),
      ContractStatusCodeId: 'R',
      GNEAddendum: false,
      Notes: ''
    };

    this.state = {
      dialogVisible: false,
      contractRequestTemplate
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  openDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleSubmit = async values => {
    const { currentUser } = this.props;

    values.RequestedById = currentUser.id;

    let result = await this.props.createContract(values);

    if (result) {
      const contract = result.data;

      let workflowItem = {
        ContractId: contract.id,
        UserId: this.props.currentUser.id,
        StatusChangedFromId: null,
        StatusChangedToId: 'R'
      };

      await this.props.postContractWorkflowLogEntry(workflowItem);

      await this.handleNotification({
        message: `${currentUser.name} created a new contract request #${contract.id}.`
      });

      this.handleClose();
    }
  };

  handleNotification = async props => {
    const { createUserMessage, currentUser } = this.props;
    const usersForRole = this.usersForRole('contracts_manager');

    _.each(usersForRole, async user => {
      await createUserMessage({
        message: props.message,
        tag: 'contract',
        SenderId: currentUser.id,
        RecipientId: user.id
      });
    });

    return;
  };

  usersForRole = role => {
    const { users } = this.props;
    return _.filter(users, el => {
      const roles = _.map(el.Roles, 'slug');
      return _.includes(roles, role);
    });
  };

  render() {
    const { classes } = this.props;
    const { dialogVisible, contractRequestTemplate } = this.state;

    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          variant={_.get(this.props, 'variant', 'contained')}
          size={_.get(this.props, 'size', 'medium')}
          className={classes.button}
          onClick={this.openDialog}
        >
          Create Contract Request
          <CreateIcon className={classes.icon} />
        </Button>
        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          aria-labelledby="create-contract-dialog"
        >
          <DialogTitle id="create-contract-dialog">
            Create Contract Request
          </DialogTitle>
          <DialogContent>
            <CreateContractForm
              handleSubmit={this.handleSubmit}
              initialValues={contractRequestTemplate}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    users: state.users.items
  };
};

export default (compose(
  connect(mapStateToProps, {
    createContract, postContractWorkflowLogEntry, fetchContractTypes, fetchTerms,
    createUserMessage, fetchUsers,
  }),
  withStyles(styles, { withTheme: true, name: 'CreateContractButton'})
)(CreateContractButton): any);

// @flow

import {
  FETCH_CONTRACT_STATUS_CODE_TRANSITIONS,
  FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_SUCCESS,
  FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_ERROR
} from '../actions/types';

import type { ContractStatusCodeTransition } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: ContractStatusCodeTransition[]
};

type Action = {
  type:
    | typeof FETCH_CONTRACT_STATUS_CODE_TRANSITIONS
    | typeof FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_SUCCESS
    | typeof FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_CONTRACT_STATUS_CODE_TRANSITIONS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

// @flow

import {
  FETCH_STUDENT_PLACEMENTS,
  FETCH_STUDENT_PLACEMENTS_SUCCESS,
  FETCH_STUDENT_PLACEMENTS_ERROR,
  FETCH_STUDENT_PLACEMENT,
  FETCH_STUDENT_PLACEMENT_SUCCESS,
  FETCH_STUDENT_PLACEMENT_ERROR
} from '../actions/types';
import type { Placement } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Placement>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_STUDENT_PLACEMENTS
    | typeof FETCH_STUDENT_PLACEMENTS_SUCCESS
    | typeof FETCH_STUDENT_PLACEMENTS_ERROR
    | typeof FETCH_STUDENT_PLACEMENT
    | typeof FETCH_STUDENT_PLACEMENT_SUCCESS
    | typeof FETCH_STUDENT_PLACEMENT_ERROR,
  payload: ?Array<Placement> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_STUDENT_PLACEMENTS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENT_PLACEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_STUDENT_PLACEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_STUDENT_PLACEMENT:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENT_PLACEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_STUDENT_PLACEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

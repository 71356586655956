// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { Grid, Typography } from '@material-ui/core';

import StudentList from '../../Students/StudentList';

import type { Student } from '../../../schemas';

type Props = {
  classes: Object,
  students: Student[],
  fetchContractNotes: Function
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  }
});

class PreceptorStudents extends Component<Props> {

  render() {
    const { classes, students } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              Students
            </Typography>
          </Grid>
        </Grid>
        {students && <StudentList students={students} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    students: _.get(
      state.preceptorDrawer.selectedPreceptor,
      'Students',
      undefined
    )
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  PreceptorStudents
): any);

// @flow

import _ from 'lodash';

import {
  FETCH_TOUCHPOINT_TYPES,
  FETCH_TOUCHPOINT_TYPES_SUCCESS,
  FETCH_TOUCHPOINT_TYPES_ERROR
} from '../actions/types';

import type { TouchpointType, ValueListItem } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<TouchpointType>,
  +values: Array<ValueListItem>
};

type Action = {
  type:
    | typeof FETCH_TOUCHPOINT_TYPES
    | typeof FETCH_TOUCHPOINT_TYPES_SUCCESS
    | typeof FETCH_TOUCHPOINT_TYPES_ERROR,
  payload: Array<TouchpointType> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_TOUCHPOINT_TYPES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TOUCHPOINT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: getValues(action.payload)
      };
    case FETCH_TOUCHPOINT_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

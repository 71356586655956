// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Grid, Typography, Chip, Divider} from '@material-ui/core';

import type { Contract } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  }
});

type Props = {
  contract: Contract,
  classes: Object
};

class ContractInfo extends Component<Props> {
  render() {
    const { contract, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle1" gutterBottom>
            Details
          </Typography>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={6}>
              <Typography gutterBottom>
                <strong>Start Term: </strong>
                {contract.StartTerm ? contract.StartTerm.name : 'N/A'}
              </Typography>
              {contract.ContractTypes && (
                <Typography gutterBottom component="span">
                  <strong>Contract Types: </strong>{' '}
                  {contract.ContractTypes.map(contractType => {
                    return (
                      <Chip
                        className={classes.chip}
                        key={contractType.name}
                        label={contractType.name}
                      />
                    );
                  })}
                </Typography>
              )}
              <Typography gutterBottom>
                <strong>DUHS ID: </strong>{' '}
                {contract.duhsId ? contract.duhsId : 'N/A'}
              </Typography>
              <Typography gutterBottom>
                <strong>Special Requirements: </strong>{' '}
                {
                  contract.specialRequirementsExist
                    ? contract.specialRequirementsDescription : 'N/A'
                }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>
                <strong>Auto-Renew: </strong>{' '}
                {contract.isAutoRenew ? 'Yes' : 'No'}
              </Typography>
              <Typography gutterBottom>
                <strong>Start Date: </strong>
                {contract.startDate
                  ? moment(contract.startDate).format('ll')
                  : 'N/A'}
              </Typography>
              {!contract.isAutoRenew && <Typography gutterBottom>
                <strong>End Date: </strong>
                {contract.endDate
                  ? moment(contract.endDate).format('ll')
                  : 'N/A'}
              </Typography>}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" gutterBottom>
            Contact
          </Typography>
          <Divider className={classes.divider} />
          {contract.contact && (
            <Typography gutterBottom>
              <strong>Name: </strong>
              {contract.contact}
            </Typography>
          )}
          {contract.contactEmail && (
            <Typography gutterBottom>
              <strong>Email: </strong>
              <a href={`mailto:${contract.contactEmail}`}>
                {contract.contactEmail}
              </a>
            </Typography>
          )}
          {contract.contactPhone && (
            <Typography gutterBottom>
              <strong>Phone: </strong>
              {contract.contactPhone}
            </Typography>
          )}
          {contract.contactFax && (
            <Typography gutterBottom>
              <strong>Fax: </strong>
              {contract.contactFax}
            </Typography>
          )}

          {contract.RequestedById && (
            <Typography gutterBottom>
              <strong>Requested By: </strong>
              {contract.RequestedBy.name}
            </Typography>
          )}

          {contract.StudentId && (
            <Typography gutterBottom>
              <strong>Associated Student: </strong>
              {contract.Student.fullName}
            </Typography>
          )}

        </Grid>
      </Grid>
    );
  }
}

export default (withStyles(styles)(ContractInfo): any);

export const placement = {
  id: 1188,
  placementGuid: null,
  packageGuid: null,
  placmentStatusCodeText: null,
  postalCode: '27455',
  targetedForPayment: null,
  lat: null,
  lng: null,
  studentNotes: null,
  facultyNotes: null,
  cpoNotes: null,
  shouldDelete: false,
  createdAt: '2018-04-26T17:58:57.000Z',
  updatedAt: '2018-05-16T18:44:16.000Z',
  PackageId: 9848,
  StudentId: 1169671,
  TermId: 1660,
  CourseId: 23099,
  ContractTypeId: null,
  PlacementOriginId: 1,
  CompletionProbabilityId: 4,
  PlacementStatusCodeId: 9,
  managerId: 'obi00002@duke.edu',
  Student: { username: 'undefined@duke.edu', fullName: 'Zula Price' },
  Term: {
    name: '2018 Fall',
    id: 1660,
    createdAt: '2018-02-02T19:53:00.000Z',
    updatedAt: '2018-05-24T20:30:00.000Z'
  },
  Package: {
    id: 9848,
    name: null,
    createdAt: '2018-04-26T17:58:57.000Z',
    updatedAt: '2018-04-26T17:58:57.000Z',
    TermId: 1660,
    CourseId: 23099,
    SiteEnrollments: [
      {
        id: 24799,
        siteHoursAvailable: 0,
        verbalCommitment: false,
        attachmentA: false,
        paidPlacement: false,
        PaymentStatusId: 3,
        SiteId: 1375,
        PackageId: 9848,
        Site: {
          id: 1375,
          name: 'Hospice & Palliative Care of Greensboro',
          Organization: {
            id: 1119,
            name: 'Hospice & Palliative Care of Greensboro',
            phone: '',
            fax: '',
            contact: '',
            contactEmail: null,
            Contracts: [
              {
                contractGroup: 'request',
                id: 3330,
                ContractStatusCodeId: 'CS',
                startDate: null,
                endDate: null,
                isAutoRenew: true,
                duhsId: null,
                ContractTypes: [
                  {
                    id: 'MC',
                    name: 'MSN Clinical',
                    description: 'MSN Clinical',
                    ContractContractType: {
                      id: 5175,
                      ContractId: 3330,
                      ContractTypeId: 'MC'
                    }
                  }
                ],
                ContractStatusCode: {
                  id: 'CS',
                  name: 'Contract Sent',
                  description: 'Contract Sent to the Organization',
                  order: 30,
                  createdAt: '2017-10-19T05:00:00.000Z',
                  updatedAt: '2017-10-19T05:00:00.000Z'
                }
              }
            ]
          },
          Specialties: [
            {
              id: 1,
              name: 'Pediatric-Oncology',
              code: 'PO',
              isActive: false,
              SiteSpecialties: {
                createdAt: '2018-05-18T17:22:36.000Z',
                updatedAt: '2018-05-18T17:22:36.000Z',
                SiteId: 1375,
                SpecialtyId: 1
              }
            },
            {
              id: 4,
              name: 'OB-GYN',
              code: 'OBGYN',
              isActive: false,
              SiteSpecialties: {
                createdAt: '2018-05-18T17:22:36.000Z',
                updatedAt: '2018-05-18T17:22:36.000Z',
                SiteId: 1375,
                SpecialtyId: 4
              }
            },
            {
              id: 9,
              name: 'Adult-Geriatrics',
              code: 'AG',
              isActive: true,
              SiteSpecialties: {
                createdAt: '2018-05-18T17:22:36.000Z',
                updatedAt: '2018-05-18T17:22:36.000Z',
                SiteId: 1375,
                SpecialtyId: 9
              }
            },
            {
              id: 12,
              name: 'Adult-Urgent',
              code: 'AU',
              isActive: true,
              SiteSpecialties: {
                createdAt: '2018-05-18T17:22:36.000Z',
                updatedAt: '2018-05-18T17:22:36.000Z',
                SiteId: 1375,
                SpecialtyId: 12
              }
            }
          ]
        },
        PreceptorEnrollments: [
          {
            id: 7280,
            isPristine: true,
            hasVerbalCommitment: false,
            preceptorHoursAvailable: 102,
            SiteEnrollmentId: 24799,
            PreceptorId: 2799,
            createdAt: '2018-05-22T20:40:20.000Z',
            updatedAt: '2018-05-22T20:40:37.000Z',
            Preceptor: {
              id: 2799,
              lastName: 'Larach',
              firstName: 'May',
              middleName: 'W.',
              name: 'May Larach',
              credentials: 'NP',
              specialties: 'Please Review',
              address: '2500 Summit Avenue',
              addressLine2: '',
              city: 'Greensboro',
              postalCode: '27405',
              phone: '',
              fax: '',
              pager: '',
              isAhecEligible: false,
              ahecLegacy: '',
              county: '',
              license: '',
              certifications: '',
              preceptor: '',
              lastActiveSemester: 'FL2011',
              note: '',
              emailAddress: 'mara.larach@mosescone.com',
              isActive: true,
              activeLegacy: '',
              previousSpecialty: 'Palliative Care',
              npiNumber: null,
              createdAt: '2012-09-13T15:05:12.000Z',
              updatedAt: '2018-02-14T20:14:03.000Z',
              GeocodeStatusId: 3,
              StateId: 'NC'
            }
          },
          {
            id: 7281,
            isPristine: true,
            hasVerbalCommitment: false,
            preceptorHoursAvailable: 0,
            SiteEnrollmentId: 24799,
            PreceptorId: 866,
            createdAt: '2018-05-22T20:40:25.000Z',
            updatedAt: '2018-05-22T20:40:25.000Z',
            Preceptor: {
              id: 866,
              lastName: 'Watkins',
              firstName: 'R. \'Chip\'',
              middleName: '',
              name: 'R. \'Chip\' Watkins',
              credentials: 'MD',
              specialties: '',
              address: '4431 Hwy. 220 North',
              addressLine2: 'POB 220',
              city: 'Greensboro',
              postalCode: '27410',
              phone: '(336) 643-7711',
              fax: '(336) 643-3047',
              pager: '',
              isAhecEligible: false,
              ahecLegacy: '',
              county: '',
              license: '',
              certifications: '',
              preceptor: '',
              lastActiveSemester: '',
              note: '',
              emailAddress: '',
              isActive: true,
              activeLegacy: '',
              previousSpecialty: '',
              npiNumber: null,
              createdAt: '2012-09-13T15:05:12.000Z',
              updatedAt: '2018-02-14T19:40:02.000Z',
              GeocodeStatusId: 3,
              StateId: 'NC'
            }
          }
        ],
        SiteEnrollmentHours: []
      },
      {
        id: 24802,
        siteHoursAvailable: 0,
        verbalCommitment: false,
        attachmentA: false,
        paidPlacement: false,
        PaymentStatusId: 3,
        SiteId: 20871,
        PackageId: 9848,
        Site: {
          id: 20871,
          name: 'Piedmont Pediatrics, P.A. (Cone Health Medical Group)',
          Organization: {
            id: 544,
            name:
              'The Moses H. Cone Memorial Hospital Operating Corporation d/b/a Cone Health',
            phone: '(336) 832-4363',
            fax: '',
            contact: 'Connie Lewter, RN, MSN, CNE',
            contactEmail: 'Connie.Lewter@conehealth.com',
            Contracts: [
              {
                contractGroup: 'active',
                id: 1754,
                ContractStatusCodeId: 'CA',
                startDate: '2013-06-30T06:06:00.000Z',
                endDate: '2018-06-30T06:06:00.000Z',
                isAutoRenew: false,
                duhsId: 7037,
                ContractTypes: [
                  {
                    id: 'MC',
                    name: 'MSN Clinical',
                    description: 'MSN Clinical',
                    ContractContractType: {
                      id: 3277,
                      ContractId: 1754,
                      ContractTypeId: 'MC'
                    }
                  },
                  {
                    id: 'M',
                    name: 'MSN Non-Clinical',
                    description: '',
                    ContractContractType: {
                      id: 3278,
                      ContractId: 1754,
                      ContractTypeId: 'M'
                    }
                  },
                  {
                    id: 'AC',
                    name: 'ABSN Clinical',
                    description: 'ABSN Clinical',
                    ContractContractType: {
                      id: 3279,
                      ContractId: 1754,
                      ContractTypeId: 'AC'
                    }
                  },
                  {
                    id: 'CR',
                    name: 'CRNA',
                    description: '',
                    ContractContractType: {
                      id: 3280,
                      ContractId: 1754,
                      ContractTypeId: 'CR'
                    }
                  },
                  {
                    id: 'D',
                    name: 'DNP Non-Clinical',
                    description: '',
                    ContractContractType: {
                      id: 3281,
                      ContractId: 1754,
                      ContractTypeId: 'D'
                    }
                  }
                ],
                ContractStatusCode: {
                  id: 'CA',
                  name: 'Agreed',
                  description:
                    'Binding Contract has been Signed by Both Parties',
                  order: 70,
                  createdAt: '2017-10-19T05:00:00.000Z',
                  updatedAt: '2014-11-10T21:57:58.000Z'
                }
              }
            ]
          },
          Specialties: []
        },
        PreceptorEnrollments: [
          {
            id: 7286,
            isPristine: true,
            hasVerbalCommitment: true,
            preceptorHoursAvailable: 102,
            SiteEnrollmentId: 24802,
            PreceptorId: 31197,
            createdAt: '2018-05-24T20:39:28.000Z',
            updatedAt: '2018-05-24T20:39:44.000Z',
            Preceptor: {
              id: 31197,
              lastName: 'Hooker',
              firstName: 'James',
              middleName: 'B',
              name: 'James Hooker',
              credentials: 'MD',
              specialties: null,
              address: '719 Green Valley',
              addressLine2: 'Suite 209',
              city: 'Greensboro',
              postalCode: '27408',
              phone: '336-272-9447',
              fax: '336-272-2112',
              pager: '',
              isAhecEligible: true,
              ahecLegacy: null,
              county: null,
              license: '',
              certifications: '',
              preceptor: null,
              lastActiveSemester: '',
              note: null,
              emailAddress: 'bhook93@gmail.com',
              isActive: true,
              activeLegacy: null,
              previousSpecialty: null,
              npiNumber: null,
              createdAt: '2014-12-05T09:45:00.000Z',
              updatedAt: '2018-02-14T20:47:03.000Z',
              GeocodeStatusId: 3,
              StateId: 'NC'
            }
          }
        ],
        SiteEnrollmentHours: []
      },
      {
        id: 24807,
        siteHoursAvailable: 0,
        verbalCommitment: false,
        attachmentA: false,
        paidPlacement: false,
        PaymentStatusId: 3,
        SiteId: 763,
        PackageId: 9848,
        Site: {
          id: 763,
          name: 'James O. Spruill, MD',
          Organization: {
            id: 594,
            name: 'James O. Spruill, MD',
            phone: '(336) 276-6911',
            fax: '',
            contact: 'Ms. Raleigh',
            contactEmail: null,
            Contracts: []
          },
          Specialties: []
        },
        PreceptorEnrollments: [
          {
            id: 7288,
            isPristine: true,
            hasVerbalCommitment: false,
            preceptorHoursAvailable: 0,
            SiteEnrollmentId: 24807,
            PreceptorId: 866,
            createdAt: '2018-05-24T20:48:20.000Z',
            updatedAt: '2018-05-24T20:48:20.000Z',
            Preceptor: {
              id: 866,
              lastName: 'Watkins',
              firstName: 'R. \'Chip\'',
              middleName: '',
              name: 'R. \'Chip\' Watkins',
              credentials: 'MD',
              specialties: '',
              address: '4431 Hwy. 220 North',
              addressLine2: 'POB 220',
              city: 'Greensboro',
              postalCode: '27410',
              phone: '(336) 643-7711',
              fax: '(336) 643-3047',
              pager: '',
              isAhecEligible: false,
              ahecLegacy: '',
              county: '',
              license: '',
              certifications: '',
              preceptor: '',
              lastActiveSemester: '',
              note: '',
              emailAddress: '',
              isActive: true,
              activeLegacy: '',
              previousSpecialty: '',
              npiNumber: null,
              createdAt: '2012-09-13T15:05:12.000Z',
              updatedAt: '2018-02-14T19:40:02.000Z',
              GeocodeStatusId: 3,
              StateId: 'NC'
            }
          }
        ],
        SiteEnrollmentHours: []
      }
    ]
  },
  Course: {
    id: 23099,
    ssrComponent: 'CLN',
    academicCareer: 'NURS',
    academicOrganization: 'NURSING',
    subject: 'NURSING',
    courseOfferNumber: 1,
    primaryComponent: 'CLN',
    unitsMax: 2,
    unitsMin: 2,
    description: 'ADV PRAC NUR OLDER ADULTS CLIN',
    status: 'A',
    effectiveDate: '2015-01-01',
    CatalogNumberId: 625,
    createdAt: '2018-02-02T19:53:00.000Z',
    updatedAt: '2018-05-24T20:30:01.000Z',
    CatalogNumber: {
      id: 625,
      ssrComponent: 'CLN',
      CourseAttr: {
        isClinical: true,
        isApplicable: true,
        id: 196,
        title: 'Advanced Practice Nursing Care of Older Adult Patients',
        minHrs: 112,
        maxHrs: 112,
        specialties: null,
        clinicalDescr:
          'In this course, advanced practice nursing students conduct comprehensive assessments, formulate differential diagnoses, and suggest plans of care to\r\nmanage acute and chronic health problems in older adults. The emphasis is on health promotion, disease prevention,\r\nevidence-based practice and inter-professional collaboration across geriatric settings. Prerequisite: Nursing 620, Corequisite:\r\nNursing 621 and 624. 2 credits.',
        clinicalType: null,
        courseSiteType: null,
        createdAt: '2015-06-17T11:19:54.000Z',
        updatedAt: '2015-11-11T15:45:24.000Z',
        CatalogNumberId: 625,
        ContractTypeId: 'MC',
        ContractType: {
          id: 'MC',
          name: 'MSN Clinical',
          description: 'MSN Clinical'
        }
      }
    },
    Specialties: []
  },
  ContractType: null,
  PlacementOrigin: {
    id: 1,
    name: 'Matriculation Plan',
    createdAt: '2017-10-19T05:00:00.000Z',
    updatedAt: '2017-10-19T05:00:00.000Z'
  },
  CompletionProbability: {
    id: 4,
    name: 'Unsure',
    createdAt: '2017-10-19T05:00:00.000Z',
    updatedAt: '2017-10-19T05:00:00.000Z'
  },
  PlacementStatusCode: {
    id: 9,
    name: 'Pending Approval',
    order: 8,
    createdAt: '2017-10-19T05:00:00.000Z',
    updatedAt: '2017-10-19T05:00:00.000Z'
  },
  PlacementApp: null,
  WorkspaceItems: [
    {
      id: 56,
      model: 'preceptor',
      foreignKey: 1935,
      PlacementId: 1188,
      name: 'Christopher Berge'
    },
    {
      id: 60,
      model: 'preceptor',
      foreignKey: 2528,
      PlacementId: 1188,
      name: 'John Knapp'
    }
  ]
};

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import { logoutUser } from '../../actions/creators/auth';

type Props = {
  logoutUser: Function,
  classes: Object
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '84px',
    padding: theme.spacing(2)
  },
  pageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%'
  },
  boxContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 1,
    boxShadow: theme.shadows[2]
  }
});

class Logout extends Component<Props> {
  componentDidMount() {
    this.props.logoutUser();
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.pageContainer} spacing={2}>
          <Grid container item className={classes.boxContainer + ' login-box'}>
            <h3>You have logged out.</h3>
            <Button variant="contained" color="primary" href="/">
              Log In
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default (compose(withStyles(styles), connect(null, { logoutUser }))(
  Logout
): any);

// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

import {
  Grid, AppBar, Toolbar, FormControl, Select, MenuItem, Typography
} from '@material-ui/core';

import { DataGrid } from 'devextreme-react';
import { MasterDetail, Column } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';

import { fetchReportPlacements } from '../../actions/creators/reports';
import { fetchTerms } from '../../actions/creators/terms';
import { fetchPlacementStatuses } from '../../actions/creators/placementStatuses';
import { fetchStates } from '../../actions/creators/states';

import {
  hasAllContracts, siteAttachmentAsSigned
} from '../../utils/placementHelpers';

import { getAllStateValues } from '../../selectors/states';
import { getCurrentUsersWorkingTerm } from '../../selectors/users';
import { getAllTermValuesWithAttrs, getActiveTerm } from '../../selectors/terms';
import {
  getReportPlacements, getAllPlacementStatusValues
} from '../../selectors/placements';

import CommonStyles from '../../styles/CommonStyles';
import LoadingIndicator from '../../components/LoadingIndicator';
import DataGridDefaults from '../../utils/data-grid-defaults';
import DetailView from './PlacementsDetail';

import type { Term, Placement } from '../../schemas';

type Props = {
  classes: Object,
  placements: Placement[],
  placementStatuses: any[],
  states: any[],
  terms: Term[],
  activeTerm: Term,
  workingTerm: Term,
  fetchReportPlacements: Function,
  fetchPlacementStatuses: Function,
  fetchTerms: Function,
  fetchPermissions: Function,
  fetchStates: Function,
};

type State = {
  selectedTermId: any,
  loading: boolean,
  dataGridInstance: Object,
};

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      padding: theme.spacing(2)
    },
    flex: { flex: 1 },
    paper: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    cell: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    cellCenter: {
      width: '100%',
      textAlign: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    input: { width: '100%' },
    icon: { margin: theme.spacing(1) },
    iconPlus: {
      margin: theme.spacing(1),
      color: green[500]
    },
    iconMinus: {
      margin: theme.spacing(1),
      color: red[500]
    },
    iconPlusMinus: {
      margin: theme.spacing(1)
    },
    selectInput: {
      width: '100%',
      fontSize: '1.5em',
    },
    chip: { margin: theme.spacing(1) },
    chipArray: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    chipWarn: {
      margin: theme.spacing(1),
      backgroundColor: commonStyles.warn.backgroundColor,
      color: commonStyles.warn.color
    },
    chipActive: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    chipRow: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    formControl: {
      minWidth: 200,
      backgroundColor: theme.palette.grey[300]
    },
    defaultColor: {
      backgroundColor: theme.palette.grey[300]
    },
    filters: {
      marginBottom: theme.spacing(1)
    },
  };
  return classes;
};

class PlacementReport extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      selectedTermId: '',
      dataGridInstance: {},
      loading: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchStates();
    await this.props.fetchPlacementStatuses();
    if (this.props.workingTerm) {
      const selectedTermId = this.props.workingTerm.id;
      this.setState(state => ({ loading: !state.loading, selectedTermId }));
      await this.props.fetchReportPlacements({ id: selectedTermId });
      this.setState(state => ({ loading: !state.loading }));
    }
  };

  handleTermChange = async e => {
    await this.setState({ loading: true });
    await this.props.fetchReportPlacements({ id: e.target.value });
    this.setState({ selectedTermId: e.target.value, loading: false });
  };

  renderTermDropdown = () => {
    const { terms } = this.props;
    return (
      <FormControl fullWidth>
        <Select
          name="selected-term"
          id="selected-term-simple"
          value={this.state.selectedTermId}
          onChange={this.handleTermChange}
        >
          {terms.map(el => (
            <MenuItem key={el.id} value={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    );
  };

  renderTableHeader = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.flex} />
          {this.renderTermDropdown()}
        </Toolbar>
      </AppBar>
    );
  };

  getAllPreceptors = enrollments => {
    const preceptors = [];
    _.each(enrollments, enrollment => {
      _.each(enrollment.PreceptorEnrollments, preceptorEnrollment => {
        const { Preceptor: el } = preceptorEnrollment;
        const preceptor = {
          id: el.id,
          name: _.get(el, 'name', 'N/A'),
          email: _.get(el, 'emailAddress', '')
        };
        preceptors.push(preceptor);
      });
    });

    return _.uniqBy(preceptors, 'id');
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return(
      <div className={classes.root}>
        <LoadingIndicator loading={loading} overlay={true} />
        <Typography variant="h5" paragraph>
          Placement Report
        </Typography>
        <Grid container spacing={8} className={classes.filters}>
          <Grid item xs={3}>
            {this.renderTermDropdown()}
          </Grid>
          <Grid item xs={3}>&nbsp;</Grid>
          <Grid item xs={3}>&nbsp;</Grid>
          <Grid item xs={3}>&nbsp;</Grid>
        </Grid>

        <DataGrid
          {...DataGridDefaults}
          id={'masterGrid'}
          dataSource={ new DataSource({
            store: this.props.placements,
            reshapeOnPush: true
          })}
          wordWrapEnabled={true}
          filterSyncEnabled={true}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          loadPanel={{ enabled: false }}
          groupPanel={{ visible: true }}
          grouping={{ contextMenuEnabled: true }}
          searchPanel={{ visible: true, width: 180 }}
          editing={{ mode: 'none' }}
          selection={{ mode: 'none' }}
          noDataText="No data for this academic term..."
          onInitialized={el => {
            this.setState({ dataGridInstance: el.component });
          }}
          export={{
            enabled: true,
            excelFilterEnabled: true,
            allowExportSelectedData: false,
            fileName: 'placement-report',
          }}
          onExporting={e => {
            e.component.beginUpdate();
            e.component.columnOption('notes', 'visible', true);
          }}
          onExported={e => {
            e.component.columnOption('notes', 'visible', false);
            e.component.endUpdate();
          }}
          customizeExportData={columns => {
            _.each(columns, column => {
              if (column.name === 'notes') {
                column.width = 480;
              }
            });
          }}
        >
          <Column
            name={'coordinator'}
            caption={'Coordinator'}
            dataType={'string'}
            calculateCellValue={
              row => _.get(row, 'manager.name', 'N/A')
            }
            minWidth={ 120 }
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name={'course'}
            caption={'Course'}
            dataType={'string'}
            calculateCellValue={
              row => _.get(row, 'Course.CatalogNumberId', 'N/A')
            }
            minWidth={ 90 }
            alignment={'center'}
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
            sortIndex={ 0 }
            sortOrder={'asc'}
          />
          <Column
            name={'studentFirstName'}
            caption={'First Name'}
            dataField={'Student.firstName'}
            dataType={'string'}
            minWidth={ 90 }
          />
          <Column
            name={'studentLastName'}
            caption={'Last Name'}
            dataField={'Student.lastName'}
            dataType={'string'}
            minWidth={ 90 }
          />
          <Column
            name={'studentCity'}
            caption={'City'}
            dataField={'Student.mailingCity'}
            dataType={'string'}
            minWidth={ 120 }
          />
          <Column
            name={'studentState'}
            caption={'State'}
            dataField={'Student.mailingState'}
            dataType={'string'}
            minWidth={ 90 }
            alignment={'center'}
            lookup={{
              dataSource: {
                store: this.props.states,
                sort: 'id'
              },
              valueExpr: 'id',
              displayExpr: 'id'
            }}
          />
          <Column
            name={'studentFullName'}
            caption={'Full Name'}
            dataField={'Student.fullName'}
            dataType={'string'}
            visible={false}
            minWidth={ 120 }
          />
          <Column
            name={'preceptors'}
            caption={'Preceptors'}
            dataType={'string'}
            calculateCellValue={row => {
              const enrollments = _.get(row, 'Package.SiteEnrollments', []);
              const preceptors = this.getAllPreceptors(enrollments);
              return _.map(preceptors, 'name').join(', ');
            }}
            minWidth={ 180 }
            allowFiltering={true}
            allowSearch={true}
          />
          <Column
            name={'preceptorEmails'}
            caption={'Preceptor Emails'}
            dataType={'string'}
            calculateCellValue={row => {
              const enrollments = _.get(row, 'Package.SiteEnrollments', []);
              const preceptors = this.getAllPreceptors(enrollments);
              return _.map(preceptors, 'email').join(', ');
            }}
            minWidth={ 180 }
            allowFiltering={true}
            allowSearch={true}
          />
          <Column
            name={'status'}
            caption={'Status'}
            dataField={'PlacementStatusCode.id'}
            dataType={'string'}
            minWidth={ 180 }
            lookup={{
              dataSource: {
                store: this.props.placementStatuses,
                sort: 'name'
              },
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          />
          <Column
            name={'notes'}
            caption={'Notes'}
            dataType={'string'}
            calculateCellValue={row => {
              const notes = _.get(row, 'Notes', []);
              return _.map(notes, el => {
                return `[${moment(el.createdAt).format('MM/DD/YY')}] - ${el.description}`;
              }).join(', ');
            }}
            minWidth={ 300 }
            visible={false}
          />
          <Column
            name={'hasContracts'}
            caption={'Contracts?'}
            dataType={'boolean'}
            calculateCellValue={
              row => hasAllContracts(row)
            }
            visible={false}
            allowFiltering={true}
            trueText={'Yes'}
            falseText={'No'}
          />
          <Column
            name={'hasAttachmentAs'}
            caption={'AttachmentAs?'}
            dataType={'boolean'}
            calculateCellValue={row => {
              const siteEnrollments = _.get(row, 'Package.SiteEnrollments', []);
              const allSigned = [];
              if (siteEnrollments.length > 0) {
                _.each(siteEnrollments, siteEnrollment => {
                  allSigned.push({ signed: siteAttachmentAsSigned(row, siteEnrollment)});
                });
                return _.every(allSigned, { signed: true });
              }
              return false;
            }}
            visible={false}
            allowFiltering={true}
            trueText={'Yes'}
            falseText={'No'}
          />
          <Column
            name={'hasNotes'}
            caption={'Notes?'}
            dataType={'boolean'}
            calculateCellValue={row => {
              const notes = _.get(row, 'Notes', []);
              return notes.length > 0 ? true : false;
            }}
            visible={false}
            allowFiltering={true}
            trueText={'Yes'}
            falseText={'No'}
          />
          <Column
            name={'isClinical'}
            caption={'Clinical?'}
            dataType={'boolean'}
            calculateCellValue={
              row => _.get(row, 'Course.CatalogNumber.CourseAttr.isClinical', false)
            }
            visible={false}
            allowFiltering={true}
            trueText={'Yes'}
            falseText={'No'}
          />
          <Column
            name={'isApplicable'}
            caption={'Applicable?'}
            dataType={'boolean'}
            calculateCellValue={
              row => _.get(row, 'Course.CatalogNumber.CourseAttr.isApplicable', false)
            }
            visible={true}
            allowFiltering={true}
            trueText={'Yes'}
            falseText={'No'}
          />
          <MasterDetail
            enabled={true}
            component={DetailView}
            autoExpandAll={false}
          />
        </DataGrid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  placements: getReportPlacements(state),
  placementStatuses: getAllPlacementStatusValues(state),
  terms: getAllTermValuesWithAttrs(state),
  activeTerm: getActiveTerm(state),
  workingTerm: getCurrentUsersWorkingTerm(state),
  states: getAllStateValues(state),
});

export default (compose(
  withStyles(styles, { withTheme: true, name: 'PlacementReport'}),
  connect(mapStateToProps, {
    fetchReportPlacements, fetchPlacementStatuses, fetchTerms, hasAllContracts,
    siteAttachmentAsSigned, fetchStates
  })
)(PlacementReport): any);

// @flow

import _ from 'lodash';
import moment from 'moment';
import FileDownload from 'js-file-download';
import axios from '../../api';

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import { Scrollbar } from 'react-scrollbars-custom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AttachmentADetails from './AttachmentADetails';
import EditAttachmentAForm from './EditAttachmentAForm';
import AttachmentList from '../Attachment/AttachmentList';

import { hasAccess } from '../../utils/permissionsCheck';
import {
  updateAttachmentA,
  postAttachmentAWorkflowLogEntry
} from '../../actions/creators/attachmentAs';
import { createUserMessage } from '../../actions/creators/userMessages';

import { fetchAttachmentA } from '../../actions/creators/attachmentAs';
import { fetchPermissions } from '../../actions/creators/permissions';

import CreateAttachmentButton from '../Attachment/CreateAttachmentButton';
import WorkflowEntriesPanel from '../WorkflowEntriesPanel';

type Props = {
  classes: Object;
  attachmentAId: number;
  selectedAttachments: any[];
  handleClose: Function;
  afterUpdate: Function;
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  },
  section: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(1)
  }
});

const AttachmentADetail: any = withStyles(styles)((props: Props) => {
  const {classes,attachmentAId,handleClose,afterUpdate,selectedAttachments} = props;
  const dispatch = useDispatch();

  const [attachmentA,setAttachmentA] = React.useState(undefined);

  const currentUser = useSelector(state => state.auth.currentUser);
  const permissions = useSelector(state => state.permissions.attachmentA);
  const users = useSelector(state => state.users.items);

  const logEntries = _.get(attachmentA, 'AttachmentAWorkflowLogEntries', []);

  function canUpdate() {
    return hasAccess(currentUser.Roles, permissions.update);
  }

  async function downloadFile(id: number) {
    const endpoint = `/attachmentA/${id}/generate`;
    let { data } = await axios.get(endpoint, { responseType: 'blob' });
    const timestamp = moment().format('YYYYMMDD');
    const fileName = `${timestamp}-attachmentA-${id}.docx`;
    FileDownload(data, fileName);
  }

  function usersForRole(role) {
    return _.filter(users, user => {
      const roles = _.map(user.Roles, 'slug');
      return _.includes(roles, role);
    });
  }

  async function handleNotification(el) {
    const arr = usersForRole('contracts_manager');
    _.each(arr, async user => {
      await createUserMessage({
        message: el.message,
        tag: 'attachment-a',
        RecipientId: user.id,
        SenderId: currentUser.id
      });
    });
  }

  function enrollmentsChanged(values) {
    const oldIds = _.map(attachmentA?.PreceptorEnrollments, el => el.id);
    const newIds = _.map(values.PreceptorEnrollments, el => el.id);
    return !_.isEqual(oldIds.sort(), newIds.sort());
  }

  async function handleSubmit(values) {
    let messageSent = false;

    if (attachmentA?.AttachmentAStatusCodeId !== values.AttachmentAStatusCodeId) {
      const workflowItem = {
        AttachmentAId: attachmentA?.id,
        UserId: currentUser.id,
        StatusChangedToId: values.AttachmentAStatusCodeId
      };
      dispatch(postAttachmentAWorkflowLogEntry(workflowItem));
      handleNotification({
        message: `${currentUser.name} changed the status on AttachmentA #${attachmentA?.id ?? ''}.`
      });
      messageSent = true;
    }

    let result = await dispatch(updateAttachmentA(values));

    if (result) {
      if (enrollmentsChanged(result)) {
        handleNotification({
          message: `${currentUser.name} changed Preceptor Enrollment(s) on AttachmentA #${attachmentA?.id ?? ''}.`
        });
        messageSent = true;
      }
      if (!messageSent) {
        handleNotification({
          message: `${currentUser.name} updated AttachmentA #${attachmentA?.id ?? ''}.`
        });
      }
      handleClose();
      dispatch(afterUpdate(true));
    }
  }

  React.useEffect(() => {
    dispatch(fetchAttachmentA(attachmentAId))
      .then(data => setAttachmentA(data));
    dispatch(fetchPermissions());
  },[attachmentAId]);

  return (
    <div className={classes.root}>
      {attachmentA && (
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              Attachment A Details
            </Typography>
            <AttachmentADetails attachmentA={attachmentA} />
            {logEntries.length > 0 && (
              <div className={classes.section}>
                <WorkflowEntriesPanel entries={logEntries} />
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.section}
            >
              Attachment A Files
            </Typography>
            {selectedAttachments.length && (
              <Scrollbar style={{ width: '100%', height: '320px' }}>
                <AttachmentList attachments={selectedAttachments} />
              </Scrollbar>
            )}
            {canUpdate() && (
              <CreateAttachmentButton
                model="ContractRequest"
                foreignKey={attachmentA?.ContractId}
                label="Upload Attachment A File"
                attachmentName="Attachment A"
              />
            )}
            {attachmentA?.AttachmentAStatusCode?.name === 'Requested' && (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => {
                  downloadFile(attachmentA?.id);
                }}
              >
                Download Word Doc
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {canUpdate() && (
              <EditAttachmentAForm
                initialValues={attachmentA}
                handleSubmit={handleSubmit}
              />
            )}
          </Grid>

        </Grid>
      )}
    </div>
  );
});

export default AttachmentADetail;

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const fetchPreceptors = (sparse=false) => async dispatch => {
  const uri = sparse ? '/preceptor/sparse' : '/preceptor';
  try {
    dispatch({ type: actions.FETCH_PRECEPTORS, payload: {sparse} });
    const res = await axios.get(uri);
    dispatch({ type: actions.FETCH_PRECEPTORS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PRECEPTORS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPreceptorList = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PRECEPTORS, payload: undefined });
    const res = await axios.get('/preceptor/list');
    dispatch({ type: actions.FETCH_PRECEPTORS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PRECEPTORS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchPreceptor = preceptor => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PRECEPTOR, payload: undefined });
    const res = await axios.get(`/preceptor/${preceptor.id}`);
    dispatch({ type: actions.FETCH_PRECEPTOR_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PRECEPTOR_ERROR,
      payload: error.response.data
    });
  }
};

export const createPreceptor = preceptor => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_PRECEPTOR, payload: preceptor });
    const res = await axios.post('/preceptor', preceptor);
    dispatch({ type: actions.CREATE_PRECEPTOR_SUCCESS, payload: res.data });
    dispatch(fetchPreceptors());
    dispatch(
      notify({
        title: 'Success',
        message: 'Preceptor successfully created',
        status: 'success',
      })
    );
    return res.data;
  } catch (err) {
    dispatch({ type: actions.CREATE_PRECEPTOR_ERROR, payload: err });
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create preceptor.',
        status: 'error',
      })
    );
  }
};

export const updatePreceptor = preceptor => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_PRECEPTOR, payload: preceptor });
    const res = await axios.put(`/preceptor/${preceptor.id}`, preceptor);
    dispatch({ type: actions.UPDATE_PRECEPTOR_SUCCESS, payload: res.data });
    dispatch(fetchPreceptorList());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_PRECEPTOR_ERROR,
      payload: error.response.data
    });
  }
};

export const mergePreceptors = options => async dispatch => {
  try {
    dispatch({ type: actions.MERGE_PRECEPTORS, payload:  options });
    await axios.post('/preceptor/merge', options);
    dispatch({ type: actions.MERGE_PRECEPTORS_SUCCESS });
    dispatch(fetchPreceptors());
  } catch (err) {
    dispatch({
      type: actions.MERGE_PRECEPTORS_ERROR,
      payload: err.response.data
    });
  }
};

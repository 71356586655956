
import _ from 'lodash';
import axios from '../../api';
import * as actions from '../types';
import { fetchStudentPlacements } from './studentPlacements';

export const fetchApplications = (student = {}) => async dispatch => {
  const url = _.get(student, 'id', null)
    ? `/placementApp/student/${student.id}`
    : `/placementApp`;
  try {
    dispatch({ type: actions.FETCH_APPLICATIONS, payload: undefined });
    const res = await axios.get(url);
    dispatch({ type: actions.FETCH_APPLICATIONS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_APPLICATIONS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchApplication = application => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_APPLICATION, payload: application });
    const res = await axios.get(`/placementApp/${application.id}`);
    dispatch({ type: actions.FETCH_APPLICATION_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_APPLICATION_ERROR,
      payload: error
    });
  }
};

export const fetchPlacementApplication = (student, placement) => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PLACEMENT_APPLICATION, payload: undefined });
    const res = await axios.get(`/placementApp/${student.id}/${placement.id}`);
    dispatch({ type: actions.FETCH_PLACEMENT_APPLICATION_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({
      type: actions.FETCH_PLACEMENT_APPLICATION_ERROR,
      payload: err
    });
  }
};

export const createApplication = application => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_APPLICATION, payload: application });
    const res = await axios.post('/placementApp', application);
    dispatch({ type: actions.CREATE_APPLICATION_SUCCESS, payload: res.data });
    dispatch(fetchApplications({ id: application.StudentId }));
    dispatch(fetchStudentPlacements({ id: application.StudentId }));
  } catch (err) {
    dispatch({
      type: actions.CREATE_APPLICATION_ERROR,
      payload: err.response.data
    });
  }
};

export const updateApplication = application => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_APPLICATION, payload: application });
    const res = await axios.put(`/placementApp/${application.id}`, application);
    dispatch({ type: actions.UPDATE_APPLICATION_SUCCESS, payload: res.data });
    dispatch(fetchApplications({ id: application.StudentId }));
    dispatch(fetchStudentPlacements({ id: application.StudentId }));
  } catch (err) {
    dispatch({
      type: actions.UPDATE_APPLICATION_ERROR,
      payload: err.response.data
    });
  }
};

export const deleteApplication = application => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_APPLICATION, payload: application });
    const res = await axios.delete(`/placementApp/${application.id}`);
    dispatch({ type: actions.DELETE_APPLICATION_SUCCESS, payload: res.data });
    dispatch(fetchApplications({ id: application.StudentId }));
    dispatch(fetchStudentPlacements({ id: application.StudentId }));
  } catch (err) {
    dispatch({
      type: actions.DELETE_APPLICATION_ERROR,
      payload: err.response.data
    });
  }
};

export const clearApplications = () => async dispatch => {
  dispatch({ type: actions.CLEAR_APPLICATIONS, payload: [] });
};

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import StudentDetail from './StudentDetail';

import type { Student } from '../../schemas';

type Props = {
  classes: Object,
  students: Student[],
  afterAction: Function
};

const styles = theme => ({
  emptyMessage: {
    marginLeft: theme.spacing(1.5)
  }
});

class StudentList extends Component<Props> {
  render() {
    const { students, afterAction, classes } = this.props;

    return (
      <Grid container spacing={2}>
        {students.length === 0 && (
          <Typography className={classes.emptyMessage}>
            There are no students associated with this preceptor.
          </Typography>
        )}
        {students.length > 0 &&
          students.map(student => {
            return (
              <Grid item xs={12} sm={6} md={4} key={student.id}>
                <StudentDetail afterAction={afterAction} student={student} />
              </Grid>
            );
          })}
      </Grid>
    );
  }
}

export default (withStyles(styles)(StudentList): any);

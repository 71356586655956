import axios from '../../api';
import * as actions from '../types';

export const fetchAmendmentStatusCodes = () => async dispatch => {
  try {
    dispatch({
      type: actions.FETCH_AMENDMENT_STATUS_CODES,
      payload: undefined
    });
    const res = await axios.get('/amendmentStatusCode');
    dispatch({
      type: actions.FETCH_AMENDMENT_STATUS_CODES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_AMENDMENT_STATUS_CODES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Grid,
  Typography
} from '@material-ui/core';

import ContractItem from './ContractItem';

import type { Contract } from '../../schemas';

type Props = {
  classes: Object,
  contracts: Contract[]
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
});

class ContractList extends Component<Props> {

  renderContracts = () => {
    const { contracts } = this.props;

    return _.map(contracts, contract => {
      return (<ContractItem key={contract.id} item={contract} />);
    });
  };

  render() {
    const { contracts } = this.props;

    return (
      <Grid container spacing={2}>
        {contracts.length === 0 && (
          <Typography>There are no contracts</Typography>
        )}
        {contracts.length > 0 && this.renderContracts()}
      </Grid>
    );
  }
}

export default (withStyles(styles)(ContractList): any);

import axios from '../../api';
import * as actions from '../types';

export const fetchCountries = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_COUNTRIES, payload: undefined });
    const res = await axios.get('/country');
    dispatch({ type: actions.FETCH_COUNTRIES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_COUNTRIES_ERROR,
      payload: error.response.data
    });
  }
};

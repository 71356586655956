// @flow

import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { AttachmentAIcon } from '../Icons';

import type { Placement } from '../../schemas';
import CommonStyles from '../../styles/CommonStyles';
import { Theme } from '@material-ui/core/styles';
import { hasAllAttachmentAs } from '../../utils/placementHelpers';

type Props = {
  placement: Placement,
  theme: typeof Theme
};

function AttachmentAStatusIcon(props: Props) {
  const { placement, theme } = props;
  const commonStyles = CommonStyles(theme);
  const isCovered = hasAllAttachmentAs(placement);

  const iconColor = isCovered
    ? commonStyles.success.backgroundColor
    : commonStyles.warn.backgroundColor;

  const titleText = isCovered
    ? 'AttachmentAs Signed'
    : 'AttachmentAs Needed';

  return (
    <Tooltip placement="top" title={titleText}>
      <AttachmentAIcon fontSize="small" style={{ color: iconColor }} />
    </Tooltip>
  );
}

export default (withTheme(AttachmentAStatusIcon): any);

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import {
  createApplication,
  updateApplication,
  deleteApplication
} from '../../actions/creators/studentApplications';
import { fetchSpecialties } from '../../actions/creators/specialties';
import { createUserMessage } from '../../actions/creators/userMessages';
import { fetchUsers } from '../../actions/creators/users';

import ApplicationForm from './ApplicationForm';
import type { PlacementApp, Placement, User, Student } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
});

type Props = {
  selectedApplication: PlacementApp,
  selectedPlacement: Placement,
  canUpdate: boolean,
  canDelete: boolean,
  classes: Object,
  users: Array<User>,
  currentUser: User,
  currentStudent: Student,
  fetchSpecialties: Function,
  createApplication: Function,
  updateApplication: Function,
  deleteApplication: Function,
  handleDelete: Function,
  handleClose: Function,
  createUserMessage: Function,
  fetchUsers: Function,
};

class StudentApplicationDetail extends Component<Props> {

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchUsers();
    await this.props.fetchSpecialties();
  };

  handleSubmit = async values => {
    const { currentUser, currentStudent, selectedPlacement, createUserMessage } = this.props;

    if (values.id) {
      this.props.updateApplication(values);
      this.props.handleClose();
    } else {
      values.PlacementAppStatusId = 1;
      values.StudentId = currentStudent.id;
      if (selectedPlacement) {
        values.PlacementId = selectedPlacement.id;
        values.TermId = selectedPlacement.TermId;
      }
      if (!selectedPlacement) {
        const message = `${currentStudent.fullName} has submitted a non-linked Placement Application.`;
        const users = this.getUsersForRole('cpo_manager');
        _.each(users, async user => {
          await createUserMessage({
            message: message,
            tag: 'application',
            RecipientId: user.id,
            SenderId: currentUser.id
          });
        });
      }
      this.props.createApplication(values);
      this.props.handleClose();
    }
  };

  getUsersForRole = slug => {
    return _.filter(this.props.users, user => {
      const roles = _.map(user.Roles, 'slug');
      return _.includes(roles, slug);
    });
  };

  getUserForUsername = username => {
    return _.find(this.props.users, { username: username });
  };

  handleDelete = async () => {
    const { deleteApplication, selectedApplication, handleClose } = this.props;

    const confirm = window.confirm(
      'Are you sure you want to delete this application?'
    );
    if (confirm) {
      await deleteApplication(selectedApplication);
    }
    handleClose();
  };

  getInitialValues = () => {
    const { selectedPlacement, selectedApplication } = this.props;
    if (!selectedApplication && !selectedPlacement) {
      return {
        PlacementAppDay: {
          monday: false, tuesday: false, wednesday: false,
          thursday: false, friday: false, saturday: false,
          sunday: false,
        },
        PlacementAppShift: {
          days: false, nights: false, weekends: false,
        }
      };
    } else if (!selectedApplication && selectedPlacement) {
      return {
        TermId: selectedPlacement.TermId,
        CourseId: selectedPlacement.CourseId,
        PlacementAppDay: {
          monday: false, tuesday: false, wednesday: false,
          thursday: false, friday: false, saturday: false,
          sunday: false,
        },
        PlacementAppShift: {
          days: false, nights: false, weekends: false,
        }
      };
    }

    return selectedApplication;
  };

  render() {
    const { classes } = this.props;
    const initialValues = this.getInitialValues();

    return (
      <div className={classes.root}>
        <ApplicationForm
          canUpdate={this.props.canUpdate}
          canDelete={this.props.canDelete}
          handleCancel={this.props.handleClose}
          handleSubmit={this.handleSubmit}
          handleDelete={this.handleDelete}
          selectedPlacement={this.props.selectedPlacement}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    specialties: state.specialties.items
  };
};

export default (compose(
  withStyles(styles, {
    name: 'StudentApplicationDetail'
  }),
  connect(mapStateToProps, {
    fetchUsers,
    fetchSpecialties,
    createApplication,
    updateApplication,
    deleteApplication,
    createUserMessage
  })
)(StudentApplicationDetail): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { addendumValidation } from '../../validation';
import { TextField } from 'formik-material-ui';

import {
  Grid, Button, MenuItem,
  Typography, Divider, ListItemText,
} from '@material-ui/core';

import { fetchAddendumStatusCodes } from '../../actions/creators/addendumStatusCodes';
import type { Addendum, ValueListItem } from '../../schemas';

const styles = theme => ({
  divider: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    padding: '2em 0 1em'
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  }
});

type Props = {
  classes: Object,
  initialValues: Addendum,
  statusCodes: ValueListItem[],
  handleSubmit: Function,
  handleDelete: Function,
  fetchAddendumStatusCodes: Function,
  canUpdate: Function,
  canDelete: Function,
};

class EditAddendumForm extends Component<Props> {

  componentDidMount() {
    this.props.fetchAddendumStatusCodes();
  }

  render() {
    const {
      initialValues, classes, statusCodes,
      canUpdate, canDelete
    } = this.props;

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={addendumValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Typography variant="h6" gutterBottom>
              GNE Addendum Details
            </Typography>
            <Divider className={classes.divider} />
            <Typography gutterBottom>
              <strong>Start Term</strong> {initialValues.StartTerm.name}
            </Typography>
            <Typography gutterBottom>
              <strong>Status</strong> {initialValues.AddendumStatusCode.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  select
                  name="AddendumStatusCodeId"
                  label="Status"
                  component={TextField}
                >
                  {_.map(statusCodes, el => (
                    <MenuItem key={el.id} value={el.id}>
                      <ListItemText primary={el.name} />
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!canUpdate || isSubmitting || !isValid}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={!canDelete || !values.id}
                onClick={this.props.handleDelete}
              >
                Delete Addendum
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    statusCodes: state.addendumStatusCodes.values
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchAddendumStatusCodes })
)(EditAddendumForm): any);

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Select, MultiSelect } from '../Formik';
import { TextField } from 'formik-material-ui';

import { Grid, Button } from '@material-ui/core';

import { getAllCulledTermValues } from '../../selectors/terms';

import type { Contract, ValueListItem } from '../../schemas';

type Props = {
  classes: Object,
  handleSubmit: Function,
  initialValues: ?Contract,
  fetchTerms: Function,
  terms: ValueListItem[],
  fetchContractTypes: Function,
  contractTypes: ValueListItem[],
};

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  }
});

class CreateContractForm extends Component<Props> {

  requestFromPlacement = () =>
    Boolean(this.props.initialValues && this.props.initialValues.StartTermId);

  render() {
    const { classes, terms, contractTypes } = this.props;

    return(
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({ handleReset }) => (
          <Form className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="StartTermId"
                  label="Starting Term"
                  disabled={this.requestFromPlacement()}
                  options={terms}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="ContractTypes"
                  label="Contract Types"
                  disabled={this.requestFromPlacement()}
                  options={contractTypes}
                  component={MultiSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="contact"
                  label="Contact Name"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="contactEmail"
                  label="Contact Email"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name="contactPhone"
                  label="Contact Phone"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="contactFax"
                  label="Contact Fax"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  multiline
                  name="Notes"
                  label="Notes"
                  type="text"
                  component={TextField}
                />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                color="primary"
                className={classes.button}
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Create Request
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractTypes: state.contractTypes.values,
    terms: getAllCulledTermValues(state)
  };
};

export default (compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true, name: 'CreateContractForm'})
)(CreateContractForm): any);

// @flow
import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {changeSection} from '../../actions/creators/placementDrawer';
import DrawerNavigation from '../../components/DrawerNavigation';

import type {DrawerNavigationItem} from '../../schemas';

type Props = {
  items: Array<DrawerNavigationItem>
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '64px',
      backgroundColor: theme.palette.grey[700],
      height: '100%',
      position: 'absolute'
    }
  };
});

function PlacementDrawerNavigation(props: Props): React$Element<"div"> {
  const {items} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentSection = useSelector(state => state.placementDrawer.currentSection);

  function handleNavigationChange(item) {
    dispatch(changeSection(item));
  }

  return (
    <div className={classes.root}>
      <DrawerNavigation
        items={items}
        currentSection={currentSection}
        handleNavigationChange={handleNavigationChange}
      />
    </div>
  );
}

export default PlacementDrawerNavigation;

// @flow

import {
  FETCH_COURSE_OFFERINGS,
  FETCH_COURSE_OFFERINGS_SUCCESS,
  FETCH_COURSE_OFFERINGS_ERROR
} from '../actions/types';

import type { CourseOffering } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<CourseOffering>
};

type Action = {
  type:
    | typeof FETCH_COURSE_OFFERINGS
    | typeof FETCH_COURSE_OFFERINGS_SUCCESS
    | typeof FETCH_COURSE_OFFERINGS_ERROR,
  payload: Array<CourseOffering>
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_COURSE_OFFERINGS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_COURSE_OFFERINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_COURSE_OFFERINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

// @flow

import * as React from 'react';
// $FlowFixMe
import ReactSelect from 'react-select';
// $FlowFixMe
import { Props as ReactSelectProps,  } from 'react-select';
// $FlowFixMe
import { OptionProps as Option  } from 'react-select';
import { FieldProps } from 'formik';
import { FormControl, FormHelperText } from '@material-ui/core';

type FieldPropTypes = typeof FieldProps;

type Props = {
  ...FieldPropTypes,
  ...ReactSelectProps
}

const SelectLookup: React.ComponentType<Props> = ({
  field, form, ...props
}) => {
  const { name } = field;
  const { options, label, required } = props;
  const { touched, errors } = form;

  const selectOptions = options.map(el => ({ value: el.id, label: el.name }));

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? '' : field.value;
  props.value = field.value;

  return (
    <FormControl style={{ marginTop: 12 }} error={touched[name] && !!errors[name]} {...props}>
      <ReactSelect
        {...props}
        {...field}
        placeholder={`${label} ${required ? '*' : ''}`}
        name={name}
        options={selectOptions}
        value={selectOptions ? selectOptions.find(option => option.value === field.value) : null}
        onChange={(option: Option) => form.setFieldValue(field.name, option ? option.value : null)}
        onBlur={field.onBlur}
        isClearable={true}
      />
      {!!errors[name] && (<FormHelperText id={`${name}-select-lookup`}>{errors[name]}</FormHelperText>)}
    </FormControl>
  );
};

SelectLookup.displayName = 'FormikReactSelect';

export default SelectLookup;

// @flow

import _ from 'lodash';
import {
  FETCH_APPLICATIONS,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_ERROR,
  CLEAR_APPLICATIONS,
  FETCH_APPLICATION,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_ERROR
} from '../actions/types';

import type { PlacementApp } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<PlacementApp>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_APPLICATIONS
    | typeof FETCH_APPLICATIONS_SUCCESS
    | typeof FETCH_APPLICATIONS_ERROR
    | typeof CLEAR_APPLICATIONS
    | typeof FETCH_APPLICATION
    | typeof FETCH_APPLICATION_SUCCESS
    | typeof FETCH_APPLICATION_ERROR,
  payload: ?Array<PlacementApp> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_APPLICATIONS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        orphans: getOrphans(action.payload)
      };
    case FETCH_APPLICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_APPLICATION:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CLEAR_APPLICATIONS:
      return initialState;
    default:
      return state;
  }
};

const getOrphans = placementApps => {
  // below to make flow happy - doesn't understand the Lodash
  if (
    placementApps === null ||
    placementApps === undefined ||
    typeof placementApps === 'string'
  ) {
    return [];
  }
  return !_.isArray(placementApps) ||
    (_.isArray(placementApps) && placementApps.length < 1)
    ? []
    : _.filter(placementApps, app => !app.PlacementId);
};

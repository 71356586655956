// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import moment from 'moment';

import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';

import type { Contract } from '../../schemas';
import DeepLink from '../DeepLink';

type Props = {
  classes: Object,
  item: Contract
};

const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(1)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
});

class ContractItem extends Component<Props> {

  render() {
    const { item, classes } = this.props;

    const organizationName = _.get(item, 'Organization.name', 'No Data Available');
    const startDate = item.startDate ? moment(item.startDate).format('ll') : 'N/A';
    const endDate = item.endDate ? moment(item.endDate).format('ll') : 'N/A';
    const status = _.get(item, 'ContractStatusCode.name', 'No Data Available');

    const contactName = _.get(item, 'contact');
    const contactPhone = _.get(item, 'contactPhone');
    const contactEmail = _.get(item, 'contactEmail');
    const contactFax = _.get(item, 'contactFax');

    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>

          <CardHeader
            title={
              <DeepLink id={item.id} model="contract">
                <Typography variant="h5" component="h2">
                  {startDate} thru {item.isAutoRenew ? 'Auto Renew' : endDate}
                </Typography>
              </DeepLink>
            }
            subheader={
              <Typography variant="caption" gutterBottom>
                {organizationName}
              </Typography>
            }
          />

          <CardContent>
            <Typography>
              <strong>Status: </strong> {status}
            </Typography>
            <Typography>
              <strong>Contact: </strong> {contactName ? contactName : 'N/A'}<br/>
              <strong>Email: </strong> {contactEmail ? contactEmail : 'N/A'}<br/>
              <strong>Phone: </strong> {contactPhone ? contactPhone : 'N/A'}<br/>
              <strong>Fax: </strong> {contactFax ? contactFax : 'N/A'}
            </Typography>
          </CardContent>

        </Card>
      </Grid>
    );
  }
}

export default (withStyles(styles)(ContractItem): any);

// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {
  Table,TableBody,TableHead,TableRow,TableCell,Typography,Paper,Grid
} from '@material-ui/core';

import PreceptorEnrollmentItem from './PreceptorEnrollmentItem';
import PreceptorEnrollmentDropzone from './PreceptorEnrollmentDropzone';

import type {PreceptorEnrollment,SiteEnrollment} from '../../../schemas';

type Props = {
  items: PreceptorEnrollment[],
  siteEnrollment: SiteEnrollment,
  editable: boolean,
  displayOnly: boolean
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2)
    },
    toolbar: {
      justifyContent: 'space-between',
      display: 'flex'
    },
    addIcon: {
      marginRight: theme.spacing(1)
    },
    centered: {
      textAlign: 'center'
    },
    dropZoneRow: {
      height: 'auto'
    }
  };
});

function PreceptorEnrollmentsList(props: Props): React$Element<any> {
  const {items,siteEnrollment,editable,displayOnly} = props;
  const classes = useStyles();

  const preceptorEnrollments = _.orderBy(items, ['id'], ['asc']);
  const hasPreceptorEnrollments = preceptorEnrollments.length > 0;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">Preceptors Assigned</Typography>
        <Paper className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Hours</TableCell>
                <TableCell className={classes.centered}>
                  Verbal Commitment
                </TableCell>
                <TableCell>Attachment A</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!hasPreceptorEnrollments && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography
                      variant="subtitle1"
                      className={classes.centered}
                    >
                      There are no preceptors assigned.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {preceptorEnrollments.map(item => {
                return (
                  <PreceptorEnrollmentItem
                    key={item.id}
                    item={item}
                    siteEnrollment={siteEnrollment}
                    editable={editable}
                    displayOnly={displayOnly || !editable}
                  />
                );
              })}
              {editable && (
                <TableRow className={classes.dropZoneRow}>
                  <TableCell padding="none" colSpan={5}>
                    <PreceptorEnrollmentDropzone
                      siteEnrollment={siteEnrollment}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PreceptorEnrollmentsList;

import axios from '../../api';
import * as actions from '../types';

export const fetchReportPlacements = term => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_REPORT_PLACEMENTS, payload: undefined });
    const res = await axios.get(`/report/placement/${term.id}`);
    dispatch({ type: actions.FETCH_REPORT_PLACEMENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_REPORT_PLACEMENTS_ERROR,
      payload: error.response.data
    });
  }
};

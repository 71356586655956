// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import axios from '../../../api';

import {
  Dialog, DialogContent, DialogContentText,
  DialogActions, DialogTitle
} from '@material-ui/core';
import {
  Typography, Grid, Card,
  CardHeader, CardContent, Button
} from '@material-ui/core';

// $FlowFixMe
import AsyncSelect from 'react-select/async';

import { fetchSites, fetchSite } from '../../../actions/creators/sites';

import type { Site, ValueListItem } from '../../../schemas';

type State = {
  selectedOption: Object,
  selectedSite: Object,
  inputValue: string,
};

type Props = {
  classes: Object,
  sites: Array<ValueListItem>,
  selectedSite: Site,
  open: boolean,
  onClose: Function,
  handleClose: Function,
  handleResponse: Function,
  fetchSites: Function,
  fetchSite: Function,
  createSite: Function,
  fetchSelectedOrganization: Function
};

const styles = theme => ({
  dialog: {
    overflow: 'visible'
  },
  dialogTitle: {
    margin: theme.spacing(1)
  },
  dialogContent: {
    overflow: 'visible',
    minHeight: 310,
    maxHeight: 360,
    margin: theme.spacing(1)
  },
  dialogContentText: {},
  dialogActions: {
    margin: theme.spacing(1)
  },
  dialogInput: {},
  dialogCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginLeft: theme.spacing(1)
  }
});

class SiteAddDialog extends Component<Props, State> {

  state = {
    selectedOption: {},
    selectedSite: {},
    inputValue: ''
  };

  componentDidMount() {
    // this.props.fetchSites();
  }

  renderSelectedSite() {
    const { classes } = this.props;
    const { selectedSite } = this.state;
    if (_.get(selectedSite, 'id', null)) {
      return(
        <Grid item>
          <Card className={classes.dialogCard}>
            <CardHeader
              title={
                <Typography variant="h5" component="h2">
                  {selectedSite.name}
                </Typography>
              }
              subheader={
                <div>
                  <Typography variant="caption" gutterBottom>
                    {_.get(selectedSite.Organization, 'name', null)}
                  </Typography>
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="subtitle1">
                    Address
                  </Typography>
                  <Typography component="div">
                    {selectedSite.address}
                  </Typography>
                  <Typography component="div">
                    {selectedSite.address2 && <div>{selectedSite.address2}</div>}
                  </Typography>
                  <Typography component="div">
                    {selectedSite.city}, {selectedSite.StateId} {selectedSite.postalCode}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Contact Info
                  </Typography>
                  <Typography component="div">
                    {selectedSite.phone && <div><strong>Phone:</strong> {selectedSite.phone}</div>}
                  </Typography>
                  <Typography component="div">
                    {selectedSite.fax && <div><strong>Fax:</strong> {selectedSite.fax}</div>}
                  </Typography>
                  <Typography component="div">
                    {selectedSite.website && <div><strong>Website:</strong> {selectedSite.website}</div>}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    }
    return(
      <Grid item>
        <Card className={classes.dialogCard}>
          <CardHeader
            title={
              <Typography variant="h5" component="h2">
                No Site Selected
              </Typography>
            }
          />
          <CardContent/>
        </Card>
      </Grid>
    );
  }

  renderActionButtons() {
    const { classes } = this.props;
    const { selectedOption, selectedSite } = this.state;
    const canCreate = (_.isEmpty(selectedSite) && !_.isEmpty(selectedOption) &&
      selectedOption.label === selectedOption.value);

    return (
      <div>
        <Button
          name="new"
          disabled={!canCreate}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => this.props.handleResponse({ type: 'new', name: selectedOption.value })}
        >
          Create New Site
        </Button>
        <Button
          name="clone"
          disabled={!canCreate}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => this.props.handleResponse({ type: 'clone', name: selectedOption.value })}
        >
          Clone Organization to Site
        </Button>
      </div>
    );
  }

  siteSearch = async query => {
    const options = [];

    let { data } = await axios.get(`/site/search?q=${query}`);
    _.each(data, site => {
      options.push({ value: site.id, label: site.name });
    });

    return options;
  };

  handleOnChange = async selectedOption => {
    const { inputValue } = this.state;

    if (selectedOption && selectedOption.label !== selectedOption.value) {
      await this.props.fetchSite({ id: selectedOption.value });
      await this.setState({
        selectedOption,
        selectedSite: this.props.selectedSite,
        inputValue
      });
    } else {
      await this.setState({
        selectedOption,
        selectedSite: {},
        inputValue
      });
    }
  };

  handleBlur = async e => {
    e.preventDefault();
    const { inputValue } = this.state;
    if (!_.isEmpty(inputValue)) {
      await this.setState({ inputValue });
      await this.handleOnChange({ label: inputValue, value: inputValue });
    }
  };

  render() {
    const { open, onClose, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="search-dialog"
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogTitle} id="search-dialog">
          New site search...
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            To create a new site associated with this organization
            you must first search to determin if a site already exists.
          </DialogContentText>
          <AsyncSelect
            cacheOptions
            defaultOptions
            placeholder="Search..."
            loadOptions={this.siteSearch}
            value={this.state.selectedOption}
            inputValue={this.state.inputValue}
            onChange={this.handleOnChange}
            onInputChange={inputValue => this.setState({ inputValue })}
            onBlur={this.handleBlur}
            isClearable={true}
          />
          {this.renderSelectedSite()}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {this.renderActionButtons()}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    // sites: state.sites.values,
    selectedSite: state.sites.selected
  };
};

export default (compose(
  connect(mapStateToProps, { fetchSites, fetchSite }),
  withStyles(styles)
)(SiteAddDialog): any);

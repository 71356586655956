// @flow

import { SiteIcon, PreceptorIcon, PlacementIcon } from '../components/Icons';

export default [
  {
    slug: 'details',
    label: 'Details',
    icon: SiteIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'preceptors',
    label: 'Preceptors',
    icon: PreceptorIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'placements',
    label: 'Placements',
    icon: PlacementIcon,
    allowedRoles: ['*']
  }
];

import { combineReducers } from 'redux';

import appInfoReducer from './appInfoReducer';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import userMessagesReducer from './userMessagesReducer';
import rolesReducer from './rolesReducer';
import permissionsReducer from './permissionsReducer';
import placementsReducer from './placementsReducer';
import placementDrawerReducer from './placementDrawerReducer';
import placementStatusReducer from './placementStatusReducer';
import placementOriginsReducer from './placementOrigins';
import termsReducer from './termsReducer';
import coordinatorsReducer from './coordinatorsReducer';
import locationsReducer from './locationsReducer';
import specialtyValuesReducer from './specialtyValuesReducer';
import specialtiesReducer from './specialtiesReducer';
import sitesReducer from './sitesReducer';
import siteDrawerReducer from './siteDrawerReducer';
import organizationsReducer from './organizationsReducer';
import organizationDrawerReducer from './organizationDrawerReducer';
import statesReducer from './statesReducer';
import countiesReducer from './countiesReducer';
import countriesReducer from './countriesReducer';
import siteTypesReducer from './siteTypesReducer';
import userSettingTypesReducer from './userSettingTypesReducer';
import contractsReducer from './contractsReducer';
import contractStatusCodesReducer from './contractStatusCodesReducer';
import contractTypesReducer from './contractTypesReducer';
import contractDrawerReducer from './contractDrawerReducer';
import contractStatusCodeTransitionsReducer from './contractStatusCodeTransitionsReducer';
import addendaReducer from './addendaReducer';
import addendumStatusCodesReducer from './addendumStatusCodesReducer';
import amendmentsReducer from './amendmentsReducer';
import amendmentStatusCodesReducer from './amendmentStatusCodesReducer';
import studentsReducer from './studentsReducer';
import studentApplicationReducer from './studentApplicationReducer';
import studentPlacementReducer from './studentPlacementReducer';
import stashReducer from './stashReducer';
import attachmentAsReducer from './attachmentAsReducer';
import attachmentAStatusCodesReducer from './attachmentAStatusCodesReducer';
import preceptorsReducer from './preceptorsReducer';
import preceptorDrawerReducer from './preceptorDrawerReducer';
import coursesReducer from './coursesReducer';
import courseOfferingsReducer from './courseOfferingsReducer';
import reportsReducer from './reportsReducer';
import dashboardReducer from './dashboardReducer';
import touchpointsReducer from './touchpointsReducer';
import touchpointTypesReducer from './touchpointTypesReducer';
import courseAttrsReducer from './courseAttrsReducer';
import placementAuditsReducer from './placementAudits';
import { reducer as notificationsReducer } from 'reapop';

export default combineReducers({
  appInfo: appInfoReducer,
  auth: authReducer,
  attachmentAs: attachmentAsReducer,
  attachmentAStatusCodes: attachmentAStatusCodesReducer,
  addenda: addendaReducer,
  addendumStatusCodes: addendumStatusCodesReducer,
  amendments: amendmentsReducer,
  amendmentStatusCodes: amendmentStatusCodesReducer,
  contracts: contractsReducer,
  contractStatusCodes: contractStatusCodesReducer,
  contractTypes: contractTypesReducer,
  contractDrawer: contractDrawerReducer,
  contractStatusCodeTransitions: contractStatusCodeTransitionsReducer,
  counties: countiesReducer,
  countries: countriesReducer,
  coordinators: coordinatorsReducer,
  courseAttrs: courseAttrsReducer,
  courses: coursesReducer,
  courseOfferings: courseOfferingsReducer,
  locations: locationsReducer,
  notifications: notificationsReducer(),
  organizations: organizationsReducer,
  organizationDrawer: organizationDrawerReducer,
  permissions: permissionsReducer,
  placements: placementsReducer,
  placementDrawer: placementDrawerReducer,
  placementStatuses: placementStatusReducer,
  preceptorDrawer: preceptorDrawerReducer,
  preceptors: preceptorsReducer,
  roles: rolesReducer,
  states: statesReducer,
  siteTypes: siteTypesReducer,
  specialtyValues: specialtyValuesReducer,
  specialties: specialtiesReducer,
  sites: sitesReducer,
  siteDrawer: siteDrawerReducer,
  students: studentsReducer,
  studentApplications: studentApplicationReducer,
  studentPlacements: studentPlacementReducer,
  stash: stashReducer,
  terms: termsReducer,
  userSettingTypes: userSettingTypesReducer,
  users: usersReducer,
  userMessages: userMessagesReducer,
  reports: reportsReducer,
  dashboard: dashboardReducer,
  touchpoints: touchpointsReducer,
  touchpointTypes: touchpointTypesReducer,
  placementAudits: placementAuditsReducer,
  placementOrigins: placementOriginsReducer,
});

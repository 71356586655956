// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { attachementAValidation } from '../../validation';

import {
  Typography,
  FormControl,
  FormControlLabel,
  Button,
} from '@material-ui/core';

import { Checkbox } from '../Formik/';
import {DatePicker} from '@material-ui/pickers';

import type {
  AttachmentA,
  Placement,
  ValueListItem,
  SiteEnrollment
} from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  inputContainer: {
    margin: theme.spacing(1),
  }
});

type Props = {
  classes: Object,
  handleSubmit: Function,
  pristine: boolean,
  submitting: boolean,
  valid: boolean,
  initialValues: AttachmentA,
  placement: Placement,
  siteEnrollment: SiteEnrollment
};

type State = {
  availablePreceptorEnrollments: ValueListItem[]
};
class CreatePlacementAttachmentAForm extends Component<Props, State> {
  state = {
    availablePreceptorEnrollments: []
  };

  componentDidMount() {
    const { siteEnrollment } = this.props;

    const availablePreceptorEnrollments = siteEnrollment.PreceptorEnrollments.map(
      preceptorEnrollment => {
        const id = preceptorEnrollment.id;
        const preceptorName = `{${preceptorEnrollment.Preceptor.firstName} ${
          preceptorEnrollment.Preceptor.lastName
        }`;
        const hours = _.get(
          preceptorEnrollment,
          'preceptorHoursAvailable',
          'TBD'
        );

        const name = `${preceptorName} - ${hours} hours`;

        return { id, name };
      }
    );

    this.setState({ availablePreceptorEnrollments });
  }

  render() {
    const {
      placement, handleSubmit, initialValues, classes, siteEnrollment
    } = this.props;
    const displayCourse = `${placement.Course.CatalogNumberId} | ${
      placement.Course.description
    }`;
    const displayContractType = _.get(
      placement,
      'Course.CatalogNumber.CourseAttr.ContractType.name',
      'No Data Available'
    );
    const displaySite = _.get(siteEnrollment, 'Site.name');
    const displayContractId = _.get(initialValues, 'ContractId', 'N/A');

    return(
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        validationSchema={attachementAValidation}
        onSubmit={values => handleSubmit(values)}
      >
        {({
          setFieldValue,handleBlur,isSubmitting,isValid,values
        }) => (
          <Form>
            <Typography>
              <strong>Course: </strong> {displayCourse}
            </Typography>
            <Typography>
              <strong>Site: </strong> {displaySite}
            </Typography>
            <Typography>
              <strong>Contract ID: </strong> {displayContractId}
            </Typography>
            <Typography>
              <strong>Contract Type: </strong> {displayContractType}
            </Typography>

            <FormControl fullWidth key="startDate" className={classes.inputContainer}>
              <Field
                fullWidth
                required
                variant="inline"
                disableToolbar
                name="startDate"
                label="Start Date"
                format="MMMM DD, YYYY"
                autoOk
                value={values.startDate}
                onChange={date => {
                  const value = date !== null ? date : initialValues.startDate;
                  setFieldValue('startDate', value);
                }}
                onBlur={handleBlur}
                component={DatePicker}
              />
            </FormControl>

            <FormControl fullWidth key="endDate" className={classes.inputContainer}>
              <Field
                fullWidth
                required
                variant="inline"
                disableToolbar
                name="endDate"
                label="End Date"
                format="MMMM DD, YYYY"
                autoOk
                value={values.endDate}
                onChange={date => {
                  const value = date !== null ? date : initialValues.endDate;
                  setFieldValue('endDate', value);
                }}
                onBlur={handleBlur}
                component={DatePicker}
              />
            </FormControl>

            <FormControlLabel
              control={
                <Field
                  checked={values.willSend}
                  name="willSend"
                  label="I will send this Attachment A"
                  component={Checkbox}
                />
              }
              label="I will send this Attachment A"
            />

            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                Create Attachment A
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles)(CreatePlacementAttachmentAForm): any);

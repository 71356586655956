// @flow

import _ from 'lodash';

export const MATCH_EXACT = 'MATCH_EXACT';
export const MATCH_IN = 'MATCH_IN';
export const MATCH_IN_STRICT = 'MATCH_IN_STRICT';

export default (
  subject: string | number | boolean,
  pattern?: string | number | boolean,
  matchingMethod: string,
  isFormInput: boolean = false
): any | boolean => {
  if (criteriaInvalid()) {
    return false;
  }
  checkMatchMethodCompatibility();
  if (isFormInput) {
    normalizeSubject();
    normalizePattern();
  }

  function normalizeSubject() {
    subject = _.isNumber(subject) ? subject.toString() : subject;
    subject = _.isBoolean(subject) ? subject === 'true' : subject;
    if (!_.isString(subject)) {
      throw new Error(
        `an invalid 'subject' was provided to the matching library`
      );
    }
    if (matchingMethod !== MATCH_IN_STRICT) {
      // $FlowFixMe
      subject = subject.toLowerCase();
    }
  }

  function normalizePattern() {
    // $FlowFixMe - flow does not understand the lodash based type checking that we are doing
    pattern = _.isNumber(pattern) ? pattern.toString() : pattern;
    pattern = _.isBoolean(pattern) ? pattern === 'true' : pattern;
    if (!_.isString(pattern)) {
      throw new Error(
        `an invalid 'pattern' was provided to the matching library`
      );
    }
    if (matchingMethod !== MATCH_IN_STRICT) {
      // $FlowFixMe
      pattern = pattern.toLowerCase();
    }
  }

  function criteriaInvalid() {
    if (pattern && subject === undefined) {
      return true;
    }

    return false;
  }

  function checkMatchMethodCompatibility() {
    if (
      (matchingMethod === MATCH_IN || (matchingMethod && MATCH_IN_STRICT)) &&
      (_.isBoolean(subject) || _.isBoolean(pattern))
    ) {
      throw new Error(
        'MATCH_IN matching can not be performed on boolean operands'
      );
    }
  }

  if (pattern === '') {
    return true;
  }

  switch (matchingMethod) {
    case MATCH_EXACT:
      return _.isEqual(subject, pattern);
    case MATCH_IN || MATCH_IN_STRICT:
      // $FlowFixMe - flow does not understand the lodash based type checking that we are doing
      return subject !== '' && subject.indexOf(pattern) !== -1;
    case '':
      return true;
    default:
      return false;
  }
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { createPlacement } from '../../actions/creators/placements';
import { fetchApplications } from '../../actions/creators/studentApplications';
import { fetchStudentsList } from '../../actions/creators/students';
import { fetchCourses } from '../../actions/creators/courses';

import { getAllTermValuesWithAttrs } from '../../selectors/terms';
import { getAllStudentValues } from '../../selectors/students';

import CreatePlacementForm from './CreatePlacementForm';

import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import type { PlacementApp, ValueListItem, User } from '../../schemas';

type Props = {
  currentUser: User,
  courses: ValueListItem,
  students: ValueListItem,
  terms: ValueListItem,
  classes: Object,
  placementApp: PlacementApp,
  mini: ?boolean,
  color: ?string,
  createPlacement: Function,
  fetchApplications: Function,
  fetchStudentsList: Function,
  fetchCourses: Function,
};

type State = {
  dialogVisible: boolean,
  template: Object
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  dialog: {
    overflowY: 'visible',
    '& > div': {
      overflowY: 'visible'
    }
  },
  dialogContent: {
    overflowY: 'visible'
  }
});

class CreatePlacementButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    const template = {
      PlacementAppId: props.placementApp ? props.placementApp.id : '',
      StudentId: props.placementApp ? props.placementApp.StudentId : '',
      TermId: props.placementApp ? props.placementApp.TermId : '',
      CourseId: props.placementApp ? props.placementApp.CourseId : '',
      ManagerId:
        props.currentUser && props.currentUser.id ? props.currentUser.id : '',
      postalCode:
        props.placementApp &&
        props.placementApp.Student &&
        props.placementApp.Student.mailingPostalCode
          ? props.placementApp.Student.mailingPostalCode
          : ''
    };
    this.state = {
      dialogVisible: false,
      template: template
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchCourses();
    await this.props.fetchStudentsList();
  };

  openDialog = () => {
    this.setState({ dialogVisible: true });
  };

  handleClose = () => {
    this.setState({ dialogVisible: false });
  };

  handleSubmit = async values => {
    try {
      values.PlacementOriginId = 3;
      await this.props.createPlacement(values);
    } catch (err) {
      throw new Error(err.data.message);
    }
    await this.handleClose();
    this.props.fetchApplications();
  };

  render() {
    const { classes, terms, courses, students, placementApp } = this.props;
    const { dialogVisible, template } = this.state;
    return (
      <div>
        <Button
          color={_.get(this.props, 'color', 'primary')}
          variant={_.get(this.props, 'variant', 'contained')}
          className={classes.button}
          onClick={this.openDialog}
        >
          New Placement
        </Button>
        <Dialog
          open={dialogVisible}
          onClose={this.handleClose}
          fullWidth
          aria-labelledby="creat-attachment-a-dialog"
          className={classes.dialog}
        >
          <DialogTitle id="create-attachment-a-dialog">
            New Placement
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <CreatePlacementForm
              handleSubmit={this.handleSubmit}
              initialValues={template}
              termOptions={terms}
              courseOptions={courses}
              studentOptions={students}
              placementApp={placementApp}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    courses: state.courses.values,
    students: getAllStudentValues(state),
    terms: getAllTermValuesWithAttrs(state)
  };
};

export default (compose(
  connect(mapStateToProps, {
    createPlacement, fetchApplications, fetchStudentsList, fetchCourses
  }),
  withStyles(styles)
)(CreatePlacementButton): any);

// @flow

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Button,Typography,Paper} from '@material-ui/core';

import {Formik,Form,Field } from 'formik';
import {TextField} from 'formik-material-ui';

type Props = {
  initialValues?: Object,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2)
    },
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    inputContainer: {
      marginTop: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(2)
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  };
});

function StashedPlacementSlotFilterForm(props: Props): React$Element<any> {
  const {handleSubmit,initialValues} = props;
  const classes = useStyles();

  const initValues = initialValues ? initialValues : {
    siteName: '',
    preceptorName: ''
  };

  return(
    <Formik
      initialValues={{...initValues}}
      onSubmit={values => handleSubmit(values)}
    >
      {({ handleReset }) => (
        <Form>
          <Paper className={classes.paper}>
            <Typography variant="subtitle1">Filter</Typography>
            <Field
              fullWidth
              name="siteName"
              label="Site Name"
              type="text"
              component={TextField}
            />
            <Field
              fullWidth
              name="preceptorName"
              label="Preceptor Name"
              type="text"
              component={TextField}
            />
            <div className={classes.buttons}>
              <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                  handleReset();
                  return handleSubmit();
                }}
              >
                Clear
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Filter
              </Button>
            </div>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default StashedPlacementSlotFilterForm;

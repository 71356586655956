import * as actions from '../types';
import axios from '../../api';

export const showPreceptorDrawer = () => async dispatch => {
  dispatch({ type: actions.SHOW_PRECEPTOR_DRAWER });
};

export const hidePreceptorDrawer = () => {
  return { type: actions.HIDE_PRECEPTOR_DRAWER };
};

export const changeSection = section => {
  return { type: actions.CHANGE_PRECEPTOR_DRAWER_SECTION, payload: section };
};

export const fetchSelectedPreceptor = preceptor => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_PRECEPTOR });
    const res = await axios.get(`/preceptor/${preceptor.id}`);
    dispatch({
      type: actions.FETCH_SELECTED_PRECEPTOR_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_PRECEPTOR_ERROR,
      payload: error.response.data
    });
  }
};

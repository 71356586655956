// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import PreceptorForm from '../../Preceptor/PreceptorForm';
import { createPreceptor } from '../../../actions/creators/preceptors';
import { fetchSelectedSite } from '../../../actions/creators/siteDrawer';
import { fetchStates } from '../../../actions/creators/states';
import { fetchSpecialties } from '../../../actions/creators/specialties';

type Classes = {
  centered: Object,
  dialogContent: Object,
  dialogActions: Object
};

type Props = {
  open: boolean,
  canCreate: boolean,
  canDelete: boolean,
  initialValues: Object,
  onClose: Function,
  handleClose: Function,
  classes: Classes,
  fetchSelectedSite: Function,
  createPreceptor: Function,
  fetchSpecialties: Function,
  specialties: Array<any>,
  states: Array<any>,
  countries: Array<any>,
  fetchStates: Function,
};

const styles = theme => ({
  centered: {
    textAlign: 'center'
  },
  dialogContent: {
    maxHeight: 435,
    padding: 0,
    marginBottom: 0
  },
  dialogActions: {
    paddingTop: theme.spacing(1),
    marginTop: 0
  }
});

class SitePreceptorAddDialog extends Component<Props> {

  handleCreate = async props => {
    const { initialValues } = this.props;

    await this.props.createPreceptor(props);
    this.props.handleClose();
    await this.props.fetchSelectedSite(initialValues.Sites[0]);
  };

  render() {
    const { open, onClose, handleClose, canCreate, initialValues } = this.props;

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="list-dialog">
        <DialogTitle id="list-dialog">Create a Preceptor</DialogTitle>
        <DialogContent>
          <PreceptorForm
            initialValues={initialValues}
            valueList={{
              specialties: this.props.specialties,
              sites: initialValues.Sites,
              states: this.props.states,
              countries: this.props.countries
            }}
            canUpdate={canCreate}
            handleSubmit={this.handleCreate}
            handleCancel={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    specialties: state.specialties.values,
    states: state.states.values,
    countries: state.countries.values
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchSelectedSite, createPreceptor, fetchStates, fetchSpecialties
  }),
  withStyles(styles)
)(SitePreceptorAddDialog): any);

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  List,
  Typography
} from '@material-ui/core';

import AttachmentItem from './AttachmentItem';
import type { Attachment } from '../../schemas';

type Props = {
  classes: Object,
  attachments: Attachment[],
  editable: boolean
};

const styles = theme => ({
  list: {
    marginTop: theme.spacing(2)
  }
});

class AttachmentList extends Component<Props> {
  render() {
    const { attachments, classes, editable } = this.props;
    return (
      <div>
        {attachments.length === 0 && (
          <Typography>There are no attachments</Typography>
        )}
        {attachments.length > 0 && (
          <Paper className={classes.list}>
            <List>
              {attachments.map(attachment => {
                return (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    editable={editable}
                  />
                );
              })}
            </List>
          </Paper>
        )}
      </div>
    );
  }
}

export default (withStyles(styles)(AttachmentList): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { Grid, MenuItem, ListItemText } from '@material-ui/core';

import FormikAutoSave from '../Formik/FormikAutoSave';

import { getAllTermValuesWithAttrs } from '../../selectors/terms';
import type { UserSetting, UserSettingType, Term } from '../../schemas';

type State = {};

type Props = {
  initialValues: any[],
  handleSubmit: Function,
  classes: Object,
  userSettingTypes: UserSettingType[],
  userSettings: UserSetting[],
  terms: Term[],
};

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  inputContainer: {
    marginTop: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  submitButton: {
    marginTop: theme.spacing(2)
  }
});

class UserSettingForm extends Component<Props, State> {

  renderGridField(props) {
    const { classes } = this.props;
    const { key, label, name, component, required, fullWidth } = props;
    let el;

    if (component === 'select') {
      const options = this.getMenuOptions(key);
      el = (
        <Field
          select
          required={required}
          fullWidth={fullWidth}
          key={key}
          label={label}
          name={name}
          margin="none"
          component={TextField}
        >
          {_.map(options, el => (
            <MenuItem key={el.key} value={el.value}>
              <ListItemText primary={el.label} />
            </MenuItem>
          ))}
        </Field>
      );
    } else {
      el = (
        <Field
          required={required}
          fullWidth={fullWidth}
          type="text"
          key={key}
          label={label}
          name={name}
          margin="none"
          component={TextField}
        />
      );
    }
    return (
      <Grid key={el.key} item xs={12} className={classes.grid}>
        {el}
      </Grid>
    );
  }

  getMenuOptions(key) {
    const themeOptions = [
      { id: 1, value: 'light', name: 'Light' },
      { id: 2, value: 'dark', name: 'Dark' }
    ];

    switch (key) {
      case 'theme':
        return themeOptions.map(el => {
          return { key: el.id, value: el.value, label: el.name };
        });
      case 'working_semester':
        return this.props.terms.map(el => {
          return { key: el.id, value: el.id, label: el.name };
        });
      default:
        return '';
    }
  }

  getComponent(userSetting) {
    const key = userSetting && userSetting.UserSettingType &&
      userSetting.UserSettingType.slug ? userSetting.UserSettingType.slug : 'none';
    switch (key) {
      case 'theme':
        return 'select';
      case 'working_semester':
        return 'select';
      default:
        return 'textField';
    }
  }

  render() {
    return(
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {props => (
          <Form>
            <Grid container spacing={2}>
              {this.props.userSettings.map(userSetting =>
                this.renderGridField({
                  required: true,
                  fullWidth: true,
                  key: userSetting.UserSettingType.slug,
                  label: userSetting.UserSettingType.name,
                  name: userSetting.UserSettingType.slug,
                  component: this.getComponent(userSetting)
                })
              )}
            </Grid>
            <FormikAutoSave
              {...props}
              debounce={300}
              onSave={this.props.handleSubmit}
              render={() => <div />}
            />
          </Form>
        )}
      </Formik>
    );

  }
}

const mapStateToProps = state => {
  return {
    userSettingTypes: state.userSettingTypes.items,
    terms: getAllTermValuesWithAttrs(state)
  };
};

export default (compose(
  withStyles(styles, { withStyles: true, name: 'UserSettingForm' }),
  connect(mapStateToProps, {}),
)(UserSettingForm): any);

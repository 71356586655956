// @flow

import * as React from 'react';
import {connect,useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {Drawer,IconButton} from '@material-ui/core';

import {hidePlacementDrawer} from '../../actions/creators/placementDrawer';

import {CloseIcon} from '../Icons';
import PlacementDrawerHeader from './PlacementDrawerHeader';
import PlacementDrawerSections from './PlacementDrawerSections';
import PlacementDrawerNavigation from './PlacementDrawerNavigation';
import navItems from '../../config/placementDrawerNavigationItems';

import type {Placement} from '../../schemas';

type Props = {
  selectedPlacement: Placement,
  isVisible: boolean,
  currentSection: Object
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      overflow: 'hidden'
    },
    content: {
      marginLeft: '64px',
      paddingLeft: theme.spacing(2),
      height: '67vh',
      overflow: 'scroll'
    },
    button: {
      margin: theme.spacing(1),
      float: 'right'
    },
  };
});

function PlacementDrawer(props: Props) {
  const {
    selectedPlacement,isVisible,currentSection
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleHideDrawer() {
    dispatch(hidePlacementDrawer(selectedPlacement));
  }

  return (
    <div className={classes.root}>
      <Drawer anchor="bottom" open={isVisible} onClose={() => handleHideDrawer()}>
        {selectedPlacement && (
          <PlacementDrawerHeader placement={selectedPlacement} />
        )}
        <PlacementDrawerNavigation items={navItems} />
        <div className={classes.content}>
          <IconButton
            aria-label="Close"
            onClick={() => handleHideDrawer()}
            className={classes.button}
          >
            <CloseIcon />
          </IconButton>
          <PlacementDrawerSections currentSection={currentSection} />
        </div>
      </Drawer>
    </div>
  );
}

const mapStateToProps = state => {
  return { ...state.placementDrawer };
};

export default (connect(mapStateToProps, {})(PlacementDrawer): any);


import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { NotificationsProvider, POSITIONS, setUpNotifications, atalhoTheme } from 'reapop';

import configureStore from './store/configureStore';
import ThemeContainer from './containers/ThemeContainer';
import SocketContainer from './containers/SocketContainer';

export const store = configureStore();

setUpNotifications({
  defaultProps: {
    position: POSITIONS.topRight,
    dismissible: true,
    showDismissButton: true,
    dismissAfter: 5000,
    theme: atalhoTheme
  }
});

const renderApp = () => render(
  <Provider store={store} style={{ overflow: 'hidden' }}>
    <NotificationsProvider>
      <SocketContainer>
        <ThemeContainer />
      </SocketContainer>
    </NotificationsProvider>
  </Provider>,
  document.getElementById('root')
);

if (process.env.REACT_APP_BASE_API_URL.includes('localhost') && module.hot) {
  module.hot.accept('./containers/AppContainer', () => {
    renderApp();
  });
}

renderApp();

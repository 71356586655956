// @flow

import { OrganizationIcon, SiteIcon, ContractIcon} from '../components/Icons';

export default [
  {
    slug: 'details',
    label: 'Details',
    icon: OrganizationIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'sites',
    label: 'Sites',
    icon: SiteIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'contracts',
    label: 'Contracts',
    icon: ContractIcon,
    allowedRoles: ['*']
  }
];

// @flow
/* eslint-disable flowtype/require-valid-file-annotation */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Drawer,Hidden} from '@material-ui/core';
import AppMenu from './AppMenu';
import AppInfo from './AppInfo';

import type { Auth,AppInfo as Info,MenuItem } from '../schemas';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    height: '100%'
  },
  drawerHeader: theme.mixins.toolbar,
  logoSection: {
    backgroundColor: theme.palette.indigo[500],
    minHeight: '64px',
    maxHeight: '64px',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: 'auto',
    maxHeight: '100%',
    width: 'auto',
    maxWidth: '100%'
  },
  drawer: {
    height: '100vh'
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  drawerPaper: {
    width: 'auto',
    backgroundColor: theme.palette.background.appBar,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'relative',
      height: '100%'
    }
  },
  grow: {
    flex: '1 0 auto'
  }
});

type Props = {
  mobileOpen: Boolean,
  className: Object,
  handleDrawerToggle: Function,
  menuItems: MenuItem,
  auth: Auth,
  appInfo: Info,
  classes: Object,
  theme: Object
};

class AppDrawer extends React.Component<Props> {
  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div className={classes.drawerContent}>
        <div className={classes.drawerHeader}>
          <div className={classes.logoSection}>
            <img
              className={classes.logo}
              src="/assets/logo-son.png"
              alt="Duke Nursing"
            />
          </div>
        </div>
        <AppMenu menuItems={this.props.menuItems} auth={this.props.auth} />
        <div className={classes.grow} />
        <AppInfo appInfo={this.props.appInfo} />
      </div>
    );

    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.props.mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(AppDrawer): any);

// @flow

import Preceptor from './Preceptor';
import Site from './Site';
import Organization from './Organization';
import Contract from './Contract';

export default {
  preceptor: Preceptor,
  site: Site,
  organization: Organization,
  contract: Contract
};

// @flow

import _ from 'lodash';

import warning from 'warning';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import inflection from 'inflection';

import type { User, Role, ValueListItem } from '../schemas';

export function titleize(string: String): string {
  warning(
    typeof string === 'string' && string.length > 0,
    'Material-UI: titleize(string) expects a non empty string argument.'
  );

  return string
    .split('-')
    .map(word => word.split(''))
    .map(letters => {
      const first = letters.shift();
      return [first.toUpperCase(), ...letters].join('');
    })
    .join(' ');
}

export function labelize(word: string): string {
  return inflection.humanize(inflection.underscore(word));
}

export function pageToTitle(page: Object): any | string {
  if (page.title) {
    return page.title;
  }

  const name = page.pathname.replace(/.*\//, '');

  if (page.pathname.indexOf('/api') === 0) {
    return upperFirst(camelCase(name));
  }

  return titleize(name);
}

export function getTermName(termId: number, terms: Array<ValueListItem>): any | void {
  const termObj = _.find(terms, term => term.id === termId);

  return termObj ? termObj.name : undefined;
}

export const cullTerms = (activeTermId: number, terms: ValueListItem[]): any => {
  return _.filter(terms, term => term.id >= (activeTermId - 80));
};

export const userHasRole = (user: User, roles: Array<Role>): boolean => {
  let hasRole = false;
  if (_.isArray(roles)) {
    roles.forEach(requestedRole => {
      const matchingRole = _.find(user.Roles, { slug: requestedRole });
      if (matchingRole) {
        hasRole = true;
      }
    });
  } else {
    throw new Error('userHasRole accepts an Array of strings');
  }
  return hasRole;
};

export const buildValueList = (
  items: Object[],
  key: ?string = 'id',
  value: ?string = 'name'
): ValueListItem[] => {
  return items.map(item => ({ id: item[key], name: item[value] }));
};

export function stripHtml(html: string): string {
  var temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function handleError(dispatch: any, type: any, error: any) {
  console.error(error);
  let payload = error;
  if (error.response) {
    payload = error.response;
    if (error.response.data) {
      payload = error.response.data;
    }
  }

  dispatch({ type, payload });
}

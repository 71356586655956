import * as actions from '../types';
import axios from '../../api';

export const showOrganizationDrawer = () => async dispatch => {
  dispatch({ type: actions.SHOW_ORGANIZATION_DRAWER });
};

export const hideOrganizationDrawer = () => {
  return { type: actions.HIDE_ORGANIZATION_DRAWER };
};

export const changeSection = section => {
  return { type: actions.CHANGE_ORGANIZATION_DRAWER_SECTION, payload: section };
};

export const fetchSelectedOrganization = organization => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_ORGANIZATION });
    const res = await axios.get(`/organization/${organization.id}`);
    dispatch({
      type: actions.FETCH_SELECTED_ORGANIZATION_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_ORGANIZATION_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchOrganizationContracts = organization => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_SELECTED_ORGANIZATION_CONTRACTS, payload: undefined });
    const res = await axios.get(`/contract/organization/${organization.id}`);
    dispatch({ type: actions.FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR,
      payload: error.response.data
    });
  }
};


import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAllOrganizations = state => state.organizations.items;
export const getSelectedOrganization = state => state.organizations.selected;

export const getAllOrganizationValues = createSelector(
  getAllOrganizations,
  states => {
    return _.map(_.orderBy(states, ['name']), el => ({ id: el.id, name: el.name }));
  }
);

export const getOrganizationById = state => createSelector(
  getAllOrganizations, organizations => {
    return _.find(organizations, { id: state.siteId });
  }
)(state);

// @flow

import _ from 'lodash';
import moment from 'moment';

import * as React from 'react';
import {useDispatch} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';

import {
  Card,CardContent,CardHeader,CardActions,Typography,Button
} from '@material-ui/core';

import {updateNote} from '../../actions/creators/notes';
// import {deleteNote} from '../../actions/creators/notes';

import NoteForm from './NoteForm';

import type { Note } from '../../schemas';

type Props = {
  note: Note,
  afterAction: Function,
};


const useStyles = makeStyles(theme => {
  return {
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    icon: {
      fontSize: theme.typography.pxToRem(16)
    },
    contentContainer: {
      flex: 1
    },
    actionsContainer: {
      justifyContent: 'flex-end'
    }
  };
});

function NoteDetail(props: Props): React$Element<any> {
  const {note,afterAction} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = React.useState(false);

  function enableEdit() {
    setIsEditing(true);
  }

  function disableEdit() {
    setIsEditing(false);
  }

  async function handleUpdate(note) {
    let result = await dispatch(updateNote(note));
    if (result) {
      setIsEditing(false);
      afterAction();
    }
  }

  // async function handleDelete() {
  //   let confirmation = window.confirm(
  //     'Are you sure you want to delete this note?'
  //   );
  //   if (confirmation) {
  //     let result = await dispatch(deleteNote(note));
  //     if (result) afterAction();
  //   }
  // }

  const meta = _.get(note, 'meta', undefined);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant="h6" gutterBottom>
            {meta ? meta : _.startCase(note.model)}
          </Typography>
        }
        subheader={
          <Typography variant="caption" gutterBottom>
            <strong>{note.User.name}</strong> &bull;{' '}
            {moment(note.updatedAt).format('ll')}
          </Typography>
        }
      />
      <CardContent className={classes.contentContainer}>
        {isEditing && (
          <NoteForm
            initialValues={note}
            handleCancel={disableEdit}
            handleSubmit={handleUpdate}
          />
        )}
        {!isEditing && <Typography>{note.description}</Typography>}
      </CardContent>

      <CardActions className={classes.actionsContainer}>
        {!isEditing && (
          <div>
            <Button color="primary" onClick={enableEdit}>
              Edit
            </Button>
            {/* <Button color="secondary" onClick={handleDelete}>
              Delete
            </Button> */}
          </div>
        )}
      </CardActions>
    </Card>
  );
}

export default NoteDetail;

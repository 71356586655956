import axios from '../../api';
import * as actions from '../types';

export const fetchContractTypes = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_TYPES });
    const res = await axios.get('/contractType');
    dispatch({ type: actions.FETCH_CONTRACT_TYPES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_TYPES_ERROR,
      payload: error.response.data
    });
  }
};

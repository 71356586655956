// @flow

import _ from 'lodash';

import * as React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {
  FilteringState,IntegratedFiltering,PagingState,IntegratedPaging,SortingState,
  IntegratedSorting,DataTypeProvider,IntegratedSelection,SelectionState
} from '@devexpress/dx-react-grid';

import {
  Grid as ReactGrid,Table,TableHeaderRow,PagingPanel,TableFilterRow,
  TableColumnResizing,TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import AttachmentAStatusCodeFormatter from
  '../../../components/AttachmentAStatusCode/AttachmentAStatusCodeFormatter';
import AttachmentADetail from
  '../../../components/AttachmentA/AttachmentADetail';

import SelectFilterCellBase from
  '../../../components/ReactGrid/SelectFilterCellBase';
import EmailAttachmentADialog from
  '../../../components/Email/emailAttachmentADialog';

import { fetchAttachmentAs } from '../../../actions/creators/attachmentAs';
import {
  fetchContractAttachments,
  clearContractAttachments
} from '../../../actions/creators/attachments';

import { fetchAttachmentAStatusCodes } from
  '../../../actions/creators/attachmentAStatusCodes';

import CreateAttachmentAButton from
  '../../../components/AttachmentA/CreateAttachmentAButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  appBar: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  drawer: {
    minHeight: '50vh'
  },
  buttonContainer: {
    textAlign: 'right'
  }
});

type Props = {
  classes: Object;
  location: Object;
}

const AttachmentAs: any = withStyles(styles)((props: Props) => {
  const {classes,location} = props;
  const dispatch = useDispatch();

  const [selectedAttachmentA, setSelectedAttachmentA] = React.useState(undefined);
  const [selection, setSelection] = React.useState([]);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);

  const rows = useSelector(state => state.attachmentAs.items);
  const attachmentAStatusCodes = useSelector(state => state.attachmentAStatusCodes.values);
  const selectedAttachments = useSelector(state => state.attachmentAs.selectedAttachments);

  async function handleRedirectCheck() {
    const params = new URLSearchParams(location.search);
    const AttachmentAId = parseInt(params.get('ID'), 10);

    if (AttachmentAId) {
      const selectedItem = _.find(rows, { id: AttachmentAId });
      await dispatch(fetchContractAttachments(selectedItem.Contract.id));
      setDetailOpen(true);
      setSelectedAttachmentA(selectedItem);
    }
  }

  function handleCloseDetail() {
    dispatch(clearContractAttachments());
    setSelection([]);
    setSelectedAttachmentA(undefined);
    setDetailOpen(false);
  }

  function handleOpenEmailDialog(selectedAttachmentA) {
    setSelectedAttachmentA(selectedAttachmentA);
    setEmailOpen(true);
  }

  function handleCloseEmailDialog(notification) {
    dispatch(clearContractAttachments());
    setSelection([]);
    setSelectedAttachmentA(undefined);
    setEmailOpen(false);
    if (notification) dispatch(notification);
  }

  async function handleSelection(gridSelections) {
    const attachmentA = rows[gridSelections[0]];
    await dispatch(fetchContractAttachments(attachmentA.ContractId));
    setDetailOpen(true);
    setSelectedAttachmentA(attachmentA);
  }

  function EmailAttachmentAButtonFormatter(props) {
    const status = _.get(props.row, 'AttachmentAStatusCode.name', '');
    return (
      <Button
        size="small"
        color='primary'
        variant="text"
        disabled={status !== 'Requested'}
        onClick={e => {
          handleOpenEmailDialog(props.row);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        Send Attachment
      </Button>
    );
  }

  function NoDataCell(props) {
    const { colSpan } = props;
    return (
      <Table.Cell colSpan={colSpan}>
        <h3 className={classes.cellCenter}>No data available...</h3>
      </Table.Cell>
    );
  }

  function renderAppBar() {
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.flex} />
          <CreateAttachmentAButton color="default" size="small" />
        </Toolbar>
      </AppBar>
    );
  }

  function filterCell(props) {
    if (props.column.name === 'status') {
      const propsWithOptions = { ...props, optionItems: attachmentAStatusCodes };
      return <SelectFilterCellBase {...propsWithOptions} />;
    }

    return <TableFilterRow.Cell {...props} />;
  }

  React.useEffect(() => {
    dispatch(fetchAttachmentAs(true));
    dispatch(fetchAttachmentAStatusCodes());
    if (rows.length) handleRedirectCheck();
    return () => {
      dispatch(clearContractAttachments());
    };
  },[dispatch]);

  const columns = [
    { name: 'id', title: 'ID' },
    {
      name: 'course',
      title: 'Course Number',
      getCellValue: row => _.get(row, 'Course.CatalogNumberId', 'No Data')
    },
    {
      name: 'site',
      title: 'Site',
      getCellValue: row => _.get(row, 'Site.name', 'No Data')
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: row =>
        _.get(row, 'AttachmentAStatusCode.id', 'No Data Available')
    },
    {
      name: 'emailAttachmentA',
      title: 'Send Attachment'
    }
  ];

  const defaultColumnWidths = [
    { columnName: 'id', width: 80 },
    {
      columnName: 'course',
      width: 160
    },
    {
      columnName: 'site',
      width: 480
    },
    {
      columnName: 'status',
      width: 160
    },
    {
      columnName: 'emailAttachmentA',
      width: 160
    }
  ];

  return (
    <div className={classes.root}>
      <Typography paragraph variant="h5" component="h1">
        Attachments A
      </Typography>
      <Paper>
        {renderAppBar()}
        <ReactGrid rows={rows} columns={columns}>
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[
              { columnName: 'emailAttachmentA', filteringEnabled: false },
            ]}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={10}
          />
          <SortingState
            defaultSorting={[{ columnName: 'id', direction: 'desc' }]}
            columnExtensions={[
              { columnName: 'emailAttachmentA', sortingEnabled: false },
            ]}
          />
          <SelectionState
            selection={selection}
            onSelectionChange={handleSelection}
          />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedSelection />
          <IntegratedPaging />

          <DataTypeProvider
            for={['emailAttachmentA']}
            formatterComponent={EmailAttachmentAButtonFormatter}
          />
          <DataTypeProvider
            for={['status']}
            formatterComponent={AttachmentAStatusCodeFormatter}
          />

          <Table noDataCellComponent={NoDataCell} />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls />

          <TableFilterRow cellComponent={filterCell} />
          <TableSelection
            selectByRowClick
            showSelectionColumn={false}
            highlightRow
          />
          <PagingPanel pageSizes={[10, 20, 50, 100, 250]} />
        </ReactGrid>
      </Paper>
      <Drawer
        open={detailOpen}
        anchor="bottom"
        onClose={handleCloseDetail}
        className={classes.drawer}
      >
        {selectedAttachmentA && (
          <AttachmentADetail
            handleClose={handleCloseDetail}
            attachmentAId={selectedAttachmentA.id}
            selectedAttachments={selectedAttachments}
            afterUpdate={fetchAttachmentAs}
          />
        )}
      </Drawer>
      {selectedAttachmentA && (
        <EmailAttachmentADialog
          open={emailOpen}
          handleClose={handleCloseEmailDialog}
          item={selectedAttachmentA}
        />
      )}
    </div>
  );
});

export default AttachmentAs;

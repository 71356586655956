// @flow
import React from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import type { Specialty } from '../../schemas';


type Props = {
  specialties: Specialty[],
  classes: Object
};

const styles = (theme: typeof Theme) => ({
  heading: {
    textAlign: 'left'
  },
  chip: {
    margin: theme.spacing(1)
  }
});

const SpecialtyChips = (props: Props) => {
  return (
    <Grid container>
      {props.specialties.length > 0 ? (
        <div>
          <Grid item xs={12} className={props.classes.heading}>
            Specialties
          </Grid>
          {props.specialties.map(specialty => {
            return (
              <Chip
                key={specialty.id}
                label={specialty.name}
                className={props.classes.chip}
              />
            );
          })}
        </div>
      ) : (
        <Grid item xs={12}>
          <Typography variant="caption">
            No Specialties assigned
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default (withStyles(styles, { withTheme: true, name: 'SpecialtyChips' })(
  SpecialtyChips
): any);

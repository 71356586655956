// @flow

import _ from 'lodash';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Chart, BarSeries, ArgumentAxis, ValueAxis, Title, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import {
  Stack, Scale, Animation, EventTracker, Palette, HoverState
} from '@devexpress/dx-react-chart';

import { Paper, Divider, Typography } from '@material-ui/core';
import { UsersIcon } from '../Icons';

type Props = {
  classes: Object,
  data: any[],
};

type State = {
  chartData: any[],
  hover?: Object,
  hoverObject: Object,
  renderChartData: Function,
};

const scheme = ['#FF7043', '#FFCA28', '#9CCC65', '#42A5F5'];
const styles = theme => ({
  stats: {
    padding: theme.spacing(1) / 2,
    textAlign: 'center',
  }
});

class PlacementsCoordinatorChart extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      hover: undefined,
      hoverObject: {},
      renderChartData: this.renderChartData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { chartData } = state.renderChartData();

    if (state.chartData !== chartData) {
      return { chartData: chartData };
    }

    return null;
  }

  changeHover = hover => {
    const { chartData } = this.state;
    let hoverObject = {};

    if (hover) {
      const data = chartData[hover.point];
      const key = _.camelCase(hover.series);
      hoverObject.title = hover.series;
      hoverObject.value = data[key];
    }

    this.setState({ hover, hoverObject });
  };

  renderChartData = () => {
    const coordinators = _.groupBy(this.props.data, 'PlacementManager');
    const chartData = [];

    _.forIn(coordinators, (value, key) => {
      const el = { coordinator: '', required: 0, pendingApproval: 0, approved: 0, cancelled: 0 };
      el.coordinator = key;
      _.forIn(value, (value) => {
        el.required += value.PlacementStatusCode === 'Required' ? value.PlacementStatusCodeCount : 0;
        el.pendingApproval += value.PlacementStatusCode === 'Pending Approval' ? value.PlacementStatusCodeCount : 0;
        el.approved += value.PlacementStatusCode === 'Approved' ? value.PlacementStatusCodeCount : 0;
        el.cancelled += value.PlacementStatusCode === 'Cancelled' ? value.PlacementStatusCodeCount : 0;
      });
      chartData.push(el);
    });

    return { chartData: chartData };
  };

  render() {
    const { hover, chartData } = this.state;
    const { classes } = this.props;

    const Overlay = () => {
      const { hover, hoverObject } = this.state;
      if (hover) {
        return (
          <Typography variant="caption">
            {hoverObject.value} {hoverObject.title}
          </Typography>
        );
      }
      return null;
    };

    return (
      <Paper style={{ padding: 10 }}>
        <Chart data={chartData}>
          <Palette scheme={scheme} />
          <ArgumentAxis />
          <ValueAxis
            label="Placements"
            name="placements"
          />
          <Title
            text="Placements By Coordinator"
            style={{ width: '100%' }}
          />
          <BarSeries
            name="Required"
            valueField="required"
            argumentField="coordinator"
          />
          <BarSeries
            name="Pending Approval"
            valueField="pendingApproval"
            argumentField="coordinator"
          />
          <BarSeries
            name="Approved"
            valueField="approved"
            argumentField="coordinator"
          />
          <BarSeries
            name="Cancelled"
            valueField="cancelled"
            argumentField="coordinator"
          />
          <Stack
            stacks={[
              { series: ['Required', 'Pending Approval', 'Approved', 'Cancelled'] },
            ]}
          />
          <Animation />
          <Scale name="placements" />
          <EventTracker />
          <Tooltip contentComponent={Overlay} />
          <HoverState hover={hover} onHoverChange={this.changeHover} />
        </Chart>
        <Divider />
        <div className={classes.stats}>
          <UsersIcon /> {chartData.length} coordinators have assigned Placements.
        </div>
      </Paper>
    );
  }
}

export default (withStyles(styles, { name: 'PlacementsCoordinatorChart', withTheme: true })(PlacementsCoordinatorChart): any);

// @flow

import {
  FETCH_LOCATION_SEARCH,
  FETCH_LOCATION_SEARCH_SUCCESS,
  FETCH_LOCATION_SEARCH_ERROR,
  FETCH_LOCATION_CONFIDENCE,
  FETCH_LOCATION_CONFIDENCE_SUCCESS,
  FETCH_LOCATION_CONFIDENCE_ERROR
} from '../actions/types';

import type { Site, Preceptor, Student } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +results: {
    sites: Site[],
    preceptors: Preceptor[],
    students: Student[]
  },
  +items: any[]
};

type Action = {
  type:
    | typeof FETCH_LOCATION_SEARCH
    | typeof FETCH_LOCATION_SEARCH_SUCCESS
    | typeof FETCH_LOCATION_SEARCH_ERROR
    | typeof FETCH_LOCATION_CONFIDENCE
    | typeof FETCH_LOCATION_CONFIDENCE_SUCCESS
    | typeof FETCH_LOCATION_CONFIDENCE_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  results: {
    sites: [],
    preceptors: [],
    students: []
  },
  items: []
};

const findByModel = (results, modelName) => {
  return results.filter(result => {
    return result.Location && result.Location.model === modelName;
  });
};

const segmentResults = results => {
  return {
    sites: findByModel(results, 'site'),
    preceptors: findByModel(results, 'preceptor'),
    students: findByModel(results, 'student')
  };
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_LOCATION_SEARCH:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_LOCATION_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: segmentResults(action.payload)
      };
    case FETCH_LOCATION_SEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_LOCATION_CONFIDENCE:
      return {...state,
        isLoading: true
      };
    case FETCH_LOCATION_CONFIDENCE_SUCCESS:
      return {...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_LOCATION_CONFIDENCE_ERROR:
      return {...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

// @flow

import * as React from 'react';

import {FieldProps} from 'formik';
import Autocomplete, {AutocompleteProps} from '@material-ui/lab/Autocomplete';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
// import Chip from '@material-ui/core/Chip';

type FieldPropTypes = typeof FieldProps;
type AutocompletePropTypes = typeof AutocompleteProps;
type TextFieldPropTypes = typeof TextFieldProps;

type Props = {
  ...FieldPropTypes,
  ...AutocompletePropTypes,
  ...TextFieldPropTypes
}

const MuiAutocomplete: React.ComponentType<Props> = ({
  field, form, ...props
}) => {

  const {name} = field;
  const {options,label} = props;
  const {setFieldValue,initialValues} = form;

  // sync props.value to field.value
  field.value = (!field.value || field.value === null) ? [] : field.value;
  if (field.value[0] && typeof field.value[0] === 'object')
    field.value = field.value.map(el => el.id);
  props.value = field.value;

  const handleChange = (e, values) => {
    let newValues = [];
    values.forEach(value => {
      if (!value) newValues = initialValues[name];
      if (typeof value === 'object') newValues.push(value.id);
      if (typeof value === 'number') newValues.push(value);
    });
    setFieldValue(name, newValues);
  };

  function getOptionLabel(value) {
    if (typeof value === 'object')
      return value.name;
    if (typeof value === 'number')
      return options.find(o => o.id === value).name;
    return '';
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      id="site-tags-standard"
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      value={props.value || ''}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={name}
          label={label}
        />
      )}
    />
  );
};

MuiAutocomplete.displayName = 'FormikMaterialUIAutocomplete';

export default MuiAutocomplete;

import axios from '../../api';
import * as actions from '../types';

export const fetchAddendumStatusCodes = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ADDENDUM_STATUS_CODES, payload: undefined });
    const res = await axios.get('/addendumStatusCode');
    dispatch({
      type: actions.FETCH_ADDENDUM_STATUS_CODES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ADDENDUM_STATUS_CODES_ERROR,
      payload: error.response.data
    });
  }
};

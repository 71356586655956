// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';

import { Grid, Button, FormControlLabel } from '@material-ui/core';
import { Checkbox } from '../Formik/';
import { TextField } from 'formik-material-ui';
import { specialtyValidation } from '../../validation';

type Props = {
  classes: Object,
  initialValues: Object,
  canUpdate: Function,
  canDelete: Function,
  handleClose: Function,
  handleSubmit: Function,
  handleDelete: Function,
  onChange: Function,
  formik: Object,
};

type State = {};

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
});

class SpecialtyDetailForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={specialtyValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          handleReset,
          touched,
          isSubmitting,
          isValid,
          values
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="name"
                  label="Name"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Field
                  fullWidth
                  type="text"
                  name="code"
                  label="Code"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isActive}
                      name="isActive"
                      label="Is Active"
                      component={Checkbox}
                    />
                  }
                  label="Is Active"
                />
              </Grid>
            </Grid>
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.props.canUpdate || isSubmitting || !isValid}
              >
                Save
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="default"
                disabled={!_.some(touched)}
                onClick={handleReset}
              >
                Reset
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                disabled={!this.props.canDelete || !values.id}
                onClick={this.props.handleDelete}
              >
                Delete
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles, {
  withTheme: true, name: 'SpecialtyDetailForm'
})(SpecialtyDetailForm): any);

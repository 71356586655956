// @flow

export const LOAD_APPINFO = 'LOAD_APPINFO';
export const LOAD_APPINFO_SUCCESS = 'LOAD_APPINFO_SUCCESS';
export const LOAD_APPINFO_ERROR = 'LOAD_APPINFO_ERROR';

// auth
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';
export const BEGIN_MASQUERADE = 'BEGIN_MASQUERADE';
export const BEGIN_MASQUERADE_SUCCESS = 'BEGIN_MASQUERADE_SUCCESS';
export const BEGIN_MASQUERADE_ERROR = 'BEGIN_MASQUERADE_ERROR';
export const END_MASQUERADE = 'END_MASQUERADE';
export const END_MASQUERADE_SUCCESS = 'END_MASQUERADE_SUCCESS';
export const END_MASQUERADE_ERROR = 'END_MASQUERADE_ERROR';

// users
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

// userMessages

export const FETCH_USER_MESSAGES = 'FETCH_USER_MESSAGES';
export const FETCH_USER_MESSAGES_SUCCESS = 'FETCH_USER_MESSAGES_SUCCESS';
export const FETCH_USER_MESSAGES_ERROR = 'FETCH_USER_MESSAGES_ERROR';

export const FETCH_USER_MESSAGE = 'FETCH_USER_MESSAGE';
export const FETCH_USER_MESSAGE_SUCCESS = 'FETCH_USER_MESSAGE_SUCCESS';
export const FETCH_USER_MESSAGE_ERROR = 'FETCH_USER_MESSAGE_ERROR';

export const CREATE_USER_MESSAGE = 'CREATE_USER_MESSAGE';
export const CREATE_USER_MESSAGE_SUCCESS = 'CREATE_USER_MESSAGE_SUCCESS';
export const CREATE_USER_MESSAGE_ERROR = 'CREATE_USER_MESSAGE_ERROR';

export const UPDATE_USER_MESSAGE = 'UPDATE_USER_MESSAGE';
export const UPDATE_USER_MESSAGE_SUCCESS = 'UPDATE_USER_MESSAGE_SUCCESS';
export const UPDATE_USER_MESSAGE_ERROR = 'UPDATE_USER_MESSAGE_ERROR';

export const UPDATE_USER_MESSAGES = 'UPDATE_USER_MESSAGES';
export const UPDATE_USER_MESSAGES_SUCCESS = 'UPDATE_USER_MESSAGES_SUCCESS';
export const UPDATE_USER_MESSAGES_ERROR = 'UPDATE_USER_MESSAGES_ERROR';

export const DELETE_USER_MESSAGE = 'DELETE_USER_MESSAGE';
export const DELETE_USER_MESSAGE_SUCCESS = 'DELETE_USER_MESSAGE_SUCCESS';
export const DELETE_USER_MESSAGE_ERROR = 'DELETE_USER_MESSAGE_ERROR';

// students
export const FETCH_STUDENTS = 'FETCH_STUDENTS';
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
export const FETCH_STUDENTS_ERROR = 'FETCH_STUDENTS_ERROR';

export const FETCH_STUDENT_VALUES = 'FETCH_STUDENT_VALUES';
export const FETCH_STUDENT_VALUES_SUCCESS = 'FETCH_STUDENT_VALUES_SUCCESS';
export const FETCH_STUDENT_VALUES_ERROR = 'FETCH_STUDENT_VALUES_ERROR';

export const FETCH_STUDENT = 'FETCH_STUDENT';
export const FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS';
export const FETCH_STUDENT_ERROR = 'FETCH_STUDENT_ERROR';

// placements
export const FETCH_PLACEMENTS = 'FETCH_PLACEMENTS';
export const FETCH_PLACEMENTS_SUCCESS = 'FETCH_PLACEMENTS_SUCCESS';
export const FETCH_PLACEMENTS_ERROR = 'FETCH_PLACEMENTS_ERROR';

export const CREATE_PLACEMENT = 'CREATE_PLACEMENT';
export const CREATE_PLACEMENT_SUCCESS = 'CREATE_PLACEMENT_SUCCESS';
export const CREATE_PLACEMENT_ERROR = 'CREATE_PLACEMENT_ERROR';

export const ENABLE_PLACEMENT_FILTERS = 'ENABLE_PLACEMENT_FILTERS';
export const DISABLE_PLACEMENT_FILTERS = 'DISABLE_PLACEMENT_FILTERS';

export const UPDATE_PLACEMENTS_MANAGER = 'UPDATE_PLACEMENTS_MANAGER';
export const UPDATE_PLACEMENTS_MANAGER_SUCCESS = 'UPDATE_PLACEMENTS_SUCCESS';
export const UPDATE_PLACEMENTS_MANAGER_ERROR = 'UPDATE_PLACEMENTS_ERROR';

export const TOGGLE_PLACEMENT_FILTERS = 'TOGGLE_PLACEMENT_FILTERS';
export const UPDATE_PLACEMENT_FILTERS = 'UPDATE_PLACEMENT_FILTERS';
export const CLEAR_PLACEMENT_FILTERS = 'CLEAR_PLACEMENT_FILTERS';
export const FILTER_PLACEMENTS = 'FILTER_PLACEMENTS';

export const SHOW_PLACEMENT_DRAWER = 'SHOW_PLACEMENT_DRAWER';
export const HIDE_PLACEMENT_DRAWER = 'HIDE_PLACEMENT_DRAWER';
export const CHANGE_PLACEMENT_DRAWER_SECTION =
  'CHANGE_PLACEMENT_DRAWER_SECTION';

export const FETCH_PLACEMENT_STATUSES = 'FETCH_PLACEMENT_STATUSES';
export const FETCH_PLACEMENT_STATUSES_SUCCESS =
  'FETCH_PLACEMENT_STATUSES_SUCCESS';
export const FETCH_PLACEMENT_STATUSES_ERROR = 'FETCH_PLACEMENT_STATUSES_ERROR';

export const ADD_ITEM_TO_WORKSPACE = 'ADD_ITEM_TO_WORKSPACE';

export const REMOVE_ITEM_FROM_PLACEMENT_DRAWER_STASH =
  'REMOVE_ITEM_FROM_PLACEMENT_DRAWER_STASH';
export const EMPTY_PLACEMENT_DRAWER_STASH = 'EMPTY_PLACEMENT_DRAWER_STASH';

export const FETCH_SELECTED_PLACEMENT = 'FETCH_SELECTED_PLACEMENT';
export const FETCH_SELECTED_PLACEMENT_SUCCESS =
  'FETCH_SELECTED_PLACEMENT_SUCCESS';
export const FETCH_SELECTED_PLACEMENT_ERROR = 'FETCH_SELECTED_PLACEMENT_ERROR';

export const UPDATE_SELECTED_PLACEMENT = 'UPDATE_PLACEMENT';
export const UPDATE_SELECTED_PLACEMENT_SUCCESS = 'UPDATE_PLACEMENT_SUCCESS';
export const UPDATE_SELECTED_PLACEMENT_ERROR = 'UPDATE_PLACEMENT_ERROR';

export const FETCH_STASHED_PLACEMENT_SLOTS = 'FETCH_STASHED_PLACEMENT_SLOTS';
export const FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS =
  'FETCH_STASHED_PLACEMENT_SLOTS_SUCCESS';
export const FETCH_STASHED_PLACEMENT_SLOTS_ERROR =
  'FETCH_STASHED_PLACEMENT_SLOTS_ERROR';
export const MOCK_SELECTED_PLACEMENT = 'MOCK_SELECTED_PLACEMENT';

export const UPDATE_SELECTED_TERM_ID = 'UPDATE_SELECTED_TERM_ID';

export const MERGE_PLACEMENTS = 'MERGE_PLACEMENTS';
export const MERGE_PLACEMENTS_SUCCESS = 'MERGE_PLACEMENTS_SUCCESS';
export const MERGE_PLACEMENTS_ERROR = 'MERGE_PLACEMENTS_ERROR';

// studentApplications
export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS';
export const FETCH_APPLICATIONS_ERROR = 'FETCH_APPLICATIONS_ERROR';

export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_ERROR = 'FETCH_APPLICATION_ERROR';

export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_ERROR = 'CREATE_APPLICATION_ERROR';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_ERROR = 'UPDATE_APPLICATION_ERROR';

export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_ERROR = 'DELETE_APPLICATION_ERROR';

export const CLEAR_APPLICATIONS = 'CLEAR_APPLICATIONS';

// studentPlacements
export const FETCH_STUDENT_PLACEMENTS = 'FETCH_STUDENT_PLACEMENTS';
export const FETCH_STUDENT_PLACEMENTS_SUCCESS =
  'FETCH_STUDENT_PLACEMENTS_SUCCESS';
export const FETCH_STUDENT_PLACEMENTS_ERROR = 'FETCH_STUDENT_PLACEMENTS_ERROR';

export const FETCH_STUDENT_PLACEMENT = 'FETCH_STUDENT_PLACEMENT';
export const FETCH_STUDENT_PLACEMENT_SUCCESS =
  'FETCH_STUDENT_PLACEMENT_SUCCESS';
export const FETCH_STUDENT_PLACEMENT_ERROR = 'FETCH_STUDENT_PLACEMENT_ERROR';

export const UPDATE_STUDENT_PLACEMENT = 'UPDATE_STUDENT_PLACEMENT';
export const UPDATE_STUDENT_PLACEMENT_SUCCESS =
  'UPDATE_STUDENT_PLACEMENT_SUCCESS';
export const UPDATE_STUDENT_PLACEMENT_ERROR = 'UPDATE_STUDENT_PLACEMENT_ERROR';

// permissions
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_ERROR = 'FETCH_PERMISSIONS_ERROR';
export const RESET_PERMISSIONS = 'RESET_PERMISSIONS';

// terms
export const FETCH_TERMS = 'FETCH_TERMS';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_TERMS_ERROR = 'FETCH_TERMS_ERROR';

export const FETCH_TERM = 'FETCH_TERM';
export const FETCH_TERM_SUCCESS = 'FETCH_TERM_SUCCESS';
export const FETCH_TERM_ERROR = 'FETCH_TERM_ERROR';

export const FETCH_ACTIVE_TERM = 'FETCH_ACTIVE_TERM';
export const FETCH_ACTIVE_TERM_SUCCESS = 'FETCH_ACTIVE_TERM_SUCCESS';
export const FETCH_ACTIVE_TERM_ERROR = 'FETCH_ACTIVE_TERM_ERROR';

export const CREATE_TERM = 'CREATE_TERM';
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS';
export const CREATE_TERM_ERROR = 'CREATE_TERM_ERROR';

export const UPDATE_TERM = 'UPDATE_TERM';
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS';
export const UPDATE_TERM_ERROR = 'UPDATE_TERM_ERROR';

export const DELETE_TERM = 'DELETE_TERM';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
export const DELETE_TERM_ERROR = 'DELETE_TERM_ERROR';

// coordinators
export const FETCH_COORDINATORS = 'FETCH_COORDINATORS';
export const FETCH_COORDINATORS_SUCCESS = 'FETCH_COORDINATORS_SUCCESS';
export const FETCH_COORDINATORS_ERROR = 'FETCH_COORDINATORS_ERROR';

// locations
export const FETCH_LOCATION_SEARCH = 'FETCH_LOCATION_SEARCH';
export const FETCH_LOCATION_SEARCH_SUCCESS = 'FETCH_LOCATION_SEARCH_SUCCESS';
export const FETCH_LOCATION_SEARCH_ERROR = 'FETCH_LOCATION_SEARCH_ERROR';
export const FETCH_LOCATION_CONFIDENCE = 'FETCH_LOCATION_CONFIDENCE';
export const FETCH_LOCATION_CONFIDENCE_SUCCESS = 'FETCH_LOCATION_CONFIDENCE_SUCCESS';
export const FETCH_LOCATION_CONFIDENCE_ERROR = 'FETCH_LOCATION_CONFIDENCE_ERROR';

// roles
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_ERROR = 'FETCH_ROLE_ERROR';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

// specialties
export const FETCH_SPECIALTIES = 'FETCH_SPECIALTIES';
export const FETCH_SPECIALTIES_SUCCESS = 'FETCH_SPECIALTIES_SUCCESS';
export const FETCH_SPECIALTIES_ERROR = 'FETCH_SPECIALTIES_ERROR';

export const FETCH_SPECIALTY = 'FETCH_SPECIALTY';
export const FETCH_SPECIALTY_SUCCESS = 'FETCH_SPECIALTY_SUCCESS';
export const FETCH_SPECIALTY_ERROR = 'FETCH_SPECIALTY_ERROR';

export const FETCH_SPECIALTY_VALUES = 'FETCH_SPECIALTY_VALUES';
export const FETCH_SPECIALTY_VALUES_SUCCESS = 'FETCH_SPECIALTY_VALUES_SUCCESS';
export const FETCH_SPECIALTY_VALUES_ERROR = 'FETCH_SPECIALTY_VALUES_ERROR';

export const CREATE_SPECIALTY = 'CREATE_SPECIALTY';
export const CREATE_SPECIALTY_SUCCESS = 'CREATE_SPECIALTY_SUCCESS';
export const CREATE_SPECIALTY_ERROR = 'CREATE_SPECIALTY_ERROR';

export const UPDATE_SPECIALTY = 'UPDATE_SPECIALTY';
export const UPDATE_SPECIALTY_SUCCESS = 'UPDATE_SPECIALTY_SUCCESS';
export const UPDATE_SPECIALTY_ERROR = 'UPDATE_SPECIALTY_ERROR';

export const DELETE_SPECIALTY = 'DELETE_SPECIALTY';
export const DELETE_SPECIALTY_SUCCESS = 'DELETE_SPECIALTY_SUCCESS';
export const DELETE_SPECIALTY_ERROR = 'DELETE_SPECIALTY_ERROR';

// sites
export const ENROLL_SITE = 'ENROLL_SITE';

export const FETCH_SITES = 'FETCH_SITES';
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
export const FETCH_SITES_ERROR = 'FETCH_SITES_ERROR';

export const MERGE_SITES = 'MERGE_SITES';
export const MERGE_SITES_SUCCESS = 'MERGE_SITES_SUCCESS';
export const MERGE_SITES_ERROR = 'MERGE_SITES_ERROR';

export const FETCH_SITE = 'FETCH_SITE';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_ERROR = 'FETCH_SITE_ERROR';

export const CREATE_SITE = 'CREATE_SITE';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_ERROR = 'CREATE_SITE_ERROR';

export const UPDATE_SITE = 'UPDATE_SITE';
export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_ERROR = 'UPDATE_SITE_ERROR';

export const DELETE_SITE = 'DELETE_SITE';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_ERROR = 'DELETE_SITE_ERROR';

// touchpoints
export const CLEAR_TOUCHPOINTS = 'CLEAR_TOUCHPOINTS';

export const FETCH_TOUCHPOINTS = 'FETCH_TOUCHPOINTS';
export const FETCH_TOUCHPOINTS_SUCCESS = 'FETCH_TOUCHPOINTS_SUCCESS';
export const FETCH_TOUCHPOINTS_ERROR = 'FETCH_TOUCHPOINTS_ERROR';

export const FETCH_TOUCHPOINT = 'FETCH_TOUCHPOINT';
export const FETCH_TOUCHPOINT_SUCCESS = 'FETCH_TOUCHPOINT_SUCCESS';
export const FETCH_TOUCHPOINT_ERROR = 'FETCH_TOUCHPOINT_ERROR';

export const CREATE_TOUCHPOINT = 'CREATE_TOUCHPOINT';
export const CREATE_TOUCHPOINT_SUCCESS = 'CREATE_TOUCHPOINT_SUCCESS';
export const CREATE_TOUCHPOINT_ERROR = 'CREATE_TOUCHPOINT_ERROR';

export const UPDATE_TOUCHPOINT = 'UPDATE_TOUCHPOINT';
export const UPDATE_TOUCHPOINT_SUCCESS = 'UPDATE_TOUCHPOINT_SUCCESS';
export const UPDATE_TOUCHPOINT_ERROR = 'UPDATE_TOUCHPOINT_ERROR';

export const DELETE_TOUCHPOINT = 'DELETE_TOUCHPOINT';
export const DELETE_TOUCHPOINT_SUCCESS = 'DELETE_TOUCHPOINT_SUCCESS';
export const DELETE_TOUCHPOINT_ERROR = 'DELETE_TOUCHPOINT_ERROR';

export const FETCH_TOUCHPOINT_TYPES = 'FETCH_TOUCHPOINT_TYPES';
export const FETCH_TOUCHPOINT_TYPES_SUCCESS = 'FETCH_TOUCHPOINT_TYPES_SUCCESS';
export const FETCH_TOUCHPOINT_TYPES_ERROR = 'FETCH_TOUCHPOINT_TYPES_ERROR';


// organizations
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';

export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_ERROR = 'FETCH_ORGANIZATION_ERROR';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';

export const MERGE_ORGANIZATION = 'MERGE_ORGANIZATION';
export const MERGE_ORGANIZATION_SUCCESS = 'MERGE_ORGANIZATION_SUCCESS';
export const MERGE_ORGANIZATION_ERROR = 'MERGE_ORGANIZATION_ERROR';

// organizationDrawer
export const SHOW_ORGANIZATION_DRAWER = 'SHOW_ORGANIZATION_DRAWER';
export const HIDE_ORGANIZATION_DRAWER = 'HIDE_ORGANIZATION_DRAWER';
export const CHANGE_ORGANIZATION_DRAWER_SECTION = 'CHANGE_ORGANIZATION_DRAWER_SECTION';
export const FETCH_SELECTED_ORGANIZATION = 'FETCH_SELECTED_ORGANIZATION';
export const FETCH_SELECTED_ORGANIZATION_SUCCESS = 'FETCH_SELECTED_ORGANIZATION_SUCCESS';
export const FETCH_SELECTED_ORGANIZATION_ERROR = 'FETCH_SELECTED_ORGANIZATION_ERROR';
export const FETCH_SELECTED_ORGANIZATION_CONTRACTS = 'FETCH_SELECTED_ORGANIZATION_CONTRACTS';
export const FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS = 'FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS';
export const FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR = 'FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR';

// siteDrawer
export const SHOW_SITE_DRAWER = 'SHOW_SITE_DRAWER';
export const HIDE_SITE_DRAWER = 'HIDE_SITE_DRAWER';
export const CHANGE_SITE_DRAWER_SECTION = 'CHANGE_SITE_DRAWER_SECTION';
export const FETCH_SELECTED_SITE = 'FETCH_SELECTED_SITE';
export const FETCH_SELECTED_SITE_SUCCESS = 'FETCH_SELECTED_SITE_SUCCESS';
export const FETCH_SELECTED_SITE_ERROR = 'FETCH_SELECTED_SITE_ERROR';
export const FETCH_SELECTED_SITE_PLACEMENTS = 'FETCH_SELECTED_SITE_PLACEMENTS';
export const FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS = 'FETCH_SELECTED_SITE_PLACEMENTS_SUCCESS';
export const FETCH_SELECTED_SITE_PLACEMENTS_ERROR = 'FETCH_SELECTED_SITE_PLACEMENTS_ERROR';

// counties
export const FETCH_COUNTIES = 'FETCH_COUNTIES';
export const FETCH_COUNTIES_SUCCESS = 'FETCH_COUNTIES_SUCCESS';
export const FETCH_COUNTIES_ERROR = 'FETCH_COUNTIES_ERROR';

// states
export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';
export const FETCH_STATES_ERROR = 'FETCH_STATES_ERROR';

// countries
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

// siteTypes
export const FETCH_SITETYPES = 'FETCH_SITETYPES';
export const FETCH_SITETYPES_SUCCESS = 'FETCH_SITETYPES_SUCCESS';
export const FETCH_SITETYPES_ERROR = 'FETCH_SITETYPES_ERROR';

// preceptors
export const FETCH_PRECEPTOR = 'FETCH_PRECEPTOR';
export const FETCH_PRECEPTOR_SUCCESS = 'FETCH_PRECEPTOR_SUCCESS';
export const FETCH_PRECEPTOR_ERROR = 'FETCH_PRECEPTOR_ERROR';

export const FETCH_PRECEPTORS = 'FETCH_PRECEPTORS';
export const FETCH_PRECEPTORS_SUCCESS = 'FETCH_PRECEPTORS_SUCCESS';
export const FETCH_PRECEPTORS_ERROR = 'FETCH_PRECEPTORS_ERROR';

export const CREATE_PRECEPTOR = 'CREATE_PRECEPTOR';
export const CREATE_PRECEPTOR_SUCCESS = 'CREATE_PRECEPTOR_SUCCESS';
export const CREATE_PRECEPTOR_ERROR = 'CREATE_PRECEPTOR_ERROR';

export const UPDATE_PRECEPTOR = 'UPDATE_PRECEPTOR';
export const UPDATE_PRECEPTOR_SUCCESS = 'UPDATE_PRECEPTOR_SUCCESS';
export const UPDATE_PRECEPTOR_ERROR = 'UPDATE_PRECEPTOR_ERROR';

export const DELETE_PRECEPTOR = 'DELETE_PRECEPTOR';
export const DELETE_PRECEPTOR_SUCCESS = 'DELETE_PRECEPTOR_SUCCESS';
export const DELETE_PRECEPTOR_ERROR = 'DELETE_PRECEPTOR_ERROR';

export const MERGE_PRECEPTORS = 'MERGE_PRECEPTORS';
export const MERGE_PRECEPTORS_SUCCESS = 'MERGE_PRECEPTORS_SUCCESS';
export const MERGE_PRECEPTORS_ERROR = 'MERGE_PRECEPTORS_ERROR';

// preceptorDrawer
export const SHOW_PRECEPTOR_DRAWER = 'SHOW_PRECEPTOR_DRAWER';
export const HIDE_PRECEPTOR_DRAWER = 'HIDE_PRECEPTOR_DRAWER';
export const CHANGE_PRECEPTOR_DRAWER_SECTION =
  'CHANGE_PRECEPTOR_DRAWER_SECTION';
export const FETCH_SELECTED_PRECEPTOR = 'FETCH_SELECTED_PRECEPTOR';
export const FETCH_SELECTED_PRECEPTOR_SUCCESS =
  'FETCH_SELECTED_PRECEPTOR_SUCCESS';
export const FETCH_SELECTED_PRECEPTOR_ERROR = 'FETCH_SELECTED_PRECEPTOR_ERROR';

// User Setting Types
export const FETCH_USER_SETTING_TYPES = 'FETCH_USER_SETTING_TYPES';
export const FETCH_USER_SETTING_TYPES_SUCCESS =
  'FETCH_USER_SETTING_TYPES_SUCCESS';
export const FETCH_USER_SETTING_TYPES_ERROR = 'FETCH_USER_SETTING_TYPES_ERROR';

// Current User Settings
export const FETCH_CURRENT_USER_SETTINGS = 'FETCH_CURRENT_USER_SETTINGS';
export const FETCH_CURRENT_USER_SETTINGS_SUCCESS =
  'FETCH_CURRENT_USER_SETTINGS_SUCCESS';
export const FETCH_CURRENT_USER_SETTINGS_ERROR =
  'FETCH_CURRENT_USER_SETTINGS_ERROR';

export const UPDATE_CURRENT_USER_SETTING = 'UPDATE_CURRENT_USER_SETTING';
export const UPDATE_CURRENT_USER_SETTING_SUCCESS =
  'UPDATE_CURRENT_USER_SETTING_SUCCESS';
export const UPDATE_CURRENT_USER_SETTING_ERROR =
  'UPDATE_CURRENT_USER_SETTING_ERROR';

// Placement Drawer Student History
export const FETCH_STUDENT_HISTORY = 'FETCH_STUDENT_HISTORY';
export const FETCH_STUDENT_HISTORY_SUCCESS = 'FETCH_STUDENT_HISTORY_SUCCESS';
export const FETCH_STUDENT_HISTORY_ERROR = 'FETCH_STUDENT_HISTORY_ERROR';

// Contracts
export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_ERROR = 'FETCH_CONTRACTS_ERROR';

export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_ERROR = 'FETCH_CONTRACT_ERROR';

export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_ERROR = 'CREATE_CONTRACT_ERROR';

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR';

export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_ERROR = 'DELETE_CONTRACT_ERROR';

// Contract Statuses
export const FETCH_CONTRACT_STATUS_CODES = 'FETCH_CONTRACT_STATUS_CODES';
export const FETCH_CONTRACT_STATUS_CODES_SUCCESS =
  'FETCH_CONTRACT_STATUS_CODES_SUCCESS';
export const FETCH_CONTRACT_STATUS_CODES_ERROR =
  'FETCH_CONTRACT_STATUS_CODES_ERROR';

// Contract Types
export const FETCH_CONTRACT_TYPES = 'FETCH_CONTRACT_TYPES';
export const FETCH_CONTRACT_TYPES_SUCCESS = 'FETCH_CONTRACT_TYPES_SUCCESS';
export const FETCH_CONTRACT_TYPES_ERROR = 'FETCH_CONTRACT_TYPES_ERROR';

// Contract Drawer
export const SHOW_CONTRACT_DRAWER = 'SHOW_CONTRACT_DRAWER';
export const HIDE_CONTRACT_DRAWER = 'HIDE_CONTRACT_DRAWER';
export const CHANGE_CONTRACT_DRAWER_SECTION = 'CHANGE_CONTRACT_DRAWER_SECTION';
export const FETCH_SELECTED_CONTRACT = 'FETCH_SELECTED_CONTRACT';
export const FETCH_SELECTED_CONTRACT_SUCCESS =
  'FETCH_SELECTED_CONTRACT_SUCCESS';
export const FETCH_SELECTED_CONTRACT_ERROR = 'FETCH_SELECTED_CONTRACT_ERROR';
export const FETCH_ALTERNATE_CONTRACTS = 'FETCH_ALTERNATE_CONTRACTS';
export const FETCH_ALTERNATE_CONTRACTS_SUCCESS =
  'FETCH_ALTERNATE_CONTRACTS_SUCCESS';
export const FETCH_ALTERNATE_CONTRACTS_ERROR =
  'FETCH_ALTERNATE_CONTRACTS_ERROR';

// Contract Status Code Transitions
export const FETCH_CONTRACT_STATUS_CODE_TRANSITIONS =
  'FETCH_CONTRACT_STATUS_CODE_TRANSITIONS';
export const FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_SUCCESS =
  'FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_SUCCESS';
export const FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_ERROR =
  'FETCH_CONTRACT_STATUS_CODE_TRANSITIONS_ERROR';

// Contract Workflow Log Entries
export const POST_CONTRACT_WORKFLOW_LOG_ENTRY =
  'POST_CONTRACT_WORKFLOW_LOG_ENTRY';
export const POST_CONTRACT_WORKFLOW_LOG_ENTRY_SUCCESS =
  'POST_CONTRACT_WORKFLOW_LOG_ENTRY_SUCCESS';
export const POST_CONTRACT_WORKFLOW_LOG_ENTRY_ERROR =
  'POST_CONTRACT_WORKFLOW_LOG_ENTRY_ERROR';

// GNE Addenda
export const FETCH_ADDENDA = 'FETCH_ADDENDA';
export const FETCH_ADDENDA_SUCCESS = 'FETCH_ADDENDA_SUCCESS';
export const FETCH_ADDENDA_ERROR = 'FETCH_ADDENDA_ERROR';

// Addendum Status Codes
export const FETCH_ADDENDUM_STATUS_CODES = 'FETCH_ADDENDUM_STATUS_CODES';
export const FETCH_ADDENDUM_STATUS_CODES_SUCCESS =
  'FETCH_ADDENDUM_STATUS_CODES_SUCCESS';
export const FETCH_ADDENDUM_STATUS_CODES_ERROR =
  'FETCH_ADDENDUM_STATUS_CODES_ERROR';

export const CREATE_ADDENDUM = 'CREATE_ADDENDUM';
export const CREATE_ADDENDUM_SUCCESS = 'CREATE_ADDENDUM_SUCCESS';
export const CREATE_ADDENDUM_ERROR = 'CREATE_ADDENDUM_ERROR';

export const UPDATE_ADDENDUM = 'UPDATE_ADDENDUM';
export const UPDATE_ADDENDUM_SUCCESS = 'UPDATE_ADDENDUM_SUCCESS';
export const UPDATE_ADDENDUM_ERROR = 'UPDATE_ADDENDUM_ERROR';

export const DELETE_ADDENDUM = 'DELETE_ADDENDUM';
export const DELETE_ADDENDUM_SUCCESS = 'DELETE_ADDENDUM_SUCCESS';
export const DELETE_ADDENDUM_ERROR = 'DELETE_ADDENDUM_ERROR';

export const FETCH_CONTRACT_ADDENDA = 'FETCH_CONTRACT_ADDENDA';
export const FETCH_CONTRACT_ADDENDA_SUCCESS = 'FETCH_CONTRACT_ADDENDA_SUCCESS';
export const FETCH_CONTRACT_ADDENDA_ERROR = 'FETCH_CONTRACT_ADDENDA_ERROR';

export const POST_ADDENDUM_WORKFLOW_LOG_ENTRY =
  'POST_ADDENDUM_WORKFLOW_LOG_ENTRY';
export const POST_ADDENDUM_WORKFLOW_LOG_ENTRY_SUCCESS =
  'POST_ADDENDUM_WORKFLOW_LOG_ENTRY_SUCCESS';
export const POST_ADDENDUM_WORKFLOW_LOG_ENTRY_ERROR =
  'POST_ADDENDUM_WORKFLOW_LOG_ENTRY_ERROR';

// Amendments
export const FETCH_AMENDMENTS = 'FETCH_AMENDMENTS';
export const FETCH_AMENDMENTS_SUCCESS = 'FETCH_AMENDMENTS_SUCCESS';
export const FETCH_AMENDMENTS_ERROR = 'FETCH_AMENDMENTS_ERROR';

export const CREATE_AMENDMENT = 'CREATE_AMENDMENT';
export const CREATE_AMENDMENT_SUCCESS = 'CREATE_AMENDMENT_SUCCESS';
export const CREATE_AMENDMENT_ERROR = 'CREATE_AMENDMENT_ERROR';

export const UPDATE_AMENDMENT = 'UPDATE_AMENDMENT';
export const UPDATE_AMENDMENT_SUCCESS = 'UPDATE_AMENDMENT_SUCCESS';
export const UPDATE_AMENDMENT_ERROR = 'UPDATE_AMENDMENT_ERROR';

export const DELETE_AMENDMENT = 'DELETE_AMENDMENT';
export const DELETE_AMENDMENT_SUCCESS = 'DELETE_AMENDMENT_SUCCESS';
export const DELETE_AMENDMENT_ERROR = 'DELETE_AMENDMENT_ERROR';

export const FETCH_CONTRACT_AMENDMENTS = 'FETCH_CONTRACT_AMENDMENTS';
export const FETCH_CONTRACT_AMENDMENTS_SUCCESS =
  'FETCH_CONTRACT_AMENDMENTS_SUCCESS';
export const FETCH_CONTRACT_AMENDMENTS_ERROR =
  'FETCH_CONTRACT_AMENDMENTS_ERROR';

// Amendment Status Codes
export const FETCH_AMENDMENT_STATUS_CODES = 'FETCH_AMENDMENT_STATUS_CODES';
export const FETCH_AMENDMENT_STATUS_CODES_SUCCESS =
  'FETCH_AMENDMENT_STATUS_CODES_SUCCESS';
export const FETCH_AMENDMENT_STATUS_CODES_ERROR =
  'FETCH_AMENDMENT_STATUS_CODES_ERROR';

export const POST_AMENDMENT_WORKFLOW_LOG_ENTRY =
  'POST_AMENDMENT_WORKFLOW_LOG_ENTRY';
export const POST_AMENDMENT_WORKFLOW_LOG_ENTRY_SUCCESS =
  'POST_AMENDMENT_WORKFLOW_LOG_ENTRY_SUCCESS';
export const POST_AMENDMENT_WORKFLOW_LOG_ENTRY_ERROR =
  'POST_AMENDMENT_WORKFLOW_LOG_ENTRY_ERROR';

// Contract Notes
export const FETCH_CONTRACT_NOTES = 'FETCH_CONTRACT_NOTES';
export const FETCH_CONTRACT_NOTES_SUCCESS = 'FETCH_CONTRACT_NOTES_SUCCESS';
export const FETCH_CONTRACT_NOTES_ERROR = 'FETCH_CONTRACT_NOTES_ERROR';

// Notes
export const CREATE_NOTE = 'CREATE_NOTE';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_ERROR = 'UPDATE_NOTE_ERROR';

export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';

// Placement Notes
export const FETCH_SELECTED_PLACEMENT_NOTES = 'FETCH_SELECTED_PLACEMENT_NOTES';
export const FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS =
  'FETCH_SELECTED_PLACEMENT_NOTES_SUCCESS';
export const FETCH_SELECTED_PLACEMENT_NOTES_ERROR =
  'FETCH_SELECTED_PLACEMENT_NOTES_ERROR';

// Contract Todos
export const FETCH_CONTRACT_TODOS = 'FETCH_CONTRACT_TODOS';
export const FETCH_CONTRACT_TODOS_SUCCESS = 'FETCH_CONTRACT_TODOS_SUCCESS';
export const FETCH_CONTRACT_TODOS_ERROR = 'FETCH_CONTRACT_TODOS_ERROR';

// Placement Todos
export const FETCH_PLACEMENT_TODOS = 'FETCH_PLACEMENT_TODOS';
export const FETCH_PLACEMENT_TODOS_SUCCESS = 'FETCH_PLACEMENT_TODOS_SUCCESS';
export const FETCH_PLACEMENT_TODOS_ERROR = 'FETCH_PLACEMENT_TODOS_ERROR';

// Placement Application
export const FETCH_PLACEMENT_APPLICATION = 'FETCH_PLACEMENT_APPLICATION';
export const FETCH_PLACEMENT_APPLICATION_SUCCESS = 'FETCH_PLACEMENT_APPLICATION_SUCCESS';
export const FETCH_PLACEMENT_APPLICATION_ERROR = 'FETCH_PLACEMENT_APPLICATION_ERROR';

// Todos
export const CREATE_TODO = 'CREATE_TODO';
export const CREATE_TODO_SUCCESS = 'CREATE_TODO_SUCCESS';
export const CREATE_TODO_ERROR = 'CREATE_TODO_ERROR';

export const UPDATE_TODO = 'UPDATE_TODO';
export const UPDATE_TODO_SUCCESS = 'UPDATE_TODO_SUCCESS';
export const UPDATE_TODO_ERROR = 'UPDATE_TODO_ERROR';

export const DELETE_TODO = 'DELETE_TODO';
export const DELETE_TODO_SUCCESS = 'DELETE_TODO_SUCCESS';
export const DELETE_TODO_ERROR = 'DELETE_TODO_ERROR';

// Attachments
export const FETCH_CONTRACT_ATTACHMENTS = 'FETCH_CONTRACT_ATTACHMENTS';
export const FETCH_CONTRACT_ATTACHMENTS_SUCCESS = 'FETCH_CONTRACT_ATTACHMENTS_SUCCESS';
export const FETCH_CONTRACT_ATTACHMENTS_ERROR = 'FETCH_CONTRACT_ATTACHMENTS_ERROR';
export const CLEAR_CONTRACT_ATTACHMENTS = 'CLEAR_CONTRACT_ATTACHMENTS';

export const FETCH_PRECEPTOR_ATTACHMENTS = 'FETCH_PRECEPTOR_ATTACHMENTS';
export const FETCH_PRECEPTOR_ATTACHMENTS_SUCCESS = 'FETCH_PRECEPTOR_ATTACHMENTS_SUCCESS';
export const FETCH_PRECEPTOR_ATTACHMENTS_ERROR = 'FETCH_PRECEPTOR_ATTACHMENTS_ERROR';
export const CLEAR_PRECEPTOR_ATTACHMENTS = 'CLEAR_PRECEPTOR_ATTACHMENTS';

export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_ERROR = 'CREATE_ATTACHMENT_ERROR';

export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_ERROR = 'DELETE_ATTACHMENT_ERROR';

// Global Stash
export const FETCH_STASH = 'FETCH_STASH';
export const FETCH_STASH_SUCCESS = 'FETCH_STASH_SUCCESS';
export const FETCH_STASH_ERROR = 'FETCH_STASH_ERROR';

export const UPDATE_STASH = 'UPDATE_STASH';
export const UPDATE_STASH_SUCCESS = 'UPDATE_STASH_SUCCESS';
export const UPDATE_STASH_ERROR = 'UPDATE_STASH_ERROR';

// Placement Attachments
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS';
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS_SUCCESS';
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS_ERROR';

// Attachments A
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A';
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_SUCCESS';
export const FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR =
  'FETCH_SELECTED_PLACEMENT_ATTACHMENTS_A_ERROR';

export const FETCH_SELECTED_CONTRACT_ATTACHMENTS_A =
  'FETCH_SELECTED_CONTRACT_ATTACHMENTS_A';
export const FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS =
  'FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_SUCCESS';
export const FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR =
  'FETCH_SELECTED_CONTRACT_ATTACHMENTS_A_ERROR';

export const CREATE_ATTACHMENT_A = 'CREATE_ATTACHMENT_A';
export const CREATE_ATTACHMENT_A_SUCCESS = 'CREATE_ATTACHMENT_A_SUCCESS';
export const CREATE_ATTACHMENT_A_ERROR = 'CREATE_ATTACHMENT_A_ERROR';

export const FETCH_ATTACHMENTS_A = 'FETCH_ATTACHMENTS_A';
export const FETCH_ATTACHMENTS_A_SUCCESS = 'FETCH_ATTACHMENTS_A_SUCCESS';
export const FETCH_ATTACHMENTS_A_ERROR = 'FETCH_ATTACHMENTS_A_ERROR';

export const FETCH_ATTACHMENT_A = 'FETCH_ATTACHMENT_A';
export const FETCH_ATTACHMENT_A_SUCCESS = 'FETCH_ATTACHMENT_A_SUCCESS';
export const FETCH_ATTACHMENT_A_ERROR = 'FETCH_ATTACHMENT_A_ERROR';

export const UPDATE_ATTACHMENT_A = 'UPDATE_ATTACHMENT_A';
export const UPDATE_ATTACHMENT_A_SUCCESS = 'UPDATE_ATTACHMENT_A_SUCCESS';
export const UPDATE_ATTACHMENT_A_ERROR = 'UPDATE_ATTACHMENT_A_ERROR';

// Attachment A Status Codes
export const FETCH_ATTACHMENT_A_STATUS_CODES =
  'FETCH_ATTACHMENT_A_STATUS_CODES';
export const FETCH_ATTACHMENT_A_STATUS_CODES_SUCCESS =
  'FETCH_ATTACHMENT_A_STATUS_CODES_SUCCESS';
export const FETCH_ATTACHMENT_A_STATUS_CODES_ERROR =
  'FETCH_ATTACHMENT_A_STATUS_CODES_ERROR';

// Attachment A Workflow Entries
export const POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY =
  'POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY';
export const POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_SUCCESS =
  'POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_SUCCESS';
export const POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_ERROR =
  'POST_ATTACHMENT_A_WORKFLOW_LOG_ENTRY_ERROR';

// Courses
export const FETCH_COURSES = 'FETCH_COURSES';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_ERROR = 'FETCH_COURSES_ERROR';

// CourseOfferings
export const FETCH_COURSE_OFFERINGS = 'FETCH_COURSE_OFFERINGS';
export const FETCH_COURSE_OFFERINGS_SUCCESS = 'FETCH_COURSE_OFFERINGS_SUCCESS';
export const FETCH_COURSE_OFFERINGS_ERROR = 'FETCH_COURSE_OFFERINGS_ERROR';

// CourseAttrs
export const FETCH_COURSE_ATTRS = 'FETCH_COURSE_ATTRS';
export const FETCH_COURSE_ATTRS_SUCCESS = 'FETCH_COURSE_ATTRS_SUCCESS';
export const FETCH_COURSE_ATTRS_ERROR = 'FETCH_COURSE_ATTRS_ERROR';

export const FETCH_COURSE_ATTR = 'FETCH_COURSE_ATTR';
export const FETCH_COURSE_ATTR_SUCCESS = 'FETCH_COURSE_ATTR_SUCCESS';
export const FETCH_COURSE_ATTR_ERROR = 'FETCH_COURSE_ATTR_ERROR';

export const CREATE_COURSE_ATTR = 'CREATE_COURSE_ATTR';
export const CREATE_COURSE_ATTR_SUCCESS = 'CREATE_COURSE_ATTR_SUCCESS';
export const CREATE_COURSE_ATTR_ERROR = 'CREATE_COURSE_ATTR_ERROR';

export const UPDATE_COURSE_ATTR = 'UPDATE_COURSE_ATTR';
export const UPDATE_COURSE_ATTR_SUCCESS = 'UPDATE_COURSE_ATTR_SUCCESS';
export const UPDATE_COURSE_ATTR_ERROR = 'UPDATE_COURSE_ATTR_ERROR';

export const DELETE_COURSE_ATTR = 'DELETE_COURSE_ATTR';
export const DELETE_COURSE_ATTR_SUCCESS = 'DELETE_COURSE_ATTR_SUCCESS';
export const DELETE_COURSE_ATTR_ERROR = 'DELETE_COURSE_ATTR_ERROR';

// reports
export const FETCH_REPORT_PLACEMENTS = 'FETCH_REPORT_PLACEMENTS';
export const FETCH_REPORT_PLACEMENTS_SUCCESS = 'FETCH_REPORT_PLACEMENTS_SUCCESS';
export const FETCH_REPORT_PLACEMENTS_ERROR = 'FETCH_REPORT_PLACEMENTS_ERROR';

// dashboard
export const FETCH_DESKTOP_OVERVIEW = 'FETCH_DESKTOP_OVERVIEW';
export const FETCH_DESKTOP_OVERVIEW_SUCCESS = 'FETCH_DESKTOP_OVERVIEW_SUCCESS';
export const FETCH_DESKTOP_OVERVIEW_ERROR = 'FETCH_DESKTOP_OVERVIEW_ERROR';

export const FETCH_DESKTOP_STATUS = 'FETCH_DESKTOP_STATUS';
export const FETCH_DESKTOP_STATUS_SUCCESS = 'FETCH_DESKTOP_STATUS_SUCCESS';
export const FETCH_DESKTOP_STATUS_ERROR = 'FETCH_DESKTOP_STATUS_ERROR';

export const FETCH_DESKTOP_COORDINATOR = 'FETCH_DESKTOP_COORDINATOR';
export const FETCH_DESKTOP_COORDINATOR_SUCCESS = 'FETCH_DESKTOP_COORDINATOR_SUCCESS';
export const FETCH_DESKTOP_COORDINATOR_ERROR = 'FETCH_DESKTOP_COORDINATOR_ERROR';

export const FETCH_DESKTOP_CONTRACT = 'FETCH_DESKTOP_CONTRACT';
export const FETCH_DESKTOP_CONTRACT_SUCCESS = 'FETCH_DESKTOP_CONTRACT_SUCCESS';
export const FETCH_DESKTOP_CONTRACT_ERROR = 'FETCH_DESKTOP_CONTRACT_ERROR';

export const SET_SITE_FILTERS = 'SET_SITE_FILTERS';
export const SET_SITE_FILTERS_ERROR = 'SET_SITE_FILTERS_ERROR';
export const SET_PRECEPTOR_FILTERS = 'SET_PRECEPTOR_FILTERS';
export const SET_PRECEPTOR_FILTERS_ERROR = 'SET_PRECEPTOR_FILTERS_ERROR';

export const FETCH_PLACEMENT_AUDITS = 'FETCH_PLACEMENT_AUDITS';
export const FETCH_PLACEMENT_AUDITS_SUCCESS = 'FETCH_PLACEMENT_AUDITS_SUCCESS';
export const FETCH_PLACEMENT_AUDITS_ERROR = 'FETCH_PLACEMENT_AUDITS_ERROR';
export const FETCH_PLACEMENT_AUDIT = 'FETCH_PLACEMENT_AUDIT';
export const FETCH_PLACEMENT_AUDIT_SUCCESS = 'FETCH_PLACEMENT_AUDIT_SUCCESS';
export const FETCH_PLACEMENT_AUDIT_ERROR = 'FETCH_PLACEMENT_AUDIT_ERROR';

export const FETCH_PLACEMENT_ORIGINS = 'FETCH_PLACEMENT_ORIGINS';
export const FETCH_PLACEMENT_ORIGINS_SUCCESS = 'FETCH_PLACEMENT_ORIGINS_SUCCESS';
export const FETCH_PLACEMENT_ORIGINS_ERROR = 'FETCH_PLACEMENT_ORIGINS_ERROR';
export const FETCH_PLACEMENT_ORIGIN = 'FETCH_PLACEMENT_ORIGIN';
export const FETCH_PLACEMENT_ORIGIN_SUCCESS = 'FETCH_PLACEMENT_ORIGIN_SUCCESS';
export const FETCH_PLACEMENT_ORIGIN_ERROR = 'FETCH_PLACEMENT_ORIGIN_ERROR';

import { useRef, useEffect, useReducer } from 'react';
import _ from 'lodash';

// use React class style setState as a hook
const useSetState = (initialState) => {
  return useReducer(
    (state, newState) => ({...state, ...newState}),
    initialState,
  );
};

// class style React setState and handle component unmount
const useSafeSetState = (initialState) => {
  const [state, setState] = useSetState(initialState);

  const mountedRef = useRef(false);
  // eslint-disable-next-line
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = (...args) => mountedRef.current && setState(...args);

  return [state, safeSetState];
};

// get previous values for comparison in a react hook
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useCached = (value) => {
  const ref = useRef();
  // eslint-disable-next-line
  useEffect(() => { ref.current = value; }, []);
  if (!_.isEqual(ref.current, value)) ref.current = value;
  return ref.current;
};

export { useSetState, useSafeSetState, usePrevious, useCached };

import axios from '../../api';
import * as actions from '../types';

export const fetchStates = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STATES, payload: undefined });
    const res = await axios.get('/state');
    dispatch({ type: actions.FETCH_STATES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STATES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import { hasAccess } from '../utils/permissionsCheck';
import { ExpandIcon } from './Icons';
import type { MenuItem, Auth } from '../schemas';

type Props = {
  menuItems: MenuItem[],
  openImmediately: Boolean,
  classes: Object,
  theme: Object,
  auth: Auth
};

type State = {
  open: boolean,
  activeBranch: ?MenuItem
};

const styles = theme => {
  const menuTransition = theme.transitions.create('all', {
    duration: theme.transitions.duration.standard
  });
  const classes = {
    root: {
      width: '100%',
      maxWidth: 360,
      background: theme.palette.background.paper
    },
    button: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      borderRadius: 0,
      justifyContent: 'flex-start',
      textTransform: 'none',
      width: '100%',
      transition: menuTransition
    },
    navItem: {
      ...theme.typography.body2,
      display: 'block',
      paddingTop: 0,
      paddingBottom: 0
    },
    navLink: {
      fontWeight: theme.typography.fontWeightRegular,
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      flexDirection: 'column',
      transition: menuTransition,
      overflow: 'hidden'
    },
    menuIcon: {
      fontFamily: 'Material Icons',
      marginRight: theme.spacing(1)
    },
    navLinkButton: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(14),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      textDecoration: 'none',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderLeft: '6px solid transparent'
    },
    activeButton: {
      borderLeftColor: theme.palette.primary[500]
    },
    navBranch: {
      flex: '1 0 auto',
      width: '100%',
      boxSizing: 'border-box',
      flexDirection: 'column',
      display: 'flex',
      transition: menuTransition
    },
    activeBranch: {
      height: 'auto',
      boxShadow:
        'inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14), inset 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      backgroundColor: theme.palette.background.default,
      '& li': {
        paddingLeft: theme.spacing(1)
      }
    },
    inactiveBranch: {
      height: 0,
      padding: 0
    },
    expander: {
      transition: menuTransition
    },
    activeBranchExpander: {
      transform: 'rotate(180deg)'
    },
    inactiveBranchExpander: {
      transform: 'none'
    },
    label: {
      flex: '1 0 auto'
    }
  };
  return classes;
};

class AppMenu extends Component<Props, State> {
  static defaultProps = {
    menuItems: null,
    openImmediately: false,
    classes: null,
    theme: null
  };

  state = {
    open: false,
    activeBranch: null
  };

  childHasActiveParent(menuItem: MenuItem) {
    if (!this.state.activeBranch || !this.state.activeBranch.Children) {
      return false;
    }
    return !!_.find(this.state.activeBranch.Children, child =>
      _.isEqual(child, menuItem)
    );
  }

  branchHasActiveChild = menuItem => {
    return _.reduce(
      menuItem.Children,
      (isActive, child) => {
        if (_.isEqual(child, this.state.activeBranch)) {
          isActive = true;
        }
        if (!isActive && child.Children && child.Children.length > 0) {
          isActive = this.branchHasActiveChild(child);
        }
        return isActive;
      },
      false
    );
  };

  branchIsActive(menuItem: MenuItem) {
    const { activeBranch } = this.state;
    let givenMenuItemActive = _.isEqual(activeBranch, menuItem);
    // branch is active if the given menu item or any of its children are active
    return givenMenuItemActive || this.branchHasActiveChild(menuItem);
  }

  navItemIsBranch(menuItem: MenuItem) {
    return (
      menuItem &&
      menuItem.Children &&
      _.isArray(menuItem.Children) &&
      menuItem.Children.length > -1
    );
  }

  toggleNavBranch(e: SyntheticEvent<HTMLButtonElement>, menuItem: MenuItem) {
    if (
      !_.isEqual(this.state.activeBranch, menuItem) &&
      !this.childHasActiveParent(menuItem)
    ) {
      this.setState({ activeBranch: menuItem });
    }
    e.stopPropagation();
  }

  userCanView(menuItem: MenuItem) {
    return (
      this.props.auth.currentUser &&
      this.props.auth.currentUser.Roles &&
      this.props.auth.currentUser.Roles.length > 0 &&
      hasAccess(this.props.auth.currentUser.Roles, menuItem.allowedRoles)
    );
  }

  // render stuff
  renderNavBranch(menuItem: MenuItem, classes: Object) {
    let navBranchStatus = this.branchIsActive(menuItem)
      ? 'activeBranch'
      : 'inactiveBranch';

    const output = this.userCanView(menuItem) ? (
      <ListItem
        className={classNames(classes.navLink, navBranchStatus)}
        disableGutters
        key={`${menuItem.url}-${menuItem.label}`}
      >
        <NavLink
          activeClassName={classes.activeButton}
          to={menuItem.url}
          className={
            classNames(classes.button, classes.navLinkButton) + ' button '
          }
          onClick={e => this.toggleNavBranch(e, menuItem)}
        >
          {<menuItem.icon className={classes.menuIcon} />}
          <div className={classes.label}>{menuItem.label}</div>
          <ExpandIcon
            className={classNames(
              classes.expander,
              classes[`${navBranchStatus}Expander`]
            )}
          />
        </NavLink>
        <List
          className={classNames(classes.navBranch, classes[navBranchStatus])}
        >
          {menuItem.Children && this.renderNavItems(menuItem.Children)}
        </List>
      </ListItem>
    ) : null;
    return output;
  }

  renderNavLeaf(menuItem: MenuItem, classes: Object) {
    const output = this.userCanView(menuItem) ? (
      <ListItem
        className={classes.navLink + ' nav-link leaf '}
        disableGutters
        key={menuItem.url}
      >
        <NavLink
          exact
          activeClassName={classes.activeButton}
          to={menuItem.url}
          className={
            classNames(classes.button, classes.navLinkButton) + ' button '
          }
          onClick={e => this.toggleNavBranch(e, menuItem)}
        >
          {menuItem.icon && <menuItem.icon className={classes.menuIcon} />}
          {menuItem.label}
        </NavLink>
      </ListItem>
    ) : null;
    return output;
  }

  renderNavItems(menuItems: MenuItem[]) {
    const { classes } = this.props;
    if (_.isArray(menuItems)) {
      return menuItems.map(menuItem => {
        return !this.navItemIsBranch(menuItem)
          ? this.renderNavLeaf(menuItem, classes)
          : this.renderNavBranch(menuItem, classes);
      });
    }
  }

  render() {
    return (
      <div>
        <List>{this.renderNavItems(this.props.menuItems)}</List>
      </div>
    );
  }
}

export default (withStyles(styles, { withTheme: true })(AppMenu): any);

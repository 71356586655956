import axios from '../../api';
import * as actions from '../types';

export const fetchOrganizations = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ORGANIZATIONS, payload: undefined });
    const res = await axios.get('/organization');
    dispatch({ type: actions.FETCH_ORGANIZATIONS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ORGANIZATIONS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchOrganizationList = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ORGANIZATIONS, payload: undefined });
    const res = await axios.get('/organization/list');
    dispatch({ type: actions.FETCH_ORGANIZATIONS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ORGANIZATIONS_ERROR, payload: error.response.data
    });
  }
};

export const fetchOrganization = organization => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_ORGANIZATION, payload: organization });
    const res = await axios.get(`/organization/${organization.id}`);
    dispatch({ type: actions.FETCH_ORGANIZATION_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_ORGANIZATION_ERROR, payload: error.response.data
    });
  }
};

export const mergeOrganizations = options => async dispatch => {
  try {
    await axios.post('/organization/merge', options);
    dispatch({ type: actions.MERGE_ORGANIZATION_SUCCESS });
    dispatch(fetchOrganizations());
  } catch (error) {
    dispatch({
      type: actions.MERGE_ORGANIZATION_ERROR,
      payload: error.response.data
    });
  }
};

export const createOrganization = organization => async dispatch => {
  try {
    dispatch({ type: actions.CREATE_ORGANIZATION, payload: organization });
    const res = await axios.post('/organization', organization);
    dispatch({ type: actions.CREATE_ORGANIZATION_SUCCESS, payload: res.data });
    dispatch(fetchOrganizations());
  } catch (error) {
    dispatch({
      type: actions.CREATE_ORGANIZATION_ERROR, payload: error.response.data
    });
  }
};

export const updateOrganization = organization => async dispatch => {
  try {
    dispatch({ type: actions.UPDATE_ORGANIZATION, payload: organization });
    const res = await axios.put(`/organization/${organization.id}`, organization);
    dispatch({ type: actions.UPDATE_ORGANIZATION_SUCCESS, payload: res.data });
    dispatch(fetchOrganizations());
  } catch (error) {
    dispatch({
      type: actions.UPDATE_ORGANIZATION_ERROR, payload: error.response.data
    });
  }
};

export const deleteOrganization = organization => async dispatch => {
  try {
    dispatch({ type: actions.DELETE_ORGANIZATION, payload: organization });
    const res = await axios.delete(`/organization/${organization.id}`);
    dispatch({ type: actions.DELETE_ORGANIZATION_SUCCESS, payload: res.data });
    dispatch(fetchOrganizations());
  } catch (error) {
    dispatch({
      type: actions.DELETE_ORGANIZATION_ERROR, payload: error.response.data
    });
  }
};

// @flow

import * as React from 'react';

import PlacementSlots from '../../pages/Placements/Drawer/PlacementSlots';
import SiteSearch from '../../pages/Placements/Drawer/SiteSearch';
import PreceptorSearch from '../../pages/Placements/Drawer/PreceptorSearch';
// import Legal from '../../pages/Placements/Drawer/Legal';
import StudentPlacementHistory from '../../pages/Placements/Drawer/StudentPlacementHistory';
import StashedPlacementSlots from '../../pages/Placements/Drawer/StashedPlacementSlots';
import Share from '../../pages/Placements/Drawer/Share';
import Notes from '../../pages/Placements/Drawer/Notes';
import Application from '../../pages/Placements/Drawer/Application';
import Todos from '../../pages/Placements/Drawer/Todos';

import type {DrawerNavigationItem} from '../../schemas';

type Props = {
  currentSection: DrawerNavigationItem
};

function PlacementDrawerSections(props: Props): React$Element<any> | React$Element<"div"> {
  const {currentSection} = props;

  switch (currentSection.slug) {
    case 'placement_slots':
      return <PlacementSlots />;
    case 'site_search':
      return <SiteSearch />;
    case 'preceptor_search':
      return <PreceptorSearch />;
    // case 'legal':
    //   return <Legal />;
    case 'placement_history':
      return <StudentPlacementHistory />;
    case 'stashed_placement_slots':
      return <StashedPlacementSlots />;
    case 'share':
      return <Share />;
    case 'notes':
      return <Notes />;
    case 'application':
      return <Application />;
    case 'todos':
      return <Todos />;
    default:
      return <div />;
  }
}

export default PlacementDrawerSections;

// @flow

import React, { Component } from 'react';

import SiteDetails from './SiteDetails/SiteDetails';
import SitePreceptors from './SitePreceptors/SitePreceptors';
import SitePlacements from './SitePlacements/SitePlacements';

import type { DrawerNavigationItem } from '../../schemas';

type Props = {
  currentSection: DrawerNavigationItem
};

class SiteDrawerSections extends Component<Props> {
  render(): React$Element<any> | React$Element<"div"> {
    const { currentSection } = this.props;

    switch (currentSection.slug) {
      case 'details':
        return <SiteDetails />;
      case 'preceptors':
        return <SitePreceptors />;
      case 'placements':
        return <SitePlacements />;
      default:
        return <div />;
    }
  }
}

export default SiteDrawerSections;

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import { fetchTerms } from '../../actions/creators/terms';
import { fetchPlacementBoard } from '../../actions/creators/placements';
import { fetchPermissions } from '../../actions/creators/permissions';
import { fetchApplications } from '../../actions/creators/studentApplications';
import { hasAccess } from '../../utils/permissionsCheck';

import {
  fetchStatuses, fetchCoordinators, fetchContracts, fetchOverview
} from '../../actions/creators/dashboard';

import { getTermsWithAttrs } from '../../selectors/terms';
import { getCurrentUsersWorkingTerm } from '../../selectors/users';

import type {
  User, Term, Permissions, Placement, PlacementApp
} from '../../schemas';

import PlacementWorkflowStatusChart from '../../components/DashboardWidgets/PlacementWorkflowStatusChart';
import PlacementsByCoordinatorChart from '../../components/DashboardWidgets/PlacementsByCoordinator';
import PlacementsContractStatusChart from '../../components/DashboardWidgets/PlacementsContractStatusChart';
import PlacementsOverviewWidget from '../../components/DashboardWidgets/SemesterPlacementsOverview';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '64px',
    padding: theme.spacing(2)
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(1) + 'px',
    paddingBottom: theme.spacing(1) + 'px',
    minHeight: '260px',
    backgroundColor: '#008B8B',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none'
  }
});

type Props = {
  classes: Object,
  currentUser: User,
  permissions: Permissions,
  activePlacements: Placement[],
  fetchPlacementBoard: Function,
  placements: Placement[],
  terms: Term[],
  workingTerm: Term,
  TermId: number,
  fetchPermissions: Function,
  fetchApplications: Function,
  fetchTerms: Function,
  permissions: Permissions,
  orphanPlacementApps: PlacementApp[],
  fetchStatuses: Function,
  fetchOverview: Function,
  desktopStatuses: any[],
  fetchCoordinators: Function,
  desktopCoordinators: any[],
  fetchContracts: Function,
  desktopContracts: Object,
  desktopOverview: Object,
};

type State = {
  workingTerm: Term,
};

class Dashboard extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      workingTerm: _.find(props.terms, { isActive: true }),
    };
  }

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.workingTerm !== state.workingTerm) {
      props.fetchOverview({ id: props.workingTerm.id });
      props.fetchCoordinators({ id: props.workingTerm.id });
      props.fetchStatuses({ id: props.workingTerm.id });
      props.fetchContracts({ id: props.workingTerm.id });
      props.fetchPlacementBoard(props.workingTerm.id); // only prefetching for performance
      return { workingTerm: props.workingTerm };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchTerms();
    await this.props.fetchApplications();
    await this.props.fetchPermissions();
    if (this.state.workingTerm) {
      const workingTermId = this.state.workingTerm.id;
      await this.props.fetchOverview({ id: workingTermId });
      await this.props.fetchCoordinators({ id: workingTermId });
      await this.props.fetchStatuses({ id: workingTermId });
      await this.props.fetchContracts({ id: workingTermId });
    }

  };

  isCoordinator = () => {
    const { currentUser } = this.props;
    return hasAccess(currentUser.Roles, ['cpo_staff', 'system_admin']);
  };

  isManager = () => {
    const { currentUser } = this.props;
    return hasAccess(currentUser.Roles, ['cpo_manager', 'system_admin']);
  };

  render() {
    const { classes, orphanPlacementApps, desktopCoordinators, desktopStatuses,
      desktopContracts, desktopOverview
    } = this.props;
    const { workingTerm } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Dashboard</h1>
          </Grid>
          {(this.isManager() || this.isCoordinator()) && workingTerm && desktopOverview && (
            <Grid item xs={12} md={12}>
              <PlacementsOverviewWidget
                overview={desktopOverview}
                workingTerm={workingTerm}
                orphans={orphanPlacementApps}
              />
            </Grid>
          )}

          {this.isManager() && workingTerm && desktopCoordinators.length > 0 && (
            <Grid item xs={12} md={12}>
              <PlacementsByCoordinatorChart
                data={desktopCoordinators}
              />
            </Grid>
          )}

          {this.isManager() && workingTerm && desktopStatuses.length > 0 && (
            <Grid item xs={12} md={6}>
              <PlacementWorkflowStatusChart
                data={desktopStatuses}
              />
            </Grid>
          )}

          {this.isManager() && workingTerm && !_.isEmpty(desktopContracts) && (
            <Grid item xs={12} md={6}>
              <PlacementsContractStatusChart
                data={desktopContracts}
              />
            </Grid>
          )}

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions,
    terms: getTermsWithAttrs(state),
    workingTerm: getCurrentUsersWorkingTerm(state),
    orphanPlacementApps: state.studentApplications.orphans,
    desktopStatuses: state.dashboard.statuses,
    desktopCoordinators: state.dashboard.coordinators,
    desktopContracts: state.dashboard.contracts,
    desktopOverview: state.dashboard.overview,
  };
};

export default (compose(
  withStyles(styles, { name: 'Dashboard' }),
  connect(mapStateToProps, {
    fetchPlacementBoard, fetchPermissions, fetchApplications, fetchStatuses,
    fetchCoordinators, fetchContracts, fetchOverview, fetchTerms
  })
)(Dashboard): any);

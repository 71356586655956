// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {
  Typography,Dialog,DialogTitle,DialogContent,
  DialogContentText,DialogActions,Button
} from '@material-ui/core';

import {
  fetchUserMessages, updateUserMessage, deleteUserMessage, readAllUserMessages
} from '../../actions/creators/userMessages';
import {fetchPermissions} from '../../actions/creators/permissions';

import UserMessageTable from './UserMessageTable';

import type {User,UserMessage} from '../../schemas';

type State = {
  selectedMessage: Object,
  alertOpen: boolean,
  messageOpen: boolean,
  loading: boolean
};

type Props = {
  classes: Object,
  currentUser: User,
  permissions: Object,
  messages: UserMessage[],
  fetchPermissions: Function,
  fetchUserMessages: Function,
  updateUserMessage: Function,
  deleteUserMessage: Function,
  readAllUserMessage: Function
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
});

class UserMessages extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedMessage: {},
      alertOpen: false,
      messageOpen: false,
      loading: true
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    await this.props.fetchPermissions();
    await this.props.fetchUserMessages(this.props.currentUser);
    this.setState({ loading: false });
  };

  handleReadMessages = async message => {
    const {messages,updateUserMessage,readAllUserMessage} = this.props;
    if (!message) await readAllUserMessage(messages);
    else if (!message.read) {
      message.read = true;
      await updateUserMessage(message);
    }
    await this.props.fetchUserMessages(this.props.currentUser);
  };

  handleDeleteMessage = async () => {
    const { selectedMessage } = this.state;
    await this.props.deleteUserMessage(selectedMessage);
    this.handleAlertClose();
  };

  handleAlertOpen = (el) => {
    this.setState({
      selectedMessage: el,
      alertOpen: true
    });
  };

  handleAlertClose = () => {
    this.setState({
      selectedMessage: {},
      alertOpen: false
    });
  };

  handleMessageOpen = async el => {
    await this.handleReadMessages(el);
    this.setState({
      selectedMessage: el,
      messageOpen: true
    });
  };

  handleMessageClose = () => {
    this.setState({
      selectedMessage: {},
      messageOpen: false
    });
  };


  render() {
    const {classes,messages} = this.props;
    const {selectedMessage} = this.state;

    return(
      <div className={classes.root}>
        <Typography variant="h6">Messages</Typography>
        <Typography paragraph variant="caption">NOTE: Messages older than 30 days will automatically be removed.</Typography>
        <UserMessageTable
          messages={_.filter(messages, ['read', false])}
          variant="unread"
          handleMessageOpen={this.handleMessageOpen}
          handleAlertOpen={this.handleAlertOpen}
        />
        <UserMessageTable
          messages={_.filter(messages, ['read', true])}
          variant="read"
          handleMessageOpen={this.handleMessageOpen}
          handleAlertOpen={this.handleAlertOpen}
        />
        <Dialog
          fullWidth
          maxWidth={'sm'}
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Delete Message?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <em dangerouslySetInnerHTML={{__html: selectedMessage.message}} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteMessage} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={'sm'}
          open={this.state.messageOpen}
          onClose={this.handleMessageClose}
          aria-labelledby="message-dialog-title"
          aria-describedby="message-dialog-description"
        >
          <DialogTitle id="message-dialog-title">{`Message`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="message-dialog-description">
              <em dangerouslySetInnerHTML={{__html: selectedMessage.message}} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleMessageClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.userMessage,
    messages: state.userMessages.items
  };
};

export default (compose(
  withStyles(styles, { name: 'Users' }),
  connect(mapStateToProps, {
    fetchUserMessages,
    updateUserMessage,
    fetchPermissions,
    deleteUserMessage,
    readAllUserMessages
  })
)(UserMessages): any);

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Typography } from '@material-ui/core';

import PreceptorDetail from '../../Preceptor/PreceptorDetail';
import type { Preceptor } from '../../../schemas';

type Props = {
  classes: Object,
  preceptor: Preceptor,
  isLoading: boolean
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }
});

class PreceptorDetails extends Component<Props> {

  render() {
    const { classes, preceptor } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Preceptor Details
        </Typography>

        <PreceptorDetail preceptor={preceptor} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    preceptor: state.preceptorDrawer.selectedPreceptor
  };
};

export default (compose(connect(mapStateToProps, {}), withStyles(styles))(
  PreceptorDetails
): any);

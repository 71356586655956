// @flow

import _ from 'lodash';
import moment from 'moment';
import axios from '../../api';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { notify } from 'reapop';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import {
  Attachment as AttachmentIcon,
  AccountCircle as AccountCircleIcon
} from '@material-ui/icons';

import type { User, AttachmentA } from '../../schemas';

type Props = {
  classes: Object,
  currentUser: User,
  item: AttachmentA,
  open: boolean,
  handleClose: Function,
};

type State = {
  attachment: AttachmentA,
  subject: string,
  content: string
};

const styles = theme => ({
  dialog: {
    overflowY: 'visible',
    '& > div': {
      overflowY: 'visible'
    }
  },
  dialogContent: {
    overflowY: 'visible'
  },
  textField: {
    margin: theme.spacing(1),
  },
});

class EmailAttachmentADialog extends Component<Props, State> {

  constructor(props) {
    super(props);
    const { item } = props;
    this.state = {
      attachment: item,
      subject: 'Duke University School of Nursing Student Participation Summary',
      content: 'Please approve the attached Student Participation Summary.',
    };
  }

  getContactEmail = () => {
    const { attachment } = this.state;

    const contactOrgEmail = _.get(attachment, 'Contract.Organization.contactEmail', null);
    const contactSiteEmail = _.get(attachment, 'Site.contactEmail', null);

    if (contactSiteEmail) {
      return contactSiteEmail;
    } else if (contactOrgEmail) {
      return contactOrgEmail;
    }

    return null;
  };

  hasContactEmail = () => {
    const contactEmail = this.getContactEmail();
    if (!contactEmail) {
      return false;
    }
    if (!_.isString(contactEmail) ||  contactEmail.length < 5) {
      return false;
    }
    return true;
  };

  getContactName = () => {
    const { attachment } = this.state;

    const contactOrg = _.get(attachment, 'Contract.Organization.name', false);
    const contactOrgName = _.get(attachment, 'Contract.Organization.contact', contactOrg || false);
    const contactSite = _.get(attachment, 'Site.name', false);
    const contactSiteName = _.get(attachment, 'Site.contact', contactSite || false);

    if (contactSiteName) {
      return contactSiteName;
    } else if (contactOrgName) {
      return contactOrgName;
    }

    return 'N/A';
  };

  handleEmailAttachment = async () => {
    const { currentUser } = this.props;
    const { subject, content, attachment } = this.state;
    const contactName = this.getContactName();
    const contactEmail = this.getContactEmail();
    let notification;

    const message = {
      objectId: attachment.id,
      from: { name: currentUser.name, email: currentUser.username },
      to: { name: contactName, email: contactEmail },
      subject: subject,
      content: content,
      messageType: 'attachmentA',
      SenderId: currentUser.username,
    };

    const res = await axios.post('email/send', message);

    if (res.status === 200) {
      notification = notify({
        title: 'ATTACHMENT SENT',
        message: `Attachment #${this.state.attachment.id} successfully sent to address ${contactEmail || 'N/A'}`,
        status: 'success',
        autoDismiss: 5
      });
    } else {
      notification = notify({
        title: 'ATTACHMENT ERROR',
        message: `There was a problem sending attachment #${this.state.attachment.id}`,
        status: 'error',
        autoDismiss: 5
      });
    }

    this.props.handleClose(notification);
  };

  handleChange = (name, e) => {
    this.setState({ [name.toString()]: e.target.value });
  };

  render() {
    const { classes, open, currentUser } = this.props;
    const { attachment } = this.state;
    const contactEmail = this.getContactEmail();
    const hasEmail = this.hasContactEmail();

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => this.props.handleClose(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">
          Send Attachment A
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {
              hasEmail
                ? <Typography
                  gutterBottom
                  color="primary"
                  component="span"
                  variant="subtitle1"
                >
                  {`You can customize the subject and message below before sending.`}
                </Typography>
                : <Typography
                  gutterBottom
                  color="secondary"
                  component="span"
                  variant="subtitle1"
                >
                  {`Attachment #${attachment.id} does not have an email address associated with either the site or organization contact!`}
                </Typography>
            }
          </DialogContentText>

          {hasEmail && (<form className={classes.container} noValidate autoComplete="off">
            <TextField
              fullWidth
              disabled={true}
              id="emailTo"
              label="To"
              className={classes.textField}
              value={contactEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              disabled={true}
              id="emailFrom"
              label="From"
              className={classes.textField}
              value={currentUser.username}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              className={classes.textField}
              id="emailSubject"
              label="Subject"
              defaultValue={this.state.subject}
              onChange={e => this.handleChange('subject', e)}
            />
            <TextField
              fullWidth
              id="emailContent"
              label="Message"
              multiline
              maxRows="3"
              defaultValue={this.state.content}
              onChange={e => this.handleChange('content', e)}
              className={classes.textField}
              margin="normal"
            />
            <TextField
              fullWidth
              disabled={true}
              className={classes.textField}
              id="emailAttachment"
              label="Attachment"
              value={`${moment().format('YYYYMMDD')}-attachmentA-${attachment.id || 'a'}.docx`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachmentIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>)}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.props.handleClose(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!hasEmail || (this.state.subject.length < 5) || (this.state.content.length < 5)}
            color="primary"
            onClick={() => this.handleEmailAttachment()}
          >
            Send
          </Button>
        </DialogActions>

      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default (compose(
  connect(mapStateToProps, {}),
  withStyles(styles)
)(EmailAttachmentADialog): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import OrganizationForm from './OrganizationForm';

import { updateOrganization, deleteOrganization } from '../../actions/creators/organizations';
import { hideOrganizationDrawer } from '../../actions/creators/organizationDrawer';

import { hasAccess } from '../../utils/permissionsCheck';
import type { Organization, User } from '../../schemas';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  organization: Organization,
  classes: Object,
  currentUser: User,
  permissions: Object,
  updateOrganization: Function,
  deleteOrganization: Function,
  hideOrganizationDrawer: Function
};

class OrganizationDetail
  extends Component<Props> {
  canUpdate = () => {
    const {currentUser, permissions} = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  };

  canDelete = () => {
    const {currentUser, permissions} = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  };

  handleDelete = async () => {
    const {organization, deleteOrganization, hideOrganizationDrawer} = this.props;
    const confirmation = window.confirm(
      'Are you sure you want to delete this organization?',
    );
    if (confirmation) {
      await deleteOrganization(organization);
    }
    hideOrganizationDrawer();
  };

  handleSubmit = async values => {
    const {updateOrganization, hideOrganizationDrawer} = this.props;
    if (values.id) {
      await updateOrganization(values);
    }
    hideOrganizationDrawer();
  };

  generateInitialValues = () => {
    return _.get(this.props, 'organization', {});
  };

  render() {
    return (
      <Grid container>
        <OrganizationForm
          initialValues={this.generateInitialValues()}
          handleSubmit={this.handleSubmit}
          handleDelete={this.handleDelete}
          canUpdate={this.canUpdate}
          canDelete={this.canDelete}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.organization
  };
};

export default (compose(
  connect(mapStateToProps, {
    updateOrganization,
    deleteOrganization,
    hideOrganizationDrawer
  }),
  withStyles(styles)
)(OrganizationDetail): any);

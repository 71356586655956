import axios from '../../api';
import * as actions from '../types';

export const fetchPermissions = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_PERMISSIONS, payload: undefined });
    const res = await axios.get('/permissions');
    dispatch({ type: actions.FETCH_PERMISSIONS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_PERMISSIONS_ERROR,
      payload: error.response.data
    });
  }
};

export const resetPermissions = () => {
  return { type: actions.RESET_PERMISSIONS, payload: null };
};

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Select } from '../Formik';

import {
  List, ListItem, ListItemText, Typography, Paper, Grid, Button, Divider,
} from '@material-ui/core';

import { hasAccess } from '../../utils/permissionsCheck';
import { formatValueListItem } from '../../utils/placementHelpers';

import { getAllTermValuesWithAttrs, getActiveTerm } from '../../selectors/terms';

import type { User, AttachmentA, ValueListItem } from '../../schemas';

const styles = theme => ({
  inputContainer: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  statusInput: {
    width: '50%'
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  section: {
    marginTop: theme.spacing(2)
  },
});

type Props = {
  currentUser: User,
  permissions: Object,
  classes: Object,
  handleSubmit: Function,
  initialValues: AttachmentA,
  statusCodes: ValueListItem[],
  terms: ValueListItem[],
};

class EditAttachmentAForm extends Component<Props> {

  canReconcile = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.reconcile);
  };

  getStatusCodes = () => {
    const { statusCodes } = this.props;
    const canSetToAgreed = this.canReconcile();

    return statusCodes.filter(code => {
      if (canSetToAgreed) {
        return true;
      }
      if (code.name === 'Agreed') {
        return false;
      } else {
        return true;
      }
    });
  };

  formatEnrollmentName = fullName => {
    const firstName = fullName.split(' ').slice(0, -1).join(' ');
    const lastName = fullName.split(' ').slice(-1).join(' ');
    return `${firstName} ${lastName}`;
  };

  renderEnrollments = () => {
    const { classes, initialValues, terms } = this.props;
    const enrollments = _.map(initialValues.PreceptorEnrollments, el => {
      const courseId = _.get(el, 'SiteEnrollment.Course.CatalogNumberId', 'NA');
      const preceptorName = _.get(el, 'Preceptor.name', 'NA');
      const studentName = _.get(el, 'SiteEnrollment.Package.Placements[0].Student.fullName', 'NA');
      const termId = _.get(el, 'SiteEnrollment.Package.TermId', null);
      const term = _.find(terms, { id: termId });
      return {
        id: el.id,
        name: `${term?.name ?? 'N/A'} | ${courseId} | ${this.formatEnrollmentName(studentName)} | ${this.formatEnrollmentName(preceptorName)}`
      };
    });

    return(
      <Grid item>
        <Typography variant="h6" gutterBottom className={classes.section}>
          Preceptor Enrollments
        </Typography>
        <Paper>
          <List>
            {enrollments.length === 0 && (
              <ListItem>
                <ListItemText primary="No Preceptor Enrollments" />
              </ListItem>
            )}
            {enrollments.length > 0 &&
              _.map(enrollments, el => (
                <ListItem key={el.id}>
                  <ListItemText primary={el.name} />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Grid>
    );
  };

  render() {
    const { classes, initialValues } = this.props;
    const statusCodeOptions = this.getStatusCodes();

    return(
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {() => (
          <Form className={classes.root}>

            {!this.canReconcile() && (
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Attached Placements
                </Typography>
                {initialValues.Placements && initialValues.Placements.length === 0 && (
                  <Typography>No placements</Typography>
                )}
                {initialValues.Placements && initialValues.Placements.length > 0 &&
                  initialValues.Placements.map(placement => {
                    let valueListItem = formatValueListItem(placement);
                    return (
                      <Typography key={valueListItem.id}>
                        {valueListItem.name}
                      </Typography>
                    );
                  })}
              </div>
            )}

            {this.renderEnrollments()}

            <Divider className={classes.divider} />

            <div className={classes.inputContainer}>
              <Field
                fullWidth
                name="AttachmentAStatusCodeId"
                label="Attachment A Status"
                options={statusCodeOptions}
                component={Select}
              />
            </div>

            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Save Changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.attachmentA,
    statusCodes: state.attachmentAStatusCodes.values,
    terms: getAllTermValuesWithAttrs(state),
    activeTerm: getActiveTerm(state),
  };
};

export default (compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true, name: 'EditAttachmentAForm'})
)(EditAttachmentAForm): any);

// @flow

import * as React from 'react';
import { FieldProps } from 'formik';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';

type FieldPropTypes = typeof FieldProps;
type MuiDatePickerPropTypes = typeof MuiDatePickerProps;
type DatePickerProps = {
  ...FieldPropTypes,
  ...MuiDatePickerPropTypes
}

const DatePicker: React.ComponentType<DatePickerProps> = ({
  field,
  form,
  ...props
}) => {
  const { name } = field;
  const { touched, errors } = form;

  field.value = !field.value ? null : field.value;

  const handleChange = (value) => {
    form.setFieldTouched(name, true);
    form.setFieldValue(name, value);
  };

  return (
    <MuiDatePicker
      {...props}
      {...field}
      autoOk
      onChange={handleChange}
      error={touched[name] && !!errors[name]}
      helperText={
        touched[name] && errors[name] ? errors[name] : props.helperText
      }
    />
  );
};

DatePicker.displayName = 'FormikMaterialUIDatePicker';

export default DatePicker;

// @flow

import {
  SHOW_ORGANIZATION_DRAWER,
  HIDE_ORGANIZATION_DRAWER,
  CHANGE_ORGANIZATION_DRAWER_SECTION,
  FETCH_SELECTED_ORGANIZATION,
  FETCH_SELECTED_ORGANIZATION_SUCCESS,
  FETCH_SELECTED_ORGANIZATION_ERROR,
  FETCH_SELECTED_ORGANIZATION_CONTRACTS,
  FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS,
  FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR
} from '../actions/types';
import type { Organization, Contract, DrawerNavigationItem } from '../schemas';
import navItems from '../config/organizationDrawerNavigationItems';

type State = {
  +isVisible: boolean,
  +selectedOrganization: ?Organization,
  +selectedOrganizationContracts: ?Contract[],
  +currentSection: DrawerNavigationItem,
  +sections: DrawerNavigationItem[],
  +isLoading: boolean,
  +error: ?string
};

type Action = {
  type:
    | typeof SHOW_ORGANIZATION_DRAWER
    | typeof HIDE_ORGANIZATION_DRAWER
    | typeof CHANGE_ORGANIZATION_DRAWER_SECTION
    | typeof FETCH_SELECTED_ORGANIZATION
    | typeof FETCH_SELECTED_ORGANIZATION_SUCCESS
    | typeof FETCH_SELECTED_ORGANIZATION_ERROR
    | typeof FETCH_SELECTED_ORGANIZATION_CONTRACTS
    | typeof FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS
    | typeof FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR,
  payload: Object
};

const initialState = {
  isVisible: false,
  selectedOrganization: undefined,
  selectedOrganizationContracts: [],
  currentSection: navItems[0],
  sections: navItems,
  isLoading: false,
  error: undefined
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case SHOW_ORGANIZATION_DRAWER:
      return {
        ...state,
        isVisible: true,
        currentSection: initialState.currentSection
      };

    case HIDE_ORGANIZATION_DRAWER:
      return initialState;

    case CHANGE_ORGANIZATION_DRAWER_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    case FETCH_SELECTED_ORGANIZATION:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_SELECTED_ORGANIZATION_SUCCESS:
      return {
        ...state,
        selectedOrganization: action.payload,
        isLoading: false
      };

    case FETCH_SELECTED_ORGANIZATION_ERROR:
      return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
        isLoading: false,
        error: action.payload
      };


    case FETCH_SELECTED_ORGANIZATION_CONTRACTS:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_SELECTED_ORGANIZATION_CONTRACTS_SUCCESS:
      return {
        ...state,
        selectedOrganizationContracts: action.payload,
        isLoading: false
      };

    case FETCH_SELECTED_ORGANIZATION_CONTRACTS_ERROR:
      return {
        ...state,
        selectedOrganizationContracts: initialState.selectedOrganizationContracts,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

// @flow

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector,useDispatch} from 'react-redux';

import {Grid,Typography} from '@material-ui/core';

import {fetchPlacementNotes} from '../../../actions/creators/placementDrawer';

import {
  getSelectedPlacement,getPlacementNotes
} from '../../../selectors/placementDrawer';

import LoadingIndicator from '../../../components/LoadingIndicator';
import NoteList from '../../../components/Note/NoteList';
import CreateNoteButton from '../../../components/Note/CreateNoteButton';

const useStyles = makeStyles(theme => {
  return {
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    },
    titleContainer: {
      marginBottom: theme.spacing(1)
    },
    buttonContainer: {
      textAlign: 'right'
    },
    container: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1)
    }
  };
});

function NotesSection(): React$Element<"div"> {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const selectedPlacement = useSelector(getSelectedPlacement);
  const placementNotes = useSelector(getPlacementNotes);

  async function fetchNotes() {
    setIsLoading(true);
    await dispatch(fetchPlacementNotes(selectedPlacement));
    setIsLoading(false);
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.titleContainer}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h5" gutterBottom>
            Notes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.buttonContainer}>
          <CreateNoteButton
            model="Placement"
            foreignKey={selectedPlacement.id}
            afterCreate={fetchNotes}
          />
        </Grid>
      </Grid>
      {isLoading && <LoadingIndicator />}
      {!isLoading && <NoteList notes={placementNotes} afterAction={fetchNotes} />}
    </div>
  );
}

export default NotesSection;

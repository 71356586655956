// @flow

import React, { Component } from 'react';

import PreceptorDetails from './PreceptorDetails/PreceptorDetails';
import PreceptorStudents from './PreceptorStudents/PreceptorStudents';
import Attachments from './Attachments/Attachments';

import type { DrawerNavigationItem } from '../../schemas';

type Props = {
  currentSection: DrawerNavigationItem
};

class PreceptorDrawerSections extends Component<Props> {
  render(): React$Element<any> | React$Element<"div"> {
    const { currentSection } = this.props;

    switch (currentSection.slug) {
      case 'edit':
        return <PreceptorDetails />;
      case 'students':
        return <PreceptorStudents />;
      case 'attachments':
        return <Attachments />;
      default:
        return <div />;
    }
  }
}

export default PreceptorDrawerSections;

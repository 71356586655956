// @flow

// import _ from 'lodash';

import React, {useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {createSite} from '../../../actions/creators/sites';

import {fetchSiteTypes} from '../../../actions/creators/siteTypes';
import {fetchCounties} from '../../../actions/creators/counties';
import {fetchCountries} from '../../../actions/creators/countries';
import {fetchStates} from '../../../actions/creators/states';
import {fetchSpecialties} from '../../../actions/creators/specialties';
import {fetchOrganizationList} from '../../../actions/creators/organizations';

import {getAllSiteTypeValues} from '../../../selectors/siteTypes';
import {getAllCountyValues} from '../../../selectors/counties';
import {getAllCountryValues} from '../../../selectors/countries';
import {getAllStateValues} from '../../../selectors/states';
import {getAllSpecialtyValues} from '../../../selectors/specialties';
import {getAllOrganizationValues} from '../../../selectors/organizations';

import {useCached} from '../../../hooks';
import {hasAccess} from '../../../utils/permissionsCheck';

import SiteForm from './SiteForm';

type Props = {
  initialData?: Object,
  afterClose?: Function
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddSiteButton = (props: Props): React$Element<React$FragmentType> => {
  const {initialData,afterClose} = props;

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const permissions = useSelector(state => state.permissions.site);
  const currentUser = useSelector(state => state.auth.currentUser);

  const states = useCached(useSelector(getAllStateValues));
  const specialties = useCached(useSelector(getAllSpecialtyValues));
  const counties = useCached(useSelector(getAllCountyValues));
  const countries = useCached(useSelector(getAllCountryValues));
  const siteTypes = useCached(useSelector(getAllSiteTypeValues));
  const organizations = useCached(useSelector(getAllOrganizationValues));

  let haveAssociations = Boolean(
    states.length && specialties.length && counties.length && countries.length
    && siteTypes.length && organizations.length
  );

  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchStates());
      dispatch(fetchSpecialties());
      dispatch(fetchCounties());
      dispatch(fetchCountries());
      dispatch(fetchSiteTypes());
      dispatch(fetchOrganizationList());
    }
  }, [dispatch,haveAssociations]);

  const handleOpen = () => setOpen(true);
  const handleClose = shouldUpdate => {
    setOpen(false);
    afterClose && shouldUpdate && afterClose();
  };

  const handleSubmit = async values => {
    await dispatch(createSite(values));
    handleClose(true);
  };

  const canCreate = hasAccess(currentUser.Roles, permissions.create);
  const canUpdate = hasAccess(currentUser.Roles, permissions.update);
  const canDelete = hasAccess(currentUser.Roles, permissions.delete);

  const site = {
    city: initialData ? initialData.searchCity : undefined,
    StateId: initialData ? initialData.searchState : undefined,
    postalCode: initialData ? initialData.searchPostalCode : undefined,
    CountryId: 'US',
    isRural: false,
    isUnderserved: false,
    isAhec: false
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleOpen}
      >
        New Site
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose()}
        aria-labelledby={`new-site-dialog`}
        open={open}
      >
        <DialogTitle id="new-site-dialog" onClose={() => handleClose()}>
          New Site
        </DialogTitle>
        {haveAssociations && <DialogContent dividers>
          <SiteForm
            initialValues={site}
            associations={{
              states,specialties,counties,countries,siteTypes,organizations
            }}
            canCreate={canCreate}
            canUpdate={canUpdate}
            canDelete={canDelete}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            handleDelete={() => console.log('delete method')}
          />
        </DialogContent>}
      </Dialog>
    </React.Fragment>
  );
};

export default AddSiteButton;

// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';

import ContractInfo from './ContractInfo';
import EditContractForm from './EditContractForm';
import WorkflowEntriesPanel from '../WorkflowEntriesPanel';
import OrganizationPanel from './OrganizationPanel';

import { hasAccess } from '../../utils/permissionsCheck';
import {
  updateContract, postContractWorkflowLogEntry
} from '../../actions/creators/contracts';

import { hideContractDrawer } from '../../actions/creators/contractDrawer';

import type { Contract, User } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  contract: Contract,
  classes: Object,
  currentUser: User,
  permissions: Object,
  updateContract: Function,
  postContractWorkflowLogEntry: Function,
  hideContractDrawer: Function,
  hideContractModal?: Function,
};

class ContractDetail
  extends Component<Props> {
  canUpdate = () => {
    const {currentUser, permissions} = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  };

  handleSubmit = async values => {
    const {
      contract,
      currentUser,
      postContractWorkflowLogEntry,
      updateContract,
      hideContractDrawer,
      hideContractModal
    } = this.props;

    // if no start or end dates, set to null
    values.startDate = values.startDate ? values.startDate : null;
    values.endDate = values.endDate ? values.endDate : null;

    // convert Moment objects to strings
    values.startDate = values.startDate && typeof values.startDate === 'object'
      ? values.startDate.format('YYYY-MM-DD')
      : values.startDate;
    values.endDate = values.endDate && typeof values.endDate === 'object'
      ? values.endDate.format('YYYY-MM-DD')
      : values.endDate;

    // if auto renew, set end date to null
    values.endDate = values.isAutoRenew ? null : values.endDate;

    if (contract.ContractStatusCodeId !== values.ContractStatusCodeId) {
      let workflowItem = {
        ContractId: contract.id,
        UserId: currentUser.id,
        StatusChangedFromId: contract.ContractStatusCodeId,
        StatusChangedToId: values.ContractStatusCodeId,
      };

      await postContractWorkflowLogEntry(workflowItem);
    }

    await updateContract(values);

    !hideContractModal && hideContractDrawer();
    hideContractModal && hideContractModal();
  };

  generateInitialValues = () => {
    let contract = _.get(this.props, 'contract', {});

    if (!contract) {
      return contract;
    }

    _.forEach(
      contract.ContractTypes,
      (el, index) => {
        if (typeof el === 'object') {
          contract.ContractTypes[index] = el.id;
        }
      },
    );

    return contract;
  };

  render() {
    const {classes, contract} = this.props;
    const organization = _.get(contract, 'Organization', undefined);
    const dateRequested = _.get(contract, 'dateRequested', undefined);
    const logEntries = _.get(contract, 'ContractWorkflowLogEntries', undefined);

    return (
      <div>
        {contract &&
          <Grid container>
            <Grid item xs={12} sm={9}>
              <div className={classes.container}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      Contract Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.gridRow}>
                    <Grid container>
                      <Grid item xs={4}>
                        {dateRequested &&
                          <Typography>
                            <strong>Date Requested: </strong>{' '}
                            {moment(dateRequested).format('ll')}
                          </Typography>}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          <strong>Starting Term: </strong>{' '}
                          {_.get(
                            contract,
                            'StartTerm.name',
                            'No Data Available',
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          <strong>Workflow Status: </strong>{' '}
                          {_.get(
                            contract,
                            'ContractStatusCode.name',
                            'No Data Available',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {!this.canUpdate() && contract &&
                  <ContractInfo contract={contract} />}
                {this.canUpdate() && contract &&
                  <EditContractForm
                    initialValues={this.generateInitialValues()}
                    canUpdate={this.canUpdate}
                    handleSubmit={this.handleSubmit}
                  />}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              {organization && <OrganizationPanel organization={organization} />}
              {logEntries && <WorkflowEntriesPanel entries={logEntries} />}
            </Grid>
          </Grid>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(
  connect(mapStateToProps, {
    updateContract,
    postContractWorkflowLogEntry,
    hideContractDrawer
  }),
  withStyles(styles)
)(ContractDetail): any);

// @flow

import _ from 'lodash';

import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {DragSource} from 'react-dnd';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import * as DNDTypes from '../../schemas/dndTypes';
import HoursStatusIcon from '../Placement/HoursStatusIcon';
import ContractStatusIcon from '../Placement/ContractStatusIcon';
import AttachmentAStatusIcon from '../Placement/AttachmentAStatusIcon';
import SiteEnrollments from './SiteEnrollments';

import CreateNoteButton from '../../components/Note/CreateNoteButton';
type Site = {
  id: number,
  name: string,
  hours: number
};

type Props = {
  id: number,
  sites: Site[],
  courseNumber: string,
  coordinator: string,
  courseName: string,
  course: string,
  student: string,
  Student: Object,
  Course: Object,
  Term: Object,
  Package: Object,
  managerId: string,
  coordinators: Array<Object>,
  index: number,
  isDragging: boolean,
  onCardClick: Function,
  connectDragSource: Function,
};

const useStyles = makeStyles(theme => {
  return {
    cardWrapper: {
      padding: 5,
      cursor: 'grab'
    },
    cardContent: {
      padding: 0,
    },
    cardHeader: {
      marginBottom: 5,
      paddingBottom: 2,
      borderBottom: '1px solid #333'
    },
    hoursStatusContainer: {
      height: theme.spacing(2)
    },
    statusIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    cardContentArea: {
      marginBottom: 5,
    },
    cardActions: {
      padding: 0,
      marginBottom: 5,
    },
    dragging: {
      opacity: 0.4
    },
  };
});

const dragSource = {
  beginDrag(props) {
    const {id,PlacementStatusCodeId} = props;
    const item = { id, PlacementStatusCodeId };

    return item;
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function PlacementCard(props: Props) {
  const {
    id,onCardClick,Student,Course,Package,managerId,connectDragSource
  } = props;
  const classes = useStyles();

  const studentName = _.get(Student, 'fullName', 'N/A');
  const courseNumber = _.get(Course, 'CatalogNumberId', 'N/A');
  const courseName = _.get(Course, 'description', 'N/A');
  const sites = _.get(Package, 'SiteEnrollments', []);

  function handleClick() {
    onCardClick(id);
  }

  return connectDragSource(
    <div>
      <Card className={classes.cardWrapper}>
        <CardContent className={classes.cardContent}>
          <Grid
            container
            alignItems="center"
            alignContent="stretch"
            className={classes.cardHeader}
          >
            <Grid item xs={1}>
              <CreateNoteButton
                mini
                edge="start"
                model="Placement"
                foreignKey={id}
              />
            </Grid>
            <Grid item xs={2} onClick={handleClick}>
              <Typography variant="caption" color="textPrimary">
                N.{courseNumber}
              </Typography>
            </Grid>
            <Grid item xs={6} onClick={handleClick}>
              <Typography variant="caption" color="textPrimary">
                {managerId}
              </Typography>
            </Grid>
            <Grid item xs={3} onClick={handleClick}>
              <Grid item className={classes.hoursStatusContainer}>
                <HoursStatusIcon
                  className={classes.statusIcon}
                  placement={{ ...props }}
                />
                <ContractStatusIcon
                  className={classes.statusIcon}
                  placement={{ ...props }}
                />
                <AttachmentAStatusIcon
                  className={classes.statusIcon}
                  placement={{ ...props }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="column"
            className={classes.cardContentArea}
            onClick={handleClick}
          >
            <Grid item xs={12}>
              <Typography variant="body1">
                {studentName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {courseName}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions} onClick={handleClick}>
          <Typography variant="caption" style={{ width: '100%' }}>
            <SiteEnrollments siteEnrollments={sites} />
          </Typography>
        </CardActions>
      </Card>
    </div>,
    { dropEffect: 'move' }
  );
}

export default (
  DragSource(
    DNDTypes.PLACEMENT_BOARD_PLACEMENT,dragSource,collect
  )(PlacementCard): any
);

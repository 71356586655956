// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Checkbox } from '../Formik/';
import { TextField } from 'formik-material-ui';
import { todoValidation } from '../../validation';

import {
  Button, FormControlLabel, MenuItem,
  ListItemText,
} from '@material-ui/core';

import type { Todo } from '../../schemas';

const STEPS = [{id: 0, name: 'None'},
  {id: 1, name: '1'},{id: 2, name: '2'},{id: 3, name: '3'},{id: 4, name: '4'},{id: 5, name: '5'},
  {id: 6, name: '6'},{id: 7, name: '7'},{id: 8, name: '8'},{id: 9, name: '9'},{id: 10, name: '10'},
  {id: 11, name: '11'},{id: 12, name: '12'},
];

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  inputContainer: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
});

type Props = {
  classes: Object,
  initialValues: Todo,
  handleSubmit: Function,
  handleClose: Function
};

class TodoForm extends Component<Props> {

  render() {
    const { classes } = this.props;
    const hasValue = Boolean(this.props.initialValues.id);

    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={todoValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({
          isSubmitting, isValid, values
        }) => (
          <Form>
            <Field
              fullWidth
              required
              type="text"
              name="description"
              label="Description"
              component={TextField}
            />
            <Field
              fullWidth
              select
              name="step"
              label="Step"
              component={TextField}
            >
              {_.map(STEPS, el => (
                <MenuItem key={el.id} value={el.id}>
                  <ListItemText primary={el.name} />
                </MenuItem>
              ))}
            </Field>
            <FormControlLabel
              className={classes.inputContainer}
              control={
                <Field
                  checked={values.sendNotification}
                  name="sendNotification"
                  label="Send message on compleation?"
                  component={Checkbox}
                />
              }
              label="Send message on compleation?"
            />
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                {hasValue ? 'Update Todo' : 'Create Todo'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default (withStyles(styles)(TodoForm): any);

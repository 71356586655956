// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import {
  Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, Paper,
  DialogActions, Button
} from '@material-ui/core';

import TouchpointForm from './TouchpointForm';

import {
  fetchTouchpoints,
  createTouchpoint,
} from '../../actions/creators/touchpoints';

import type { User, Touchpoint } from '../../schemas';

type Props = {
  classes: Object,
  data: {
    TouchableId: number,
    name: string,
    model: string
  },
  open: boolean,
  currentUser: User,
  touchpoints: Touchpoint[],
  handleClose: Function,
  fetchTouchpoints: Function,
  createTouchpoint: Function
};

const styles = theme => ({
  dialogContent: {
    padding: 0
  },
  button: {
    marginTop: theme.spacing(1)
  },
  listText: {
    width: '50%'
  },
  formPaper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100]
  },
  dialogActions: {
    paddingTop: theme.spacing(1),
    marginTop: 0
  },
});

class Touchpoints extends Component<Props> {

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchTouchpoints(this.props.data);
  };

  handleSubmit = async values => {
    await this.props.createTouchpoint(values);
    this.props.handleClose();
    await this.loadData();
  };

  renderTouchpoints = () => {
    const { touchpoints, data } = this.props;
    if (!touchpoints.length) {
      return (
        <ListItem>
          <ListItemText primary={`No Touchpoints for this ${_.upperFirst(data.model)}!`} />
        </ListItem>
      );
    }
    return _.map(touchpoints, el => (
      <ListItem key={el.id} divider>
        <ListItemText
          primary={el.note}
          secondary={
            `${el.User.name} via ${el.TouchpointType.name} on ${moment(el.createdAt).format('ll @ LT')}`
          }
        />
      </ListItem>
    ));
  };

  render() {
    const { open, handleClose, data, currentUser, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="log-dialog"
      >
        <DialogTitle id="log-dialog">
          Touchpoints for {data.name}
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.formPaper}>
            <TouchpointForm
              handleSubmit={this.handleSubmit}
              handleClose={handleClose}
              initialValues={{
                UserId: currentUser.id,
                TouchpointTypeId: '',
                TouchableId: data.TouchableId,
                model: data.model
              }}
            />
          </Paper>
          <List>
            {this.renderTouchpoints()}
          </List>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    touchpoints: _.orderBy(state.touchpoints.items, ['createdAt'], ['desc'])
  };
};

export default (compose(
  connect(mapStateToProps, {
    fetchTouchpoints, createTouchpoint
  }),
  withStyles(styles)
)(Touchpoints): any);

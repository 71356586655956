// @flow

import _ from 'lodash';
import moment from 'moment';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Paper, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Card, CardHeader, CardBody } from '../Card';
import OrphanPlacementsWidget from '../PlacementApp/Orphans/OrphansWidget';
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle';

import LoadingIndicator from '../LoadingIndicator';

import type { Term, PlacementApp } from '../../schemas';

type Props = {
  classes: Object,
  overview: Object,
  workingTerm: Term,
  orphans: PlacementApp[]
};

const styles = theme => ({
  listItem: {
    borderBottom: '1px solid #cccccc'
  },
  paperColor: {
    backgroundColor: theme.palette.background.paper
  },
  ...dashboardStyle
});

export class PlacementsOverview extends React.Component<Props> {

  renderDeadline: (() => string | React$Element<"strong">) = () => {
    const { workingTerm } = this.props;
    const termAttr = _.get(workingTerm, 'TermAttr');
    const deadline = moment(termAttr.approvalDeadline);
    const now = moment();
    const isWas = deadline > now ? 'is' : 'was';

    if (!termAttr.approvalDeadline) {
      return <strong>
        {workingTerm.name} does not have a deadline set!
      </strong>;
    }

    return `Placement approval deadline ${isWas} ${now.to(deadline)}.`;
  };

  render(): React$Element<any> {
    const { classes, workingTerm, orphans, overview } = this.props;

    return (
      <Card>
        <Paper>
          <CardHeader>
            {workingTerm && (
              <Typography variant="h5" component="h3">
                { workingTerm.name } Placements Overview
              </Typography>
            )}
          </CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {
                  !overview.count
                    ? <LoadingIndicator />
                    : <List>
                      <ListItem className={classes.listItem}>
                        <ListItemText primary={`There are ${overview.count} placements in your working semester.`} />
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <ListItemText>
                          You have {overview.assigned} placements assigned to you.
                        </ListItemText>
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <ListItemText>
                          {overview.matriculation} placements derived from matriculation data have not yet been applied for.
                        </ListItemText>
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <ListItemText>
                          {this.renderDeadline()}
                        </ListItemText>
                      </ListItem>
                    </List>
                }
              </Grid>
              {orphans && orphans.length > 0 && (
                <OrphanPlacementsWidget orphans={orphans} />
              )}
            </Grid>
          </CardBody>
        </Paper>
      </Card>
    );
  }
}

export default (withStyles(styles, { name: 'PlacementsOverview', withTheme: true })(PlacementsOverview): any);

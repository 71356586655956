// @flow

import * as React from 'react';
import {useDispatch} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {Card,CardHeader,Avatar,IconButton} from '@material-ui/core';
import {DragSource} from 'react-dnd';

import {removeFromWorkspace} from '../../actions/creators/placementDrawer';

import * as DNDTypes from '../../schemas/dndTypes';
import {PreceptorIcon,SiteIcon,CloseIcon,ObjectIcon} from '../../components/Icons';

import type {WorkspaceItem} from '../../schemas';

type Props = {
  item: WorkspaceItem,
  connectDragSource: Function,
  isDragging: boolean
};

// Drag-and-drop source/collect functions needed for the DragSource
const dragSource = {
  beginDrag(props) {
    const {item} = props;

    return item;
  }
};

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

const renderIcon = type => {
  switch (type) {
    case 'site':
      return <SiteIcon />;
    case 'preceptor':
      return <PreceptorIcon />;
    default:
      return <ObjectIcon />;
  }
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      maxWidth: 345,
    },
    dragging: {
      opacity: 0.6
    },
    avatar: {
      backgroundColor: theme.palette.indigo[500]
    },
  };
});

function PlacementDrawerWorkspaceCard(props: Props) {
  const {connectDragSource,isDragging,item} = props;
  const {model,name} = item;

  const classes = useStyles();
  const dispatch = useDispatch();

  const draggingClass = isDragging ? classes.dragging : '';

  function handleRemoveFromWorkspace() {
    dispatch(removeFromWorkspace(item));
  }

  return connectDragSource(
    <div className={draggingClass}>
      <Card className={classes.root}>
        <CardHeader
          title={name}
          titleTypographyProps={{
            variant: 'button',
            align: 'left'
          }}
          subheader={model}
          subheaderTypographyProps={{
            variant: 'caption',
            align: 'left'
          }}
          avatar={
            <Avatar variant="rounded" className={classes.avatar}>
              {renderIcon(model)}
            </Avatar>
          }
          action={
            <IconButton
              size="small"
              onClick={() => handleRemoveFromWorkspace()}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Card>
    </div>,
    { dropEffect: 'copy' }
  );
}

export default (compose(
  DragSource(DNDTypes.PLACEMENT_DRAWER_WORKSPACE, dragSource, collect)
)(PlacementDrawerWorkspaceCard): any);

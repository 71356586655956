// @flow

import {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  FETCH_ROLE,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_ERROR
} from '../actions/types';

import type { Role } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Role>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_ROLES
    | typeof FETCH_ROLES_SUCCESS
    | typeof FETCH_ROLES_ERROR
    | typeof FETCH_ROLE
    | typeof FETCH_ROLE_SUCCESS
    | typeof FETCH_ROLE_ERROR,
  payload: ?Array<Role> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_ROLE:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

// @flow

import moment from 'moment';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CreateContractButton from './CreateContractButton';
import { Chip, Avatar } from '@material-ui/core';

import { WorkflowIcon, ContractIcon } from '../../components/Icons';
import {
  hasActiveContract,
  hasRequestedContract,
  getActiveContracts,
  getRequestedContracts,
  contractExpiring
} from '../../utils/placementHelpers';

import CommonStyles from '../../styles/CommonStyles';

import type { Placement, SiteEnrollment, Contract } from '../../schemas';
import DeepLink from '../DeepLink';

type Classes = {
  warn: Object,
  warnAvatar: Object,
  error: Object,
  errorAvatar: Object,
  success: Object,
  successAvatar: Object
};

type Props = {
  placement: Placement,
  siteEnrollment: SiteEnrollment,
  classes: Classes,
  editable: boolean
};

const styles = theme => {
  const commonStyles = CommonStyles(theme);
  const classes = {
    success: commonStyles.success,
    successAvatar: commonStyles.successEmphasis,
    warn: commonStyles.warn,
    warnAvatar: commonStyles.warnEmphasis,
    error: commonStyles.error,
    errorAvatar: commonStyles.errorEmphasis,
  };
  return classes;
};

function ContractBrief(props: Props) {
  const {
    placement, siteEnrollment, classes, editable,
    siteEnrollment: { Site: { Organization } }
  } = props;

  let message = 'No Relevant Contract Exists';
  let contracts: Contract[] = [];
  let chipClass = classes.error;
  let avatarClass = classes.errorAvatar;
  const hasActive = hasActiveContract(placement, siteEnrollment);
  const hasRequested = hasRequestedContract(placement, siteEnrollment);
  const contractExists = hasActive || hasRequested;

  if (hasActive) {
    contracts = getActiveContracts(placement, siteEnrollment);
    const expiring = contractExpiring(placement);
    message = 'Signed Contract in Force';
    chipClass = classes.success;
    avatarClass = classes.successAvatar;
    if (expiring) {
      message = `Signed Contract in Force until ${moment(contracts[0].endDate).format('MMM DD, YYYY')}`;
      chipClass = classes.warn;
      avatarClass = classes.warnAvatar;
    }
    if (contracts[0].isAutoRenew) {
      message = 'Signed, Auto Renew Contract in Force';
    }
  } else if (hasRequested) {
    contracts = getRequestedContracts(placement, siteEnrollment);
    message = 'Contract Requested';
    chipClass = classes.warn;
    avatarClass = classes.warnAvatar;
  }

  return (
    <div>
      <Chip
        avatar={
          <Avatar className={avatarClass}>
            <ContractIcon />
          </Avatar>
        }
        label={message}
        className={chipClass}
      />
      {contractExists &&
        contracts.map(contract => (
          <div key={contract.id}>

            {hasActive && contract.duhsId && (
              <DeepLink
                id={contract.id}
                model="contract"
                chip
                label={contract.duhsId.toString()}
                avatar="ID"
              />
            )}

            {!hasActive && (
              <Chip
                label={contract.ContractStatusCode.name}
                avatar={
                  <Avatar>
                    <WorkflowIcon />
                  </Avatar>
                }
              />
            )}

          </div>
        ))}
      {!contractExists &&
        editable && (
        <CreateContractButton
          variant="outlined"
          size="small"
          organization={Organization}
          placement={placement}
        />
      )}
    </div>
  );
}

export default (withStyles(styles, { name: 'ContractBrief', withTheme: true })(
  ContractBrief
): any);

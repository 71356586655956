
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NotificationsSystem, { atalhoTheme, dismissNotification } from 'reapop';

export const NotificationComponent = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  return (
    <NotificationsSystem
      notifications={notifications}
      dismissNotification={(id) => dispatch(dismissNotification(id))}
      theme={atalhoTheme}
    />
  );
};

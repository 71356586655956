// @flow

import { updateItem } from './methods';

import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR
} from '../actions/types';
import type { Organization, ValueListItem } from '../schemas';
import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Organization>,
  +values: Array<ValueListItem>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_ORGANIZATIONS
    | typeof FETCH_ORGANIZATIONS_SUCCESS
    | typeof FETCH_ORGANIZATIONS_ERROR
    | typeof FETCH_ORGANIZATION
    | typeof FETCH_ORGANIZATION_SUCCESS
    | typeof FETCH_ORGANIZATION_ERROR
    | typeof UPDATE_ORGANIZATION
    | typeof UPDATE_ORGANIZATION_SUCCESS
    | typeof UPDATE_ORGANIZATION_ERROR,
  payload: ?Array<Organization> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
        values: getValues(_.sortBy(action.payload, ['name']))
      };
    case FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case FETCH_ORGANIZATION:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_ORGANIZATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case UPDATE_ORGANIZATION:
      return { ...state, isLoading: true };
    case UPDATE_ORGANIZATION_SUCCESS:
      return { ...state,
        isLoading: false,
        selected: action.payload,
        items: updateItem(state.items, action.payload),
      };
    case UPDATE_ORGANIZATION_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

// @flow

import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR
} from '../actions/types';

import type { Country, ValueListItem } from '../schemas';

import _ from 'lodash';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<Country>,
  +values: Array<ValueListItem>,
};

type Action = {
  type:
    | typeof FETCH_COUNTRIES
    | typeof FETCH_COUNTRIES_SUCCESS
    | typeof FETCH_COUNTRIES_ERROR,
  payload: Array<Country>
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  values: [],
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: _.sortBy(action.payload, ['name']),
        values: getValues(_.sortBy(action.payload, ['name']))
      };
    case FETCH_COUNTRIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const getValues = arr => {
  return _.map(arr, el => {
    return { id: el.id, name: el.name };
  });
};

// @flow

import {
  FETCH_USER_MESSAGES,
  FETCH_USER_MESSAGES_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  FETCH_USER_MESSAGE,
  FETCH_USER_MESSAGE_SUCCESS,
  FETCH_USER_MESSAGE_ERROR
} from '../actions/types';

import type { UserMessage } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: Array<UserMessage>,
  +selected: Object
};

type Action = {
  type:
    | typeof FETCH_USER_MESSAGES
    | typeof FETCH_USER_MESSAGES_SUCCESS
    | typeof FETCH_USER_MESSAGES_ERROR
    | typeof FETCH_USER_MESSAGE
    | typeof FETCH_USER_MESSAGE_SUCCESS
    | typeof FETCH_USER_MESSAGE_ERROR,
  payload: Array<UserMessage> | Object | string
};

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  selected: {}
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_USER_MESSAGES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_USER_MESSAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case FETCH_USER_MESSAGE:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_USER_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload
      };
    case FETCH_USER_MESSAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

import axios from '../../api';
import * as actions from '../types';

export const fetchTouchpointTypes = () => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_TOUCHPOINT_TYPES, payload: undefined });
    const res = await axios.get('/touchpointType');
    dispatch({ type: actions.FETCH_TOUCHPOINT_TYPES_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: actions.FETCH_TOUCHPOINT_TYPES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import React from 'react';
import { Formik, Form, Field } from 'formik';

import { Typography, Grid, Button, FormControlLabel, makeStyles } from '@material-ui/core';

import { Select, Checkbox, TextField } from '../../Formik';
import { organizationValidation } from '../../../validation';

type Props = {
  classes?: Object,
  initialValues: Object,
  associations: Object,
  canUpdate: boolean,
  handleSubmit: Function,
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 320
  },
  formSection: {
    marginBottom: theme.spacing(2)
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const OrganizationForm = (props: Props): React$Element<any> => {
  const {initialValues,canUpdate,associations,handleSubmit} = props;
  const {states,countries} = associations;
  const classes = useStyles();

  return(
    <Formik
      initialValues={initialValues}
      validationSchema={organizationValidation}
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, isSubmitting, isValid }) => (
        <Form style={{ width: '100%' }}>
          <Grid container className={classes.formSection}>
            <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
              Organization Information
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={9} sm={8} xs={12}>
                <Field
                  fullWidth
                  required
                  name="name"
                  label="Name"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="abbreviation"
                  label="Abbreviation"
                  type="text"
                  component={TextField}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.formSection}>
            <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
              Contact Information
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={6} sm={8} xs={12}>
                <Field
                  fullWidth
                  required
                  name="contact"
                  label="Contact"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={6} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="contactTitle"
                  label="Title"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Field
                  fullWidth
                  name="contactEmail"
                  label="Email Address"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="fax"
                  label="Fax"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="website"
                  label="Website"
                  type="text"
                  component={TextField}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.formSection}>
            <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
              Address Information
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={4} sm={8} xs={12}>
                <Field
                  fullWidth
                  required
                  name="address"
                  label="Address"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="addressLine2"
                  label="Address Line 2"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Field
                  fullWidth
                  name="addressLine3"
                  label="Address Line 3"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  required
                  name="city"
                  label="City"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  name="StateId"
                  label="State"
                  options={states}
                  component={Select}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  required={values.CountryId === 'US'}
                  name="postalCode"
                  label="Zip Code"
                  type="text"
                  component={TextField}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Field
                  fullWidth
                  required
                  id="CountryId"
                  name="CountryId"
                  label="Country"
                  options={countries}
                  component={Select}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.formSection}>
            <Typography type="subtitle1" gutterBottom className={classes.groupLabel}>
              Additional Information
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isActive}
                      name="isActive"
                      label="Active?"
                      component={Checkbox}
                    />
                  }
                  label="Active?"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.isDuke}
                      name="isDuke"
                      label="Duke?"
                      component={Checkbox}
                    />
                  }
                  label="Duke?"
                />
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.buttons}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!canUpdate || isSubmitting || !isValid}
            >
              Update Organization
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;

// @flow

import _ from 'lodash';

import * as React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {
  Grid,Select,MenuItem,FormControl,Tooltip,IconButton
} from '@material-ui/core';

import {RevertIcon} from '../../components/Icons';

import {getAllTermValuesWithAttrs} from '../../selectors/terms';

type Props = {
  selectedTermId: number,
  handleSelectedTermIdChange: Function,
  handleResetTerm: Function,
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      margin: theme.spacing(1.9)
    },
    formControl: {
      width: '100%'
    },
    buttonContainer: {
      flex: '0 1 auto'
    },
    selectContainer: {
      flex: '1 0 auto',
      maxWidth: '300px'
    },
    selectedTerm: {
      fontSize: theme.typography.h6.fontSize,
      // fontWeight: theme.typography.h6.fontWeight,
      // fontFamily: theme.typography.h6.fontFamily,
      color: theme.typography.h6.color
    }
  };
});

function TermSelector(props: Props): React$Element<"div"> {
  const {selectedTermId,handleSelectedTermIdChange,handleResetTerm} = props;
  const classes = useStyles();

  const terms = useSelector(getAllTermValuesWithAttrs);

  function renderTermsList() {
    if (terms !== undefined) {
      return _.map(terms, el => (
        <MenuItem key={el.id.toString()} value={el.id}>
          {el.name}
        </MenuItem>
      ));
    }
  }

  return (
    <div className={classes.root}>
      {selectedTermId && <Grid container spacing={2}>
        <Grid className={classes.selectContainer} item xs>
          <FormControl className={classes.formControl}>
            <Select
              value={selectedTermId}
              onChange={e => handleSelectedTermIdChange(e)}
              className={classes.selectedTerm}
            >
              {renderTermsList()}
            </Select>
          </FormControl>
        </Grid>
        <Grid className={classes.buttonContainer} item xs>
          <Tooltip
            id="tooltip-reset-term"
            title="Reset to your Working Term"
          >
            <IconButton
              onClick={() => handleResetTerm()}
              aria-label="Reset"
            >
              <RevertIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>}
    </div>
  );
}

export default TermSelector;

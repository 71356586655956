// @flow

import _ from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { Select, Checkbox, MultiSelect, SelectLookup } from '../Formik/';

import { TextField } from 'formik-material-ui';
import {DatePicker} from '@material-ui/pickers';

import {
  Grid, Button, FormControlLabel, Typography, Divider
} from '@material-ui/core';

import { fetchUsers } from '../../actions/creators/users';
import { fetchStudentsList } from '../../actions/creators/students';

import { getAllTermValuesWithAttrs } from '../../selectors/terms';
import { getAllStudentValues } from '../../selectors/students';
import { getAllUserValues } from '../../selectors/users';

import type { Contract, ValueListItem, User } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  fullWidth: {
    flex: 1
  },
  inputContainer: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
});

type Props = {
  classes: Object,
  initialValues: ?Contract,
  handleSubmit: Function,
  contractTypeOptions: ValueListItem[],
  contractStatusCodeOptions: ValueListItem[],
  termOptions: ValueListItem[],
  userOptions: ValueListItem[],
  studentOptions: ValueListItem[],
  users: User[],
  selectedContract: Contract,
  alternateContracts: Contract[],
  fetchUsers: Function,
  currentValues: any,
  specialRequirementsExists: boolean,
  isAutoRenew: boolean,
  contractId: ?number,
  fetchStudentsList: Function,
};

class EditContractForm extends Component<Props> {

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await this.props.fetchUsers();
    await this.props.fetchStudentsList();
  };

  getUserOptions = () => {
    const students = [];
    _.each(this.props.users, user => {
      const roles = _.map(user.Roles, role => role.slug);
      if (!_.includes(roles, 'student')) {
        students.push(user);
      }
    });
    return students;
  };

  getAlternateContractOptions = () => {
    const { alternateContracts } = this.props;
    return alternateContracts.map(option => {
      return ({ id: option.id , name: `${option.id} | ${moment(option.dateRequested).format('ll')}` });
    });
  };

  render() {
    const {
      classes, termOptions, contractTypeOptions, contractStatusCodeOptions,
      studentOptions,initialValues
    } = this.props;

    const userOptions = this.getUserOptions();
    const alternateContractOptions = this.getAlternateContractOptions();

    return(
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        // validationSchema={organizationValidation} // TODO: add validation
        onSubmit={this.props.handleSubmit}
      >
        {({ setFieldValue,handleBlur,isSubmitting,isValid,values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Typography variant="subtitle1" gutterBottom>
                  Details
                </Typography>
                <Divider className={classes.divider} />
                <Grid container>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="StartTermId"
                      label="Starting Term"
                      options={termOptions}
                      component={Select}
                    />
                    <Field
                      fullWidth
                      name="ContractTypes"
                      label="Contract Types"
                      options={contractTypeOptions}
                      component={MultiSelect}
                    />
                    <Field
                      fullWidth
                      name="duhsId"
                      label="DUHS ID"
                      type="text"
                      component={TextField}
                    />
                    <FormControlLabel
                      control={
                        <Field
                          checked={values.isUrgent}
                          name="isUrgent"
                          label="Mark as Urgent"
                          component={Checkbox}
                        />
                      }
                      label="Mark as Urgent"
                    />
                    <Field
                      fullWidth
                      name="ContractStatusCodeId"
                      label="Status"
                      options={contractStatusCodeOptions}
                      component={Select}
                    />
                    {alternateContractOptions.length > 0 && (<Field
                      fullWidth
                      name="ReplacementContractId"
                      label="Superseded By"
                      options={alternateContractOptions}
                      component={Select}
                    />)}
                  </Grid>
                  <Grid item xs={6} style={{paddingLeft: '20px'}}>
                    <FormControlLabel
                      control={
                        <Field
                          checked={values.isAutoRenew}
                          name="isAutoRenew"
                          label="Auto-Renew"
                          component={Checkbox}
                        />
                      }
                      label="Auto-Renew"
                    />
                    <Field
                      fullWidth
                      required
                      variant="inline"
                      disableToolbar
                      name="startDate"
                      label="Start Date"
                      format="MMMM DD, YYYY"
                      autoOk
                      value={values.startDate}
                      onChange={date => {
                        const value = date !== null ? date : (initialValues?.startDate);
                        setFieldValue('startDate', value);
                      }}
                      onBlur={handleBlur}
                      component={DatePicker}
                    />
                    {!values.isAutoRenew && (<Field
                      fullWidth
                      required
                      variant="inline"
                      disableToolbar
                      name="endDate"
                      label="End Date"
                      format="MMMM DD, YYYY"
                      autoOk
                      value={values.endDate}
                      onChange={date => {
                        const value = date !== null ? date : (initialValues?.endDate);
                        setFieldValue('endDate', value);
                      }}
                      onBlur={handleBlur}
                      component={DatePicker}
                    />)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Contact
                </Typography>
                <Divider className={classes.divider} />
                <Field
                  fullWidth
                  name="contact"
                  label="Name"
                  type="text"
                  component={TextField}
                />
                <Field
                  fullWidth
                  name="contactEmail"
                  label="Email"
                  type="text"
                  component={TextField}
                />
                <Field
                  fullWidth
                  name="contactPhone"
                  label="Phone"
                  type="text"
                  component={TextField}
                />
                <Field
                  fullWidth
                  name="contactFax"
                  label="Fax"
                  type="text"
                  component={TextField}
                />
                <Field
                  fullWidth
                  name="RequestedById"
                  label="Requested By"
                  options={userOptions}
                  component={SelectLookup}
                />

                <Field
                  fullWidth
                  name="StudentId"
                  label="Associated Student"
                  options={studentOptions}  // TODO: load the student async
                  component={SelectLookup}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      checked={values.specialRequirementsExist}
                      name="specialRequirementsExist"
                      label="Has Special Requirements"
                      component={Checkbox}
                    />
                  }
                  label="Has Special Requirements"
                />
                {values.specialRequirementsExist && (<Field
                  fullWidth
                  multiline
                  name="specialRequirementsDescription"
                  label="Special Requirements Description"
                  type="text"
                  component={TextField}
                />)}
              </Grid>
            </Grid>
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                Save Changes
              </Button>
            </div>

          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractTypeOptions: state.contractTypes.values,
    contractStatusCodeOptions: state.contractStatusCodes.values,
    termOptions: getAllTermValuesWithAttrs(state),
    userOptions: getAllUserValues(state),
    studentOptions: getAllStudentValues(state),
    selectedContract: state.contractDrawer.selectedContract,
    alternateContracts: state.contractDrawer.alternateContracts,
    users: state.users.items,
  };
};

export default (compose(
  withStyles(styles, { withTheme: true, name: 'EditContractForm' }),
  connect(mapStateToProps, { fetchUsers, fetchStudentsList })
)(EditContractForm): any);

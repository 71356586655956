// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Typography } from '@material-ui/core';

import SiteDetail from '../../Site/SiteDetail';
import { fetchPermissions } from '../../../actions/creators/permissions';
import { hasAccess } from '../../../utils/permissionsCheck';
import type { Site, User } from '../../../schemas';

type Props = {
  classes: Object,
  site: Site,
  isLoading: boolean,
  currentUser: User,
  permissions: Object,
  fetchPermissions: Function
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
});

class SiteDetails extends Component<Props> {

  canUpdate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  }

  canCreate() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.create);
  }

  canDelete() {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  }

  render() {
    const { classes, site } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          {site ? 'Edit' : 'Create'} Site
        </Typography>
        <SiteDetail
          site={site}
          canCreate={this.canCreate()}
          canUpdate={this.canUpdate()}
          canDelete={this.canDelete()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    site: state.siteDrawer.selectedSite,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.site
  };
};

export default (compose(connect(mapStateToProps, {
  fetchPermissions
}), withStyles(styles))(
  SiteDetails
): any);

// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

import OrganizationPanel from '../Contract/OrganizationPanel';

import { hasAccess } from '../../utils/permissionsCheck';
import EditAddendumForm from './EditAddendumForm';
import WorkflowEntriesPanel from '../WorkflowEntriesPanel';

import {
  updateAddendum,
  deleteAddendum,
  postAddendumWorkflowLogEntry
} from '../../actions/creators/addenda';

import type { Addendum, User } from '../../schemas';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  addendum: Addendum,
  updateAddendum: Function,
  deleteAddendum: Function,
  handleClose: Function,
  classes: Object,
  currentUser: User,
  permissions: Object,
  postAddendumWorkflowLogEntry: Function
};

class AddendaDetail
  extends Component<Props> {
  canUpdate = () => {
    const {currentUser, permissions} = this.props;
    return hasAccess(currentUser.Roles, permissions.update);
  };

  canDelete = () => {
    const {currentUser, permissions} = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  };

  handleSubmit = async values => {
    const {
      addendum,
      updateAddendum,
      handleClose,
      currentUser,
      postAddendumWorkflowLogEntry
    } = this.props;

    if (addendum.AddendumStatusCodeId !== values.AddendumStatusCodeId) {
      let workflowItem = {
        AddendumId: addendum.id,
        UserId: currentUser.id,
        StatusChangedFromId: addendum.AddendumStatusCodeId,
        StatusChangedToId: values.AddendumStatusCodeId,
      };

      await postAddendumWorkflowLogEntry(workflowItem);
    }

    let result = updateAddendum(values);
    if (result) {
      handleClose();
    }
  };

  handleDelete = async () => {
    const {addendum, deleteAddendum, handleClose} = this.props;
    let confirmation = window.confirm(
      'Are you sure you want to delete this addendum?',
    );

    if (confirmation) {
      let result = await deleteAddendum(addendum);
      if (result) {
        handleClose();
      }
    }
  };

  render() {
    const {addendum, classes} = this.props;
    const organization = _.get(addendum, 'Contract.Organization', undefined);
    const logEntries = _.get(addendum, 'AddendumWorkflowLogEntries', undefined);
    return (
      <div className={classes.root}>
        {addendum &&
          <Grid container>
            <Grid item xs={12} sm={8}>
              {this.canUpdate() &&
                <EditAddendumForm
                  initialValues={addendum}
                  handleSubmit={this.handleSubmit}
                  handleDelete={this.handleDelete}
                  canUpdate={this.canUpdate}
                  canDelete={this.canDelete}
                />}
              {!this.canUpdate() &&
                <div>
                  <Typography variant="h6" gutterBottom>
                    GNE Addendum Details
                  </Typography>
                  <Divider className={classes.divider} />

                  <Typography gutterBottom>
                    <strong>Start Term</strong> {addendum.StartTerm.name}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Status</strong> {addendum.AddendumStatusCode.name}
                  </Typography>
                </div>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <OrganizationPanel organization={organization} />
              {logEntries && <WorkflowEntriesPanel entries={logEntries} />}
            </Grid>
          </Grid>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (compose(
  connect(mapStateToProps, {
    updateAddendum,
    deleteAddendum,
    postAddendumWorkflowLogEntry
  }),
  withStyles(styles)
)(AddendaDetail): any);

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import SelectBox from 'devextreme/ui/select_box';

import { DataGrid } from 'devextreme-react';
import { Column, RequiredRule } from 'devextreme-react/data-grid';
import GridDefaults from '../../utils/data-grid-defaults';
import CustomStore from 'devextreme/data/custom_store';

import {
  fetchCourseAttrs, createCourseAttr,
  updateCourseAttr, deleteCourseAttr
} from '../../actions/creators/courseAttrs';
import { fetchCourses } from '../../actions/creators/courses';
import { fetchContractTypes } from '../../actions/creators/contractTypes';

import { getAllCourseAttrs, getAllCourseValues } from '../../selectors/courses';
import { getAllContractTypeValues } from '../../selectors/contracts';

import type { CourseAttr, ValueListItem } from '../../schemas';

type Props = {
  classes: Object,
  courseAttrs: CourseAttr[],
  contractTypes: ValueListItem[],
  courses: ValueListItem[],
  fetchCourses: Function,
  fetchCourseAttrs: Function,
  createCourseAttr: Function,
  updateCourseAttr: Function,
  deleteCourseAttr: Function,
  fetchContractTypes: Function,
}

type State = {
  parentGridInstance?: Object,
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
});

class CourseAttrs extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      parentGridInstance: {},
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    await this.props.fetchContractTypes();
    await this.props.fetchCourses();
    await this.props.fetchCourseAttrs();
  }

  unassignedCourses = () => {
    const { courses, courseAttrs } = this.props;
    const ids = _.map(courseAttrs, 'CatalogNumberId');
    return _.filter(courses, o => !_.includes(ids, o.id));
  };

  editCourseTemplate = (cellElement, cellInfo) => {
    const div = document.createElement('div');
    const colorBox = new SelectBox(div, {
      dataSource: this.unassignedCourses(),
      valueExpr: 'id',
      displayExpr: 'id',
      value: cellInfo.value,
      onValueChanged: e =>
        cellInfo.setValue(e.value),
    });
    cellElement.appendChild(div);
    return colorBox;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" paragraph>
          Course Attributes
        </Typography>
        <DataGrid
          {...GridDefaults}
          id={'masterGrid'}
          wordWrapEnabled={true}
          filterSyncEnabled={true}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          groupPanel={{ visible: true }}
          grouping={{ contextMenuEnabled: true }}
          searchPanel={{ visible: true, width: 180 }}
          dataSource={ new CustomStore({
            key: 'id',
            load: async () =>
              await this.props.courseAttrs,
            insert: values =>
              this.props.createCourseAttr({ ...values }),
            update: (key, values) =>
              this.props.updateCourseAttr({ id: key, ...values }),
            remove: key =>
              this.props.deleteCourseAttr({ id: key }),
          })}
          selection={{ mode: 'none' }}
          editing={{
            mode: 'cell',
            allowUpdating: true,
            allowDeleting: true,
            allowAdding: true
          }}
          export={{
            enabled: true,
            allowExportSelectedData: false,
            fileName: 'course-attributes',
          }}
          noDataText="No Course Attributes..."
          onInitialized={el => {
            this.setState({ parentGridInstance: el.component });
          }}
        >
          <Column
            type={'buttons'}
            buttons={['delete']}
          />
          <Column
            dataField={'CatalogNumberId'}
            caption={'Course'}
            dataType={'string'}
            minWidth={ 120 }
            alignment={'center'}
            sortOrder={'desc'}
            editCellTemplate={this.editCourseTemplate}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'title'}
            caption={'Title'}
            dataType={'string'}
            width={ 240 }
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'minHrs'}
            caption={'Min. Hrs.'}
            dataType={'number'}
            minWidth={ 120 }
            alignment={'center'}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'maxHrs'}
            caption={'Max. Hrs.'}
            dataType={'number'}
            minWidth={ 120 }
            alignment={'center'}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'ContractTypeId'}
            caption={'Contract Type'}
            dataType={'string'}
            minWidth={ 180 }
            lookup={{
              dataSource: {
                store: this.props.contractTypes,
                sort: 'id'
              },
              valueExpr: 'id',
              displayExpr: 'name'
            }}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'clinicalDescr'}
            caption={'Description'}
            dataType={'string'}
            minWidth={ 240 }
          />
          <Column
            dataField={'isClinical'}
            caption={'Clinical?'}
            dataType={'boolean'}
            minWidth={ 120 }
            trueText={'Yes'}
            falseText={'No'}
            allowEditing={false}
            visible={false}
          />
          <Column
            dataField={'isApplicable'}
            caption={'Applicable?'}
            dataType={'boolean'}
            minWidth={ 120 }
            trueText={'Yes'}
            falseText={'No'}
            allowEditing={true}
            visible={true}
          />
        </DataGrid>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  courses: getAllCourseValues(state),
  courseAttrs: getAllCourseAttrs(state),
  contractTypes: getAllContractTypeValues(state),
});

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchCourseAttrs, createCourseAttr, updateCourseAttr, deleteCourseAttr,
    fetchContractTypes, fetchCourses
  })
)(CourseAttrs): any);

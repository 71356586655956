
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import { createLogger } from 'redux-logger';

import rootReducer from '../reducers';

export default function configureStore(preloadedState) {

  const middlewares = [thunkMiddleware];

  if ( process.env.REACT_APP_BASE_API_URL.includes('localhost') ) {
    const logger = createLogger({ collapsed: true });
    middlewares.push(logger);
  }

  const enhancers = composeWithDevTools(
    applyMiddleware(...middlewares),
    persistState(['auth', 'permissions'])
  );

  const store = createStore(rootReducer, preloadedState, enhancers);

  if ( process.env.REACT_APP_BASE_API_URL.includes('localhost') && module.hot ) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(rootReducer)
    );
  }

  return store;
}

// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/creators/auth';
import { Redirect } from 'react-router-dom';

type Props = {
  loginUser: Function,
  location: Object,
};

type State = {
  authenticated: boolean,
}

class Auth extends Component<Props, State> {

  constructor () {
    super();
    this.state = {
      authenticated: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authenticated !== state.authenticated) {
      return { authenticated: props.authenticated };
    }

    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const qToken = query.get('token');
    if (qToken) {
      const token = JSON.parse(qToken);
      this.props.loginUser(token);
    }
  }

  render() {
    const { authenticated } = this.state;

    return authenticated
      ? <Redirect to="/" />
      : <span>Loading Dashboard...</span>;
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.isLoggedIn,
  };
};

export default (connect(mapStateToProps, { loginUser })(Auth): any);

// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { AppInfo as Info } from '../schemas';

type Props = {
  appInfo: Info,
  classes: Object
};

const renderEnvironment = (mode, classes) => {
  return mode === 'production' ? (
    ''
  ) : (
    <div className={classes.environment}>{mode}</div>
  );
};

const styles = theme => ({
  appInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.text.hint,
    fontSize: theme.typography.body2.fontSize
  },
  name: {
    textTransform: 'capitalize',
    paddingBottom: theme.spacing(1) + 'px'
  },
  version: {
    paddingBottom: theme.spacing(1) + 'px'
  },
  environment: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(1) + 'px'
  }
});

const AppInfo = (props: Props) => {
  const { name, version, mode } = props.appInfo;
  const { classes } = props;
  return (
    <div className={classes.appInfo}>
      <div className={classes.name}>{name}</div>
      <div className={classes.version}>{version}</div>
      {renderEnvironment(mode, classes)}
    </div>
  );
};

export default (withStyles(styles, { withTheme: true })(AppInfo): any);

// @flow

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core';

import { MenuIcon, VerifiedUserIcon } from '../components/Icons';
import CurrentUserSettingsPopover from './User/CurrentUserSettingsPopover';
import CurrentUserMessages from './User/CurrentUserMessages';

import type { Auth } from '../schemas';

type Props = {
  handleDrawerToggle: Function,
  mobileOpen: boolean,
  auth: Auth,
  classes: Object,
  handleEditSettings: Function,
  handleEndMasquerade: Function
};

type State = {
  anchorEl?: Object
};

const drawerWidth = 240;

const styles = theme => ({
  root: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1
  },
  accountMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  accountMenuItem: {
    margin: '0 10px'
  },
  navLink: {
    color: theme.palette.primary.contrastText
  }
});

class AppBarComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined
    };
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ anchorEl: undefined });
  };

  handleLogout() {}

  render() {
    const { classes, auth, handleEndMasquerade } = this.props;

    let appBarClassName = `${classes.root} ${classes.appBarShift}`;
    return (
      <AppBar className={appBarClassName}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.props.handleDrawerToggle}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          {auth.isLoggedIn && (
            <div className={classes.accountMenu}>
              <CurrentUserMessages
                currentUser={auth.currentUser}
              />
              <CurrentUserSettingsPopover />
              <VerifiedUserIcon  className={classes.accountMenuItem} />
              <Typography
                variant="subtitle1"
                color="inherit"
                noWrap
                className={classes.accountMenuItem}
              >
                {auth.currentUser.name}
              </Typography>
              {auth.isMasquerading && (
                <Button
                  color="inherit"
                  onClick={() => handleEndMasquerade()}
                  className={classes.accountMenuItem}
                >
                  End Masquerade
                </Button>
              )}
              <Button
                color="inherit"
                href="/logout"
                className={classes.accountMenuItem}
              >
                Logout
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default (withStyles(styles, {
  withTheme: true,
  name: 'AppBarComponent'
})(AppBarComponent): any);

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Button, Typography } from '@material-ui/core';

import PlacementSlotList from '../../../components/PlacementDrawer/PlacementSlots/PlacementSlotList';
import PlacementDrawerWorkspace from '../../../components/PlacementDrawer/PlacementDrawerWorkspace';
import type { Placement } from '../../../schemas';

import { changeSection } from '../../../actions/creators/placementDrawer';
import { findSection } from '../../../utils/placementDrawer';

import PlacementSlotDropzone from '../../../components/PlacementDrawer/PlacementSlots/PlacementSlotDropzone';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    ...theme.typography.h5,
    marginRight: theme.spacing(1)
  },
  subheading: theme.typography.subheading,
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  content: {
    flex: 3
  },
  sidebar: {
    flex: 1,
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1)
  },
  message: {
    marginTop: theme.spacing(2)
  }
});

type Classes = {
  root: Object,
  title: Object,
  subheading: Object,
  container: Object,
  content: Object,
  sidebar: Object,
  paper: Object,
  button: Object,
  message: Object
};

type Props = {
  classes: Classes,
  placement: Placement,
  changeSection: Function,
  isLoading: boolean
};

const hasPackage = placement => {
  return placement && placement.Package;
};

const hasEnrollments = placement => {
  return (
    hasPackage(placement) &&
    placement.Package.SiteEnrollments &&
    placement.Package.SiteEnrollments.length > 0
  );
};

class PlacementSlotsSection extends Component<Props> {
  renderList() {
    const { placement, classes } = this.props;

    if (!hasEnrollments(placement)) {
      return (
        <div className={classes.message}>
          <Typography gutterBottom>
            There are no sites attached to this placement.<br /> Please search
            for a site and add it to the placement.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.props.changeSection(findSection('site_search'));
            }}
          >
            Search for Sites
          </Button>
        </div>
      );
    } else {
      return (
        <PlacementSlotList
          editable={true}
          displayOnly={false}
          items={placement.Package.SiteEnrollments}
        />
      );
    }
  }

  render() {
    const { classes, placement } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <span className={classes.title}>Placement Slots</span>
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            <PlacementSlotDropzone />
            {placement && this.renderList()}
          </div>

          <div className={classes.sidebar}>
            <PlacementDrawerWorkspace />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placement: state.placementDrawer.selectedPlacement
  };
};

export default (compose(
  connect(mapStateToProps, { changeSection }),
  withStyles(styles)
)(PlacementSlotsSection): any);

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FileDownload from 'js-file-download';
import axios from '../../api';
import _ from 'lodash';

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';

import { DeleteIcon } from '../../components/Icons';

import { deleteAttachment } from '../../actions/creators/attachments';
import { hasAccess } from '../../utils/permissionsCheck';

import type { Attachment, User } from '../../schemas';

type Props = {
  attachment: Attachment,
  deleteAttachment: Function,
  currentUser: User,
  permissions: Object,
  editable: boolean
};

class AttachmentItem extends Component<Props> {
  canDelete = () => {
    const { currentUser, permissions } = this.props;
    return hasAccess(currentUser.Roles, permissions.delete);
  };
  downloadFile = async () => {
    const { attachment } = this.props;
    const endpoint = `/attachment/${attachment.id}/download`;
    let { data } = await axios.get(endpoint, { responseType: 'blob' });
    FileDownload(data, attachment.attachment);
  };
  handleDelete = () => {
    let confirmation = window.confirm(
      'Are you sure you want to delete this attachment?'
    );
    if (confirmation) {
      const { attachment, deleteAttachment } = this.props;
      deleteAttachment(attachment);
    }
  };
  render() {
    const { attachment } = this.props;

    const showDelete = _.get(this.props, 'editable', true);

    return (
      <ListItem button onClick={this.downloadFile}>
        <ListItemText
          primary={attachment.name}
          secondary={moment(attachment.createdAt).format('ll')}
        />
        {showDelete &&
          this.canDelete() && (
          <ListItemSecondaryAction>
            <IconButton onClick={this.handleDelete}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contract
  };
};

export default (connect(mapStateToProps, { deleteAttachment })(AttachmentItem): any);

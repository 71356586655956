import * as Yup from 'yup';

export const userValidation = Yup.object().shape({
  name: Yup.string()
    .max(52, 'Name cannot be larger than 52 characters long.')
    .min(3, 'Name must be at least 3 characters long.')
    .required('Name is required.'),
  username: Yup.string()
    .email('Invalid Email Address.')
    .required('Email Address is required.'),
  Roles: Yup.array()
    .min(1, 'At least one role is required.')
    .required('Role is required.'),
});

export const roleValidation = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name must be at least 3 characters long.'),
});

export const specialtyValidation = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name must be at least 3 characters long.'),
  code: Yup.string()
    .required('Code is required.')
    .min(2, 'Code must be at least 2 characters long.'),
  isActive: Yup.boolean(),
});

export const termValidation = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  TermAttr: Yup.object({
    startDate: Yup.date().required('Start Date is required.'),
    endDate: Yup.date().required('End Date is required.'),
    numberOfWeeks: Yup.number().notRequired(),
    placementStartDate: Yup.date().required('Placement Start Date is required.'),
    placementEndDate: Yup.date().required('Placement End Date is required.'),
    approvalDeadline: Yup.date().required('Approval Deadline is required.'),
    isActive: Yup.boolean().notRequired(),
  })
});

export const placementAttachementAValidation = Yup.object().shape({
  startDate: Yup.date().required('Start Date is required.'),
  endDate: Yup.date().required('End Date is required.'),
  willSend: Yup.boolean(),
});

export const attachementAValidation = Yup.object().shape({
  StartTermId: Yup.number().required('Start Term is required.'),
  CourseId: Yup.number().required('Course is required.'),
  ContractId: Yup.number().required('Contract is required.'),
  SiteId: Yup.number().required('Site is required.'),
  startDate: Yup.date().required('Start Date is required.'),
  endDate: Yup.date().required('End Date is required.'),
  willSend: Yup.boolean(),
});

export const todoValidation = Yup.object().shape({
  description: Yup.string()
    .min(3, 'Description must be at least 3 characters long.')
    .required('Description is required.'),
  step: Yup.number(),
  sendNotification: Yup.boolean(),
});

export const touchpointValidation = Yup.object().shape({
  TouchpointTypeId: Yup.number()
    .required('Type is required.'),
  note: Yup.string()
    .min(3, 'Notes must be at least 3 characters long.')
    .required('Notes is required.'),
});

export const noteValidation = Yup.object().shape({
  description: Yup.string()
    .min(3, 'Notes must be at least 3 characters long.')
    .required('Notes is required.'),
});

export const addendumValidation = Yup.object().shape({
  AddendumStatusCodeId: Yup.string()
    .required('Status is required.'),
});

export const amendmentCreateValidation = Yup.object().shape({
  description: Yup.string()
    .min(3, 'Description must be at least 3 characters long.')
    .required('Description is required.'),
});

export const amendmentEditValidation = Yup.object().shape({
  AmendmentStatusCodeId: Yup.string()
    .required('Status is required.'),
  description: Yup.string()
    .min(3, 'Description must be at least 3 characters long.')
    .required('Description is required.'),
});

export const preceptorValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  credentials: Yup.string().nullable(),
  Specialties: Yup.array(),
  Sites: Yup.array(),
  previousSpecialty: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  fax: Yup.string().nullable(),
  pager: Yup.string().nullable(),
  emailAddress: Yup.string().nullable(),
  address: Yup.string().required('Address is required.'),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().required('City is required.'),
  StateId: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('State is required in the US.')
  }),
  postalCode: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('Postal Code is required in the US.')
  }),
  county:  Yup.string().nullable(),
  CountryId: Yup.string().nullable().required('Country is required.'),
  isAhecEligible: Yup.boolean(),
  ahecLegacy: Yup.string().nullable(),
  license: Yup.string().nullable(),
  npiNumber: Yup.string().nullable(),
  certifications: Yup.string().nullable(),
  preceptor: Yup.string().nullable(),
  lastActiveSemester: Yup.string().nullable(),
  isActive: Yup.boolean(),
  activeLegacy: Yup.string().nullable(),
});

export const applicationValidation = Yup.object().shape({
  maidenName: Yup.string().nullable(),
  employer: Yup.string().nullable(),
  licensures: Yup.string().nullable(),
  activeMilitary: Yup.boolean().nullable(),
  ogachi: Yup.boolean().nullable(),
  TermId: Yup.number().required('Term is required.'),
  CourseId: Yup.number().required('Course is required.'),
  courseHours: Yup.number().required('Course Hours are required.'),
  Specialties: Yup.array().nullable(),
  travel1: Yup.string().nullable(),
  travel2: Yup.string().nullable(),
  travel3: Yup.string().nullable(),
  PlacementAppDay: Yup.object().nullable(),
  PlacementAppShift: Yup.object().nullable(),
});

export const locationSearchValidation = Yup.object().shape({
  searchKey: Yup.string().required('Search By is required.'),
  searchPostalCode: Yup.string().when('searchKey', {
    is: 'postal_code',
    then: Yup.string().required('Postal Code is required.'),
    otherwise: Yup.string(),
  }),
  searchCity: Yup.string().when('searchKey', {
    is: 'city',
    then: Yup.string().required('City is required.'),
    otherwise: Yup.string(),
  }),
  searchState: Yup.string().when('searchKey', {
    is: (val) => val === 'city' || val === 'state',
    then: Yup.string().required('State is required.'),
    otherwise: Yup.string(),
  }),
  searchRadius: Yup.number().when('searchKey', {
    is: 'postal_code',
    then: Yup.number().required('Radius is required.'),
    otherwise: Yup.number(),
  }),
});

export const siteValidation = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  SiteTypeId: Yup.string().nullable()
    .required('Site Type is required.'),
  contact: Yup.string().nullable(),
  contactTitle: Yup.string().nullable(),
  contactEmail: Yup.string().nullable(),
  address: Yup.string().nullable()
    .required('Address is required.'),
  addressLine2: Yup.string().nullable(),
  addressLine3: Yup.string().nullable(),
  city: Yup.string().nullable()
    .required('City is required.'),
  postalCode: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('Zip Code is required in the US.')
  }),
  CountyId: Yup.number().nullable(),
  CountryId: Yup.string().nullable().required('Country is required.'),
  phone: Yup.string().nullable(),
  fax: Yup.string().nullable(),
  website: Yup.string().nullable(),
  isRural: Yup.boolean().nullable(),
  isUnderserved: Yup.boolean().nullable(),
  isAhec: Yup.boolean().nullable(),
  Specialties: Yup.array().nullable(),
  siteRequirements: Yup.string().nullable(),
  OrganizationId: Yup.number().nullable()
    .required('Organization is required.'),
  StateId: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('State is required in the US.')
  }),
});

export const organizationValidation = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  contact: Yup.string()
    .required('Contact Name is required.'),
  address: Yup.string()
    .required('Address is required.'),
  city: Yup.string()
    .required('City is required.'),
  postalCode: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('Zip Code is required in the US.')
  }),
  CountryId: Yup.string()
    .required('Country is required.'),
  StateId: Yup.string().nullable().when('CountryId', {
    is: value => value === 'US',
    then: Yup.string().required('State is required in the US.')
  }),
});

export const createPlacementValidation = Yup.object().shape({
  StudentId: Yup.number()
    .required('Student is required.'),
  postalCode: Yup.string()
    .min(5, 'Must be at least 5 characters')
    .required('Postal Code is required.'),
  TermId: Yup.number()
    .required('Term is required.'),
  CourseId: Yup.number()
    .required('Course is required.'),
});

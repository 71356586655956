// @flow

import _ from 'lodash';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';

import {Typography,Grid} from '@material-ui/core';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import GridDefaults from '../../../utils/data-grid-defaults';

import notify from 'devextreme/ui/notify';

import {createPlacement} from '../../../actions/creators/placements';
import {fetchPlacementStatuses} from '../../../actions/creators/placementStatuses';
import {fetchPlacementOrigins} from '../../../actions/creators/placementOrigins';
import {
  getAllPlacementStatusValues,getAllPlacementOriginValues
} from '../../../selectors/placements';


type Props = {|
  data: any[],
|};

const PlacementAuditGrid = ({ data }: Props): React$Element<any> => {
  const dispatch = useDispatch();

  const [dataGridInstance, setDataGridInstance] = React.useState({});
  const [plansWoPlacements, setPlansWoPlacements] = React.useState(0);
  const [placementsWoPlans, setPlacementsWoPlans] = React.useState(0);

  const placementStatuses = useSelector(getAllPlacementStatusValues);
  const placementOrigins = useSelector(getAllPlacementOriginValues);

  React.useEffect(() => {
    Promise.all([
      dispatch(fetchPlacementStatuses()),
      dispatch(fetchPlacementOrigins()),

    ]);
    // @see https://github.com/facebook/create-react-app/issues/6880#issuecomment-486640921
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    processCounts();
  });

  const dataSource = new DataSource({ store: data, reshapeOnPush: true });

  const getRowByType = (rowData, type) => { // [0,1], [0]
    let index = 0;

    if (type === 'placement' && rowData.length === 2) index = 1;
    const row = _.get(rowData, `[${index}]`, null);
    if (row && row.type === type) return row;

    return null;
  };

  const handleCreatePlacement = async props => {
    const { row: { data } } = props;
    const row = data[0];
    const placementValues = {
      StudentId: row.StudentId,
      CourseId: row.CourseId,
      TermId: row.TermId,
      PlacementOriginId: 3,
      postalCode: row.Student ? row.Student.mailingPostalCode : undefined
    };
    await dispatch(createPlacement(placementValues));

    const toastSettings = {
      message: `The Placement for ${row.Student.fullName} was Created.`,
      type: 'success',
      closeOnClick: true,
      position: 'bottom center'
    };

    return notify(toastSettings);
  };

  const createVisible = props => {
    const { row: { data } } = props;
    return data.length !== 2 && data[0].type !== 'placement';
  };

  const processCounts = () => {
    const plans = _.chain(data).filter(o => o.length < 2).flatten()
      .filter(o => o.type === 'matriculation').value();
    const placements = _.chain(data).filter(o => o.length < 2).flatten()
      .filter(o => o.type === 'placement').value();
    setPlansWoPlacements(plans.length);
    setPlacementsWoPlans(placements.length);
  };

  window.onresize = () =>
    dataGridInstance.updateDimensions();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          gutterBottom={true}
          variant="body2"
          color="primary"
          display="inline"
        >{plansWoPlacements}</Typography>{' '}
        <Typography
          gutterBottom={true}
          variant="caption"
          color="textSecondary"
        >Matriculation Plans w/o Placements,</Typography>{' '}
        <Typography
          gutterBottom={true}
          variant="body2"
          color="primary"
          display="inline"
        >{placementsWoPlans}</Typography>{' '}
        <Typography
          gutterBottom={true}
          variant="caption"
          color="textSecondary"
        >Placements w/o Matriculation Plans</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          {...GridDefaults}
          dataSource={dataSource}
          selection={{ mode: 'none' }}
          groupPanel={{ visible: true }}
          grouping={{ contextMenuEnabled: true }}
          loadPanel={{ enabled: true }}
          allowColumnResizing={true}
          columnAutoWidth={true}
          width="100%"
          onInitialized={el => setDataGridInstance(el.component)}
        >
          <Column type="buttons">
            <Button
              text="Create"
              icon="repeat"
              hint="Create new placement"
              onClick={handleCreatePlacement}
              visible={createVisible}
            />
          </Column>
          <Column
            name="record.id"
            caption="Record ID"
            dataType="number"
            calculateCellValue={rowData => {
              const matriculationRow = getRowByType(rowData, 'matriculation');
              const placementRow = getRowByType(rowData, 'placement');
              const row = matriculationRow ? matriculationRow : placementRow;
              return _.get(row, 'id', null);
            }}
            alignment="center"
            visible={false}
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="record.type"
            caption="Record Type"
            dataType="string"
            calculateCellValue={rowData => {
              const matriculationRow = getRowByType(rowData, 'matriculation');
              const placementRow = getRowByType(rowData, 'placement');
              const row = matriculationRow ? matriculationRow : placementRow;
              return _.get(row, 'type', null);
            }}
            lookup={{
              dataSource: () => [
                {id: 'matriculation', name: 'Matriculation'},
                {id: 'placement', name: 'Placement'}
              ],
              valueExpr: 'id',
              displayExpr: 'name'
            }}
            alignment="center"
            visible={false}
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="matriculation.student.name"
            caption="Student Name"
            dataType="string"
            calculateCellValue={rowData => {
              const matriculationRow = getRowByType(rowData, 'matriculation');
              const placementRow = getRowByType(rowData, 'placement');
              const row = matriculationRow ? matriculationRow : placementRow;
              return _.get(row, 'Student.fullName', null);
            }}
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="matriculation.course.number"
            caption="Course Number"
            dataType="number"
            calculateCellValue={rowData => {
              const matriculationRow = getRowByType(rowData, 'matriculation');
              const placementRow = getRowByType(rowData, 'placement');
              const row = matriculationRow ? matriculationRow : placementRow;
              return _.get(row, 'Course.CatalogNumberId', null);
            }}
            alignment="center"
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="matriculation.term.name"
            caption="Academic Term"
            dataType="string"
            calculateCellValue={rowData => {
              const matriculationRow = getRowByType(rowData, 'matriculation');
              const placementRow = getRowByType(rowData, 'placement');
              const row = matriculationRow ? matriculationRow : placementRow;
              return _.get(row, 'Term.name', null);
            }}
            visible={false}
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="placement.placementOrigin"
            caption="Placement Origin"
            dataType="string"
            calculateCellValue={rowData => {
              const row = getRowByType(rowData, 'placement');
              return _.get(row, 'PlacementOriginId', null);
            }}
            lookup={{
              dataSource: () => placementOrigins,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
            alignment="center"
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="placement.placementStatusCode"
            caption="Placement Status"
            dataType="string"
            calculateCellValue={rowData => {
              const row = getRowByType(rowData, 'placement');
              return _.get(row, 'PlacementStatusCodeId', null);
            }}
            lookup={{
              dataSource: () => placementStatuses,
              valueExpr: 'id',
              displayExpr: 'name'
            }}
            alignment="center"
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="matriculation.exists"
            caption="Matriculation Plan Exists"
            dataType="boolean"
            calculateCellValue={rowData => {
              const row = getRowByType(rowData, 'matriculation');
              return Boolean(row);
            }}
            trueText="Yes"
            falseText="No"
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
          <Column
            name="placement.exists"
            caption="Placement Exists"
            dataType="boolean"
            calculateCellValue={rowData => {
              const row = getRowByType(rowData, 'placement');
              return Boolean(row);
            }}
            trueText="Yes"
            falseText="No"
            allowFiltering={true}
            allowGrouping={true}
            allowSorting={true}
            allowSearch={true}
          />
        </DataGrid>
      </Grid>
    </Grid>
  );
};

export default PlacementAuditGrid;

import axios from '../../api';
import * as actions from '../types';
import { notify } from 'reapop';

export const createNote = note => async dispatch => {
  try {
    let { data } = await axios.post(`/note`, note);
    dispatch({ type: actions.CREATE_NOTE_SUCCESS });
    dispatch(
      notify({
        title: 'Success',
        message: 'Note successfully created.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not create note.',
        status: 'error',
      })
    );
  }
};

export const updateNote = note => async dispatch => {
  try {
    let { data } = await axios.put(`/note/${note.id}`, note);
    dispatch({ type: actions.UPDATE_NOTE_SUCCESS });
    dispatch(
      notify({
        title: 'Success',
        message: 'Note successfully updated.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not update note.',
        status: 'error',
      })
    );
  }
};

export const deleteNote = note => async dispatch => {
  try {
    let { data } = await axios.delete(`/note/${note.id}`);
    dispatch({ type: actions.DELETE_NOTE_SUCCESS });
    dispatch(
      notify({
        title: 'Success',
        message: 'Note successfully deleted.',
        status: 'success',
      })
    );
    return data;
  } catch (err) {
    dispatch(
      notify({
        title: 'Error',
        message: 'Could not delete note.',
        status: 'error',
      })
    );
  }
};

export const fetchContractNotes = ContractId => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_CONTRACT_NOTES, payload: undefined });
    const res = await axios.get(`/note/ContractRequest/${ContractId}`);
    dispatch({
      type: actions.FETCH_CONTRACT_NOTES_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_CONTRACT_NOTES_ERROR,
      payload: error.response.data
    });
  }
};

// @flow

import _ from 'lodash';

import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

import {fetchOrganization,updateOrganization} from '../../../actions/creators/organizations';
import {fetchCountries} from '../../../actions/creators/countries';
import {fetchStates} from '../../../actions/creators/states';

import {getSelectedOrganization} from '../../../selectors/organizations';
import {getAllCountryValues} from '../../../selectors/countries';
import {getAllStateValues} from '../../../selectors/states';

import {useCached} from '../../../hooks';
import {hasAccess} from '../../../utils/permissionsCheck';

import OrganizationForm from './OrganizationForm';

type Props = {
  id: number,
  model: string,
  handleClose: Function
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const OrganizationDialog = (props: Props): React$Element<React$FragmentType> => {
  const {id,model,handleClose} = props;
  const dispatch = useDispatch();

  const organization = useSelector(getSelectedOrganization);
  const permissions = useSelector(state => state.permissions.organization);
  const currentUser = useSelector(state => state.auth.currentUser);

  const states = useCached(useSelector(getAllStateValues));
  const countries = useCached(useSelector(getAllCountryValues));

  let haveAssociations = Boolean(states.length && countries.length);

  useEffect(() => {
    dispatch(fetchOrganization({ id: id }));
  }, [dispatch,id]);

  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchStates());
      dispatch(fetchCountries());
    }
  }, [dispatch,haveAssociations]);

  const handleSubmit = async values => {
    await dispatch(updateOrganization(values));
    handleClose(true);
  };

  const canUpdate = hasAccess(currentUser.Roles, permissions.update);
  const objectMatch = _.get(organization, 'id', undefined) === id;

  return (
    <React.Fragment>
      {(!objectMatch || !haveAssociations) &&  <DialogTitle id={`${model}-${id}-dialog`}>
        <LinearProgress variant="query" color="primary" />
      </DialogTitle>}
      {objectMatch && organization && haveAssociations && <DialogTitle id={`${model}-${id}-dialog`} onClose={() => handleClose()}>
      Organization: {organization.name}
      </DialogTitle>}
      {objectMatch && haveAssociations && <DialogContent dividers>
        {<OrganizationForm
          initialValues={organization}
          canUpdate={canUpdate}
          handleSubmit={handleSubmit}
          associations={{states,countries}}
        />}
      </DialogContent>}
    </React.Fragment>
  );
};

export default OrganizationDialog;

// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

import {
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  IntegratedSelection,
  SelectionState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import AmendmentStatusCodeFormatter from '../../components/AmendmentStatusCode/AmendmentStatusCodeFormatter';
import AmendmentDetail from '../../components/Amendment/AmendmentDetail';

import type { Amendment, User, ValueListItem } from '../../schemas';
import _ from 'lodash';

import SelectFilterCellBase from '../../components/ReactGrid/SelectFilterCellBase';

import { fetchAmendments } from '../../actions/creators/amendments';

import { fetchAmendmentStatusCodes } from '../../actions/creators/amendmentStatusCodes';
import { fetchPermissions } from '../../actions/creators/permissions';

import LoadingIndicator from '../../components/LoadingIndicator';


const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  drawer: {
    minHeight: '50vh'
  },
  appBar: {
    flexGrow: 1,
    height: 64
  },
  flex: {
    flex: 1
  },
});

type Props = {
  classes: Object,
  amendments: Amendment[],
  currentUser: User,
  permissions: Object,
  fetchAmendments: Function,
  fetchAmendmentStatusCodes: Function,
  amendmentStatusCodes: ValueListItem[],
  fetchPermissions: Function,
  dataLoading: boolean,
  colSpan: number
};

type State = {
  columns: any[],
  defaultFilters: any[],
  defaultCurrentPage: number,
  defaultPageSize: number,
  defaultSorting: any[],
  pageSizes: number[],
  filteringStateColumnExtensions: any[],
  selection: any[],
  showDrawer: boolean,
  selectedAmendment: ?Amendment,
  rows: Amendment[],
  loading: boolean
};

class Amendments extends Component<Props, State> {
  state = {
    columns: [
      { name: 'id', title: 'ID' },
      {
        name: 'organization',
        title: 'Organization/Site',
        getCellValue: row =>
          _.get(row, 'Contract.Organization.name', 'No Data Available')
      },
      {
        name: 'ContractId',
        title: 'Contract ID'
      },
      {
        name: 'status',
        title: 'Status',
        getCellValue: row => {
          return _.get(row, 'AmendmentStatusCodeId', 'No Data Available');
        }
      },
      {
        name: 'description',
        title: 'Description'
      }
    ],
    defaultFilters: [],
    defaultCurrentPage: 0,
    defaultPageSize: 10,
    defaultSorting: [{ columnName: 'id', direction: 'desc' }],
    pageSizes: [10, 20, 50, 100, 250],
    filteringStateColumnExtensions: [],
    selection: [],
    selectedAmendment: undefined,
    showDrawer: false,
    rows: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.amendments !== state.rows) {
      return { rows: props.amendments };
    }
    if (props.dataLoading !== state.loading) {
      return { loading: props.dataLoading };
    }

    return null;
  }

  loadData = async () => {
    await this.props.fetchAmendments();
    await this.props.fetchAmendmentStatusCodes();
    await this.props.fetchPermissions();
  };

  handleSelection = selection => {
    const { amendments } = this.props;
    const selectedAmendment = amendments[selection];

    this.setState({ showDrawer: true, selectedAmendment });
  };

  handleClose = () => {
    this.setState({
      selection: [],
      selectedAmendment: undefined,
      showDrawer: false
    });
  };

  filterCell = props => {
    if (props.column.name === 'status') {
      const optionItems = this.props.amendmentStatusCodes;
      const newProps = { ...props, optionItems };
      return <SelectFilterCellBase {...newProps} />;
    }

    return <TableFilterRow.Cell {...props} />;
  };

  render() {
    const { classes } = this.props;
    const {
      columns,
      defaultFilters,
      defaultCurrentPage,
      defaultPageSize,
      defaultSorting,
      pageSizes,
      filteringStateColumnExtensions,
      selection,
      selectedAmendment,
      showDrawer,
      rows,
      loading
    } = this.state;

    function NoDataCell(props) {
      const { colSpan } = props;
      return (
        <Table.Cell colSpan={colSpan}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <h3 className={classes.cellCenter}>No data available...</h3>
          )}
        </Table.Cell>
      );
    }

    return (
      <div className={classes.root}>
        <Typography paragraph variant="h5" component="h1">
          Amendments
        </Typography>
        <Paper>
          <AppBar position="static" className={classes.appBar} />
          <Grid rows={rows} columns={columns}>
            <FilteringState
              defaultFilters={defaultFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              defaultCurrentPage={defaultCurrentPage}
              defaultPageSize={defaultPageSize}
            />
            <SortingState defaultSorting={defaultSorting} />
            <SelectionState
              selection={selection}
              onSelectionChange={this.handleSelection}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />

            <DataTypeProvider
              for={['status']}
              formatterComponent={AmendmentStatusCodeFormatter}
            />

            <Table noDataCellComponent={props => NoDataCell(props)} />
            <TableHeaderRow showSortingControls />

            <TableFilterRow cellComponent={props => this.filterCell(props)} />
            <TableSelection
              selectByRowClick
              showSelectionColumn={false}
              highlightRow
            />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <Drawer
          open={showDrawer}
          anchor="bottom"
          onClose={this.handleClose}
          className={classes.drawer}
        >
          {selectedAmendment && (
            <AmendmentDetail
              handleClose={this.handleClose}
              amendment={selectedAmendment}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    amendments: state.amendments.items,
    currentUser: state.auth.currentUser,
    permissions: state.permissions.contracts,
    amendmentStatusCodes: state.amendmentStatusCodes.values,
    dataLoading: _.some([
      state.amendments.isLoading,
      state.amendmentStatusCodes.isLoading,
      state.permissions.isLoading,
    ]),
  };
};

export default (compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchAmendments,
    fetchAmendmentStatusCodes,
    fetchPermissions
  })
)(Amendments): any);

// @flow

import _ from 'lodash';
import moment from 'moment';

import * as React from 'react';
import {useDispatch,useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';

import {
  Table,TableBody,TableHead,TableFooter,TableRow,TableCell,IconButton,
  Tooltip,Fab,Paper
} from '@material-ui/core';

import {
  AnonymousAvatarIcon,PlacementIcon,ContractIcon,SuccessIcon,
  MessageIcon,AddendaIcon,AmendmentIcon,ApplicationIcon,
  AttachmentAIcon,DeleteIcon,SystemIcon
} from '../../components/Icons';

import {
  fetchUserMessages,updateUserMessage,readAllUserMessages
} from '../../actions/creators/userMessages';

import {getCurrentUser} from '../../selectors/users';

import {stripHtml} from '../../utils/helpers';

type Props = {
  messages: Object,
  variant: string,
  handleMessageOpen: Function,
  handleAlertOpen: Function,
};

const useStyles = makeStyles(theme => {
  return {
    table: {
      tableLayout: 'auto'
    },
    head: {
      borderBottom: '1px solid'
    },
    cellLeft: {
      borderTopLeftRadius: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 700
    },
    cellRight: {
      borderTopRightRadius: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {},
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      }
    },
    senderCell: {
      display: 'flex',
      alignContent: 'space-between',
      whiteSpace: 'nowrap'
    },
    senderCellItem: {
      display: 'flex',
      alignSelf: 'stretch',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    paper: {
      marginBottom: theme.spacing(3)
    },
  };
});

function UserMessageTable(props: Props): React$Element<"div"> {
  const {messages,variant,handleMessageOpen,handleAlertOpen} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  function handleReadMessages(message) {
    if (!message) dispatch(readAllUserMessages(messages));
    else if (!message.read) {
      message.read = true;
      dispatch(updateUserMessage(message));
    }

    dispatch(fetchUserMessages(currentUser));
  }

  function renderIconForTag(props) {
    const ObjectId = props.message.match(/#([1-9]\d*)/i)[1];
    switch (props.tag) {
      case 'addenda':
        return <Tooltip id="addenda-tag" title="Addenda">
          <Fab size="small" color="primary">
            <AddendaIcon />
          </Fab>
        </Tooltip>;
      case 'amendment':
        return <Tooltip id="amendment-tag" title="Amendment">
          <Fab size="small" color="primary">
            <AmendmentIcon />
          </Fab>
        </Tooltip>;
      case 'contract':
        return <Tooltip id="contract-tag" title="Goto Contract">
          <Fab href={`/contracts?ID=${ObjectId}`} size="small" color="primary">
            <ContractIcon />
          </Fab>
        </Tooltip>;
      case 'placement':
        return <Tooltip id="placement-tag" title="Placement">
          <Fab size="small" color="primary">
            <PlacementIcon />
          </Fab>
        </Tooltip>;
      case 'application':
        return <Tooltip id="application-tag" title="Application">
          <Fab size="small" color="primary">
            <ApplicationIcon />
          </Fab>
        </Tooltip>;
      case 'attachment-a':
        return <Tooltip id="attachment-a-tag" title="Goto AttachmentA">
          <Fab href={`/contracts/attachmentAs?ID=${ObjectId}`} size="small" color="primary">
            <AttachmentAIcon />
          </Fab>
        </Tooltip>;
      case 'system':
        return <Tooltip id="system-tag" title="System">
          <Fab size="small" color="primary">
            <SystemIcon />
          </Fab>
        </Tooltip>;
      default:
        return <Tooltip id="default-tag" title="No tag provided">
          <Fab size="small" color="primary">
            <MessageIcon />
          </Fab>
        </Tooltip>;
    }
  }

  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.head}>
            <TableCell className={classes.cellLeft} colSpan={3}>
              <h3>{_.startCase(variant)} Messages ({ messages.length })</h3>
            </TableCell>
            <TableCell className={classes.cellRight}>
              {variant === 'unread' && messages.length > 0 ? (
                <IconButton
                  onClick={() => handleReadMessages()}
                  aria-label="Mark all messages as read"
                >
                  <Tooltip id="mark-all-message-read" title="Mark all messages as read">
                    <SuccessIcon />
                  </Tooltip>
                </IconButton>) : null}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {messages.length === 0 &&
          <TableRow className={classes.row}>
            <TableCell colSpan={4}>
              You have no {variant} messages.
            </TableCell>
          </TableRow>}
          {messages.length > 0 && _.map(messages, message => (
            <TableRow className={classes.row} key={message.id}>
              <TableCell>
                {renderIconForTag(message)}
              </TableCell>
              <TableCell onClick={() => handleMessageOpen(message)}>
                {message.message.length > 64 ? stripHtml(message.message).substring(0, 64) + '...' : stripHtml(message.message)}
              </TableCell>
              <TableCell>
                <div className={classes.senderCell}>
                  <Tooltip id="sender-tag" title={message.Sender ? message.Sender.username : 'System'}>
                    <AnonymousAvatarIcon />
                  </Tooltip> &nbsp;
                  <span className={classes.senderCellItem}>
                    {moment(message.createdAt).fromNow()}
                  </span>
                </div>
              </TableCell>
              <TableCell>
                {
                  !message.read
                    ? <IconButton
                      onClick={() => handleReadMessages(message)}
                      aria-label="Mark message as read"
                    >
                      <Tooltip id="mark-message-read" title="Mark message as read">
                        <SuccessIcon />
                      </Tooltip>
                    </IconButton>
                    : <IconButton
                      onClick={() => handleAlertOpen(message)}
                      color="secondary"
                      aria-label="Delete message"
                    >
                      <Tooltip id="delete-message" title="Delete message">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </Paper>
  );
}

export default UserMessageTable;

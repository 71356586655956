// @flow

import {
  ContractIcon,
  AmendmentIcon,
  AttachmentIcon,
  AttachmentAIcon,
  NoteIcon,
  TodoIcon
} from '../components/Icons';
export default [
  {
    slug: 'details',
    label: 'Contract',
    icon: ContractIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'amendments',
    label: 'Amendments',
    icon: AmendmentIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'attachments',
    label: 'Attachments',
    icon: AttachmentIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'attachment_a',
    label: 'Attachments A',
    icon: AttachmentAIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'notes',
    label: 'Notes',
    icon: NoteIcon,
    allowedRoles: ['*']
  },
  {
    slug: 'todos',
    label: 'Todos',
    icon: TodoIcon,
    allowedRoles: ['*']
  }
];

// @flow

import _ from 'lodash';
import moment from 'moment';

import React, {useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import {Typography,AppBar,Tabs,Tab} from '@material-ui/core';

import {LoadPanel} from 'devextreme-react/load-panel';
import DataGrid, {Column} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';


import {fetchContracts} from '../../actions/creators/contracts';
import {fetchContractTypes} from '../../actions/creators/contractTypes';
import {fetchTerms} from '../../actions/creators/terms';

import {
  showContractDrawer,fetchSelectedContract
} from '../../actions/creators/contractDrawer';
import {fetchContractAddenda} from '../../actions/creators/addenda';
import {fetchContractAmendments} from '../../actions/creators/amendments';
import {fetchContractStatusCodes} from '../../actions/creators/contractStatusCodes';
import {fetchContractNotes} from '../../actions/creators/notes';
import {fetchContractTodos} from '../../actions/creators/todos';
import {fetchContractAttachments} from '../../actions/creators/attachments';
import {fetchContractAttachmentAs} from '../../actions/creators/attachmentAs';
import {fetchPermissions} from '../../actions/creators/permissions';

import {
  getAllContractTypeValues,getAllContracts,getAllContractStatusCodeValues
} from '../../selectors/contracts';
import {getAllTermValuesWithAttrs} from '../../selectors/terms';

import DataGridDefaults from '../../utils/data-grid-defaults';
import ContractDrawer from '../../components/ContractDrawer/ContractDrawer';

type Props = {
  classes: Object
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(2)
  },
  paper: {
    flexGrow: 1,
  },
  appBar: {
    flexGrow: 1,
    height: 64
  },
});

const Contracts: any = withStyles(styles)((props: Props) => {
  const {classes} = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filteredContracts, setFilteredContracts] = useState(undefined);
  const [filter, setFilter] = useState('request');

  const contracts = useSelector(getAllContracts);
  const contractTypes = useSelector(getAllContractTypeValues);
  const contractStatusCodes = useSelector(getAllContractStatusCodeValues);
  const terms = useSelector(getAllTermValuesWithAttrs);

  let haveAssociations = Boolean(
    contractStatusCodes.length && contractTypes.length && terms.length
  );

  const handleFilterContracts = () => {
    const filtered = _.filter(contracts, {contractGroup: filter});
    setFilteredContracts(filtered);
  };

  const handleRowClick = async data => {
    if(data.rowType === 'data') {
      const item = data.data;
      setLoading(true);
      dispatch(fetchContractAddenda(item.id));
      dispatch(fetchContractAmendments(item.id));
      dispatch(fetchContractNotes(item.id));
      dispatch(fetchContractTodos({id: item.id}));
      dispatch(fetchContractAttachments(item.id));
      dispatch(fetchContractAttachmentAs(item.id));
      await dispatch(fetchSelectedContract(item.id));
      setLoading(false);

      return dispatch(showContractDrawer());
    }
    return data;
  };

  const handleEditorPreparing = e => {
    if (e.parentType === 'filterRow' && e.editorName === 'dxSelectBox' && e.dataType !== 'boolean') {
      e.editorOptions.onOpened = (e) => e.component._popup.option('width', 200);
    }
  };

  useEffect(() => { dispatch(fetchContracts()); }, [dispatch]);
  useEffect(() => {
    if (!haveAssociations) {
      dispatch(fetchContractTypes());
      dispatch(fetchContractStatusCodes());
      dispatch(fetchPermissions());
      dispatch(fetchTerms());
    }
  }, [dispatch,haveAssociations]);

  // eslint-disable-next-line
  useEffect(() => { handleFilterContracts(); }, [filter,contracts]);

  const dataGridOpts = {
    ...DataGridDefaults,
    dataSource: new DataSource({ store: filteredContracts }),
    wordWrapEnabled: true,
    filterSyncEnabled: true,
    repaintChangesOnly: true,
    columnAutoWidth: true,
    loadPanel: { enabled: true },
    groupPanel: { visible: true },
    grouping: { contextMenuEnabled: true },
    searchPanel: { visible: true, width: 180 },
    editing: { mode: 'none' },
    selection: { mode: 'none' },
    noDataText: 'No Data...',
    export: {
      enabled: true,
      excelFilterEnabled: true,
      allowExportSelectedData: false,
      fileName: 'contracts',
    },
    onRowClick: handleRowClick,
    onEditorPreparing: handleEditorPreparing,
  };

  return (
    <div className={classes.root}>
      <LoadPanel visible={loading} shadingColor="rgba(0,0,0,0.5)" />
      <Typography paragraph variant="h5" component="h1">
        Contracts
      </Typography>
      <AppBar position="static" className={classes.appBar}>
        <Tabs value={filter} onChange={(e,value) => setFilter(value)}>
          <Tab value="request" label="Contract Requests" />
          <Tab value="active" label="Active Contracts" />
          <Tab value="inactive" label="Inactive Contracts" />
        </Tabs>
      </AppBar>
      <DataGrid {...dataGridOpts}>
        <Column
          name={'isUrgent'}
          dataField={'isUrgent'}
          caption={'Urgent'}
          dataType={'boolean'}
          trueText={'Yes'}
          falseText={'No'}
          visible={false}
        />
        <Column
          name={'duhsId'}
          dataField={'duhsId'}
          caption={'DUHS ID'}
          dataType={'number'}
          alignment={'center'}
        />
        <Column
          name={'id'}
          dataField={'id'}
          caption={'Record ID'}
          dataType={'number'}
          alignment={'center'}
        />
        <Column
          name={'organizationName'}
          dataField={'Organization.name'}
          caption={'Organization/Site'}
          dataType={'string'}
          width={200}
        />
        <Column
          name={'organizationCity'}
          dataField={'Organization.city'}
          caption={'City'}
          dataType={'string'}
        />
        <Column
          name={'organizationState'}
          dataField={'Organization.StateId'}
          caption={'State'}
          dataType={'string'}
          alignment={'center'}
        />
        <Column
          name={'contractStatusCode'}
          dataField={'ContractStatusCode.id'}
          caption={'Status'}
          dataType={'string'}
          width={120}
          lookup={{
            dataSource: {
              store: contractStatusCodes,
              sort: 'id'
            },
            valueExpr: 'id',
            displayExpr: 'name'
          }}
        />
        <Column
          name={'contractTypes'}
          dataField={'ContractTypes'}
          caption={'Type(s)'}
          dataType={'string'}
          calculateCellValue={rowData => {
            const values = _.get(rowData, 'ContractTypes', []);
            if (values.length) {
              return _.reduce(values, (acc, value) => {
                const id = value.ContractContractType.ContractTypeId;
                const option = _.find(contractTypes, { id: id });
                if (acc.length) return acc+= `, ${option?.name}`;
                else return acc+= ` ${option?.name}`;
              }, '');
            }
            return '';
          }}
        />
        <Column
          name={'dateRequested'}
          dataField={'dateRequested'}
          caption={'Requested'}
          dataType={'date'}
        />
        <Column
          name={'startTerm'}
          dataField={'StartTermId'}
          caption={'Start Term'}
          dataType={'string'}
          lookup={{
            dataSource: {
              store: terms,
              sort: 'id'
            },
            valueExpr: 'id',
            displayExpr: 'name'
          }}
          sortIndex={0}
          sortOrder={'asc'}
        />
        <Column
          name={'startDate'}
          dataField={'startDate'}
          caption={'Start'}
          dataType={'date'}
        />
        <Column
          name={'endDate'}
          dataField={'endDate'}
          caption={'End'}
          dataType={'date'}
          calculateDisplayValue={row => {
            if (row.isAutoRenew) return 'Auto Renew';
            if (!row.endDate) return '';
            return moment(row.endDate).format('M/D/YYYY');
          }}
        />
      </DataGrid>
      <ContractDrawer />
    </div>
  );
});

export default Contracts;

// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import {
  Typography,
  Grid,
  Paper,
  Divider
} from '@material-ui/core';

import type { Organization } from '../../schemas';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
});

type Props = {
  classes: Object,
  organization: Organization
};

class OrganizationPanel extends Component<Props> {
  render() {
    const { classes, organization } = this.props;
    const address2 = _.get(organization, 'addressLine', undefined);
    const address3 = _.get(organization, 'addressLine3', undefined);

    return (
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Organization
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Divider className={classes.divider} />
            <Typography>
              <strong>{organization.name}</strong>
            </Typography>
            <Typography>{organization.address}</Typography>
            {address2 && <Typography>{address2}</Typography>}
            {address3 && <Typography>{address3}</Typography>}
            <Typography>
              {organization.city} {organization.StateId} ,{' '}
              {organization.postalCode}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Contact
            </Typography>
            <Divider className={classes.divider} />
            {organization.contact && (
              <Typography>
                <strong>Name: </strong>
                {organization.contact}
              </Typography>
            )}
            {organization.contactTitle && (
              <Typography>
                <strong>Title: </strong>
                {organization.contactTitle}
              </Typography>
            )}
            {organization.contactEmail && (
              <Typography>
                <strong>Email: </strong>
                <a href={`mailto:${organization.contactEmail}`}>
                  {organization.contactEmail}
                </a>
              </Typography>
            )}
            {organization.phone && (
              <Typography>
                <strong>Phone: </strong>
                {organization.phone}
              </Typography>
            )}
            {organization.fax && (
              <Typography>
                <strong>Fax: </strong>
                {organization.fax}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default (withStyles(styles)(OrganizationPanel): any);

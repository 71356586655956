// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { Drawer, IconButton } from '@material-ui/core';

import navItems from '../../config/organizationDrawerNavigationItems';
import { hideOrganizationDrawer } from '../../actions/creators/organizationDrawer';
import { CloseIcon } from '../Icons';

import OrganizationDrawerNavigation from './OrganizationDrawerNavigation';
import OrganizationDrawerSections from './OrganizationDrawerSections';

import type { Organization } from '../../schemas';

type Props = {
  selectedOrganization: Organization,
  isVisible: boolean,
  hideOrganizationDrawer: Function,
  classes: Object,
  currentSection: Object
};

const styles = theme => ({
  root: {
    overflow: 'hidden',
    minHeight: '50vh'
  },
  content: {
    marginLeft: '64px',
    paddingLeft: theme.spacing(2),
    minHeight: '50vh',
    overflow: 'scroll'
  },
  button: {
    margin: theme.spacing(1),
    float: 'right'
  },
});

class OrganizationDrawer extends Component<Props> {
  render() {
    const {
      classes,
      isVisible,
      hideOrganizationDrawer,
      currentSection
    } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          anchor="bottom"
          open={isVisible}
          onClose={hideOrganizationDrawer}
        >
          <OrganizationDrawerNavigation items={navItems} />
          <div className={classes.content}>
            <IconButton
              aria-label="Close"
              onClick={hideOrganizationDrawer}
              className={classes.button}
            >
              <CloseIcon />
            </IconButton>
            <OrganizationDrawerSections currentSection={currentSection} />
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.organizationDrawer
  };
};

export default (compose(
  connect(mapStateToProps, { hideOrganizationDrawer }),
  withStyles(styles, { name: 'OrganizationDrawer', withTheme: true })
)(OrganizationDrawer): any);

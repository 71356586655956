// @flow
import * as React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {DropTarget} from 'react-dnd';

import {List,AutoSizer} from 'react-virtualized';

import * as DNDTypes from '../../schemas/dndTypes';
import CommonStyles from '../../styles/CommonStyles';
import {userCanMovePlacement} from '../../utils/placementHelpers';
import {updateSelectedPlacement} from '../../actions/creators/placements';

import type {User,Permissions} from '../../schemas';

const useStyles = makeStyles(theme => {
  const commonLaneStyles = CommonStyles(theme);
  return {
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: 0
      },
      '&:first-child': {
        marginLeft: 0
      },
      width: 300,
      paddingBottom: 13
    },
    row: {
      overflowY: 'hidden',
      height: 145,
      padding: theme.spacing(1) + 'px',
      boxSizing: 'border-box'
    },
    list: commonLaneStyles.lane,
    listCanDrop: commonLaneStyles.listCanDrop,
    listActive: commonLaneStyles.listActive
  };
});

type Props = {
  data: Object,
  classes: Object,
  connectDropTarget: Function,
  key: number,
  children: any,
  onCardClick: Function,
  selectedTermId: number,
  isOver: boolean,
  canDrop: boolean,
  currentUser: User,
  permissions: Permissions
};

// Drag-and-drop spec/collect functions needed for the DragSource
const laneDropTarget = {
  drop(props,monitor) {
    const {
      data,currentUser,permissions,updateSelectedPlacement,selectedTermId
    } = props;

    const placementCard = monitor.getItem();
    let result = { dropped: false, id: placementCard.index };

    if (placementCard.id) {
      if (
        userCanMovePlacement(
          currentUser,placementCard.PlacementStatusCodeId,data.id,permissions
        )
      ) {
        updateSelectedPlacement({
          id: placementCard.id,
          PlacementStatusCodeId: data.id
        }, selectedTermId);

        result.dropped = true;
      }
    }

    return result;
  },
  canDrop(props,monitor) {
    const {currentUser,permissions} = props;
    const sourceStatusId = monitor.getItem().PlacementStatusCodeId;
    const targetStatusId = props.data.id;
    return userCanMovePlacement(
      currentUser,sourceStatusId,targetStatusId,permissions
    );
  }
};

function collect(connect,monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    isOver: monitor.isOver(),
    workspaceItem: monitor.getItem()
  };
}

function PlacementLane(props: Props) {
  const {
    data,key,children,onCardClick,connectDropTarget,isOver,canDrop
  } = props;
  const classes = useStyles();

  const isActive = (isOver && canDrop);

  let listClass = 'list';
  if (isActive) listClass = 'listActive';
  else if (canDrop) listClass = 'listCanDrop';

  const renderChild = (style,key,index) => {
    const card = data.cards[index];
    let PlacementCard = React.Children.only(children);
    let placementCard = React.cloneElement(PlacementCard, {
      ...card,onCardClick,index
    });

    return (
      <div className={classes.row} style={style} key={card.id}>
        {placementCard}
      </div>
    );
  };

  const renderRow = ({style,key,index}) =>
    renderChild(style,key,index);

  return connectDropTarget(
    <div key={key} className={classes.root}>
      <h3>{data.title}</h3>
      <AutoSizer disableWidth>
        {({ height }) => (
          <List
            className={classes[listClass]}
            width={300}
            height={height - 50}
            rowCount={data.cards.length}
            rowHeight={145}
            rowRenderer={renderRow}
          />
        )}
      </AutoSizer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedTermId: state.placements.selectedTermId,
    currentUser: state.auth.currentUser,
    permissions: state.permissions
  };
}

export default (compose(
  connect(mapStateToProps, { updateSelectedPlacement }),
  DropTarget(DNDTypes.PLACEMENT_BOARD_PLACEMENT, laneDropTarget, collect)
)(PlacementLane): any);

// @flow

import * as React from 'react';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox';
import { FieldProps } from 'formik';

type FieldPropTypes = typeof FieldProps;
type MuiCheckboxPropTypes = typeof MuiCheckboxProps;
type CheckboxProps = {
  ...FieldPropTypes,
  ...MuiCheckboxPropTypes
}

const Checkbox: React.ComponentType<CheckboxProps> = ({
  field,
  ...props
}) => (
  <MuiCheckbox
    {...props}
    {...field}
    value={field.value ? 'checked' : ''}
  />
);

Checkbox.displayName = 'FormikMaterialUICheckbox';

export default Checkbox;

// @flow

import _ from 'lodash';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { touchpointValidation } from '../../validation';

import {
  Button, MenuItem, ListItemText,
} from '@material-ui/core';

import { fetchTouchpointTypes } from '../../actions/creators/touchpointTypes';

type Props = {
  classes: Object,
  touchpointTypes: any[],
  initialValues: any[],
  handleSubmit: Function,
  handleClose: Function,
  fetchTouchpointTypes: Function
};

const styles = theme => ({
  formSection: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  groupLabel: {
    paddingTop: theme.spacing(2)
  }
});

class TouchpointForm extends Component<Props> {

  componentDidMount() {
    this.props.fetchTouchpointTypes();
  }

  render() {
    return(
      <Formik
        validateOnMount={true}
        initialValues={this.props.initialValues}
        validationSchema={touchpointValidation}
        onSubmit={values => this.props.handleSubmit(values)}
      >
        {({isSubmitting,isValid}) => (
          <Form>
            <Field
              fullWidth
              required
              select
              name="TouchpointTypeId"
              label="Type"
              component={TextField}
            >
              {_.map(this.props.touchpointTypes, el => (
                <MenuItem key={el.id} value={el.id}>
                  <ListItemText primary={el.name} />
                </MenuItem>
              ))}
            </Field>
            <Field
              fullWidth
              required
              multiline
              rows={2}
              type="text"
              name="note"
              label="Notes"
              component={TextField}
            />
            <div style={{ padding: '2em 0 1em' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                Create Touchpoint
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    touchpointTypes: state.touchpointTypes.values
  };
};

export default (compose(
  connect(mapStateToProps, { fetchTouchpointTypes }),
  withStyles(styles),
)(TouchpointForm): any);

// @flow

import {
  FETCH_STASH,
  FETCH_STASH_SUCCESS,
  FETCH_STASH_ERROR,
  UPDATE_STASH,
  UPDATE_STASH_SUCCESS,
  UPDATE_STASH_ERROR
} from '../actions/types';

import type { SiteEnrollment } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: SiteEnrollment[]
};

type Action = {
  type:
    | typeof FETCH_STASH
    | typeof FETCH_STASH_SUCCESS
    | typeof FETCH_STASH_ERROR
    | typeof UPDATE_STASH
    | typeof UPDATE_STASH_SUCCESS
    | typeof UPDATE_STASH_ERROR,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_STASH:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STASH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_STASH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case UPDATE_STASH:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_STASH_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_STASH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

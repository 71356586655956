import axios from '../../api';
import * as actions from '../types';

export const fetchStudentPlacements = student => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENT_PLACEMENTS, payload: undefined });
    const res = await axios.get(`/placement/student/${student.id}`);
    dispatch({
      type: actions.FETCH_STUDENT_PLACEMENTS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENT_PLACEMENTS_ERROR,
      payload: error.response.data
    });
  }
};

export const fetchStudentPlacement = placement => async dispatch => {
  try {
    dispatch({ type: actions.FETCH_STUDENT_PLACEMENT, payload: undefined });
    const res = await axios.get(`/placement/${placement.id}`);
    dispatch({
      type: actions.FETCH_STUDENT_PLACEMENT_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actions.FETCH_STUDENT_PLACEMENT_ERROR,
      payload: error
    });
  }
};

export const updateStudentPlacement = placement => async dispatch => {
  try {
    const endpoint = `/placement/${placement.id}`;
    await axios.put(endpoint, placement);
    dispatch({ type: actions.UPDATE_STUDENT_PLACEMENT_SUCCESS });
    dispatch(fetchStudentPlacements({ id: placement.StudentId }));
  } catch (error) {
    dispatch({
      type: actions.UPDATE_STUDENT_PLACEMENT_ERROR,
      payload: error.response.data
    });
  }
};

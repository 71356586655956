// @flow

import {
  FETCH_USER_SETTING_TYPES,
  FETCH_USER_SETTING_TYPES_ERROR,
  FETCH_USER_SETTING_TYPES_SUCCESS
} from '../actions/types';

import type { UserSettingType } from '../schemas';

type State = {
  +isLoading: boolean,
  +error: string,
  +items: UserSettingType[]
};

type Action = {
  type:
    | typeof FETCH_USER_SETTING_TYPES
    | typeof FETCH_USER_SETTING_TYPES_ERROR
    | typeof FETCH_USER_SETTING_TYPES_SUCCESS,
  payload: any
};

const initialState = {
  isLoading: false,
  error: '',
  items: []
};

export default (state: State = initialState, action: Action): Function => {
  switch (action.type) {
    case FETCH_USER_SETTING_TYPES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_USER_SETTING_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case FETCH_USER_SETTING_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
